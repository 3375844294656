import { DropdownOption } from '../FilterDropdown';
import { FilterOption, State, ValueType } from '../hooks/useFilters';

export const valueAsLabel = (value: ValueType) => value;

export const dropdownValueLabel =
  (options: DropdownOption[]) => (value: ValueType) =>
    options.find(
      (option) =>
        option.value === value ||
        option.value === parseInt(value as string, 10),
    )?.label;

export const currentLocationWithQueryParams = (state: State) => {
  const url = new URL(window.location.href);
  url.searchParams.delete('page');

  Object.entries(state.options).forEach(([_key, { paramKey, multiValue }]) => {
    const value = state.filters[paramKey];
    if (multiValue) paramKey += '[]';
    url.searchParams.delete(paramKey);

    if (value) {
      if (Array.isArray(value)) {
        value.forEach((v) => url.searchParams.append(paramKey, v as string));
      } else {
        url.searchParams.set(paramKey, value as string);
      }
    }
  });

  return url.toString();
};

export const filtersFromQueryParams = (filterOptions: FilterOption[]) => {
  const url = new URL(window.location.href);

  return filterOptions.reduce(
    (filters, { multiValue, paramKey, numericValue }) => {
      if (multiValue) {
        const value = url.searchParams.getAll(`${paramKey}[]`);
        if (value.length) {
          filters[paramKey] = numericValue
            ? value.map((v) => parseInt(v, 10))
            : value;
        }
      } else {
        let value = url.searchParams.get(paramKey);
        if (value) {
          filters[paramKey] = numericValue ? parseInt(value, 10) : value;
        }
      }

      return filters;
    },
    {},
  );
};

export const filtersToDisplay = (selectedFilters: { [key: string]: any; }, setForm: (arg0: any) => any, form: any) => {
  const filters = [];

  for (const key in selectedFilters) {
    const item = selectedFilters[key];

    if (key != "include_archived") {
      if (item !== undefined && item !== null) {
        if (key === "date_range") {
          filters.push({value: key, label: item, onRemove: () => setForm({...form, start_day: "", end_day: ""})});
        } else {
        filters.push({value: key, label: item, onRemove: () => setForm({...form, [key]: undefined})});
        }
      }
    }
  }
  return filters;
};

export const findLabel = (options: any[], value: any) => {
  const option = options.find(option => option.value == value);
  return option ? option.label : undefined;
};

export const updateTags = (tags: any[], setForm: (arg0: any) => void, form: any, setSelectedFilters: (arg0: any) => void, selectedFilters: any) => {
  let formTags = tags.map(tag => tag.value).join(",");
  let tagLabels = tags.map(tag => tag.label).join(", ");
  setForm({...form, tags: formTags});
  setSelectedFilters({...selectedFilters, tags: tagLabels});
}
