import React, { useState, useEffect, useRef, Fragment } from 'react';
import Loader from "react-loader-spinner";
import FloatingLabelInput from "../../../common/components/FloatingLabelInput";
import FormSelect from "../../../common/components/FormSelect";
import DateRangeField from "../../../common/components/DateRangeField";
import AsyncMultiSelect from '../../../common/components/AsyncMultiSelect';
import SideBar, { Body } from "../../../common/components/SearchFilters/SideBar";
import { FilterChipsContainer } from "../../../common/components/SearchFilters/FilterArea";
import { filtersToDisplay, findLabel, updateTags } from '../../../common/components/SearchFilters/helpers/utils';

const SearchFilters = ({
  testGroup,
  params,
  locationOptions,
  laneOptions,
  consentedToFilterOptions,
  selectedTags,
  tagOptionsUrl,
  appointmentsStatusOptions,
 }) => {
  const [form, setForm] = useState({
    include_archived: "false",
    q: params.q,
    appointment_slot_group_id: params.appointment_slot_group_id,
    test_lane: params.test_lane,
    tested: params.tested,
    consented_to: params.consented_to,
    tags: params.tags,
    archived_status: params.archived_status,
    start_day: params.start_day,
    end_day: params.end_day,
  });
  const initialForm = useRef(form);
  const [isExpanded, setIsExpanded] = useState(false);
  const [searchInput, setSearchInput] = useState(params.q);
  const [searchInput2, setSearchInput2] = useState(params.q);
  const [isLoading, setIsLoading] = useState(false);
  const testedOptions = [{label: "Administered", value: "true"}, {label: "Not Administered", value: "false"}];

  const filterParticipants = () => {
    setIsLoading(true);
    const query = {};
    Object.keys(form).forEach(key => {
      if (form[key]) {
        query[key] = form[key];
      }
    });
    const url = `/test_groups/${testGroup.slug}/participants?` + new URLSearchParams(query).toString();
    window.location.href = url
  }

  const toggleIsExpanded = () => {
    setIsExpanded(!isExpanded);
  }

  const [selectedFilters, setSelectedFilters] = useState({
    q: params.q,
    appointment_slot_group_id: findLabel(locationOptions, params.appointment_slot_group_id),
    test_lane: findLabel(laneOptions, params.test_lane),
    tested: findLabel(testedOptions, params.tested),
    consented_to: findLabel(consentedToFilterOptions, params.consented_to),
    tags: selectedTags && selectedTags.map(tag => tag.friendly_name).join(", "),
    archived_status: findLabel(appointmentsStatusOptions, params.archived_status),
    date_range: params.start_day && params.end_day ? `${params.start_day} - ${params.end_day}` : undefined,
  });

  const updateForm = (key, value, label) => {
    setForm({...form, [key]: value});
    setSelectedFilters({...selectedFilters, [label]: label});
  }

  const updateDateRange = (start, end) => {
    setForm({...form, start_day: start.format("YYYY-MM-DD"), end_day: end.format("YYYY-MM-DD")});
    let range = params.start_day && params.end_day ? `${params.start_day} - ${params.end_day}` : undefined;
    setSelectedFilters({...selectedFilters, date_range: range});
  }

  const clearAll = () => {
    setForm({});
    setSelectedFilters({});
  }

  const MainFilters = ({containerClass}) => (
    <>
      <div className={containerClass}>
        <FormSelect
          fieldName="appointment_slot_group_id"
          prompt="Location"
          options={locationOptions}
          selected={form.appointment_slot_group_id}
          onChange={(id) => updateForm("appointment_slot_group_id", id, findLabel(locationOptions, id))}
        />
      </div>
      <div className={containerClass}>
        <DateRangeField
          values={[form.start_day, form.end_day]}
          start_day_field_name= "start_day"
          end_day_field_name= "end_day"
          placeholder= "Date range"
          date_format= "MM/DD"
          onChange={(start, end) => updateDateRange(start, end)}
          clearDate={() => setForm({...form, start_day: "", end_day: ""})}
        />
      </div>
      <div className={containerClass}>
        <FormSelect
          fieldName= "tested"
          prompt= "Status"
          options= {testedOptions}
          selected= {form.tested}
          onChange={(value) => updateForm("tested", value, findLabel(testedOptions, value))}
        />
      </div>
    </>
  );

  useEffect(() => {
    if (initialForm.current !== form) filterParticipants();
  }, [form]);

  return (
    <Fragment>
      {isLoading && (
        <div className="spinner-container">
          <Loader
            type="ThreeDots"
            color="#2862FA"
            height={200}
            width={200}
          />
        </div>
      )}
      <div className="d-sm-block d-md-flex align-items-center flex-wrap mb-3">
        <FloatingLabelInput
          label="Search by name, email, or phone"
          value={searchInput}
          onChange={(e) => setSearchInput(e.target.value)}
          onKeyPress={(e) => e.key === "Enter" && updateForm('q', e.target.value, e.target.value)}
          onKeyDown={(e) => e.key === "Tab" && updateForm('q', e.target.value, e.target.value)}
          ariaLabel="Search by name, email, or phone"
          className="d-sm-block flex-md-fill mb-3 mb-md-0"
          style={{minWidth: "305px"}}
          prependIcon="search"
        />
        <MainFilters containerClass="d-sm-block d-md-inline-block mb-3 mb-md-0 ms-md-3" />
        <a
          className="text-primary inter semibold mx-1 mx-md-3"
          type="button"
          data-bs-toggle="offcanvas"
          data-bs-target="#filterSideBar"
          aria-controls="filterSideBar"
          aria-label="Toggle filters"
          onClick={toggleIsExpanded}
        >
          <i className="fa-regular fa-bars-filter"></i>
        </a>
        <SideBar
          title="Filters"
          id="filterSideBar"
          placement="end"
          extraClasses="transition-none"
        >
          <Body>
            <FloatingLabelInput
              label="Search by name, email, or phone"
              value={searchInput2}
              onChange={(e) => setSearchInput2(e.target.value)}
              onKeyPress={(e) => e.key === "Enter" && updateForm('q', e.target.value, e.target.value)}
              onKeyDown={(e) => e.key === "Tab" && updateForm('q', e.target.value, e.target.value)}
              ariaLabel="Search by name, email, or phone"
              className="d-sm-block flex-md-fill mb-3 mb-md-0"
              style={{minWidth: "305px"}}
              prependIcon="search"
            />
            <MainFilters containerClass="my-3" />
            <div className="my-3">
              <FormSelect
                fieldName= "test_lane"
                prompt= "Lane"
                options={laneOptions}
                selected={form.test_lane}
                onChange={(value) => updateForm("test_lane", value, findLabel(laneOptions, value))}
              />
            </div>
            <div className="my-3">
              <FormSelect
                fieldName= "consented_to"
                prompt= "Consent"
                options={consentedToFilterOptions}
                selected={form.consented_to}
                onChange={(value) => updateForm("consented_to", value, findLabel(consentedToFilterOptions, value))}
              />
            </div>
            <div className="my-3">
              <AsyncMultiSelect
                name= "Tags"
                selected={selectedTags || []}
                optionsUrl={tagOptionsUrl}
                onSelect={(value) => updateTags(value, setForm, form, setSelectedFilters, selectedFilters)}
              />
            </div>
            <div className="my-3">
              <FormSelect
                fieldName= "archived_status"
                prompt= "Appointment status"
                options={appointmentsStatusOptions}
                selected={form.archived_status}
                onChange={(value) => updateForm("archived_status", value, findLabel(appointmentsStatusOptions, value))}
              />
            </div>
          </Body>
        </SideBar>
      </div>
      {filtersToDisplay(selectedFilters, setForm, form).length > 0 && (
        <FilterChipsContainer
          displayFilters={filtersToDisplay(selectedFilters, setForm, form)}
          clearAll={clearAll}
        />
      )}
    </Fragment>
  )
 };
export default SearchFilters;
