import React from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { Section } from '../../components/styled';

export function FooterButtons({
  stepForward,
  stepBackward,
  skipInstructions,
  isMobile,
  t,
  contactSupport,
  currentStep,
  completeKitActivation,
  instructions,
  currentInstruction,
}) {
  if (currentStep === 'reviewInstructionsStep') {
    return (
      <Section className="py-0">
        <div style={{ marginBottom: '56px' }}>
          <Row className="d-flex justify-content-end">
            <Col xs="auto">
              <Button onClick={stepForward} data-test-hook="submit_review_instructions">
                {t('test_strip.kit_flow.button_1')}
              </Button>
            </Col>
          </Row>
        </div>
      </Section>
    );
  }

  if (currentStep === 'contentsStep') {
    return (
      <Section className="py-0 mb-3">
        <div
          className="d-flex justify-content-center"
          style={{ minHeight: 'min-content', padding: '12px 0' }}
        >
          <Button
            onClick={() => contactSupport(true)}
            variant="danger"
            block={isMobile}
          >
            {t('no')}
          </Button>
          <Button
            onClick={stepForward}
            className="ms-3"
            block={isMobile}
            data-test-hook="submit_contents"
          >
            {t('yes')}
          </Button>
        </div>
      </Section>
    );
  }

  if (currentStep === 'instructionsStep') {
    return (
      <Section className="py-0">
        <div className={`${isMobile ? '' : 'kit-footer'}`}>
          <Row
            style={{ maxWidth: '770px', margin: '24px auto 24px' }}
            className="d-flex justify-content-end"
          >
            {!isMobile && instructions.length !== 1 && ( 
              <Col xs="auto">
                <Button
                  className="kit-flow header-btn mt-3"
                  variant="link"
                  onClick={skipInstructions}
                >
                  {t('test_strip.kit_flow.button_6')}
                </Button>
              </Col>
            )}
            <Col sm="auto">
              <Button
                onClick={stepForward}
                data-test-hook={`submit_instructions_${currentInstruction}`}
                className={`${isMobile && 'btn-block mb-3'}`}
              >
                {t('stepper.next')}
              </Button>
            </Col>
          </Row>
        </div>
      </Section>
    );
  }

  if (currentStep === 'packagingStep') {
    return (
      <Section className="py-0">
        <div style={{ marginBottom: '56px' }}>
          <Row
            className={`${
              isMobile
                ? `d-flex justify-content-center`
                : `d-flex justify-content-end`
            }`}
          >
            <Col xs="auto" className={`${isMobile ? 'd-none' : ''}`}>
              <Button
                className="kit-flow header-btn mt-3"
                variant="link"
                onClick={stepBackward}
              >
                {t('otc.back_button')}
              </Button>
            </Col>
            <Col xs="auto">
              <Button onClick={completeKitActivation} data-test-hook="submit_packaging">
                {t('test_strip.kit_flow.button_3')}
              </Button>
            </Col>
          </Row>
        </div>
      </Section>
    );
  }

  if (currentStep === 'testTipsStep') {
    return (
      <Section className="py-0">
        <div style={{ marginBottom: '56px' }}>
          <Row className="d-flex justify-content-end">
            <Col xs="auto">
              <Button
                className="kit-flow header-btn mt-3"
                variant="link"
                onClick={stepBackward}
              >
                {t('otc.back_button')}
              </Button>
            </Col>
            <Col xs="auto">
              <Button onClick={stepForward} data-test-hook="submit_test_tips">
                {t('test_strip.kit_flow.button_2')}
              </Button>
            </Col>
          </Row>
        </div>
      </Section>
    );
  }
  return null;
}

export function MobileHeaderButtons({
  currentStep,
  t,
  stepForward,
  stepBackward,
  instructions,
  currentInstruction,
}) {
  if (currentStep === 'instructionsStep') {
    return (
      <Row
        className="d-flex justify-content-between align-items-center"
        style={{
          height: '60px',
          backgroundColor: 'white',
          boxShadow: '0px 0px 4px #60617029',
        }}
      >
        <Col xs="auto">
          <Button
            className="kit-flow lead-20-medium"
            variant="link"
            onClick={stepBackward}
          >
            {t('otc.back_button')}
          </Button>
        </Col>
        <Col xs="auto">
          <Button
            className="kit-flow lead-20-medium"
            variant="link"
            onClick={stepForward}
          >
            {instructions.length === currentInstruction + 1
              ? t('otc.next_button')
              : t('test_strip.kit_flow.button_5')}
          </Button>
        </Col>
      </Row>
    );
  }

  if (currentStep === 'packagingStep') {
    return (
      <Row
        className="d-flex justify-content-start"
        style={{
          height: '60px',
          backgroundColor: 'white',
          boxShadow: '0px 0px 4px #60617029',
        }}
      >
        <Col xs="auto">
          <Button
            className="kit-flow lead-20-medium"
            variant="link"
            onClick={stepBackward}
          >
            {t('otc.back_button')}
          </Button>
        </Col>
      </Row>
    );
  }
  return null;
}
