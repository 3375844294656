import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';

import ModalHeader from './ModalHeader';


const SupportPhoneNumberModal = ({
  isMobile,
  phoneNumber,
  t,
}) => {
  const [show, setShow] = useState(false);

  if (isMobile) {
    return (
      <Button
        block
        className="ms-3"
        href={`tel:${phoneNumber}`}
      >
        {t('self_resulting.contact_support')}
      </Button>
    );
  }

  return (
    <>
      <Button
        onClick={() => setShow(true)}
        className="ms-3"
      >
        {t('self_resulting.contact_support')}
      </Button>
      <Modal show={show} onHide={() => setShow(false)}>
        <ModalHeader closeButton onHide={() => setShow(false)}>
          <Modal.Title>
            <h2 className="mb-0">{t('contact_support.contact_us')}</h2>
          </Modal.Title>
        </ModalHeader>
        <Modal.Body>
          <div className="mb-4">
            <p>For assistance, please contact our support team:</p>
            <p>
              <a
                className="my-3"
                href={`tel:${phoneNumber}`}
              >
                <b>{phoneNumber}</b>
              </a>
            </p>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default SupportPhoneNumberModal;