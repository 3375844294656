export default {
  "date": {
    "order": [
      "year",
      "month",
      "day"
    ],
    "month": "ወር",
    "day": "ቀን",
    "year": "ዓመት",
    "january": "ጥር",
    "february": "የካቲት",
    "march": "መጋቢት",
    "april": "ሚይዚያ",
    "may": "ግንቦት",
    "june": "ሰኔ",
    "july": "ሐምሌ",
    "august": "ነሐሴ",
    "september": "መስከረም",
    "october": "ጥቅምት",
    "november": "ህዳር",
    "december": "ታህሳስ"
  },
  "user": {
    "send_appointment_confirmation_message": "ለ {{name}} ይስያዛችሁት ቀጠሮ ማረጋገጫ/ተቀባይነት አግኝቷል።",
    "landline_appointment_reminder_message": "ሰላም, {{full_name}}.ይህ ለቀጠሮዎ ማስታዋሽ ለ {{name}}.ቆጠራቹ በ {{date}} ሰኣት {{time}} በ {{address}}.",
    "send_at_home_visit_confirmation_message": "ይስያዛችሁት ቀጠሮ በ {{date}} ከ {{time}} በኋላ ተቀባይነት አግኝቷል።",
    "send_mail_order_confirmation_message": "የላካችሁት የመልዕክት ጥያቄ ተቀባይነት አግኝቷል።",
    "send_waitlist_message": "{{name}} ተጠባባቂዎች ዝርዝር ውስጥ ገብተዋል። የቀጠሮ ጊዜ እስክናረጋግጥ ድረስ እባክዎን አይታዩ።",
    "verify_contact": {
      "phone": "ጠቅ በማድረግ ስልክ ቁጥርዎን ያረጋግጡ: {{confirmation_link}}",
      "email": "{{confirmation_link}}"
    },
    "duplicate_user_continue_registering": "እዚህ ለቀጠሮ መመዝገብዎን ይቀጥሉ {{url}}",
    "on_demand_confirmation_message": "ለ {{name}} በስኬታማነት ተመዝግባችኋል።",
    "appointment_reminder": "  በ {{start_time}} ከቀጠሮዎ በፊት ላይ, ማንኛውንም የላቀ የድርጊት ንጥሎችን ለማጠናቀቅ እና ማረጋገጫዎን ለማየት እባክዎን አገናኙን ጠቅ ያድርጉ። እባክዎን ቀጠሮዎ ከመድረሱ ከ 15 ደቂቃዎች በላይ አይድረሱ።",
    "appointment_reminder_base": "{{text}} እዚህ ጠቅ ያድርጉ {{link}}",
    "test_result_notifier": {
      "text_message": "የታካሚ ፖርታል ለ {{first_name_with_last_initial}} ተዘምኗል.ወደዚህ በመሄድ {url} እና ኮድ ይጠቀሙ {{access_code}}",
      "email_subject": "በታካሚዎ መግቢያ ላይ አዲስ ዝመናዎች",
      "email_intro": "ሰላም  {{first_name_with_last_initial}}",
      "email_p1": "አዲስ መልእክት አሎት",
      "email_p2": "ከዚህ በታች ያለውን ቁልፍ ጠቅ ያድርጉ ወይም ሊንኩን ይጠቀሙ የታካሚዎን መግቢያ ለማየት እና ኮድ ይጠቀሙ",
      "email_p3": "የታካሚውን መግቢያ በ:' ይመልከቱ",
      "email_button": "ፖርታልን ይመልከቱ"
    },
    "landline_appointment_reminder_message_without_time": "ሰላም ፣ {{full_name}}። ይህ {{name}} ላይ ለቀጠሮዎ አስታዋሽ ነው። ቀጠሮዎ በ {{date}} በ {{address}} ላይ ነው።",
    "appointment_reminder_on_demand": "{{date}} ላይ ከመሾምዎ በፊት ማንኛውንም የላቀ የድርጊት ንጥሎችን ለማጠናቀቅ እና ማረጋገጫዎን ለማየት አገናኙን ጠቅ ያድርጉ።",
    "new_test_result_notifier": {
      "email_intro": "የታካሚዎ ፖርታል ተዘምኗል።",
      "email_text_1": "ማናቸውንም አዲስ የታካሚ መዝገቦችን ወይም የላብራቶሪ ሪፖርቶችን ለማየት ከዚህ በታች የእርስዎን የህክምና ታሪክ ፖርታል ይጎብኙ።",
      "email_button": "የህክምና ታሪክ",
      "email_text_2": "በተጨማሪም ይሄንን URL ከዚህ ገልብጣችሁ (copy) በእራሳችሁ ድህረገፅ መጎብኛ ላይ መለጠፍ (paste) ትችላላችሁ።"
    },
    "mailers": {
      "email_contact_us": "ጥያቄ አለዎት? ከእኛ ጋር ይገናኙ፣ በ"
    }
  },
  "errors": {
    "generic": {
      "message": "የሆነ ችግር ተፈጥሯል ፣ እባክዎ እንደገና ይሞክሩ"
    },
    "messages": {
      "blank": "ባዶ መሆን አይችልም"
    },
    "incorrect_credentials": "ያስገቡት የይለፍ ቃል  የተሳሳተ ነዉ ፣ እባክዎ እንደገና ይሞክሩ።",
    "error_activating_test_kit": "የምርመራ እቃ ወይም ትቦውን ለመጠቀም በሞከሩበት ግዘ የተሳሳቱት ነገር አለ።"
  },
  "type": "ዓይነት",
  "payment": {
    "continue_label": "ወደ ክፍያ ይቀጥሉ",
    "policy_text": "ከቀጠሩት ወይም ለቀጠሮዎ ካልታዩ ፣ ከቀጠሮዎ ቀን 7 ቀናት በኋላ የክፍያዎን 80% እንመልሳለን። ሙሉውን [የአገልግሎት ውሎች] ({{link}}) መመልከት ይችላሉ",
    "card_number": "የካርታ ቁጥር",
    "expiry_date": "የአገልግሎት ማብቂያ ጊዜ",
    "pay_button": "ይክፈሉ",
    "no_credit_card": "በጣቢያው ላይ እከፍላለሁ",
    "cash_payment_helper": "እባክዎን ወደ ቀጠሮዎ **{{payment}}** ማምጣትዎን ያረጋግጡ",
    "invoice": "ደረሰኝ",
    "pay_by_card": "በካርድ ይክፈሉ",
    "cost": "ወጪ",
    "total": "ጠቅላላ",
    "pay_cash_at_appointment": "በቀጠሮዬ በ {{appointment}} በጥሬ ገንዘብ እከፍላለሁ",
    "view_invoice": "የክፍያ መጠየቂያ ይመልከቱ/ያትሙ",
    "refund_policy": "ቀጠሮዎን ከሰረዙ፣ ከቀጠሮዎ ቀን ከ 7 ቀናት በኋላ ከከፈሉት ገንዘቡን {{amount}} እንመልሳለን nየአገልግሎት ውል ሙሉውን ማየት ይችላሉ [ውሎች እና አገልግሎት]({{link}})",
    "refund_window_passed": "የቀጠሮዎ ጊዜ ስላለፈ ለቀጠሮ ያስያዙት ገንዘብ ተመላሽ አይሆንም። እባክዎ ለማንኛውም አይነት የውሳኔ ሃሳቦች በቀጥታ የጣቢያ አስተባባሪዎችን ያግኙ።",
    "amount_paid": "የተከፈለበት መጠን",
    "balance": "ሒሳብ"
  },
  "consent_waiver": {
    "upload_waiver_or_sign": "የስምምነት መሻገሪያ ይክፈቱ ወይም ከዚህ በታች ባለው የፊርማ ሳጥን ላይ ይፈርሙ",
    "stop": "ምዝገባን ለማጠናቀቅ ስምምነት ያስፈልጋል",
    "parent_or_guardian_required_html": "ምዝገባዎን ለማጠናቀቅ የእርስዎ ወላጅ ፣ አሳዳጊ ወይም የተፈቀደ ውሳኔ ሰጪ ከዚህ በታች ባለው ቅጽ ላይ ስምምነት መስጠት እና ስማቸውን መፈረም አለባቸው። እርስዎ ለዚህ አገልግሎት እርስዎ  <b>አይችሉም</b>.",
    "send_parent_or_guardian": "ይህንን አገናኝ ለወላጅዎ ወይም ለአሳዳጊዎ መላክ ወይም በዚህ ገጽ ላይ ከዚህ በታች እንዲፈርሙ ማድረግ ይችላሉ።",
    "consent_guardian_name_signature": "ስም",
    "consent_for_minor": "ለአካለ መጠን ያልደረሰ ልጅን በመወከል ስምምነት ይፈርሙ",
    "guardian_signature": "የወላጅ ፣ የአሳዳጊ ፣ ወይም የተፈቀደ የህክምና ውሳኔ ሰጪ ፊርማ",
    "after_you_schedule": "ዕድሜያቸው ከ {{age}} በታች የሆኑ ተሳታፊዎች በወላጆቻቸው ወይም በአሳዳጊዎቻቸው ወክለው የተፈረሙበት ስምምነት ሊኖራቸው ይገባል። ለአሳዳጊዎ ለመላክ ወይም ለጥገኝነትዎ ለመፈረም እባክዎን እስከ ምዝገባው መጨረሻ ይቀጥሉ።",
    "consent_guardian_last_name_signature": "ስም",
    "consent_first_and_last_name": "የአባትህን እና የታላቅ አባትህ ስም",
    "phone_number": "የጥበቃ ስልክ ቁጥር",
    "email": "የጥበቃ ኢሜይል",
    "consent_registrar_with_guardian_name_signature": "የአሳዳጊ የመጀመሪያ ስም",
    "consent_registrar_with_guardian_last_name_signature": "የአሳዳጊ የመጨረሻ (የአያት) ስም",
    "consent_registrar_first_name_signature": "የመዝጋቢ የመጀመሪያ ስም",
    "consent_registrar_last_name_signature": "የመዝጋቢ የአያት ስም",
    "consent_on_behalf": "የምፈርመው {{first_name}} {{last_name}}ን ወክዬ ነው።",
    "consent_on_behalf_registrar": "እነርሱን ወክዬ እንድፈርም በ{{first_name}} {{last_name}} የቃል ፍቃድ ተሰጥቶኛል።",
    "consent_on_behalf_registrar_with_guardian": "በ{{first_name}} {{last_name}} አሳዳጊ እነርሱን ወክዬ እንድፈርም የቃል ፍቃድ ተሰጥቶኛል።",
    "registrar_phone": "የሬጅስትራር ስልክ ቁጥር",
    "registrar_email": "የሬጅስትራር ኢሜል",
    "consented_by_parent_guardian": "ወላጅ / ሞግዚት",
    "consented_by_decision_maker": "ስልጣን ያለው የህክምና ውሳኔ ሰጪ",
    "consented_by_guardian_verbal": "ሬጅስትራር ከሞግዚት/አሳዳጊ ጋር (የቃል ስምምነት ተገኝቷል)",
    "consented_by_registrar": "ሬጅስትራር (የቃል ስምምነት ተገኝቷል)",
    "full_name": "ሙሉ ስም",
    "print": "አትም"
  },
  "registration": {
    "contact_information": "ስም እና አድራሻ",
    "contact_information_additional": "ለቀጠሮ ግንኙነት እና ውጤቶች የወላጅ ወይም የአሳዳጊ የእውቂያ መረጃ",
    "personal_information": "የግል መረጃ",
    "address_placeholder": "እባክዎን የቤት አድራሻዎን ያስገቡ",
    "first_name_label": "ስም",
    "last_name_label": "የታላቅ አባትህ ስም",
    "errors": {
      "state": "ግዛት: 2 ፊደል የስቴት ኮድ",
      "phone_number_invalid": "ስልክ ቁጥር ልክ አይደለም",
      "required": "ይህ ግደታ ሞምላት ኣለበት",
      "email_domain": "የእርስዎ ግቤት ልክ ያልሆነ ነው ፤ እባክዎን እንደገና ያረጋግጡ። ምዝገባው በስፖንሰር አድራጊው ድርጅት በተመረጡት ተሳታፊዎች ላይ ብቻ የተወሰነ ነው። እባክዎን የእርስዎን ሥራ ወይም የማህበረሰብ ኢሜል ለመጠቀም ይሞክሩ። እርስዎ ብቁ እንደሆኑ ከተሰማዎት እና ስህተት ካለ እባክዎን ድጋፍን ያነጋግሩ።",
      "age_requirement": "የልደት ቀን የዕድሜ መስፈርትን አያሟላም",
      "signature_required": "ፊርማ ያስፈልጋል",
      "regex_invalid": "ይህ እሴት ልክ ያልሆነ ይመስላል።",
      "date_invalid": "ይህ ቀን የለም",
      "invalid_entry": "እባክዎን በምዝገባ አይቀጥሉ። በማያ ገጹ ላይ ያሉትን መመሪያዎች ይከተሉ ወይም ወደ መገናኛ ቦታዎ ይድረሱ።",
      "city": "ልክ ከተማ መሆን አለበት",
      "survey_unanswered": "በ * ምልክት የተደረገባቸው ሁሉም አስፈላጊ ጥያቄዎች መልስ ማግኘታቸውን ያረጋግጡ።",
      "postal_code": "የሚሰራ ባለ 5 አሃዝ የፖስታ ኮድ መሆን አለበት",
      "field_required": "{{field}} እሴት ሊኖረው ይገባል።",
      "option_required": "ከአማራጮች ውስጥ አንዱን መምረጥ አለብህ",
      "year_length_invalid": "የአራት ዲጂት የልደት ቀን ከነ ዓ.ም ያስገቡ (ዓመት)",
      "invalid_day": "ቀኑ በ1-31 መካከል መሆን አለበት።",
      "reached_max_chars": "ከፍተኛው የቁምፊዎች ብዛት ላይ ደርሰዋል።",
      "chars_max": "የቁምፊ ከፍተኛ",
      "minimum_length": "ግብዓቱ ቢያንስ {{length}} ያህል ቃላት እርዝማኔ ሊኖረው ይገባል።"
    },
    "insurance_status": {
      "question": "የጤና መድን አለዎት?",
      "have_health_insurance": "አዎ የጤና መድን አለኝ",
      "do_not_have_health_insurance": "አይ ፣ የጤና መድን የለኝም"
    },
    "insurance_policy_holder": {
      "question": "የፖሊሲው ባለቤት ማን ነው?",
      "i_am": "እነ ነኝ",
      "my_spouse": "ባለቤቴ ወይም ባልደረባዬ",
      "my_parents": "ወላጆቼ",
      "someone_else": "ሌላ ሰው",
      "policy_first_name": "የፖሊሲ ያዥ የመጀመሪያ ስም",
      "policy_last_name": "የፖሊሲ ያዥ  አያቴየ ስም",
      "policy_dob": "የፖሊሲ ያዥ የትውልድ ቀን",
      "name": "ጉዳዩ የሚመለከቱ አካል ስም"
    },
    "insurance_information": {
      "title": "የኢንሹራንስ መረጃ",
      "company_name": "የኢንሹራንስ ኩባንያ ስም",
      "id_number": "የአባል መለያ ቁጥር",
      "group_number": "የአባል ቡድን ቁጥር",
      "secondary_insurance_label": "ሁለተኛ መድህን አለኝ",
      "take_photo": "እባክዎ የኢንሹራንስ ካርድዎን ፎቶ ያንሱ። ፎቶግራፍ ማንሳት አንዳንድ መረጃዎችን በራስ-ሰር ይሞላል።",
      "front_of_card": "የካርድ ፊት",
      "card_information": "የኢንሹራንስ ካርድ መረጃ",
      "back_of_card": "የካርድ ጀርባ"
    },
    "employment_information": {
      "label": "ሥራ\r",
      "address_1": "የኩባንያ አድራሻ\r",
      "address_2": "ለምሳሌ - Suite 200\r",
      "phone_number": "የኩባንያ ስልክ ቁጥር\r",
      "company": "የድርጅት ስም",
      "postal_code": "የአሠሪ የፖስታ ኮድ\r",
      "employed": "ሠራተኛ",
      "sole_prorietor": "ብቸኛ ባለቤት",
      "not_employed": "ስራ ተቀጣሪ አይደለም",
      "reporting_supervisor": "ሪፖርት ማድረግያ ተቆጣጣሪ",
      "reporting_department": "የሪፖርት ክፍል",
      "supervisor_name": "የተቆጣጣሪ ስም",
      "supervisor_email": "ተቆጣጣሪ ኢሜይል"
    },
    "location_availability": "በእነዚህ አካባቢዎች ለሚኖሩ የማህበረሰብ አባላት ፈተና በአሁኑ ወቅት ቅድሚያ ተሰጥቶታል",
    "custom_survey": "መጠይቅ\r",
    "confirmation": "ይገምግሙ፡ ክለሳ",
    "waitlisted": "ተጠባባቂ ዝርዝር\r",
    "schedule_your_appointment": "ቀጠሮ ቀጠሮ",
    "information": " መረጃ ",
    "consent": "ስምምነት",
    "location": " አካባቢ",
    "symptoms": "ምልክቶች",
    "address_required": "እባክዎን አድራሻዎን ያስገቡ",
    "consent_required": "ለመቀጠል እባክዎ ይስማሙ",
    "required_field": "አስፈላጊውን መስክ ያመለክታል",
    "phone_number": "የሞባይል ስልክ ቁጥር",
    "email": "ኢሜል",
    "date_of_birth": "የትውልድ ቀን",
    "minimum_age": "ለመሳተፍ ዝቅተኛው ዕድሜ {{year}} ዓመት ነው።",
    "no_minimum_age": "ለሙከራ ዝቅተኛ ዕድሜ የለም",
    "continue_button": "ቀጥል",
    "email_required": "ይህ እሴት የሚሰራ ኢሜይል መሆን አለበት።",
    "done": "ተከናውኗል",
    "signature": "ፊርማ",
    "clear_button": "አጽዳ",
    "back_button": "ተመለስ",
    "choose_location": "ቦታ ይምረጡ",
    "no_slots": "በ {{location}} ላይ ተጨማሪ ቦታዎች አይገኙም",
    "choose_appointment": "{{location}} ላይ የቀጠሮ ጊዜ ይምረጡ",
    "appointment_required": "እባክዎ የቀጠሮ ጊዜ ይምረጡ",
    "phone_number_required": "ስልክ ቁጥር ልክ አይደለም",
    "phone_number_label": "ለፈጣን የሙከራ ውጤቶች እባክዎን የጽሑፍ መልዕክቶችን ሊቀበል የሚችል ቁጥር ያስገቡ",
    "symptoms_experiencing": "እባክዎን በአሁኑ ጊዜ እያጋጠሙዎት ያሉትን ምልክቶች ጠቅ ያድርጉ። ምንም ምልክቶች ከሌሉዎት እባክዎ ይቀጥሉ።",
    "household": "የቤተሰብ አባላት",
    "household_p1": "በአማራጭ ፣ ለመመርመር ለሚፈልጉ የቤተሰብ አባላት ቀጠሮ ይፍጠሩ።",
    "add_dependent": "የቤተሰብ አባል ይጨምሩ",
    "remove_dependent": "ጥገኛን ያስወግዱ",
    "dependent_consents": "ፈቃዶች",
    "submit": "ተጠናቀቀ",
    "add_waitlist": "ወደ ተጠባባቂ ዝርዝር ያክሉ",
    "address": "የቤት አድራሻ",
    "address_1": "አድራሻ መስመር 1",
    "address_2": "የቀጠሮ ቤት ቁጥር (Apt/Suite #)",
    "address_city": "ከተማ",
    "address_state": "ግዛት",
    "postal_code": "የፖስታ መላኪያ ኮድ",
    "race_ethnicity": "ዘር",
    "gender": "የጾታ ማንነት",
    "self_described_gender": "በራስ የተገለጸ ጾታ",
    "interpreter": "አስተርጓሚ ይፈልጋሉ? አዎ ከሆነ ፣ የትኛው ቋንቋ ነው?",
    "consent_to_terms": "በእነዚህ ውሎች እስማማለሁ።",
    "reg_not_open": "ምዝገባዎች አልተከፈቱም",
    "no_more_avail_spots": "የመረጡት የጊዜ ክፍተት ከአሁን በኋላ አይገኝም። እባክዎ ዳግም ይሞክሩ.",
    "consent_helper": "ጠቅ ያድርጉ እና ለመፈረም ከዚህ በታች ባለው ሳጥን ላይ መዳፊትዎን ወይም ጣትዎን ይጎትቱ",
    "phone_number_unreachable_label": "የመስመር ስልክ?",
    "select": "ይምረጡ",
    "test_surveys": "የቀጠሮ ጥያቄዎች",
    "edit": "አርትዕ",
    "continue_to_registration": "ወደ ምዝገባ ይቀጥሉ",
    "accounts": {
      "already_have_an_account": "አስቀድመው መለያ አለዎት?",
      "log_in": "ግባ",
      "sign_up": "ክፈት",
      "sign_up_description": "እባክዎ መለያዎን ለመፍጠር እና ለወደፊት ምዝገባዎች ጊዜን ለመቆጠብ  መረጃዎን ያስገቡ።",
      "log_in_description": "እባክዎን ኢሜልዎን እና የይለፍ ቃልዎን (password) ያስገቡ ፣ ወይም ከዚህ በታች በ Google ወይም በ Outlook በኩል ይግቡ",
      "sign_in_with_label": "በ {{provider}} ይግቡ",
      "password_must_match": "የሚስጥሩ ቃል (password) ​​የግድ መመሳሰል አለበት",
      "password_uppercase": "የይለፍ ቃልዎ (password) ቢያንስ (%s) አቢይ ፊደል መያዝ አለበት።",
      "password_lowercase": "የይለፍ ቃልዎ (password) ቢያንስ (%s) ንዑስ ፊደል መያዝ አለበት።",
      "password_number": "የይለፍ ቃልዎ (password) ቢያንስ (%s) ቁጥር ​​መያዝ አለበት።",
      "password_special": "የይለፍ ቃልዎ (password) ቢያንስ (%s) ልዩ ቁምፊዎችን መያዝ አለበት።"
    },
    "password": "ፕስወርድ (የይለፍ ቃል)",
    "password_confirmation": "የይለፍ ቃል (ፕስወርድ) ማረጋገጫ",
    "consent_for": "ለ {{name}} ስምምነት",
    "book_one_time_appointment": "የአንድ ጊዜ ቀጠሮ ይያዙ",
    "duplicate_users": {
      "exists": "አስቀድመው ተመዝግበዋል",
      "overlapping_email_and_phone_p1": "ወደ ስልክ ቁጥርዎ እና የኢሜል አድራሻዎ መልእክት ልከናል።",
      "p2": "መልእክትዎን በመጠቀም ቀጠሮዎን መለወጥ ወይም የፈተና ውጤቶችን ማስገባት ይችላሉ።",
      "overlapping_phone_and_email_p3": "ለዝርዝሮች እባክዎን ኢሜልዎን ወይም ስልክዎን ይፈትሹ።",
      "overlapping_email_p3": "ለዝርዝሮች እባክዎን ኢሜልዎን ይፈትሹ።",
      "overlapping_phone_p3": "ለዝርዝሮች እባክዎን ስልክዎን ይፈትሹ።",
      "overlapping_phone_p1": "ወደ ስልክ ቁጥርዎ መልእክት ልከናል።",
      "overlapping_email_p1": "ወደ ኢሜል አድራሻዎ መልእክት ልከናል።",
      "p4": "ይህ ስህተት ነው ብለው የሚያስቡ ከሆነ እባክዎን በ support@primary.health ላይ ያነጋግሩን",
      "overlapping_email_and_phone_p3": "ለዝርዝሮች እባክዎን ኢሜልዎን ወይም ስልክዎን ይፈትሹ።"
    },
    "duplicate_waitlist": {
      "exists": "አስቀድመው በመጠባበቂያ ዝርዝር ውስጥ ነዎት",
      "overlapping_email_and_phone_p1": "ወደ ስልክ ቁጥርዎ እና የኢሜል አድራሻዎ ሌላ ማረጋገጫ ልከናል",
      "p2": "ማረጋገጫዎን በመጠቀም ተጨማሪ መረጃ መስጠት ወይም ከተጠባባቂ ዝርዝር ውስጥ እራስዎን ማስወገድ ይችላሉ።",
      "overlapping_phone_and_email_p3": "ለዝርዝሮች እባክዎን ኢሜልዎን ወይም ስልክዎን ይፈትሹ",
      "overlapping_email_p3": "ለዝርዝሮች እባክዎን ኢሜልዎን ይፈትሹ",
      "overlapping_phone_p3": "ለዝርዝሮች እባክዎን ስልክዎን ይፈትሹ",
      "overlapping_email_p1": "ወደ ኢሜልዎ ሌላ ማረጋገጫ ልከናል።"
    },
    "insist_guardian_consent": "እኔ ወክዬ ፈቃድ እሰጣለሁ",
    "confirmation_section": {
      "title": "ይገምግሙ እና ያረጋግጡ",
      "p1": "ምዝገባውን ከማጠናቀቅዎ በፊት እባክዎ መረጃዎን ይከልሱ።",
      "edit_information": "ተመለስ እና አርትዕ"
    },
    "iemodal": {
      "title": "የማይደገፍ አሳሽ (browsers)",
      "body": "በእነዚህ አሳሾች ላይ ምዝገባ በአሁኑ ጊዜ ይቻላል"
    },
    "show_other_locations": "ሌሎች ቦታዎችን ለማሳየት ጠቅ ያድርጉ",
    "non_us_address": "ይህ ዓለም አቀፍ አድራሻ ነው",
    "test_group_user_survey": "የተሳታፊ ማጣሪያ",
    "self_consent": "እኔ ለራሴ እስማማለሁ",
    "address_country": "ሀገር",
    "appointment": "ቀጠሮ",
    "employee_id": "የሰራተኛ መታወቂያ",
    "appointment_time": "የቀጠሮ ጊዜ",
    "appointment_location": "አካባቢ",
    "phone_or_email": "የስልክ ቁጥር ወይም ኢሜይል",
    "no_self_consent": "ለራሴ ፈቃድ አልቀበል",
    "no_insist_guardian_consent": "ስምምነቱን ወክዬ አልቀበልም {{full_name}}",
    "minimum_age_with_months": "ለመሳተፍ ዝቅተኛው ዕድሜ {{year}} ዓመት ከ {{month}} ወር ነው።",
    "additional_consents_helpertext": "ተጨማሪ ፍቃዶችን በኋላ ሊጠየቁ ይችላሉ",
    "assistive_technology": "አጋዥ ቴክኖሎጂ",
    "covid_vaccine_y_n": "ከዝኛዉ ቀጠሮ ጋር የኮቪድ-19 ክትባት ይፈልጋሉ?",
    "received_covid_vaccine_y_n": "ከዚህ በፊት የኮቪድ-19 ክትባት ወስደዋል?",
    "covid_vaccinations": "የኮቪድ-19 ክትባቶች",
    "select_vaccine_dose": "እባክህ መቀበል የምትፈልገውን የክትባት መጠን ይምረጡ",
    "demographic_info": "የስነሕዝብ መረጃ",
    "additional_info": "ተጨማሪ መረጃ",
    "self_described_race": "በራስ የተገለጸ ዘር",
    "verify_contact_information": "ማረጋገጥ",
    "verify_contact": {
      "we_sent_you_a_code": "እባክህ ወደ {{contact}} የላክነውን ኮድ ያስገቡ",
      "confirmation_code": "ማረጋግጫ ኮድ",
      "code_resent": {
        "email": "ሌላ ኮድ ወደ ኢሜልዎ ተልኳል።",
        "phone_number": "ሌላ ኮድ ወደ ስልክዎ ተልኳል።"
      },
      "did_not_receive_code": "የማረጋገጫ ኮድ አልደረሦትም?",
      "verify_by": {
        "email": "በምትኩ በኢሜል ያረጋግጡ",
        "phone_number": "በምትኩ በስልክ ቁጥር ያረጋግጡ"
      }
    },
    "preferred_method_of_communication": {
      "question": "ተመራጭ የሆነ የግንኙነት መንገድ",
      "helper_text": "ቀጠሮ ላይ ስለሚኖሩ ማሻሻያዎች እናሳውቅዎታለን",
      "phone_number": "ስልክ (አጭር የፅሁፍ መልእክት ብቻ እና የዳታ ክፍያዎች ሊኖሩ ይችላሉ።)",
      "landline": "አሁን ላይ የመስመር ስልኮችን አንቀበልም። እባክዎን፣ አስፈላጊ ለሆኑ ግንኙነቶች የኢሜል አድራሻዎን ያቅርቡ።",
      "verification_method": "የእርስዎን የማረጋገጫ ኮድ በምን አይነት መንገድ ነው እንዲደርስዎት የሚፈልጉት?",
      "verification_helper_text": "ከመግባትዎ ከማድረግዎ (log in) በፊት ማንነትዎን ለማረጋገጥ ኮድ ይላክልዎታል።"
    },
    "skip_for_now": "ለአሁኑ ይዝለሉት",
    "default_information": {
      "title": "እሺ፣ ይሄን አድራሻ እንጠቀማለን",
      "title_v2": "እሺ፣ እኛ ይህን የእውቂያ መረጃ እንጠቀማለን"
    },
    "middle_name_label": "የመሀል/የአባት ስም",
    "confirm_appointment": "ቀጠሮዎን ያረጋግጡ",
    "dob": "የተወለደበት ቀን",
    "different_appointment": "የተለየ ቀጠሮ",
    "select_appointment_time": "የቀጠሮ ጊዜ ይምረጡ",
    "decline_address": "አድራሻ ለመስጠት ፈቃደኛ አልሆንኩም። በምትኩ እባክዎ የፕሮግራሙን አድራሻ ይጠቀሙ።",
    "patient_information": "የታካሚ መረጃ",
    "scan_license": "መንጃ ፍቃድዎን እስካን ያድርጉ",
    "how_to_contact": "እንዴት ልናገኞት እንችላለን?",
    "decline_email_or_phone": "ኢሜል ወይም ስልክ ቁጥር ለመስጠት ፈቃደኛ አይደለሁም። የፕሮግራም አስተዳዳሪዎች ውጤቶቼን እንዲቀበሉ ፈቅጃለሁ።",
    "medical_screening": "የሕክምና ምርመራ",
    "show_service_at_this_location": "በዚህ አከባቢ ያሉትን አገልግሎቶች አሳይ",
    "verbal_consent_provided": "በአሜሪካ የአካል ጉዳተኞች ህግ መሰረት ተሳታፊ ወይም በህጋዊ የተፈቀደላቸው ውሳኔ ሰጭ የቃል ፍቃድ ሰጥተዋል። የጽሁፍ ፍቃድ ከተሳታፊ ወይም በህጋዊ ስልጣን ከተሰጠው ውሳኔ ሰጪ በ24 ሰአት ውስጥ ማግኘት አለበት።",
    "unexpired_written_consent_present": "የምርመራ  ጣቢያ በፋይልዎ  ላይ ጊዜው የማያልፍበት የጽሁፍ ፍቃድ አለው።",
    "select_to_proceed": "እባክዎ ለመቀጠል ከላይ ያለውን አማራጭ ይምረጡ።",
    "guardians_information": "የአሳዳጊዎች መረጃ",
    "registrars_information": "የመዝጋቢ መረጃ",
    "optional": "አማራጭ",
    "vfc_eligibility": "የህፃናት ክትባት (VFC) ብቁነት",
    "vfc_eligibility_subtext": "ልጅዎ በቪኤፍሲ ፕሮግራም ውስጥ በተመዘገቡ የጤና እንክብካቤ አቅራቢዎች ቢሮዎች፣ ፋርማሲዎች እና የጤና ክሊኒኮች በሲዲሲ የህፃናት ክትባቶች (VFC) ፕሮግራም በኩል ምንም ወጪ የማይጠይቁ ክትባቶችን ማግኘት ይችል ይሆናል።"
  },
  "gender_key": {
    "male": "ወንድ",
    "female": "ሴት",
    "cisgender": "የእኔ የትውልድ ጾታ ነው :Cisgender",
    "non_binary": "ጾታ ወይም ሁለትዮሽ ያልሆነ",
    "other": "ሌላ",
    "prefer_to_self_describe": "ውስንነት አልተዘረዘረም",
    "prefer_not_to_disclose": "መልስ ለመስጠት ፈቃደኛ አይደለሁም",
    "transgender_male": "ወደ ወንድ የተቀየረ",
    "transgender_female": "ወደ ሴት የተቀየረ",
    "unknown": "ያልታወቀ",
    "non_binary_only": "ሁለትዮሽ ያልሆነ",
    "intersex": "ኢንተርሴክስ",
    "transgender": "ፆታን የለወጠው",
    "gender_fluid": "ጆንደር ፍሉድ",
    "not_applicable": "አልተፈቀደም"
  },
  "ethnicity": {
    "american_indian_alaska_native": "የአሜሪካ ሕንዳዊ ወይም የአላስካ ተወላጅ",
    "american_indian_central_america": "አሜሪካዊ ሕንዳዊ ከደቡብ ወይም ከመካከለኛው አሜሪካ",
    "asian": "እስያዊ",
    "black": "ጥቁር ወይም አፍሪካዊ አሜሪካዊ",
    "latinx": "ላቲኖ ፣ ላቲንክስ ወይም ሂስፓኒክ",
    "middle_eastern_north_african": "መካከለኛው ምስራቅ ወይም ሰሜን አፍሪካ",
    "native_hawaiian_pacific_islander": "ተወላጅ የሃዋይ ወይም የፓስፊክ ደሴት",
    "white_european": "ነጭ ወይም ካውካሰስ",
    "unknown": "ያልታወቀ",
    "prefer_not_to_disclose": "ላለመግለጽ ይመርጣሉ",
    "asian_indian": "የእስያ ሕንዳዊ",
    "filipino": "ፊሊፒኖ",
    "japanese": "ጃፓንኛ",
    "korean": "ኮሪያኛ",
    "vietnamese": "ቪትናምኛ",
    "other_asian": "ሌላ እስያ",
    "native_hawaiian": "ተወላጅ ሃዋይ",
    "guamanian_or_chamorro": "ጉዋማኒያ ወይም ቻሞሮ",
    "samoan": "ሳሞአን",
    "other_pacific_islander": "ሌላ የፓስፊክ ደሴት",
    "chinese": "ቻይንኛ",
    "help": "ዘር በሰዎች ቡድኖች መካከል ለመለየት የሚያገለግሉ የአካላዊ ባህሪያትን ስብስብ ያመለክታል። ዘር ከዜግነት ፣ ወይም ዜግነት ካላችሁበት አገር ጋር ሊስማማ ወይም ላይስማማ ይችላል።",
    "subtitle": "ደንቦች የሚከተሉትን መረጃዎች በሙሉ እንድንሰበስብ ይጠይቃሉ።",
    "laotian": "ላኦትያን",
    "cambodian": "ካምቦዲያኛ",
    "other": "ሌላ",
    "bangladeshi": "ባንግላዲሽ",
    "hmong": "ሆምንግ",
    "indonesian": "ኢንዶኔዥያን",
    "malaysian": "ማሌዥያን",
    "pakistani": "ፓኪስታኒ",
    "sri_lankan": "ሲሪላንካ",
    "thai": "ታይ",
    "taiwanese": "ታይዋኔዝ",
    "fijian": "ፊጂን",
    "guamanian": "ጉዋምን",
    "tongan": "ቶንጋን",
    "bahamian": "ባሃሚያን"
  },
  "languages": {
    "en": "እንግሊዝኛ",
    "fr": "ፈረንሳይኛ",
    "es": "እስፓኛል",
    "so": "ሶማሊኛ",
    "hmn": "ሕሞንግ",
    "asl": "የአሜሪካ የምልክት ቋንቋ (ASL)",
    "kar": "ካረን",
    "am": "አማርኛ",
    "ru": "русский",
    "om": "ኦሮምኛ"
  },
  "symptoms": {
    "fever": "ትኩሳት ወይም ብርድ ብርድ ይላል",
    "cough": "ሳል",
    "muscle_aches": "የጡንቻ ሕመም",
    "severe_fatigue": "ከባድ ድካም (ከተለመደው በላይ)",
    "trouble_breathing": "የመተንፈስ ችግር",
    "diarrhea": "ተቅማጥ",
    "loss_of_smell": "ማሽተት ማጣት",
    "loss_of_taste": "ጣዕም ማጣት",
    "shortness_of_breath": "የትንፋሽ እጥረት ወይም የመተንፈስ ችግር",
    "headache": "ራስ ምታት",
    "sore_throat": "በጉንፋን የተዘጋ ጉሮሮ",
    "congestion": "መጨናነቅ ወይም ንፍጥ",
    "nausea": "ማቅለሽለሽ ወይም ማስታወክ",
    "close_contact": "በበሽታው ከተያዘ ሰው ጋር የቅርብ ግንኙነት*",
    "helper_text": {
      "close_contact": "*COVID-19 ን ካረጋገጠ ሰው ጋር የቅርብ ግንኙነት (በ 6 ጫማ ውስጥ ቢያንስ ለ 15 ደቂቃዎች) ኖረዋል?"
    },
    "suspected_exposure": "የተጠረጠረ ተጋላጭነትዎ",
    "none": "ምንም ምልክቶች የሉም"
  },
  "instructions": {
    "title": "ተመዝግበዋል ግን ከመሄድዎ በፊት ...",
    "p1": "ይህ ገጽ ስለ ቀጠሮዎ እንዲሁም ስለ ቀጠሮዎ ባርኮድ መረጃ ይዞዋል",
    "appointment_scheduled": "ቀጠሮዎ ተይዞዋል",
    "verify_info": {
      "title": "መረጃዎን ያረጋግጡ",
      "p1": "የታካሚዎን መግቢያ በፍጥነት መድረስ እንዲችሉ የእውቂያ መረጃዎን ማረጋገጥ አስፈላጊ ነው።",
      "p2": "ማረጋገጫ ወደ ኢሜልዎ ተልኳል። እባክዎን የቀረበውን አገናኝ ጠቅ ያድርጉ።",
      "p3": "የእውቂያ መረጃዎን መለወጥ ከፈለጉ እባክዎን የእገዛ ቁልፍን ይጫኑ።"
    },
    "verified": "ተረጋግጧል",
    "not_verified": "አልተረጋገጠም",
    "resend_text": "አገናኙን አልተቀበሉትም?",
    "resend": "ዳግም ላክ",
    "skip": "ማረጋገጫውን ይዝለሉ እና ማረጋገጫውን ያሳዩ",
    "name": "ስም",
    "appointment_details": "ቀጠሮ",
    "date_and_time": "ቀን እና ሰዓት",
    "add_to_calendar": "ወደ ቀን መቁጠሪያ ያክሉ",
    "instructions": "አጠቃላይ መመሪያዎች",
    "successfully_flash": "በተሳካ ሁኔታ ቀጠሮ ተፈጥሯል!",
    "success_flash_email": "ኢሜል በተሳካ ሁኔታ ተረጋግጧል",
    "success_flash_phone": "በስልክ ቁጥር በተሳካ ሁኔታ ተረጋግጧል",
    "mail_order": "በሳምንት ውስጥ የሙከራ ኪትዎን በፖስታ ይቀበላሉ።",
    "at_home": "በዚህ ጊዜ አካባቢ የሕክምና አቅራቢ ወደ ቤትዎ ይመጣል",
    "at_home_instructions": "በቤት ውስጥ መመሪያዎች",
    "mail_order_instructions": "የደብዳቤ ትዕዛዝ መመሪያዎች",
    "request_additional_appointment": " የክትትል ቀጠሮ  ይያዙ",
    "book_additional_appointment": "ሌላ ቀጠሮ ይያዙ",
    "confirmation_code": "ማረጋግጫ ኮድ",
    "completed": "ተጠናቅቋል",
    "appointment_barcode": "ቀጠሮ ባርኮድ",
    "dependent_links": "የቤት ቀጠሮ አገናኞች",
    "on_demand_title": "ተመዝግበዋል ግን ከመሄድዎ በፊት ...",
    "save_link": "ይህንን አገናኝ ያስቀምጡ",
    "verify_phone": "ስልክ ቁጥርዎን ያረጋግጡ",
    "verify_phone_description": "መዝገቦችዎን በፍጥነት መድረስ እንዲችሉ የእውቂያ መረጃዎን ማረጋገጥ አስፈላጊ ነው። የማረጋገጫ የጽሑፍ መልዕክት ወደ ቁጥርዎ ተልኳል።",
    "verify_email_description": "መዝገቦችዎን በፍጥነት መድረስ እንዲችሉ የእውቂያ መረጃዎን ማረጋገጥ አስፈላጊ ነው። የማረጋገጫ ኢሜል ወደ ኢሜል አድራሻዎ ተልኳል።",
    "information": "የምዝገባ መረጃ",
    "follow_up_appointment": "ተከታይ ቀጠሮ",
    "get_directions": "አቅጣጫዎችን ያግኙ",
    "cancel": "ቀጠሮ ሰርዝ",
    "reschedule_appointment": "ቀጠሮ ለሌላ ጊዜ ያስተላልፉ",
    "reschedule_linked_appointments": "ቀጠሮዎችን ለሌላ ጊዜ ያስተላልፉ",
    "no_slots": "ተጨማሪ ጊዜያት አይገኙም",
    "check_results": "የታካሚ ፖርታልን ይፈትሹ",
    "follow_up_modal_header": "እባክዎን 2 ኛ መጠንዎን ያቅዱ",
    "are_you_sure_you_want_to_cancel": "እርግጠኛ ነዎት ይህን ቀጠሮ መሰረዝ ይፈልጋሉ?",
    "please_choose_cancellation_reason": "እባክዎን ከዚህ በታች ያለውን የስረዛ ምክንያት ይምረጡ",
    "additional_details": "እባክዎን ማንኛውንም ተጨማሪ ዝርዝሮች ከዚህ በታች ያክሉ",
    "errors": {
      "missing_cancellation_reason": "እባክዎን የስረዛ ምክንያት ይምረጡ"
    },
    "verify_email": "ኢሜልዎን ያረጋግጡ",
    "redcap_url": "የዳሰሳ ጥናትዎን ለማጠናቀቅ ጠቅ ያድርጉ",
    "verify_contact_information": "የእውቂያ መረጃዎን ያረጋግጡ",
    "please_also": "እባክዎ እንዲሁም",
    "new_title": "{{name}}ለ ማረጋገጫ",
    "contact_method": "የመገናኛ መንገድ",
    "next_steps": {
      "title": "ቀጣይ እርምጃዎች",
      "p1": "ማስጀመር የሚገባዎት የምርመራ መሳሪያ/ኪት አለ። ምርመራውን ለመውሰድ ዝግጁ ሲሆኑ፣ ኢሜል የተደረገላችሁን ሊንክ በመከተል ወደ እዚህ ገፅ ተመለሱ እና ከታች ያለውን የምርመራ ኪት አስጀምሩ።",
      "administered": "We will contact you when your kit reaches the lab and when your results are ready. Results typically take 24-48 hours after they reach the lab. You can view results from your [Medical history]({{link}}) page when they are ready.",
      "resulted": "ውጤቶች የሚገኙት በእርስዎ [የህክምና ታሪክ / Medical History]({{link}}) ድረ-ገፅ ላይ ነው።",
      "shipping_information": "የማጓጓዣ መረጃ"
    },
    "save_this_page": {
      "title": "ይሄንን ገፅ (save) አስቀምጡት።",
      "p1": "የራስን በራስ ምርመራን ሪፖርት ለማድረግ ይሄንን ድረ-ገፅ ይጠቀሙ ወይም የህክምና ታሪክን በመጠቀም ውጤቶችን ተመልከቱ።",
      "p2": "ወደ ፊት ላይ ይሄንን ድረ-ገፅ ለማግኘት በእርስዎ የማረጋገጫ ኢሜል ወይም አጭር የፅሁፍ መልእክት ላይ ያለውን ሊንክ / link ይጫኑ።",
      "p3": "ይሄንን ገፅ ለማስቀመጥ (save) ከፈለጉ Bookmark አድርጉት፣ ወደ home screen ይጨምሩት እና ሊንኩን ደህንነቱ ወደተጠበቀ ቦታ ይገልብጡት።"
    },
    "show_my_barcode": "የእኔን ባርኮድ (barcode) አሳየኝ",
    "my_account": "የእኔ አድራሻ (account)",
    "register_another": "ሌላ ተሳታፊ ማስመዝገብ",
    "update_vaccine": "የክትባት መረጃን ያዘምኑ/ያሻሽሉ",
    "medical_history": "የህክምና ታሪክ",
    "upcoming_appointments": "መጪ ቀጠሮዎች",
    "reschedule": "እንደገና ቀጠሮ አስይዝ",
    "resend_confirmation": "ማረጋገጫ እንደገና ላክ",
    "appointment_details_v2": "የቀጠሮ ዝርዝሮች",
    "confirm_cancellation": "መሰረዙን ያረጋግጡ",
    "confirm_cancellation_question": "እርግጠኛ ነዎት ይህን ቀጠሮ መሰረዝ ይፈልጋሉ?",
    "select_new_appointment_time_below": "ከዚህ በታች አዲስ የቀጠሮ ጊዜ ይምረጡ። አገልግሎቶቹን ወይም የቀጠሮውን ቦታ መቀየር ከፈለጉ፣ እባክዎ ይህን ቀጠሮ ይሰርዙ እና አዲስ ቀጠሮ ያስይዙ።",
    "no_take_me_back": "አይ   ወደ ዉዓላ  መልሰኝ",
    "yes_cancel_appointment": "አዎ፣ ቀጠሮውን ይሰርዙ",
    "update_appointment": "ቀጠሮውን አዘምን",
    "select_new_appointment_time": "አዲስ የቀጠሮ ጊዜ ይምረጡ",
    "clinic": "ክሊኒክ",
    "services": "አገልግሎቶች",
    "appointment_missed": "በቀጠሮ ቀን አልተገኙም",
    "appointment_canceled": "ቀጠሮ ተሰርዟል።",
    "screening_complete_for": "Screening complete for {{name}}"
  },
  "result_page": {
    "errors": {
      "invalid_access_code": "ልክ ያልሆነ የመዳረሻ ኮድ"
    },
    "landing": {
      "p1": "ውጤቶችዎን ለማየት ፣ እባክዎ ያስገቡ ፦",
      "access_code_label": "መግብያ ቃል",
      "submit_button": "አስረክብ",
      "recent_results": "የእርስዎ የቅርብ ጊዜ ውጤቶች"
    },
    "view_results": {
      "headline": "ለ {{name}} የሙከራ ውጤቶች",
      "reregister": "እንደገና ይመዝገቡ",
      "status": {
        "likely_positive": "ውጤቶች ያልተወሰነ",
        "test_not_performed": "እንደገና መሞከር ያስፈልጋል",
        "results_ready": "ውጤቶች ዝግጁ ናቸው",
        "processing": "በማስኬድ ላይ",
        "test_status": "የሙከራ ሁኔታ",
        "test_result": "የሙከራ ውጤት\r",
        "administered": "ክትባት ተሰቷል",
        "results_pending": "ያልተጠናቀቁ ውጤቶች",
        "test_results": "የምርመራ ውጤቶች"
      },
      "at": "በ{{address}}"
    },
    "result_label": "ውጤት ",
    "result": {
      "flu_a": {
        "result_label": "እንፍሌዌንዛ A ዉጤት"
      },
      "flu_b": {
        "result_label": "እንፍሌዌንዛ B ዉጤት"
      },
      "covid19": {
        "result_label": "የ ኮቪድ 19 ዉጤት"
      },
      "covid": {
        "result_label": "የ ኮቪድ 19 ዉጤት"
      },
      "sars": {
        "result_label": "SARS ውጤት"
      },
      "control": {
        "result_label": "ቁጥጥር"
      },
      "rsv": {
        "result_label": "የRSV ውጤት"
      },
      "result": {
        "result_label": "ውጤት"
      },
      "hba1c": {
        "normal": "ጤናማ",
        "warning": "ቅድመ-የስኳር በሽታ (Prediabetes)",
        "danger": "የስኳር በሽታ"
      },
      "lead_venous": {
        "danger": "ጤናማ-ያልሆነ",
        "normal": "ጤናማ"
      },
      "tc": {
        "result_label": "TC ውጤት"
      },
      "hdl": {
        "result_label": "HDL ውጤት"
      },
      "trg": {
        "result_label": "TRG ውጤት"
      },
      "ldl": {
        "result_label": "LDL ውጤት"
      },
      "non_hdl": {
        "result_label": "HDL-ያልሆነ ውጤት"
      },
      "tc_hdl_ratio": {
        "result_label": "TC/HDL ውጤት"
      },
      "glu": {
        "result_label": "GLU ውጤት"
      },
      "alere_cholestech_ldx": {
        "danger": "አደጋ ላይ",
        "warning": "ጤናማ-ያልሆነ",
        "normal": "ጤናማ"
      },
      "lead": {
        "result_label": "ሊድ"
      },
      "zinc": {
        "result_label": "ዚንክ"
      },
      "lead_standard_profile": {
        "danger": "ጤናማ-ያልሆነ",
        "normal": "ጤናማ"
      },
      "creatinine": {
        "danger": "ያልተለመደ",
        "normal": "መደበኛ ወይም የተለመደ"
      },
      "igg": {
        "result_label": "የ IgG ውጤት"
      },
      "igm": {
        "result_label": "የ IgM ውጤት"
      },
      "blood_glucose_fasted": {
        "normal": "አንቀጽ",
        "warning": "ከፍተኛ",
        "danger": "ከፍተኛ",
        "hypoglycemia": "ሃይፖግላይሴሚያ",
        "prediabetes": "የስኳር በሽታ በፊት አቅጣጫ",
        "diabetes": "ስኳር በሽታ"
      },
      "total_cholesterol_fasted": {
        "normal": "አንቀጽ",
        "elevated": "ከፍተኛ",
        "high": "ከፍተኛ",
        "low": "ከስላሰ",
        "abnormal": "ያልተለመደ"
      },
      "total_cholesterol_unfasted": {
        "normal": "አንቀጽ",
        "elevated": "ከፍተኛ",
        "high": "ከፍተኛ"
      },
      "a1c_now": {
        "normal": "አንቀጽ",
        "warning": "የማዕድናዊ ሁኔታ በፊት",
        "danger": "ማዕድና"
      },
      "blood_glucose": {
        "warning": "ዝቅተኛ",
        "normal": "መደበኛ",
        "prediabetes": "በቅድሚያ የስኳር ህመም",
        "danger": "የስኳር ህመም",
        "hypoglycemia": "ሃይፖግላይሴሚያ",
        "medical_emergency": "ሕክምና አስቸኳይ ሁኔታ",
        "inconclusive_1": "ውሳኔ ያልተጠናቀቀ 1",
        "inconclusive_2": "ውሳኔ ያልተጠናቀቀ 2",
        "possible_diabetes": "የሚታመን ስኳር በሽታ"
      },
      "triglycerides": {
        "result_label": "ትራይግሊሪየስ"
      },
      "blood_glucose_ucsf": {
        "low": "ትንሽ",
        "normal": "መደበኛ",
        "high": "ከፍተኛ"
      },
      "syphilis": {
        "result_label": "ሲፊሊስ ውጤት"
      },
      "hepatitis_c": {
        "result_label": "ሄፓታይቲስ C ውጤት"
      },
      "hiv": {
        "result_label": "ኤች አይ ቪ ውጤት"
      },
      "rapid_hiv": {
        "positive": "አበልፊ (ቀንድ አበልፊ)",
        "negative": "ተበላሽ (አለበላሽ)",
        "did_not_result": "አልተከለከለም (አላነሸም)"
      },
      "rapid_hcv": {
        "positive": "አበልፊ (ቀንድ አበልፊ)",
        "negative": "ተበላሽ (አለበላሽ)",
        "did_not_result": "አልተከለከለም (አላነሸም)"
      },
      "rapid_syphilis": {
        "positive": "አበልፊ (ቀንድ አበልፊ)",
        "negative": "ተበላሽ (አለበላሽ)",
        "did_not_result": "አልተከለከለም (አላነሸም)"
      }
    },
    "documents": "ሰነዶች",
    "self_administered": "በግል የተወሰደ ምርመራ {{location}}",
    "patient": "ታካሚ",
    "medical_history": "የህክምና ታሪክ",
    "overview_title": "ተጨማሪ ዝርዝሮችን እና ማንኛውንም ተጨማሪ የሚያስፈልጉ የሚወሰዱ እርምጃዎች ይዘቶችን ለመመልከት ምርመራ ወይም አገልግሎት ይምረጡ። አንዳንዱ የምርመራ ውጤቶች የማይገኙ መሆኑን ወይም ማግኘት የሚቻሉት ከአቅራቢው ጋር ከተነጋገሩ በኋላ ብቻ እንደሆነ ያስተውሉ።",
    "insurance_information": "የኢንሹራንስ መረጃ ያስገቡ",
    "contact_support": "እርዳታ ከፈለጉ እባክዎን ድጋፍ ሰችዎችን ያነጋግሩ።",
    "show": "አሳይ",
    "hide": "ደብቅ",
    "lab_report": "የላብራቶሪ ሪፖርት",
    "contact_provider": {
      "header": "ውጤጥዎ ፖዘትቭ ነው።",
      "description": "ስለ ውጤቶችዎ እና ስለ ህክምናዎ ለመወያየት አቅራቢን ማነጋገር ይፈልጋሉ?",
      "yes_option_text": "አዎ፣ አቅራቢውን ማነጋገር እፈልጋለሁ",
      "no_option_text": "አይ፣ ውጤቶቼን አንብቤ ተረድቻለሁ፣ እና አቅራቢውን ማነጋገር አልፈልግም።",
      "confirm_phone_number_header": "ስልክ ቁጥርዎን ያረጋግጡ",
      "confirm_phone_number_description": "እባክዎን ለአማካሪዎ ስልክ ቁጥርዎን ያረጋግጡ።",
      "confirm_button_text": "ያረጋግጡ።",
      "consultation_confirmed_header": "ምክክር ተረጋግጧል",
      "consultation_confirmed_description": "በ2-3 የስራ ቀናት ውስጥ አቅራቢ በ {{phone_number}} ይደውልልዎታል።",
      "acknowledgement_option_helper_text": "የሕክምናን አስፈላጊነት የሚያብራራ እና ተጠቃሚው ሃሳባቸውን ከቀየሩ እንዴት ጥሪን እንደሚያዝ እንዲያውቅ የሚያደርግ ነገር።",
      "acknowledgement_confirmed_header": "እውቅና ተረጋግጧል",
      "acknowledgement_confirmed_description": "አስፈላጊነት በተያያዙ ህክምና መረጃዎች የማግኘት ላይ የሆነ። ምክክሩ ሙሉ በሙሉ ነፃ እንደሆነ እና አቅራቢው ኢንፌክሽኑን/በሽታውን እንዲፈቱ እንዲረዳቸው ወይም ሌላ ማንኛውንም ነገር ማዘዝ እንደሚችል ያስታውሱ።",
      "acknowledgement_confirmed_change_mind_text": "ሃሳብዎን ከቀየሩ፣ በቀላሉ ከታች \"መመካከር እፈልጋለሁ\" የሚለውን ይጫኑ።",
      "request_consultation_button_text": "አማካሪ እፈልጋለሁ"
    },
    "phone": "ስልክ ቁጥር",
    "source": "Source"
  },
  "required": {
    "errors": {
      "address_not_in_bounding_box": "በዚህ ፕሮግራም ውስጥ ለጣቢያዎች የብቁነት መስፈርቶች የእርስዎ አድራሻ አይዛመድም።"
    }
  },
  "member": {
    "medical_history": "ታሪክ",
    "view_instructions": "ማረጋገጫ ይመልከቱ\r",
    "next_appointment": "ቀጣይ ቀጠሮ\r",
    "over_18": "ዕድሜዬ 18 ወይም ከዚያ በላይ መሆኑን አረጋግጣለሁ",
    "choose_test_configurations_title": "ለዚህ ቀጠሮ እባክዎን የሚፈልጉትን አገልግሎት (ዎች) ይምረጡ",
    "member_taken_error": "ተጠቃሚ አስቀድሞ መለያ አለው",
    "choose_test_configurations_subtitle": "ተጠቃሚ አስቀድሞ መለያ አለው",
    "service": "አገልግሎት",
    "group_or_location": "ቡድን/ቦታ",
    "details": "ዝርዝር",
    "see_all": "ሁሉንም ይዩ",
    "no_history": "በዚህ ጊዜ ምንም ታሪክ የለም::",
    "medical_history_title_with_name": "{{name}}' ታሪክ",
    "no_dashboard": "በአሁኑ ጊዜ ምንም አይገኝም",
    "product": "ምርት",
    "price": "ዋጋ",
    "quantity": "ብዛት",
    "total_services_selected": "ጠቅላላ አገልግሎቶች ተመርጠዋል",
    "total_price": "ጠቅላላ ዋጋ"
  },
  "or": "ወይም",
  "account": {
    "errors": {
      "must_be_13": "ለመለያ ለመመዝገብ ዕድሜው 13 ዓመት መሆን አለበት"
    }
  },
  "self_administration": {
    "title": "ራስን ማስተዳደር",
    "self_administer_action": "ራስን ማስተዳደር ",
    "skip_to_link": "ወደ ራስን ማስተዳደር ዝለል",
    "select_person": " ለመፈተሽ አንድ ሰው ይምረጡ",
    "adult": " አዋቂ",
    "child": " ልጅ ",
    "checked_out": "ተመልክቷል - ወጥቷል",
    "go_back": "ተመለስ",
    "switch_to_scanner": "ወደ ስካነር ቀይር",
    "enter_barcode": "ባር ኮድ ያስገቡ",
    "scan_barcode": "ባርኮድ ይቃኙ",
    "cancel": "መሰረዝ, ሰርዝ",
    "barcode_for": "ባር ኮድ ለ",
    "enter_barcode_manually": "ባርኮድ እራስዎ ያስገቡ",
    "instructions": "ከዚህ በታች ባለው የሙከራ ቱቦዎ ላይ የሚገኘውን ባር ኮድ ይቃኙ ወይም ያስገቡ",
    "regex_prefix": "ባርኮድ ማካተት አለበት",
    "all_completed": "ተጠናቅቋል፡ ሁሉም የቤተሰብ አባላት ይህንን ደረጃ አጠናቀዋል",
    "errors": {
      "no_appointment": "ቀጠሮ ማግኘት ችግር አለበት",
      "barcode_in_use": "ይህ ባር ኮድ ከዚህ ቀደም ጥቅም ላይ ውሏል። ከዚህ ቀደም በዚህ ኪት እራስን ካላስተዳድሩ እባኮትን ከሰጡዎት ጣቢያ ሰራተኞች ጋር ይነጋገሩ እና አዲስ ኪት ይጠይቁ። አለበለዚያ, ከታች ያለውን የእገዛ አዝራር ጠቅ ያድርጉ",
      "test_error_general": "ፈተናው ችግር ይፈጥራል",
      "different_barcodes": "ያስገቡት ባርኮዶች አይዛመዱም"
    },
    "confirm_barcode_input": "ለማረጋገጥ የአሞሌ ኮድ ያስገቡ",
    "click_to_self_test": "እራስን ለመሞከር እዚህ ጠቅ ያድርጉ",
    "or_enter_barcode_manually": "Or enter manually with all letters and numbers"
  },
  "location_details": {
    "next_appointment": {
      "waitlist": "የተጠባባቂ ዝርዝር ",
      "unavailable": "ምንም ቀጠሮዎች የሉም"
    },
    "labels": {
      "address": " አድራሻ",
      "next_appointment": " የሚቀጥለው ቀጠሮ ",
      "services": "አገልግሎቶች ይገኛሉ"
    }
  },
  "waiting_room": {
    "header": "እርስዎ በመጠባበቂያ ክፍል ውስጥ ነዎት; ከመርሐግብር  ስርዓት ጋር እስክንገናኝዎት ድረስ ይጠብቁ።",
    "p1": "እባክዎን ይታገሱ። ይህንን ወረርሽኝ በጋራ እናጠናቅቃለን።",
    "signature": "ከሰላምታ ጋር",
    "p2": "የ COVID-19 ተሞክሮ ተስፋ አስቆራጭ ሊሆን እንደሚችል እናውቃለን። እባክዎን ቀጠሮዎን ለማስያዝ ወደ ጣቢያው እንዲገባዎት በተቻለ መጠን በፍጥነት ስንሠራ እዚህ ይጠብቁ።"
  },
  "users": {
    "send_appointment_confirmation_message": "ሰላም {{full_name}}። ይህ {{name}} ላይ ለቀጠሮዎ አስታዋሽ ነው። ቀጠሮዎ በ {{date}} በ {{time}} በ {{address}} ላይ ነው።",
    "send_registration_link": "ሰላም ፣ {{full_name}}። ለእርስዎ {{name}} {{registration_link}} ለመመዝገብ ይህንን አገናኝ ይከተሉ"
  },
  "follow_up": {
    "first_dose": "የመጀመሪያ መጠን",
    "previous_appointment": "ያለፈው ቀጠሮ",
    "booked_appointment": "የእርስዎ ቀጠሮ",
    "second_dose": "ሁለተኛ መጠን",
    "choose_second_location": "ሁለተኛ ቀጠሮ ቦታ ይምረጡ"
  },
  "cancellation_types": {
    "cant_get_to_location": "የጊዜ ግጭት ነበረኝ",
    "timing_conflict": "በ COVID-19 ታምሜ አለሁ",
    "became_ill_with_covid19": "ይህንን አገልግሎት በሌላ ቦታ አግኝቻለሁ",
    "received_service_elsewhere": "ሌላ",
    "other": " በምትኩ ስም ይጻፉ (ታይፕ) ",
    "duplicate_appointment": "ይህ የድጋሚ ቀጠሮ ነዉ"
  },
  "translation": {
    "consent": {
      "type_name_instead": " ስም ይጻፉ (ታይፕ)",
      "type_name": "ሌላ ሳይሆን ፊርማዎን ይፃፉ"
    }
  },
  "email": {
    "confirmation": {
      "your_access_code_is": "የእርስዎ የመዳረሻ ኮድ ነው",
      "please_confirm_your_email": "ከታች ያለውን አዝራር ጠቅ በማድረግ እባክዎ ኢሜልዎን ያረጋግጡ",
      "hello": "ሰላም",
      "click_to_reschedule": "መመሪያዎችን (መርሃ ግብር ለማሳየት) ጠቅ ያድርጉ /ለሌላ ጊዜ  አስተላልፍ ",
      "click_to_reschedule_on_demand": "መመሪያዎችን ለማሳየት ጠቅ ያድርጉ"
    },
    "cancellation": {
      "your_appointment_has_been_canceled": "በ {{time}} በ {{location}} ያስያዙት ቀጠሮ ተሰርዟል። ይሄ ስህተት ከሆነ፣ እባክዎን ወደ support@primary.health ኢሜል ይላኩ።"
    }
  },
  "signature_lines": {
    "name": "የተሳታፊ ስም",
    "date": "ቀን",
    "signature": "የተሳታፊ ፊርማ",
    "and_or": "እና/ወይም",
    "guardian_name": "የወላጅ/አሳዳጊ ስም",
    "guardian_signature": "የወላጅ/አሳዳጊ ፊርማ"
  },
  "employer_testing": {
    "hello_name": "ሰላም {{name}}",
    "results": {
      "negative": "አሉታዊ",
      "positive": "አዎንታዊ",
      "did_not_result": "ያልታወቀ",
      "unknown": "የማይታወቅ",
      "invalid": "የማይሰራ",
      "pending": "በመጠባበቅ ላይ"
    },
    "code_reader": {
      "scan_your_code": "በሙከራ ካርድዎ ላይ ኮዱን ይቃኙ",
      "find_in_top_right_hand_corner": "ስካን ኮዱ ለሙከራዎ ልዩ ነው እና በፈተናዎ የላይኛው ቀኝ ጥግ ላይ ሊገኝ ይችላል",
      "center_code": "ኮዱን እዚህ ያቁሙ።",
      "already_used_error": "ይህ የሙከራ ባርኮድ አስቀድሞ ጥቅም ላይ ውሏል። ድጋፍ ለማግኘት እባክዎን የሙከራ ኪት አቅራቢዎን ያነጋግሩ።",
      "click_to_scan": "የ QR ኮድ ለመቃኘት(ስካን) ጠቅ ያድርጉ",
      "scan_new_test_card": "አዲስ የሙከራ ካርድ ለመቃኘት(ስካን) እዚህ ጠቅ ያድርጉ።",
      "not_working": "እየሰራ አይደለም?",
      "try_again": "እንደገና ለመሞከር እዚህ ጠቅ ያድርጉ።"
    },
    "continue": "ቀጥል",
    "verify_date_of_birth": {
      "confirm_date_of_birth": "ለመቀጠል እባክዎ የተሳታፊውን የትውልድ ቀን ያረጋግጡ",
      "contact_administrator": "የታየው መረጃ ትክክል ካልሆነ ፣ ለእርዳታ አስተዳዳሪን ያነጋግሩ",
      "error": "የገባው የልደት ቀን ትክክል አይደለም - እባክዎን እንደገና ይሞክሩ ወይም ይህንን ለማዘመን የጣቢያዎን አስተዳዳሪ ያነጋግሩ።"
    },
    "result_entry": {
      "code_is_registered": "የእርስዎ ኮድ ተመዝግቧል።",
      "take_the_test": "ፈተናውን ይውሰዱ",
      "follow_the_instructions": "በፈተናዎ ላይ የመጡትን መመሪያዎች ይከተሉ እና በፈተና ካርዱ ላይ ወደ አፍንጫው እፍኝ ከገቡ በኋላ የ 15 ደቂቃ ሰዓት ቆጣሪዎን ይጀምሩ።",
      "cant_find_instructions": "መመሪያዎቹን ማግኘት አልቻሉም?",
      "view_instructions": "የ Binax መመሪያዎችን ይመልከቱ",
      "start_timer": " 15 ደቂቃ ሰዓት ቆጣሪ ጀምር(አማራጭ)",
      "submit_within_15": "ጠቃሚ ምክር - ውጤቶችዎን በ 15 ደቂቃዎች ውስጥ ያስገቡ",
      "enter_test_results": "የሙከራ ውጤቶችን ያስገቡ",
      "choose_a_result": "የእርስዎን የሙከራ ካርድ ውጤት የሚገልጽ አማራጭ ይምረጡ",
      "submit": "አስረክብ",
      "must_log_result_and_photo": "ለመቀጠል ውጤቱን ማስገባት እና የፈተናዎን ፎቶ ማንሳት አለብዎት",
      "submit_within_15_v2": "ጠቃሚ ምክር - ምርመራው ለ 15 ደቂቃዎች ከሄደ በኋላ ውጤቶችዎን ያስገቡ",
      "click_to_take_photo": "ፎቶ ለማንሳት ጠቅ ያድርጉ",
      "results_may_be_invalid": "የሙከራ ውጤቶች ልክ ላይሆኑ ይችላሉ"
    },
    "summary": {
      "title": "የውጤቶች ማጠቃለያ",
      "negative_subtitle": "COVID-19 የለዎትም",
      "negative_message": "በቦታው ላይ ተመልሰው እንዲመጡ ተቀባይነት አግኝቷል መሆኑን ለአሠሪዎ ማሳወቂያ ልከናል።",
      "positive_subtitle": "እናዝናለን ፣ ግን እርስዎ COVID-19 አለዎት",
      "positive_message": "COVID-19 እንዳለዎት ለአሠሪዎ ማሳወቂያ ልከናል።",
      "what_should_you_do": "ምን ማድረግ አለብዎት:",
      "employer_name_resources": "የአሠሪ ስም ሀብቶች;",
      "follow_cdc_guidelines": "CDC የሚመከሩ መመሪያዎችን ይከተሉ"
    },
    "clarifying_results": {
      "title": "ውጤቶችዎን በማብራራት ላይ",
      "which_did_your_result_most_look_like": "በጣም ውጤትዎ የትኛው ይመስል ነበር?",
      "no_lines": "ምንም መስመሮች አልታዩም",
      "blue_control_line_only": "ሰማያዊ መቆጣጠሪያ መስመር ብቻ",
      "pink_sample_line_only": "ሮዝ ናሙና (sample) መስመር ብቻ",
      "blue_control_line_and_pink_sample_line": "ሰማያዊ መቆጣጠሪያ መስመር እና ሮዝ/ሐምራዊ ናሙና መስመር",
      "still_not_sure": "አሁንም እርግጠኛ አይደለሁም",
      "submit_results": "ውጤቶችን ያስገቡ",
      "thank_you": "አመሰግናለሁ!",
      "clarification_received_message": "የውጤትዎ ማብራሪያ ደርሷል።",
      "only_pink_line": "ሮዝ መስመር ብቻ",
      "no_lines_v2": "ምንም መስመሮች የሉም"
    },
    "return_to_confirmation": "ወደ ማረጋገጫ ገጽ ይመለሱ"
  },
  "preferred_language": {
    "title": "ተመራጭ ቋንቋ",
    "subtitle": "የትኛውን ቋንቋ ነው የሚመርጡት?"
  },
  "appointment_type": {
    "initial_vaccine_appointment": "የመጀመሪያ ተከታታይ ክትባት",
    "one_dose": "ለአንድ የተወሰነ መጠን ቀጠሮ ይያዙ። በተለይ ይህ የእኔ ነው",
    "title": "የመድኃኒት መጠን ምርጫ",
    "first_dose": "የመጀመሪያ መጠን",
    "second_dose": "ሁለተኛ መጠን",
    "single_dose_title": "ነጠላ መጠን",
    "additional": "ተጨማሪ",
    "booster": "ማበረታቻ",
    "supplemental": "ተጨማሪ",
    "third_dose": "ሦስተኛው መጠን ብቻ",
    "no_vaccine": "ከላይ ከተጠቀሱት ውስጥ አንዳቸውም አይደሉም"
  },
  "activemodel": {
    "errors": {
      "models": {
        "importers/results/row": {
          "attributes": {
            "administered_at": {
              "invalid_datetime": "ቅርጸት (መዋቅር) {{datetime_format}} ሊኖረው ይገባል"
            },
            "results_at": {
              "invalid_datetime": "ቅርጸት(መዋቅር)   {{datetime_format}} ሊኖረው ይገባል"
            },
            "uid": {
              "unknown_test": "ሙከራ አልተገኘም"
            }
          }
        },
        "importers/participants/row": {
          "attributes": {
            "access_code": {
              "not_allow_update_test_group_user": "ተጠቃሚ አለ እና ለማዘመን አልተፈቀደለትም"
            },
            "date_of_birth": {
              "invalid_date": "ቅርጸት (መዋቅር) {{date_format}} ሊኖረው ይገባል"
            },
            "ethnicity": {
              "unknown_ethnicities": {
                "one": "{{unknown_ethnicities}} የለም ፣ ከሚከተሉት ተቀባይነት ያላቸው እሴቶች ይምረጡ ፦  {{ethnicities}}",
                "other": "{{unknown_ethnicities}} የለም ፣ ከሚከተሉት ተቀባይነት ካላቸው እሴቶች ውስጥ ይምረጡ ፦  {{ethnicities}}"
              }
            },
            "gender": {
              "unknown_gender": "ጾታ የለም ፣ ከሚከተሉት ተቀባይነት ካላቸው እሴቶች ውስጥ ይምረጡ ፦ {{genders}}"
            },
            "phone_number": {
              "invalid": "ልክ ያልሆነ ቁጥር ነው"
            },
            "population_name": {
              "unknown_population": "{{population_names}} የለም ፣ ከሚከተሉት ተቀባይነት ካላቸው እሴቶች ውስጥ ይምረጡ ፦ {{population_names}}"
            },
            "self_described_gender": {
              "not_allowed": "ጾታው \"prefer_to_self_describe\" ካልፈለገ ሊሞላው አይችልም"
            },
            "sex": {
              "unknown_sex": "ወሲብ (የወሲብ ዝንባሌ) የለም ፣ ከሚከተሉት ተቀባይነት ያላቸው እሴቶች ውስጥ ይምረጡ ፦ {{sexes}}"
            },
            "sexual_orientation": {
              "unknown_sexual_orientation": "የወሲብ ዝንባሌ (sex_orientation) የለም ፣ ከሚከተሉት ተቀባይነት ያላቸው እሴቶች ይምረጡ ፦ {{sex_orientations}}"
            },
            "status": {
              "unknown_status": "ሁኔታ ልክ ያልሆነ ነው ፣ ከሚከተሉት ተቀባይነት ካላቸው እሴቶች ውስጥ ይምረጡ ፦ {{statuses}}"
            },
            "races": {
              "unknown_races": {
                "one": "{{unknown_races}} የለም ፣ ከሚከተሉት ተቀባይነት ያላቸው እሴቶች ይምረጡ ፦ {{races}}",
                "other": "{{unknown_races}} የለም ፣ ከሚከተሉት ተቀባይነት ካላቸው እሴቶች ውስጥ ይምረጡ ፦ {{races}}"
              }
            },
            "vaccination_status": {
              "unknown_vaccination_status": "vaccination status is invalid, pick from the following acceptable values: {{vaccination_statuses}}"
            }
          }
        },
        "importers/organization_user/row": {
          "attributes": {
            "role": {
              "insufficient_privileges": "ናውን ለማዘጋጀት በቂ ያልሆኑ መብቶች"
            },
            "user_id": {
              "unknown_user": "ተጠቃሚ አልተገኘም"
            }
          }
        },
        "importers/additional_information/row": {
          "attributes": {
            "additional_information": {
              "invalid_json": "ልክ ያልሆነ JSON"
            },
            "user_id": {
              "unknown_user": "ተጠቃሚ አልተገኘም"
            }
          }
        },
        "importers/emr/row": {
          "attributes": {
            "appointment_location": {
              "unknown_location": "የቀጠሮ ቦታ አልተገኘም"
            },
            "date_of_birth": {
              "invalid_date": "ቅርጸት (መዋቅር) {{date_format}} ሊኖረው ይገባል"
            },
            "email": {
              "duplicated": "አስቀድሞ ጥቅም ላይ ውሏል"
            },
            "ethnicity": {
              "unknown_ethnicities": {
                "one": "{{unknown_ethnicities}} የለም ፣ ከሚከተሉት ተቀባይነት ያላቸው እሴቶች ይምረጡ ፦ {{ethnicities}}",
                "other": "{{unknown_ethnicities}} የለም ፣ ከሚከተሉት ተቀባይነት ካላቸው እሴቶች ውስጥ ይምረጡ ፦ {{ethnicities}}"
              }
            },
            "gender": {
              "unknown_gender": "ጾታ የለም ፣ ከሚከተሉት ተቀባይነት ካላቸው እሴቶች ውስጥ ይምረጡ ፦ {{genders}}"
            },
            "phone_number": {
              "invalid": "ልክ ያልሆነ ቁጥር ነው"
            },
            "population": {
              "unknown_population": "{{unknown_population}} የለም ፣ ከሚከተሉት ተቀባይነት ካላቸው እሴቶች ውስጥ ይምረጡ ፦ {{population_names}}"
            },
            "self_described_gender": {
              "not_allowed": "ጾታው \"prefer_to_self_describe\" ካልፈለገ ሊሞላው አይችልም"
            },
            "sex": {
              "unknown_sex": "ጾታው የለም ፣ ከሚከተሉት ተቀባይነት ያላቸው እሴቶች ውስጥ ይምረጡ ፦ {{sexes}}"
            },
            "sexual_orientation": {
              "unknown_sexual_orientation": "(sexual_orientation) የወሲብ ዝንባሌ የለም ፣ ከሚከተሉት ተቀባይነት ያላቸው እሴቶች ይምረጡ ፦ {{sex_orientations}}"
            },
            "races": {
              "unknown_races": {
                "one": "{{unknown_races}} የለም ፣ ከሚከተሉት ተቀባይነት ያላቸው እሴቶች ይምረጡ ፦ {{races}}",
                "other": "{{unknown_races}} የለም ፣ ከሚከተሉት ተቀባይነት ካላቸው እሴቶች ውስጥ ይምረጡ ፦ {{races}}"
              }
            }
          }
        },
        "importers/additional_consents/row": {
          "attributes": {
            "consent_form_id": {
              "unknown_consent_form": "consent_form not found"
            },
            "access_code": {
              "unknown_user": "user not found"
            }
          }
        },
        "importers/vaccine/row": {
          "attributes": {
            "date_of_birth": {
              "invalid_date": "should have format {{date_format}}"
            },
            "phone_number": {
              "invalid": "is an invalid number"
            },
            "email": {
              "duplicated": "is already used"
            },
            "races": {
              "unknown_races": {
                "one": "{{unknown_races}} does not exist, pick from the following acceptable values: {{races}}",
                "other": "{{unknown_races}} do not exist, pick from the following acceptable values: {{races}}"
              }
            },
            "gender": {
              "unknown_gender": "gender does not exist, pick from the following acceptable values: {{genders}}"
            },
            "sexual_orientation": {
              "unknown_sexual_orientation": "sexual_orientation does not exist, pick from the following acceptable values: {{sexual_orientations}}"
            },
            "self_described_gender": {
              "not_allowed": "cannot fill it if the gender is not \"prefer_to_self_describe\""
            },
            "sex": {
              "unknown_sex": "sex does not exist, pick from the following acceptable values: {{sexes}}"
            },
            "ethnicity": {
              "unknown_ethnicities": {
                "one": "{{unknown_ethnicities}} does not exist, pick from the following acceptable values: {{ethnicities}}",
                "other": "{{unknown_ethnicities}} do not exist, pick from the following acceptable values: {{ethnicities}}"
              }
            },
            "tag_action": {
              "unknown": "{{unknown_tag_action}} is an invalid value, pick from the following acceptable values: {{tag_actions}}"
            },
            "population": {
              "unknown_population": "{{unknown_population}} does not exist, pick from the following acceptable values: {{population_names}}"
            },
            "appointment_location": {
              "unknown_location": "appointment location not found"
            },
            "appointment_date": {
              "invalid_date": "should have format {{date_format}}"
            },
            "dose_type": {
              "invalid_dose": "{{unknown_dose}} is not a valid dose, pick from the following acceptable values: {{acceptable_doses}}",
              "unavailable_dose": "{{dose_type}} is not available within the vaccine_lot, pick from the following available values: {{available_doses}}"
            },
            "vis_version_date": {
              "invalid_date": "should have format {{date_format}}"
            }
          }
        },
        "importers/base/row": {
          "attributes": {
            "appointment_time": {
              "invalid_time": "should have format {{time_format}}"
            }
          }
        }
      }
    }
  },
  "activerecord": {
    "errors": {
      "models": {
        "test_location": {
          "attributes": {
            "metadata": {
              "wrong_json": "is not a valid JSON"
            },
            "name": {
              "taken": "እና አድራሻው በሌላ ነባር ሥፍራ ውስጥ አስቀድሞ ጥቅም ላይ እየዋለ ነው"
            }
          }
        }
      }
    },
    "attributes": {
      "organization_user": {
        "organization_user_leads": {
          "one": "User",
          "other": "Users"
        }
      },
      "appointment_slot_group": {
        "organization_user_leads": {
          "one": "Location",
          "other": "Locations"
        }
      }
    }
  },
  "hispanic_questions": {
    "hispanic": "ሂስፓኒክ ወይም ላቲኖ/ሀ",
    "not_hispanic": "እስፓኒሽ ወይም ላቲኖ/ሀ አይደለም",
    "hispanic_expanded": {
      "other": "ሌላ ሂስፓኒክ ፣ ላቲኖ/ሀ ወይም የስፔን መነሻ",
      "mexican": "የሜክሲኮ ፣ የሜክሲኮ አሜሪካዊ ፣ ቺካኖ/ሀ",
      "puerto_rican": "ፖርቶ ሪካን",
      "cuban": "ኩባዊ"
    },
    "subtitle": "ደንቦች የሚከተሉትን መረጃዎች እንድንሰበስብ ይጠይቃሉ",
    "help": "ጎሳ ማለት እንደ ቋንቋ ፣ ባህላዊ ልምዶች ፣ ሃይማኖት እና የሰዎች ቡድኖችን ለመለየት የሚያገለግሉ ሌሎች ባህሪያትን የመሳሰሉ የጋራ ባሕርያትን ያመለክታል። ከዘር ማንነትም ጋር ሊስማማም ላይሆንም ይችላል።",
    "unknown": "ያልታወቀ",
    "title": "ጎሳ: የጋራ ብሔራዊ ወይም ባህላዊ ወግ"
  },
  "sex_at_birth": {
    "question": "ጾታ",
    "female": "ሴት",
    "male": "ወንድ",
    "help": "ጾታ በልደት ምስክር ወረቀትዎ ላይ ያለውን ያመለክታል።",
    "non_binary": "የሁለትዮሽ ያልሆነ የወሲብ ዝንባሌ",
    "subtitle": "ደንቦች: የሚከተሉትን መረጃዎች በሙሉ እንድንሰበስብ ይጠይቃሉ።",
    "decline": "መልስ ለመስጠት ፈቃደኛ አይደለሁም",
    "unknown": "ያልታወቀ"
  },
  "gender": {
    "help": "የሥርዓተ -ፆታ ማንነት እርስዎ በግል የሚለዩበትን መንገድ ያመለክታል። በተወለዱበት ጊዜ ከተመደቡት ጾታ ጋር ሊስማማ ወይም ላይስማማ ይችላል።",
    "subtitle": "ስለ ጾታ ማንነትዎ መረጃ ማከል ከፈለጉ እባክዎን ያድርጉት።"
  },
  "sexual_orientation": {
    "title": "ወሲባዊ ዝንባሌ",
    "subtitle": "ስለ ወሲባዊ ዝንባሌዎ መረጃ ማከል ከፈለጉ እባክዎን ያድርጉት።",
    "gay": "ግብረ ሰዶማዊነት ማለት ተመሳሳይ ፆታ ላላቸው ሰዎች መሳብ",
    "heterosexual": "ከተቃራኒ ጾታ ሰዎች ጋር በወሲብ ይስባል",
    "bisexual": "ሁለት ጾታ ያላቸው",
    "questioning": "ጥያቄ /እርግጠኛ አይደለሁም /አላውቅም",
    "prefer_not_to_disclose": "ላለመግለጽ ይምረጡ",
    "unknown": "ያልታወቀ",
    "orientation_not_listed": "ወሲባዊ ዝንባሌ አልተዘረዘረም",
    "not_applicable": "አልተፈቀደም",
    "pansexual": "ፓንሴክሹዋል",
    "queer": "ኩዊር"
  },
  "pronouns": {
    "title": "ተመራጭ ተውላጠ ስሞች",
    "he": "እሱ/ የራሱ",
    "she": "እሷ/የራስዋ",
    "they": "እነሱ/የእነሳቸው",
    "choose_not_to_disclose": "ላለመግለጽ ይምረጡ",
    "prefer_to_self_describe": "ራስን መግለፅን ይመርጣል"
  },
  "no_insurance_information": {
    "driver_license_or_state_id": "የመንጃ ፈቃድ ወይም የስቴት መታወቂያ ቁጥር",
    "driver_license": "መንጃ ፈቃድ",
    "use_ssn_instead": "በምትኩ SSN ን ይጠቀሙ",
    "social_security_number": "የማህበራዊ ዋስትና መለያ ቁጥር",
    "state": "ግዛት",
    "upload_front_of_driver_license": "የመንጃ ፈቃድዎን ወይም የመኖሪያ መታወቂያ ቁጥር ከፊት ለፊት ጫን",
    "choose_file": "ፋይል ይምረጡ",
    "no_file_chosen": "የተመረጠ ፋይል የለም",
    "no_identification": "መታወቂያ የለኝም",
    "insurance_card_has_a_back": "የእኔ ኢንሹራንስ ካርድ ጀርባ አለው",
    "upload_front_of_insurance_card": "የኢንሹራንስ ካርድህን ፊት ለፊት አውርድ።",
    "front_of_insurance_card_uploaded": "የኢንሹራንስ ካርድህ ፊት ለፊት ይጫናል።",
    "insurance_card": "የኢንሹራንስ ካርድ",
    "insurance_card_back": "የኢንሹራንስ ካርድ ጀርባ",
    "back_of_insurance_card_uploaded": "የኢንሹራንስ ካርድዎ ጀርባ ላይ ይጫናል።"
  },
  "quidel": {
    "certify_text": "ለዚህ ፈተና ውጤቱን የምመዘግበው አንድ ጊዜ ብቻ መሆኑን አረጋግጣለሁ።",
    "entering_results": "ውጤቶችዎን ያስገቡ",
    "review_instructions_1": "መመሪያዎቹን በጥንቃቄ ይከልሱ",
    "review_instructions_2": "ከፈተናዎ ጋር የመጡትን መመሪያዎች ደግሞው ያንብቡ ፣ የደረጃ-በደረጃ መመሪያን ያንብቡ ወይም ከዚህ በታች የማስተማሪያ ቪዲዮን ይመልከቱ-",
    "read_instructions": "መምርያዎቹን ያንብቡ",
    "watch_video": "ቪዲዮውን ይመልከቱ",
    "timer": "ረግረጋማውን ናሙና በቱቦ ውስጥ ከጣለ በኋላ ሰዓት ቆጣሪውን ይጀምሩ። ሰዓት ቆጣሪው ከ 1 ደቂቃ በኋላ ይቆማል (እርስዎ ከቧንቧው ውስጥ ያለውን  ናሙና ለማስወገድ)። እባክዎን ያስተውሉ -ፈተናዎ እንዲመጣ 10 ደቂቃዎችን ለመጀመር ሰዓት ቆጣሪውን እንደገና ማስጀመር አለብዎት።",
    "start_timer": "ሰዓት ቆጣሪውን ያስጀምሩ (ከተፈለገ)",
    "restart_timer": "አቁም እና እንደገና አስጀምር",
    "resume": " ከቆመበት ቀጥል",
    "one_minute_countdown": "ኣንድ ደቂቃ አለፈ! ቧንቧዎን ከቱቦው ያስወግዱ እና ሰዓት ቆጣሪውን ይቀጥሉ።",
    "take_photo": "የሙከራ ስትሪፕዎን ፎቶ ያንሱ",
    "photo_warning": "ለመቀጠል ፎቶ ማቅረብ አስፈላጊ ነው።",
    "enter_results": "የሙከራ ውጤቶችን ያስገቡ",
    "choose_result": "የእርስዎን የሙከራ ስትሪፕ በተሻለ የሚያንፀባርቅ አማራጭ ይምረጡ። እርዳታ ከፈለጉ? የምሳሌ ውጤቶችን ለማየት እዚህ ጠቅ ያድርጉ።",
    "positive": "አዎንታዊ",
    "negative": "አሉታዊ",
    "unknown": "ያልታወቀ",
    "clarify": "ውጤትዎን ግልፅ ያድርጉ",
    "strip_question": "የሙከራ ማሰሪያዎ የትኛውን ይመስል ነበር?",
    "no_lines": "መስመሮች የሉም",
    "pink_line": "ሮዝ መስመር ብቻ",
    "not_sure": "አሁንም እርግጠኛ አይደለሁም"
  },
  "ihealth": {
    "take_a_photo_of_your_test": "የውጤት ፎቶ",
    "retake_photo": "ፎቶን እንደገና አንሳ",
    "capture_photo": "ፎቶ አንሳ",
    "confirm_information_correct": "ከላይ ያለው መረጃ ትክክል መሆኑን አረጋግጣለሁ።",
    "submit": "አስረክብ",
    "title": "የ{{name}} ውጤቶችን አስገባ",
    "subtitle": "የ iHealth COVID-19 መተግበሪያን (app) ማውረድ ወይም መጠቀም አያስፈልግዎትም።",
    "instruction_title": "የ iHealth መመሪያዎችን ይመልከቱ",
    "instruction_guide": "መመሪያዎችን ያንብቡ",
    "video_guide": "ቪዲዮ ይመልከቱ",
    "add_photo": "ፎቶ አክል",
    "confirm_result": "ውጤቱን ያረጋግጡ",
    "result_warning": "ለማስገባት ውጤቱን ይምረጡ",
    "confirm_warning": "ለማስገባት ውጤቱን ያረጋግጡ",
    "administered_at_label": "ይህንን ፈተና መቼ ወሰዱት?",
    "instruction_subtitle": "ከሙከራዎ ጋር የመጡትን መመሪያዎች ይመልከቱ ወይም መመሪያዎቹን እዚህ ይከልሱ።"
  },
  "public_test_groups": {
    "title": "የኮቪድ-19 መመርመሪያ ዕቃዎች",
    "appointment_recoveries_title": "መመለስ?",
    "appointment_recoveries_button_text": "ማገናኛዬን (link) አግኝ",
    "search_test_group_title": "ለመጀመሪያ ጊዜዎ ነው?",
    "search_test_group_button_text": "አሁን መመዝገብ",
    "title_2": "ቀላል ምዝገባ እና ሪፖርት ማድረግ",
    "step_one": "የምዝገባ መለያ (Registration account)",
    "step_two": "መልሰው ለመላክ የምራቅ ናሙና ይሰብስቡ",
    "step_three": "ውጤቶችን በመስመር ላይ (online) ይመልከቱ",
    "title_3": "ኪትዎን ለማግበር ይመዝገቡ",
    "population_title": "ምዝገባ",
    "population_button": "ማንቃት",
    "faq_subtitle": "ተጨማሪ እወቅ",
    "faq_title": "ተደጋግሞ የሚነሱ ጥያቄዎች",
    "faq_1": "የሙከራ ኪትዬን እንዴት እመለሳለሁ?",
    "faq_1_footer": "የምራቅ ቀጥታ ፈንገስ መመሪያዎችን ይመልከቱ",
    "faq_2": "ተመዝግቤያለሁ ግን መለያዬን ረሳሁት።",
    "faq_3": "ኪትዬን እንዴት ማንቃት እችላለሁ?",
    "faq_2_subtitle": "እንደ ተመላሽ ተጠቃሚ፣ ከላይ ያለውን  '**ማገናኛዬን (link) አግኝ**' የሚለውን ቁልፍ በመጠቀም ሪፖርትህን ማረጋገጥ ትችላለህ።\"",
    "faq_3_subtitle": "እባኮትን ከታች ያሉትን አማራጮች በመጠቀም የመጀመሪያ ደረጃ.የጤና መለያ  ሂደትን ይሂዱ።",
    "footer_text_1": "ይህ ምርት ኤፍዲኤ (FDA) አልጸደቀም፣ ነገር ግን በኤፍዲኤ ለድንገተኛ ጊዜ አገልግሎት በ EUA ተፈቅዶለታል።",
    "footer_text_2": "ይህ ምርት ኑክሊክ አሲድ ከ SARS-CoV-2 ለመለየት የሚረዳ የምራቅ ናሙናዎችን ለመሰብሰብ እና ለመጠገን ብቻ የተፈቀደለት እንጂ ለሌላ ቫይረሶች ወይም በሽታ አምጪ ተውሳኮች አይደለም.",
    "footer_text_3": "የዚህን ምርት የአደጋ ጊዜ ጥቅም ላይ ማዋል የሚፈቀደው በፌዴራል የምግብ፣ የመድኃኒት እና የመዋቢያዎች ሕግ አንቀጽ 564(b)(1) መሠረት የሕክምና መሣሪያዎችን የድንገተኛ አጠቃቀም ፈቃድ የሚያረጋግጡ ሁኔታዎች መኖራቸውን ለተገለጸው መግለጫ ጊዜ ብቻ ነው። USC § 360bbb-3(b)(1)፣ መግለጫው ካልተቋረጠ ወይም ፈቃዱ ቶሎ ካልተሻረ በስተቀር።",
    "description": "\"ለእርስዎ ምቾት፣ ከዚህ በታች ያሉትን ቁልፎች በመጠቀም የሙከራ ኪትዎን ማግበር ይችላሉ።\"",
    "subtitle": "የመጀመሪያ ደረጃ.የጤና መለያ ለመጀመሪያ ጊዜ?",
    "subtitle_1": "ስለ የኮቪድ-19 መመርመሪያ ኪትዎ",
    "faq_1_1": "የተዘጋውን የናሙና ቱቦ ወደ ባዮ ናሙና ቦርሳ ውስጥ ያስገቡ",
    "faq_1_2": "የባዮ ናሙና ቦርሳውን ወደ ገባበት የምራቅ ዳይሬክት በተሰየመ ሳጥን ውስጥ ያስገቡ። የሳሊቫ (የምራቅ) ዳይሬክት የተሰየመውን ሳጥን ወደ FedEx UN 3373 Pak ያስገቡ።",
    "faq_1_3": "የ FedEx UN 3373 Pak ማጣበቂያውን ያስወግዱ እና ፓክን በጥብቅ ለመዝጋት በጥብቅ ይጫኑ።",
    "faq_1_4": "እባክዎን ናሙናዎን በወሰዱበት ቀን እና ከመጨረሻው የመውሰጃ ጊዜ በፊት ያውርዱ። በሳምንቱ መጨረሻ (ቅዳሜ እና እሁድ) ናሙናዎን ወደ መቆሚያ ሳጥን አታቅርቡ።",
    "faq_1_5": "የFedEx ድህረ ገጽን በ **[fedex.com/labreturns](https://www.fedex.com/labreturns)** ይጎብኙ እና የተቀመጡ ቦታዎችን ለማየት እና መርሃ ግብሮችን ለመውሰድ።",
    "demo": {
      "title": "COVID-19 at-home test kit additional information & instructions",
      "online_instructions": "Online instructions",
      "download_instructions": "Download instruction sheet",
      "begin": "Begin",
      "instructions": {
        "welcome_title": "Welcome to the at-home COVID-19 testing portal.",
        "welcome_text_1": "Before you start testing, wash you hands or use hand sanitizer.",
        "welcome_text_2": "Make sure your hands are dry before starting.",
        "continue": "Continue",
        "back": "Back",
        "step_one_title": "Step 1: Check your test kit",
        "step_one_text_1": "Locate the kit components:",
        "step_one_text_2": "It is recommended gloves (not provided) also be used during testing.",
        "step_two_title": "Step 2: Place tube in the tube holder",
        "step_two_text_1": "Remove cap from one **TUBE** and place it in the **TUBE HOLDER.**",
        "step_two_text_2": "**NOTE:** Use of gloves is recommended.",
        "step_five_title": "Step 5: Remove swab from the tube",
        "step_five_text_1": "After **ONE MINUTE**, remove the swab from the **TUBE** by rubbing the swab head against the inside wall of the tube to squeeze out as much liquid as possible.",
        "step_five_text_2": "Dispose of swab in the trash.",
        "step_six_title": "Step 6: Open the test strip",
        "step_six_text_1": "Open the **TEST STRIP** pouch carefully at the slit and hold the **TEST STRIP** as indicated.",
        "step_seven_title": "Step 7: Place test strip in the tube",
        "step_seven_text_1": "Place the **TEST STRIP** into the **TUBE** with the arrows pointing down. Leave the strip in the **TUBE** for a **FULL TEN MINUTES**– do not handle or remove.",
        "step_eight_title": "Step 8: Remove test strip from the tube",
        "step_eight_text_1": "At **TEN MINUTES**, remove the **TEST STRIP** from the **TUBE**. Next, place the **TEST STRIP** on the outline in Steps 10-12. Ensure the **TEST STRIP** is on a flat surface in good lighting.",
        "step_eight_text_2": "**Note:** The test is intended to be read at 10 minutes. If the test is read before this or is read more than 5 minutes after the indicated read time, results may be inaccurate (false negative, false positive, or invalid) and the test should be repeated.",
        "step_nine_title": "Step 9: Check your results",
        "step_nine_text_1": "There are three type of results possible.",
        "step_nine_text_2": "Check for a Positive Result",
        "step_nine_text_3": "Check for a Negative Result",
        "step_nine_text_4": "Check for an Invalid Result",
        "step_ten_title": "Step 10: Remove test strip from the tube",
        "step_ten_text_1": "Place the **TEST STRIP** on the test strip outline below and compare with test result examples shown.",
        "step_three_title": "Step 3: Swab the nostrils",
        "step_three_text_1": "Remove the **SWAB** from its wrapper, being careful not to touch the **SWAB** head.",
        "step_three_text_2": "Gently insert the **SWAB** ½ to ¾ of an inch into the nostril, depending on the size of the person’s nose. Firmly rub the **SWAB** in a circular motion around the inside wall of EACH NOSTRIL at least 4 times.",
        "step_three_text_3": "Be sure to rub BOTH nostrils with the SAME SWAB.",
        "step_three_text_5": "**NOTE:** Failure to swab properly may cause false negative results.",
        "step_four_title": "Step 4: Place swab in the tube",
        "step_four_text_1": "Immediately place the **SWAB** into the liquid inside the **TUBE**, and ensure it is touching the bottom. Stir 3-4 times.",
        "step_four_text_2": "Leave the swab in the solution for **ONE MINUTE**.",
        "step_four_text_3": "**NOTE**: If the swab is in the solution for more than 10-minutes it should not be used.",
        "step_three_text_4": "**NOTE:** If you are swabbing others, please wear a face mask. With children, you may not need to insert the swab as far into the nostril. For very young children, you may need another person to steady the child’s head while swabbing."
      },
      "choose_language": "Choose your language"
    }
  },
  "self_resulting": {
    "report_result": "ውጤት ይግለፁ",
    "report_result_subtitle": "የእርስዎ ድርጅት ራስን መፈተሽ ቢያቀርብ፣ እና ውጤቱን ሪፖርት ለማድረግ ዝግጁ ከሆንክ፣ እባክዎ ከታች ያለውን ቁልፍ ይጫኑ።",
    "need_help": "እርዳታ ያስፈልጋል?",
    "assistance": "እርዳታ የሚያስፈልግዎ ከሆነ፣ ወይም የእርስዎን አድራሻ መረጃ ለመቀየር፣ እባክዎ ድጋፍ ማዕከል ን ያነጋግሩ።",
    "contact_support": "ድጋፍ ሰጪ ያጊኙ",
    "save_link": "ውጤቱን ከጊዜ በኋላ ሪፖርት ለማድረግ ይህን ሊንክ አስቀምጥ።",
    "send_via_email": "በኢሜይል በኩል አገናኝ መላክ",
    "send_via_text": "በጽሑፍ በኩል አገናኝ መላክ",
    "copy_link": "አገናኝ መገልበጥ",
    "resend_email": "ኢሜይል ማረጋገጫ መልሶ መላክ",
    "phone_verified": "ስልክ ተረጋግጧል",
    "add_vaccination_status": "የ COVID-19 ክትባት ደረጃ ይጨምሩ/ያድሱ",
    "terms_of_service": "የአገልግሎት መስፈርት",
    "verify_contact_information": "የአድራሻመረጃዎን አረጋግጥ፣ እርስዎ ውጤት እና የግንኙነት ግንኙነት ማግኘት ይችላሉ። የእርስዎን አድራሻ መረጃ መቀየር ካስፈለገ፣ እባክዎ የእኛን የድጋፍ ማእከል ያነጋግሩ።",
    "resend_text": "በድጋሚ በ SMS የጽሑፍ ማረጋገጫ ይላኩ",
    "loading": "በመጫን ላይ",
    "add_to_account": "ወደ አካውንት ጨምር",
    "hi": "ታዲያስ",
    "email_verified": "የተረጋገጠ ኢሜይል",
    "go_to_my_account": "ወደ አካውንቴ ሂዱ",
    "activate_kit": "የምርመራ ማሽኑን ያስጀምሩ",
    "report_custom_result": "ውጤቶን ሪፖርት ያድርጉ {{test}}",
    "activate_custom_test": "መመርመርያዉን ያስጀምሩ  {{test}}",
    "update_vaccination_status": "የክትባት  መራጀዎትን የሻሽሉ",
    "order_a_test_kit": "የሙከራ ኪት ይዘዙ"
  },
  "enter_information": {
    "contact_info": "የአድራሻ መረጃዎን ያስገቡ",
    "label": "ስልክ ወይም ኢሜይል",
    "invalid": "ይህ ትክክለኛ የኢሜይል ወይም የስልክ ቁጥር አይደለም።",
    "send": "ወደ ስልክዎ ወይም ኢሜይልዎ ኮድ እንልካለን",
    "legal": "የስልክ ቁጥርዎን ወይም ኢሜልዎን በመግባት የመጀመሪያ ደረጃ ጤናዎን ይስማማሉ  **[Terms of Service](https://primary.health/terms-of-service/)** and **[Privacy Policy](https://primary.health/privacy-policy/)**.",
    "subtitle": "የእርስዎን የህክምና ታሪክ ወይም የምርመራ ውጤት ሪፖርት ለማድረግ በ ጽሑፍ ወይም ኢሜል እንልክልዎታለን!",
    "find_link": "ተመዝግቧል?ሊንኩ እሄው ",
    "not_registered": "አልተመዘገበም? ጣቢያዎን ይፈልጉ",
    "search_by_keyword": "በስም ፣ በቁልፍ ቃል ፣ በከተማ ፣ በዚፕ ወይም በድርጅት ኮድ ይፈልጉ!",
    "search": "ፈልግ",
    "register_here": "እዚህ ይመዝገቡ"
  },
  "contact_support": {
    "invalid": "የማይረባ መረጃ",
    "invalid_explanation": "በቅርቡ ወደ Primary.Health ጋር የምልክት አገናኝ ጥያቄ አቅርበሃል። ይሁን እንጂ፣",
    "never_registered": "ከዚህ በፊት አልተመዘገበም? እባክዎ ለሙከራ አገልግሎት ሰጪዎ ለምዝገባ አገናኝ ያግኙ ወይም ጣቢያዎን ከታች ያግኙት።",
    "get_help": "የእርስዎን መዝገብ ከአድራሻዎ መረጃ ጋር ለማጣጣም እርዳታ ማግኘት ይችላሉ",
    "get_help_calling": "ወይም በመደወል",
    "invalid_explanation_bold": "የሰጠኸው መረጃ ተቀባይነት የለውም ።",
    "get_help_label": "እርዳታ ያግኙ",
    "mobile": {
      "invalid_explanation": "ወደ መለያህ ሊታከል የሚችል ምንም ተጠቃሚዎች አልተገኙም።",
      "get_help": "ማንኛውም ተጨማሪ ጥያቄዎች ካሉዎት እባክዎ ያነጋግሩ",
      "reason": "ሁሉም የ Primary.Health ተጠቃሚዎች ለአዲሱ የመለያ ባህሪያችን ብቁ አይደሉም። አንዴ ብቁ ከሆኑ፣ ከ Primary.Health ወይም ከቡድን አስተዳዳሪዎ ግብዣ ይደርሰዎታል።"
    },
    "zen_name": "የእውቂያ ስም",
    "ticket_type": "ይህ ምንን ይመለከታል",
    "appointment_access_code": "የማረጋገጫ ኮድ (የሚታወቅ ከሆነ)",
    "zen_desc": "መልእክት",
    "contact_us": "አግኙን",
    "need_assistance": "እርዳታ ከፈለጉ ወይም የአድራሻዎን መረጃ መቀየር ከፈለጉ፣ እባክዎን ከድጋፍ ሰጪ ጋር ይገናኙ።"
  },
  "show_appointments": {
    "welcome": "የእርስዎን መገለጫ ወይም ፕሮፋይል  ያግኙ",
    "welcome_subtitle_1": "ከታች ያለው ውጤት የ",
    "added_to_account": "ወደ መለያ ታክሏል።",
    "error": "ይህ ተሳታፊ አስቀድሞ መለያ ሊኖረው ይችላል።",
    "welcome_subtitle_2": "ከታች ያለውን ስም አይታይም?",
    "welcome_not_logged_in": "ወደ ድህረ ገፁ የሚወስዶትን ሊንክ እዚጋ ያገኛሉ",
    "welcome_logged_in": "ቡድንዎን ይምረጡ",
    "welcome_subtitle_1_logged_in": "አባል ማከል ለመጨረስ ተጠቃሚውን እና ቡድኑን ይምረጡ። አባላት በአንድ ቡድን ዉስጥ ነው የሚመደቡት።",
    "welcome_subtitle_2_logged_in": "ከላይ ከተዘረዘሩት ውስጥ የርስን አባል ካላገኙ",
    "welcome_subtitle_1_not_logged_in": "መግባት የሚፈልገውን ሰው ያስገቡ ወይም ደግሞ አዲስ አበባል ይጨምሩ. አባል አላገኙም ?",
    "welcome_subtitle_3_logged_in": "የተለየ የመገናኛ ዘዴ ይሞክሩ",
    "welcome_subtitle_4_logged_in": "ወይም ደግሞ በዚህ አድራሻ ያገኙን",
    "welcome_subtitle_2_not_logged_in": "ፍለጋን አዘምን",
    "welcome_subtitle_3": "ፍለጋን አዘምን"
  },
  "enter_code": {
    "code": "ኮዱን ምንድን ነው?",
    "enter_code": "ወደ ተላከበት ኮድ ያስገቡ",
    "incorrect_code": "የተሳሳተ ኮድ ገብተሃል።",
    "verify": "አካውንት ያረጋግጡ",
    "verified": "የተረጋገጠ",
    "verify_account_information": "መለያዎን ያረጋግጡ"
  },
  "general_self_checkout": {
    "choose_test": "ምን ፈተና ፈተሽ?",
    "photo_required": "የውጤት ፎቶ ያስፈልጋል",
    "find_your_test": "ፈተናዎን ያግኙ"
  },
  "login": {
    "create_account": "አካውንት ይፍጠሩ",
    "create_account_help": "መለያ መፍጠር ለፈተና ወይም ለክትባት ከመመዝገብ ጋር አንድ አይነት አይደለም። አንዴ ከተመዘገቡ",
    "create_account_help_schedule": "ቀጠሮ ማስያዝ",
    "create_account_help_record": "የቤት ውስጥ ፈተናዎችን መዝግበው",
    "create_account_help_match": "ክትባቶች እና የምርመራ መዝገቦችን ያስተዳድሩ።",
    "continue": "እንደ እንግዳ ቀጥል",
    "already": "አሁንስ ዘገባ አለህ? ይጫኑ",
    "login": "ለመግባት",
    "or_sign_in": "ወይም በሚከተለዉ ይግቡ፡",
    "no_account": "አካውንት አለህ? ይጫኑ",
    "signup": "ለመክፈት።",
    "here": "ይህን",
    "email_address": "የኢሜይል አድራሻ፦",
    "password": "የይለፍ ቃል፦",
    "complete_registration": "ምዝገባ ይጨርሱ",
    "last_covid_test": "የመጨረሻው COVID-19 የፈተና ውጤት",
    "no_record": "የተመዘገበ የለም",
    "viewing_information": "{{first_name}}'s መረጃ በማየት ላይ",
    "download": "አዉርድ",
    "not_registered": "ወደ ማንኛውም ቡድኖች ያልተመዘገቡ ይመስላል",
    "please_follow_link": "እባክዎከ ከኢሜይልዎ የመጋበዣ አገናኝ ወደ ፕሮግራም ይከተሉ እና መረጃዎን ከPrimary.Health ጋር ያግኙ።",
    "log_results": "የእኔን የፈተና ውጤቶች አስቀምጥ",
    "book_appointment": "ቀጠሮ ያዝ",
    "no_new_appointments": "በአሁኑ ጊዜ አዳዲስ ቀጠሮዎችን አለመቀበል",
    "upload_vaccinations": "የክትባት ይጫኑ",
    "vaccination": "ክትባት",
    "new_appointment": "አድስ ቀጠሮ",
    "log_otc_results": "የ OTC ምርመራ ውጤት ማስገቢያ",
    "no_household_members": "ገና የቤተሰብ አባላት የሉህም።",
    "no_upcoming": "የሚመጣ ቀጠሮ የለም",
    "update_vaccination": "የክትባት ማሻሻያ",
    "upload_vaccination": "ክትባት ያስገቡ",
    "title": "ወደ Primary.Health እንኳን ደህና መጡ",
    "sub_title": "Before registering please create an account.",
    "p1": "አካውንት መፍጠር የሚያስችልዎት፡",
    "p2": "ወደፊት ቀጠሮ ለመያዝ",
    "p3": "የ COVID-19 የቤት ውስጥ ምርመራዎችን ለመመዝገብ",
    "p4": "ያስተዳድሩ ጥገኛ ክትባቶች እና የሙከራ መዝገቦች",
    "p5": "ቀላል የመግቢያ ሂደት ከ my.primary.health",
    "no_vaccination_record": "እስካሁን ምንም ክትባት አልተጨመረም።",
    "vaccination_title_card": "የኮቪድ-19 ክትባት ሁኔታ",
    "account_and_settings": "መለያ ማደራጃ",
    "delete_account": "መለያ ሰርዝ",
    "language": "ቋንቋ",
    "language_current": "የአሁኑ ቋንቋ፡ {{language}}",
    "log_out": "ውጣ",
    "delete_account_title": "እርግጠኛ ነህ መለያህን መሰረዝ ትፈልጋለህ?",
    "new_encounter": "የ{{first_name}} አዲስ ገጠመኝ",
    "new_encounter_subtitle": "ይህ አዲስ ገጠመኝ ለየትኛው ቡድን ነው?",
    "no_programs": "በማንኛውም ፕሮግራም ያልተመዘገቡ ይመስላሉ።",
    "edit_members": "አባላትን ያርትዑ",
    "edit_members_subtitle": "የትኛውን ዳሽቦርድ መሄድ እንደሚፈልጉ ይምረጡ ወይም ሌላ አባል ወደ መለያዎ ያክሉ",
    "add_members": "አባል ጨምር",
    "delete_member": "የ{{full_name}} የተጠቃሚ መለያ ይሰረዝ?",
    "delete_member_subtitle": "ተጠቃሚውን ማስወገድ ከመለያው ጋር የተያያዙ ሁሉንም መረጃዎች ይሰርዛል.",
    "select_member": "የአባል ምርጫ",
    "edit_appointment": "ቀጠሮ ያርትዑ",
    "route": {
      "me": "ዳሽቦርድ",
      "history": "ታሪክ",
      "programs": "ፈተና ጨምር",
      "contact_us": "እገዛ",
      "choose_member": "አባል",
      "settings": "ቅንብሮች",
      "help": "እገዛ"
    },
    "user_dashboard": "የ{{first_name}} ዳሽቦርድ",
    "view_full_results": "በውጤቶች ፖርታል ውስጥ ይመልከቱ",
    "required": "ዋናውን መተግበሪያ እየተጠቀሙ ከሆነ ያስፈልጋል።",
    "no_email": "የኢሜል አድራሻ የለኝም"
  },
  "vaccination_status": {
    "not_vaccinated": "አልተከተበም",
    "fully_vaccinated": "ሙሉ ለሙሉ ተከትቧል",
    "partially_vaccinated": "በከፊል የተከተበ",
    "vaccination_records": "የክትባት መዝገቦች",
    "title": "የኮቪድ-19 ክትባት ሁኔታዎ ምን ያህል ነው?",
    "definition_title": "የክትባት ሁኔታ መግለጫዎች",
    "definition_cdc": "የ CDC የክትባት ሁኔታ ፍቺ",
    "definition_osha": "OSHA የክትባት ሁኔታ ፍቺ",
    "definition": {
      "fully_vaccinated": "ከ 2 ሳምንታት በኋላ በ 2 ዶዝ ተከታታይ Pfizer-BioNTech ወይም Moderna ወይም 1 ዶዝ የጆንሰን እና ጆንሰን Janssen ክትባት።",
      "partially_vaccinated": "በአንደኛ ደረጃ ተከታታዮች ከ 2 ዶዝዎች 1 ብቻ ወይም በ 2 ዶዝ ተከታታዮች ወይም 1 ዶዝ ጆንሰን እና ጆንሰን Janssen የመጨረሻውን መጠን ከተቀበለ ከ 2 ሳምንታት ባነሰ ጊዜ ውስጥ።",
      "not_vaccinated": "የኮቪድ-19 ክትባት አልተሰጠም።",
      "fully_vaccinated_with_one_booster": "የPfizer-BioNTech ወይም Moderna ክትባቶች ከሙሉ ተከታታይ ወይም 1 ዶዝ የጆንሰን እና ጆንሰን ጃንሰን ክትባት በኋላ ከፍ ያለ መጠን ወስደዋል።",
      "fully_vaccinated_with_two_boosters": "የPfizer-BioNTech ወይም Moderna ክትባቶች ሙሉ ተከታታይ ወይም የጆንሰን እና ጆንሰን ጃንሰን ክትባት እና የማጠናከሪያ መጠን ከወሰዱ በኋላ ሁለተኛ የማጠናከሪያ መጠን ተቀብለዋል።"
    },
    "label": {
      "fully_vaccinated": "ሙሉ በሙሉ የተከተቡ",
      "partially_vaccinated": "በከፊል የተከተቡ",
      "not_vaccinated": "አልተከተበም።",
      "fully_vaccinated_with_one_booster": "ሙሉ በሙሉ የተከተቡ + ማበረታቻ ወሰደ (booster)",
      "fully_vaccinated_with_two_boosters": "ሙሉ በሙሉ የተከተቡ + 2 ማበረታቻዎች/ booster",
      "prefer_not_to_answer": "ላለመመለስ እመርጣለሁ።"
    },
    "progress_bar_title": "የኮቪድ-19 ክትባት ታሪክ",
    "upload_record_card": "የኮቪድ-19 የክትባት መዝገብ ካርድዎን ይስቀሉ።",
    "show_example": "ምሳሌ አሳይ",
    "hide_example": "ምሳሌ ደብቅ",
    "take_photo": "ፎቶ ለማንሳት ይንኩ።",
    "or": "ወይም",
    "upload_image_or_pdf_instead": "በምትኩ ምስል/ፒዲኤፍ ስቀል",
    "select_doses_received": "የተቀበሏቸውን ሁሉንም የኮቪድ-19 የክትባት መጠኖች ይምረጡ።",
    "first_dose": "የመጀመሪያ መጠን",
    "second_dose": "ሁለተኛ መጠን",
    "first_booster_dose": "የመጀመሪያው የማጠናከሪያ መጠን",
    "second_booster_dose": "ሁለተኛ የማጠናከሪያ መጠን",
    "additional_dose": "ተጨማሪ መጠን",
    "first_dose_manufacturer": "ለመጀመሪያ መጠንዎ የኮቪድ-19 አምራች ማን ነበር?",
    "first_dose_date": "የመጀመሪያ መጠንዎ ቀን ስንት ነበር?",
    "second_dose_manufacturer": "ለሁለተኛ መጠንዎ የኮቪድ-19 አምራች ማን ነበር?",
    "second_dose_date": "የሁለተኛው መጠንዎ ቀን ስንት ነበር?",
    "first_booster_dose_manufacturer": "ለመጀመሪያው የማጠናከሪያ መጠንዎ የኮቪድ-19 አምራች ማን ነበር?",
    "first_booster_dose_date": "የመጀመሪያው የማጠናከሪያ መጠንዎ ስንት ቀን ነበር?",
    "second_booster_dose_manufacturer": "ለሁለተኛው የማበረታቻ መጠንዎ የኮቪድ-19 አምራች ማን ነበር?",
    "second_booster_dose_date": "የሁለተኛው የማበረታቻ መጠንዎ ቀን ስንት ነበር?",
    "additional_dose_manufacturer": "ለተጨማሪ መጠንዎ የኮቪድ-19 አምራች ማን ነበር?",
    "additional_dose_date": "የተጨማሪ መጠንዎ ቀን ስንት ነበር?",
    "completed_one": "ተጠናቀቀ",
    "completed_two": "የክትባት ሁኔታዎን ስላዘመኑ እናመሰግናለን!",
    "progress_bar_complete": "ተጠናቀቀ",
    "upload_image": "ምስል ስቀል",
    "retake_photo": "ፎቶ እንደገና አንሳ",
    "other": "ሌላ",
    "remove_first_dose": "የመጀመሪያ መጠን ታሪክን ያስወግዱ",
    "remove_second_dose": "የሁለተኛ መጠን ታሪክን ያስወግዱ",
    "remove_first_booster_dose": "የመጀመሪያውን የማጠናከሪያ መጠን ታሪክን ያስወግዱ",
    "remove_second_booster_dose": "የሁለተኛ አበረታች መጠን ታሪክን ያስወግዱ",
    "remove_additional_dose": "ተጨማሪ የመጠን ታሪክን ያስወግዱ",
    "exemption": "ነፃ (exempted) መብት አለህ?",
    "exempt": "ነፃ (exempted) ነኝ",
    "not_exempt": "ነፃ (exempted) አይደለሁም።",
    "enter_exemption": "ነፃ (exempted) መሆንን አስገባ",
    "upload_exemption_documentation": "ነጻ መሆንዎን የሚያሳይ ሰነድ ይስቀሉ።",
    "remove_dose": "መጠኑን ያስወግዱ",
    "continue": "ቀጥል",
    "enter_credentials": "እባክዎ ምስክርነቶችዎን ያስገቡ",
    "incorrect_credentials": "የተሳሳቱ ምስክርነቶች፣ እባክዎ እንደገና ይሞክሩ።",
    "add_photo": "ፎቶ ያክሉ"
  },
  "mailers": {
    "appointment_mailer": {
      "payment_pending": "ሰላም ነው {{name}}, መርሐግብርዎን እንዳልጨረሱ አስተውለናል {{group_name}} ጋር ቀጠሮ {{org_name}}. ቀጠሮዎን ለማስያዝ የክፍያውን ደረጃ ማጠናቀቅ ያስፈልግዎታል። \\nእባክዎ ሂደቱን ለማጠናቀቅ ይህንን ሊንክ ይጫኑ {{complete_url}}. ቀጠሮዎን መሰረዝ ከፈለጉ እባክዎን ይህንን ሊንክ ይጫኑ {{cancel_url}}. እናመሰግናለን"
    }
  },
  "otc": {
    "record_results_for_abbott": "ለባይናክስ ሙከራ ውጤቶትን ይሞክሩ እና ይመዝግቡ",
    "record_results": "ይመርመሩና እና ውጤቶትን ይመዝግቡ",
    "activate_a_kit": "መመርመርያዉን ያስጀምሩ",
    "activate_a_kit_for_test": "መመርመርያዉን ለ {{test}} ያስጀምሩ",
    "todays_test_process": "የዛሬው የሙከራ ሂደት",
    "date_of_birth_question": "ምንድነው {{first_name}}'s የትውልድ ቀን?",
    "confirm_dob_to_continue": "እባክዎ ለመቀጠል የተሳታፊውን የልደት ቀን ያረጋግጡ",
    "incorrect_dob": "የተሳሳተ የልደት ቀን",
    "file_too_large": "እባክዎ ኢዝ ላይ ያለዉ ፋይል መጠን ከዚህ {{size}} ያነሰ መሆኑን ያረጋግጡ",
    "enter_valid_date_in_last_x_days": "በመጨረሻ የሚሰራ ቀን አስገባ  {{n}} ቀን",
    "barcode_format_not_valid": "የተጠቀሙት ባርኮድ ልክ ያልሆነ ነዉ",
    "complete_button": "ያጠናቅቁት",
    "next_button": "ቀጣይ",
    "back_button": "ተመለስ",
    "report_a_test": "ምርመራዎን ሪፖርት ያድርጉ",
    "x_%_completed": "{{n}}%ተጠናቀቀ",
    "answer_survey": "የዳሰሳ ጥናት ጥያቄዎችን ይመልሱ",
    "steps": {
      "answer_survey": "የዳሰሳ ጥናት መልስ",
      "review_instructions": "መመሪያዎችን ያንብቡ",
      "scan_code": "ኮዱን እስካን ያድችጉ",
      "enter_time": "ጊዜ ወይም ሰአት ያስገቡ",
      "enter_result": "ውጤቱን ያስገቡ",
      "take_photo": "ፎቶ  ያንሱ",
      "confirm_result": "ውጤቱን ያረጋግጡ",
      "selest_test_kit": "የምርመራ ዓይነት ይምረጡ",
      "enter_date": "ቀን ያስገቡ",
      "enter_date_and_time": "ቀን እና ሰዓት ያስገቡ"
    },
    "review_instructions": "የምርመራ መመሪያዎችን ይመልከቱ",
    "follow_instructions_or_below": "ከሙከራዎ ጋር የመጡትን መመሪያዎች ይከተሉ ወይም ከታች ያሉትን መመሪያዎች ያንብቡ",
    "watch_video": "ቪዲዮ ይመልከቱ",
    "view_instructions": "መመሪያዎችን ይመልከቱ",
    "follow_instructions": "ምችመራ ጋር የመጡትን መመሪያዎች ይከተ",
    "scan_code": "የምርመራ QR ኮድን እስካን ያድችጉ",
    "qr_code_description": "ባርኮዱ ለሙከራዎ ልዩ ነው እና ሙከራዎ ላይ ሊገኝ ይችላል።",
    "show_example": "ምሳሌ ይመልከቱ",
    "hide_example": "ምሳሌዉን ደብቅ",
    "barcode_scanned": "ባር ኮዱ በተሳካ ሁኔታ ስካን ተደርጔል",
    "enter_barcode": "የባር ኮድ ያስገቡ",
    "scan_qr_code": "QR ኮዱን እስካን ያድርጉ",
    "which_test_did_you_take": "ምን አይነት ምርመራ ነው የወሰዱት?",
    "when_did_you_take_test": "ይህንን ምርመራ መቸ ነዉ የወሰዱት?",
    "what_day_did_you_take_test": "ይህንን ምርመራ መች ቀን ነው ያደረጉት?",
    "what_time_did_you_take_test": "ይህንን ምርመራ ስንት ሰዓት ነዉ የወሰዱት?",
    "time": {
      "today": "ዛረ",
      "yesterday": "ትናንት",
      "two_days_ago": "ከ 2 ቀናት በፊት"
    },
    "enter_current_time": "የአሁኑን ጊዜ እና ሰአት ያስገቡ",
    "enter_result": "የምርመራዉን ውጤት ያስገቡ",
    "choose_result_option": "የምርመራ ካርድዎን ውጤት የሚገልጽ አማራጭ ይምረጡ",
    "clarify_results": "ውጤቶትን ግልጽ ያድርጉ",
    "take_result_photo": "ውጤቱን ፎቶ ያንሱ",
    "take_result_photo_description": "ውጤቶችዎን ለመመዝገብ የተጠናቀቀውን የውጤት ካርድ ፎቶ ያንሱ",
    "my_result": "የኔ ውጤት",
    "switch_camera": "ካሜራዉን ወደርሶ ያዙሩ",
    "confirm_result": "ውጤቱን ያረጋግጡ",
    "name": "ስም",
    "date": "ቀን",
    "result": "ውጤት",
    "results": "ውጤቶ",
    "test_submitted": "አናመሰግናለን {{first_name}}! የእርሦ {{test}} በተሳካ ሁኔታ ገብቷል",
    "dob": "የትውልድ ቀን",
    "administered": "የሚተዳደር",
    "or": "ወይም",
    "upload": "ይጫኑ",
    "change_uploaded_file": "አሁን ያለዉን ፋይል ይቀይሩ",
    "take_photo": "ፎቶ  ያንሱ",
    "confirm_information": "መረጃዉን ያረጋግጡ",
    "barcode": "የባር ኮድ",
    "scan_test_barcode": "የምርመራ  ኮድን እስካን ያድችጉ",
    "barcode_description": "ባርኮዱ ለሙከራዎ ልዩ ነው እና ሙከራዎ ላይ ሊገኝ ይችላል።",
    "enter_date_in_last_30_days": "ባለፉት 30 ቀናት ውስጥ  ምርመራዉን የወሰደቦትን ግምታዊ ቀን እና ጊዜ ያስገቡ",
    "add_image": "ፎቶ ያስገቡ",
    "change_image": "ፎቶ ይቀይሩ",
    "skip_barcode_reader": "ኮዱን ስካን ማረግ አልቻሉም? ለማንኛውም የምርመራዉን ውጤት ሪፖርት ለማድረግ ቀጣይ የሚለውን ይጫኑ",
    "enter_date_and_time": "የፈተናውን ቀን እና ሰዓት ያስገቡ",
    "enter_date": "የፈተና ቀን ያስገቡ",
    "did_you_take_test_today": "ዛሬ ምርመራውንወስደዋል?",
    "record_results_for_generic": "ለ {{name}} ምርመራ፣ ይመርምሩ እና የእርስዎን ውጤቶችዎን ይመዝግቡ",
    "choose_result_option_custom": "የእርስዎን {test_name} ውጤት የሚገልጽ አማራጭ ይምረጡ፡-"
  },
  "yes": "አዎ",
  "no": "አይ",
  "event_token": {
    "title": "የሙከራ ኪትዎን ለማሰራጨት እባክዎ ከታች ያለውን ኮድ ወደ መሸጫ ማሽን ያስገቡ",
    "loading_text": "ኢየፈለገ ነው፣ እባክዎ ከአንድ ደቂቃ በኋላ ተመልሰው ይሞክሩ"
  },
  "appointment_recovery": {
    "complete_title": "ዝግጁ ነዎት!",
    "subtitle_1": "አይፎን እየተጠቀሙ ከሆነ ወደ ዳሽቦርድዎ ለመመለስ በዚህ ስክሪኑ ላይኛው ግራ ጥግ ላይ ያለውን **ተከናውኗል** ይንኩ።",
    "subtitle_2": "አንድሮይድ እየተጠቀሙ ከሆነ በዚህ ስክሪን ላይኛው ግራ ጥግ ላይ ያለውን **X** መታ ያድርጉ።",
    "subtitle_0": "ለመቀጠል እባክዎን ከአሳሹ ይውጡ።"
  },
  "primary": {
    "support_email": "support@primary.health"
  },
  "consented_relationship": "የተፈቀደ ግንኙነት",
  "finish_registration": {
    "title": "ምዝገባዎን ያጠናቅቁ",
    "subtitle": "እባክዎ ከቀጠሮዎ በፊት የሚከተለውን መረጃ ይሙሉ፡-",
    "button": " ምዝገባዎን ይጨርሱ"
  },
  "arab_ethnicity": {
    "arab": "አረብ",
    "non_arab": "አረብ ያልሆኑ",
    "prefer_not_to_answer": "ላለመመለስ እመርጣለሁ።",
    "question": "የአረብ ብሄረሰብ",
    "subtitle": "የሚከተሉትን መረጃዎች እንድንሰበስብ ደንቦቹ ያስፈልጉናል።",
    "help": "ብሔረሰብ ማለት እንደ ቋንቋ፣ባህላዊ ልማዶች፣ሀይማኖት እና ሌሎች የሰዎች ቡድኖችን ለመለየት የሚያገለግሉ የጋራ ባህሪያት ስብስብ ነው። ከዘር ማንነት ጋር ሊጣጣምም ላይሆንም ይችላል።"
  },
  "custom_comms_quick_add": {
    "mobile_app_links": {
      "email_text": "በሚገለገሉበት መሳሪያ መሰረት፣ የመጀመሪያ ጤና ማለፊያ / Primary Health Pass ለማውረድ/ዳውንሎድ ለማድረግ ከታች የሚገኘውን ሊንክ ይጫኑ።"
    }
  },
  "stepper": {
    "next": "ቀጥሎ",
    "back": "ተመለስ",
    "complete": "ተጠናቀቀ"
  },
  "registration_direcory": {
    "keyword_search_label": "ቁልፍ ቃል, ከተማ, ዚፕ ኮድ, የድርጅት ስም",
    "keyword_search_label_mobile": "ከተማ ፣ ዚፕ ኮድ ፣ የድርጅት ስም",
    "zero_results": {
      "title": "በመረጡት ቃል ፈልገን አቅራቢዎችን አላገኘንም።",
      "sub_title": "አቅራቢዎን በሚከተለው መንገድ ለመፈለግ ይሞክሩ፦",
      "p1": "አካባቢያዊ መለያ ቁጥር",
      "p2": "የከተማ ወይም የከተማ ስም",
      "p3": "የክልል ስም",
      "p4": "የአቅራቢ ወይም የድርጅት ስም"
    }
  },
  "registration_flyer": {
    "open_camera": "በመሳሪያዎ ላይ የካሜራ መተግበሪያን ይክፈቱ",
    "point_to_qr_code": "ወደ QR ወይም ስካን ኮድ ያመልክቱ እና አገናኙን ወይም ሊንኩን ይንኩ።",
    "complete_registration": "ምዝገባዎን ያጠናቅቁ",
    "need_help": "እርዳታ ያስፈልጋል? በዚህ አድራሻ ሊያገኙን ይችላሉ።።"
  },
  "vaccine_info_sheets": {
    "vaccine_info_attestation": "የክትባት መረጃን ማረጋገጫ",
    "select_checkbox": "እባክዎን የሚከተለውን ያገምግሙ እና ከመቀጠልዎ በፊት ከታች ያለውን ተገቢ የሆነውን ማረጋገጫ ሳጥን (checkbox) ይምረጡ።",
    "opportunity_to_read": "የሚሰጡ ክትባት(ቶች) ላይ የክትባት መረጃ መግለጫ(ዎች)ን (“VIS”) ወይም የአደጋ ጊዜ መጠቀሚያ ፈቃድ (“EUA”) ማብራሪያ ፅሁፍ ወረቀትን አንብቢያለሁ ወይም ለማንበብ እድል ተሰጥቶኛል፣ ወይም ተነቦልኛል።",
    "vaccine_info_sheet": "የክትባት መረጃ ፅሁፍ ወረቀት",
    "vaccine_fact_sheet": "የክትባት ማብራሪያ ፅሁፍ ወረቀት",
    "vaccine_info_statement": "እንቅስቃሴ መረጃ መግለጫ"
  },
  "exemption": {
    "dtap_tdap": "DTaP/Tdap",
    "mmr": "ኩፍኝ፣ ደግፍ እና ኩፍኝ (MMR)",
    "ipv_opv": "ፖሊዮ",
    "hib": "የሂሞፊለስ ኢንፍሉዌንዛ ዓይነት (HIB)",
    "hep_b": "ሄፓታይተስ ቢ (HEPB)",
    "var": "ቫሪሴላ",
    "hep_a": "ሄፓ(HEPA)",
    "pcv": "ፒሲቪ(PCV)",
    "mcv": "ኤም.ሲ.ቪ(MCV)"
  },
  "test_strip": {
    "A": "ይሄ ኪት ለ chlamydia እና ለ gonorrhea ምርመራዎችን ያካትታል።",
    "B": "ይሄ ኪት ለ chlamydia እና ለጨብጥ ምርመራዎችን ያካትታል።",
    "C": "ይሄ ኪት ለቂጥኝ ምርመራዎችን ያካትታል።",
    "D": "ይህ የምርመራ እቃ ወይም ትቦ የኤችአይቪ፣ ቂጥኝ፣ ክላሚዲያ እና ጨብጥ ምርመራዎችን ያካትታል።",
    "E": "ይሄ ኪት ለኤች.አይ.ቪ እና ለቂጥኝ ምርመራዎችን ያካትታል።",
    "box_type": "ሳጥን / Box {{type}}",
    "administered_text": "ኪታችሁ ላብራቶሪ ሲደርስ እና ውጤቶችዎ ዝግጅ ሲሆኑ እናሳውቅዎታለን።",
    "activate_kit": "የእኔን ምርመራ ኪት ማስጀምር",
    "register_another_test": "ሌላ ምርመራ መመዝገብ",
    "credentials": {
      "title": "እንጀምር",
      "label": "አድራሻ",
      "p1": "እርስዎን በሚመለከቱ ጥቂት መረጃዎች እንጀምራለን።",
      "location_step": {
        "p1": "የእርስዎን የምርመራ ኪት ማነው ያቀረበላችሁ/የሰጣችሁ?",
        "load": "የበለጠ አሳይ / Load more"
      }
    },
    "checklist": {
      "not_urinated": "ባለፈው አንድ ሰአት ውስጥ ሽንት አልሸኑም።",
      "bathroom": "ለሽንት ምርመራ መሰብሰቢያ መፀዳጃ ቤት መጠቀም ይችላሉ።",
      "anal_swab": "የፊንጢጣ ናሙና ይዞ ለማምጣት የግል ቦታ ተዘጋጅቶልዎታል።",
      "blood_extraction": "የደም ናሙና ለመስጠት ንፁህ አካባቢ ተዘጋጅቶልዎታል።",
      "washed_hands": "እጆችዎን በሳሙና እና በሙቅ ውሀ ለ 30 ሰከንዶች ታጥበዋል።",
      "title": "የናሙና ስብሰባውን ለመጀመር ዝግጁ ነዎት?",
      "no_alcohol": "በሚሰበሰብበት ቀን ላይ አልኮል መጠጥ አይጠጡ።",
      "menstruation": "የወር አበባ በሚያዩበት ወቅት ወይም የግብረስጋ ግንኙነት ባደረጉ 24 ሰአታት ውስጥ የሴት ብልት ናሙና አይውሰዱ።"
    },
    "confirm": {
      "title": "Now, begin collecting your sample",
      "p1": "Is this kit information correct?"
    },
    "display_name": {
      "A": "Chlamydia & ጨብጥ (አንድ ቦታ መታየት / Single Site)",
      "B": "Chlamydia & ጨብጥ (3 ቦታ መታየት / Site)",
      "C": "ቂጥኝ",
      "shortened": {
        "A": "Chlamydia & ጨብጥ",
        "B": "Chlamydia & ጨብጥ",
        "C": "ቂጥኝ"
      },
      "CC": "COVID-19",
      "CE": "የሴላይክ በሽታ ምርመራ",
      "CR": "የክሬቲኒን ምርመራ",
      "D": "4 የፓነል ምርመራ  - ኤች አይ ቪ ፣ ቂጥኝ ፣ ክላሚዲያ እና ጨብጥ (ነጠላ ቦታ)",
      "DD": "የስኳር በሽታ ምርመራ (HbA1c)",
      "E": "ኤች አይ ቪ እና ቂጥኝ",
      "H": "ኤች ፒቪ",
      "P": "ሄፓታይተስ ሲ",
      "HS": "ሄርፒስ ሲምፕሌክስ",
      "I": "ኤችአይቪ",
      "TT": "ቴስቶስትሮን ደረጃ",
      "VD": "ቫይታሚን ዲ",
      "VS": "የቫይረስ ክትትል",
      "AP": "Chlamydia & Gonorrhea",
      "NN": "COVID-19",
      "HH": "COVID-19, Flu A/B, & RSV",
      "FFFWW": "COVID-19 & Flu A/B",
      "YYYWW": "COVID-19, Flu A/B & RSV",
      "HA": "COVID-19, Flu A/B, & RSV"
    },
    "rescan": "ደግማችሁ አረጋግጡ (Rescan)",
    "scanned": "ሳጥን / Box {{type}} አረጋግጣችኋል (scanned)",
    "not_yet": "ገና አላለቀም",
    "success_registration": "ምዝገባ ተሳክቷል!",
    "no_box": "ሳጥን የላችሁም?",
    "faq_link": "FAQs ይጎብኙ",
    "welcome": "እንኳን ደህና መጡ!",
    "scan": {
      "title": "You're one step closer to a healthier you. Scan or enter the **kit bar code** on the side of your box.",
      "p1": "Please begin when you are ready to begin sample collection. Otherwise, return to this page when you are ready.",
      "unknown_barcode": "You’ve entered an unknown barcode. Please contact support for assistance."
    },
    "appointments": {
      "title": "ታካሚ እና አቅራቢ ይምረጡ",
      "p1": "ከእርስዎ አድራሻ ጋር የተገናኘ ከአንድ በላይ የግል መግለጫ (profile) አግኝተናል። ትክክለኛ የሆነውን የግል መግለጫ ይምረጡ፦",
      "new": "አዲስ ታካሚ ወይም አቅርቢ"
    },
    "verify": {
      "title": "Confirm your identity",
      "p1": "የታካሚ መዝገብዎን አግኝተናል።"
    },
    "section_1": "የኪት ይዘቶችን ያረጋግጡ።",
    "section_2": "የምርመራ ስብሰባ",
    "section_3": "አስተሻሸግ",
    "section_4": "Return kit",
    "box_a": "ሳጥን A",
    "box_b": "ሳጥን B",
    "box_c": "ሳጥን C",
    "kit_flow": {
      "button_1": "ወደ ማሸግ ይቀጥሉ",
      "button_2": "ዝግጁ ነኝ",
      "button_3": "የእኔ ኪት ተጠናቋል እና ታሽጓል",
      "button_4": "ጨርሻለሁ",
      "button_5": "ቀጣይ ምርመራ",
      "button_6": "መመሪያዎችን ዝለሉ"
    },
    "contents": {
      "header": "በጣም ጥሩ! የእርስዎ ኪት መጠናቀቁን እናረጋግጥ",
      "text_1": "Does your test kit box contain the following?",
      "blood_collection_card": "የደም መሰብሰቢያ ካርድ",
      "blood_collection_card_subtitle": "(በምርመራው ኪት መሰረት 1 ወይም 2)",
      "alchohol_pads": "2 የአልኮል ፋሻዎች",
      "lancets": "አንድ ጊዜ መጠቀሚያ የህክምና ምላጭ (lancets)",
      "up_to_other": "(በምርመራው ኪት መሰረት እስከ 4 የሚደርስ)",
      "adhesive_bandages": "የተጠቀጠቁ ፋሻዎች",
      "pipette": "የፈሳሽ መጠን መለኪያ ብልቃጥ (Pipette)",
      "urine_tube": "የሽንት ናሙና መሰብሰቢያ ብልቃጥ (tube)",
      "urine_cup": "የሽንት ናሙና መሰብሰቢያ ኩባያ",
      "oral_swab": "የአፍ ናሙና መውሰጃ ጥጥ (swab)",
      "oral_tube": "የአፍ ናሙና መሰብሰቢያ ብልቃጥ",
      "anal_swab": "የፊንጢጣ ናሙና መውሰጃ ጥጥ",
      "anal_tube": "የፊንጢጣ ናሙና መሰብሰቢያ ብልቃጥ",
      "vaginal_tube": "1 የሴት ብልት ናሙና መውሰጃ ብልቃጥ",
      "vaginal_swab": "1 የሴት ብልት ናሙና መውሰጃ ጥጥ",
      "biodegradable_paper": "ሊበላሽ የሚችል (Biodegradable) የመሰብሰቢያ ወረቀት",
      "bottle_and_probe": "የናሙና ጠርሙስ እና መመርመሪያ",
      "prepaid_envelope": "ቀድሞ-የተከፈለ የማጓጓዣ ፖስታ / ኤንቨሎፕ",
      "biohazard_subtitle": "(ከሚያጣብቅ መለጠፊያ ጋር)",
      "biohazard_bag": "1 የናሙና ቦርሳ",
      "biohazard_bag_plural": "({{count}}) የአካባቢ በካይ (Biohazard) ቦርሳዎች",
      "alcohol_pads_plural": "{{count}} የአልኮል ፋሻዎች",
      "sterile_gauze_pad": "1 የብክለት መከላከሻ የሚጠቀለል ፋሻ",
      "collection_tube": "1 collection tube",
      "collection_swabs": "2 collection swabs"
    },
    "packaging": {
      "title": "የአስተሻሸግ ማረጋገጫ ዝርዝር",
      "subtitle": "Before you ship or drop your kit at a kiosk, make sure that:"
    },
    "packaging_box_a": {
      "step_1": "You have packed all test kit contents according to the instructions",
      "step_2": "You have used all collection materials contained in the kit to collect the required specimen",
      "step_3": "Your **date-of-birth** is written on the specimen collection tube using MM/DD/YYYY format",
      "step_4": "The **collection date** is written on the specimen collection tube using MM/DD/YYYY format",
      "step_5": "የናሙና መሰብሰቢያ ቱቡ በአካባቢ በካይ (Biohazard) ቦርሳ ውስጥ ታሽጓል",
      "step_6": "ሁሉም ናሙናዎች በሳጥኑ ውስጥ ተቀምጠዋል",
      "step_7": "ሳጥኑ ቀድሞ-የተከፈለበት የማጓጓዣ ፖስታ / ኤንቨሎፕ ውስጥ ተቀምጧል",
      "step_8": "መልሶ የመላኪያ ፖስታ / ኤንቨሎፕ ሙሉ በሙሉ ታሽጓል"
    },
    "packaging_box_b": {
      "step_1": "ሁሉም የምርመራ ኪት ይዘቶች ተካተው ታሽጓል፣ ምንም እንኳን ምርመራውን(ዎቹን) የዘለሏቸውም ቢሆን",
      "step_2": "የሚያስፈልጉትን ናሙናዎች ለመሰብሰብ በኪቱ ውስጥ የሚገኙትን ሁሉንም የመሰብሰቢያ እቃዎች ተጠቅመዋል",
      "step_3": "የእርስዎን **ትውልድ ቀን / date-of-birth** በሁሉም የናሙና መሰብሰቢያ ብልቃጦች ላይ ወር/ቀን/አመተ ምህረት አፃፃፍን በመጠቀም ተፅፏል",
      "step_4": "**የተሰበሰበበት ቀን / collection date** በሁሉም የናሙና መሰብሰቢያ ብልቃጦች ላይ ወር/ቀን/አመተ ምህረት አፃፃፍን በመጠቀም ተፅፏል",
      "step_5": "እያንዳንዱ የናሙና መሰብሰቢያ ብልቃጥ የታሸገው ራሱን በቻለ በአካባቢ በካይ (Biohazard) ቦርሳ ውስጥ ነው (በአንድ ቦርሳ ውስጥ **አንድ** ናሙና ብቻ)",
      "step_6": "ሁሉም ናሙናዎች በሳጥኑ ውስጥ ተቀምጠዋል",
      "step_7": "ሳጥኑ ቀድሞ-የተከፈለበት የማጓጓዣ ፖስታ / ኤንቨሎፕ ውስጥ ተቀምጧል",
      "step_8": "መልሶ የመላኪያ ፖስታ / ኤንቨሎፕ ሙሉ በሙሉ ታሽጓል"
    },
    "packaging_box_c": {
      "step_1": "ሁሉንም የምርመራ ኪት ይዘቶች አሽጋችኋል፣ የህክምና ምላጭ (lancets) ጨምሮ።",
      "step_2": "የእርስዎን **ትውልድ ቀን / date-of-birth** በደም መሰብሰቢያ ካርድ ላይ ወር/ቀን/አመተ ምህረት አፃፃፍን በመጠቀም ተፅፏል",
      "step_3": "**የተሰበሰበበት ቀን / collection date** በደም መሰብሰቢያ ካርድ ላይ ወር/ቀን/አመተ ምህረት አፃፃፍን በመጠቀም ተፅፏል",
      "step_4": "ደም መሰብሰቢያ ካርድ እና ሁሉም ጥቅም ላይ የዋሉ የህክምና ምላጮች በአካባቢ በካይ (Biohazard) ቦርሳ ውስጥ ተቀምጠዋል ",
      "step_5": "በአካባቢ በካይ (Biohazard) ቦርሳው በሳጥኑ ውስጥ ተቀምጧል",
      "step_6": "ሳጥኑ ቀድሞ-የተከፈለበት የማጓጓዣ ፖስታ / ኤንቨሎፕ ውስጥ ተቀምጧል",
      "step_7": "መልሶ የመላኪያ ፖስታ / ኤንቨሎፕ ሙሉ በሙሉ ታሽጓል"
    },
    "success": {
      "header_1": "በጣም ጥሩ!",
      "text_1": "የመጀመሪያ ናሙናችሁን ለመውሰድ ዝግጁ ናችሁ።",
      "header_2": "አሳክታችሁታል!",
      "text_2": "**ከዚህ በመቀጠል፦** መልሳችሁ ለመላክ ኪቱን አሽጉ።",
      "header_3": "በጣም ጥሩ ሰርታችኋል!",
      "text_3": "ለተሻለ ጤና አንድ እርምጃ ቅርብ ነዎት።",
      "header_4": "አሪፍ ነው የሰራችሁት!",
      "text_4": "አንድ ብቻ ትጨምሩና ትጨርሳላችሁ!",
      "text_5": "You're ready to collect your sample."
    },
    "instructions": {
      "title": "መመሪያዎችን ለመመልከት ምርመራ ይምረጡ፦",
      "subtitle": "ምርመራ",
      "button": "መመሪያዎች",
      "pdf": "PDF መመሪያዎች"
    },
    "instructions_box_a": {
      "title": "የሽንት ናሙና ስብሰባ",
      "subtitle": "ለዚህ ምርመራ የሚከተሉት ቁሳቁሶች ያስፈልግዎታል፦",
      "step_1": "ከመሰብሰባችሁ በፊት ቢያንስ ለ 1 ሰአት እንዳትሸኑ። ጠዋት ስትነሱ የመጀመሪያውን ሽንታችሁን መጠቀም በጣም ጥሩ ነው።",
      "step_2": "በቀጥታ ወደ PeeCanter ወይም መሰብሰቢያ ኩባያ ውስጥ ሽኑ፣ 1/3 እና 1/2 ያህል ሙሉት።",
      "step_3": "ከ PeeCanter ይገልብጡ ወይም መለኪያ ብልቃጥ (pipette) ይጠቀሙ እና ከመሰብሰቢያ ኩባያው ወደ መሰብሰቢያ ብልቃጥ(ቦች) አስተላፉ፣ ይሄም ምልክት የተደረገው በብርቱካናማ <span style=\"color: #FF5000\">**\"ሽንት / URINE\"**</span> ተሰይሞ ነው።",
      "step_4": "የተዋሀደው ፈሳሽ ምልክት በተደረጉት መስመሮች መካከል እስከሚሆን ድረስ የመሰብሰቢያ ብልቃጥ(ቦች)ን ሙሉ።",
      "step_4_sublabel": "ቱቡ ከከፍተኛው የመሙያ መስመር በላይ አትሙሉት፣ ካልሆነ ናሙናው ተቀባይነት አይኖረውም።",
      "step_5": "በመሰብሰቢያ ብልቃጥ ላይ ክዳኑን አድርጉ እና ተስተካክሎ እና ጠብቆ መዘጋቱን አረጋግጡ።",
      "step_6": "የእርስዎን ትውልድ ቀን እና የናሙና መሰብሰቢያ ቀን (የዛሬን ቀን) ወር/ቀን/አመተ ምህረት አፃፃፍን በመጠቀም በቱቡ ላይ ተለይቶ በተቀመጠው ቦታ ላይ ፃፉ።",
      "step_7": "ናሙናውን ባዶ የሆነ በአካባቢ በካይ (Biohazard) ቦርሳ ውስጥ ከፈሳሽ የሚመጥ ጥጥ ጋር አስቀምጡ እና ክዳኑ ሙሉ በሙሉ መዘጋቱን አረጋግጡ።"
    },
    "instructions_box_b": {
      "test_1": {
        "title": "የሽንት ናሙና ስብሰባ",
        "subtitle": "ለዚህ ምርመራ የሚከተሉት ቁሳቁሶች ያስፈልግዎታል፦",
        "step_1": "ከመሰብሰባችሁ በፊት ቢያንስ ለ 1 ሰአት እንዳትሸኑ። ጠዋት ስትነሱ የመጀመሪያውን ሽንታችሁን መጠቀም በጣም ጥሩ ነው።",
        "step_2": "በቀጥታ ወደ PeeCanter ወይም መሰብሰቢያ ኩባያ ውስጥ ሽኑ፣ 1/3 እና 1/2 ያህል ሙሉት።",
        "step_3": "ከ PeeCanter ይገልብጡ ወይም መለኪያ ብልቃጥ (pipette) ይጠቀሙ እና ከመሰብሰቢያ ኩባያው ወደ መሰብሰቢያ ብልቃጥ(ቦች) አስተላፉ፣ ይሄም ምልክት የተደረገው በብርቱካናማ <span style=\"color: #FF5000\">**\"ሽንት /URINE\"**</span> ተሰይሞ ነው።",
        "step_4": "የተዋሀደው ፈሳሽ ምልክት በተደረጉት መስመሮች መካከል እስከሚሆን ድረስ የመሰብሰቢያ ብልቃጥ(ቦች)ን ሙሉ።",
        "step_4_subtitle": "ቱቡ ከከፍተኛው የመሙያ መስመር በላይ አትሙሉት፣ ካልሆነ ናሙናው ተቀባይነት አይኖረውም።",
        "step_5": "በመሰብሰቢያ ብልቃጥ ላይ ክዳኑን አድርጉ እና ተስተካክሎ እና ጠብቆ መዘጋቱን አረጋግጡ።",
        "step_6": "የእርስዎን ትውልድ ቀን እና የናሙና መሰብሰቢያ ቀን (የዛሬን ቀን) ወር/ቀን/አመተ ምህረት አፃፃፍን በመጠቀም በቱቡ ላይ ተለይቶ በተቀመጠው ቦታ ላይ ፃፉ።",
        "step_7": "ናሙናውን ባዶ የሆነ በአካባቢ በካይ (Biohazard) ቦርሳ ውስጥ ከፈሳሽ የሚመጥ ጥጥ ጋር አስቀምጡ እና ክዳኑ ሙሉ በሙሉ መዘጋቱን አረጋግጡ።"
      },
      "test_2": {
        "title": "የአፍ ናሙና መውሰጃ ጥጥ",
        "subtitle": "ለዚህ ምርመራ የሚከተሉት ቁሳቁሶች ያስፈልግዎታል፦",
        "step_1": "ናሙና መውሰጃ ጥጡን ፈዘዝ ያለው የነጥብ መስመር (ካለ) እና የናሙና መውሰጃው ጥጥ-ያለበት ጫፍ መሀል ላይ ያዙት።",
        "step_2": "ናሙና መውሰጃውን (swab) ወደ አፋችሁ አስገቡ እና ጫፉን ከጉሮሯችሁ ጀርባ ለ 10 ሰከንዶች ጥረጉት።",
        "step_3": "በጥንቃቄ ናሙና መውሰጃውን (swab) አውጡት እና ወደ መሰብሰቢያ ብልቃጥ ውስጥ ክተቱት፣ ይሄም ምልክት የተደረገበት በሰናያዊ <span style=\"color: #001F70\">**\"የአፍ / ORAL\"**</span> ስያሜ ነው። መሀል ያለው ነጥብ ጋር ናሙና መውሰጃውን (swab) ከመሰብሰቢያ ቱቡ ጫፍ ጋር በማጣመም ቁረጡት።",
        "step_4": "በመሰብሰቢያ ብልቃጥ ላይ ክዳኑን አድርጉ እና ተስተካክሎ እና ጠብቆ መዘጋቱን አረጋግጡ።",
        "step_5": "የእርስዎን ትውልድ ቀን እና የናሙና መሰብሰቢያ ቀን (የዛሬን ቀን) ወር/ቀን/አመተ ምህረት አፃፃፍን በመጠቀም በቱቡ ላይ ተለይቶ በተቀመጠው ቦታ ላይ ፃፉ።",
        "step_6": "ናሙናውን ባዶ የሆነ በአካባቢ በካይ (Biohazard) ቦርሳ ውስጥ ከፈሳሽ የሚመጥ ጥጥ ጋር አስቀምጡ እና ክዳኑ ሙሉ በሙሉ መዘጋቱን አረጋግጡ።"
      },
      "test_3": {
        "title": "የፊንጢጣ ናሙና መውሰጃ ጥጥ",
        "subtitle": "ለዚህ ምርመራ የሚከተሉት ቁሳቁሶች ያስፈልግዎታል፦",
        "step_1": "ናሙና መውሰጃ ጥጡን ፈዘዝ ያለው የነጥብ መስመር (ካለ) እና የናሙና መውሰጃው ጥጥ-ያለበት ጫፍ መሀል ላይ ያዙት።",
        "step_2": "ናሙና መውሰጃውን (swab) ከ 3 - 5 ሴንቲሜትር (1 - 2”) ያህል ወደ ፊንጥጣ ቀዳዳ አስገቡት። ማንኛውም ፍጥረታት ካሉ ለመሰብሰብ ቀስ ብላችሁ ናሙና መውሰጃውን ከ 5 - 10 ሰከንዶች አዟዙሩ።",
        "step_3": "በጥንቃቄ ናሙና መውሰጃውን (swab) አውጡት እና ወደ መሰብሰቢያ ብልቃጥ ውስጥ ክተቱት፣ ይሄም ምልክት የተደረገበት በአረንጓዴ <span style=\"color: #001F70\">**\"የፊንጢጣ / ANAL \"**</span> ስያሜ ነው። መሀል ያለው ነጥብ ጋር ናሙና መውሰጃውን (swab) ከመሰብሰቢያ ቱቡ ጫፍ ጋር በማጣመም ቁረጡት።",
        "step_4": "በመሰብሰቢያ ብልቃጥ ላይ ክዳኑን አድርጉ እና ተስተካክሎ እና ጠብቆ መዘጋቱን አረጋግጡ።",
        "step_5": "የእርስዎን ትውልድ ቀን እና የናሙና መሰብሰቢያ ቀን (የዛሬን ቀን) ወር/ቀን/አመተ ምህረት አፃፃፍን በመጠቀም በቱቡ ላይ ተለይቶ በተቀመጠው ቦታ ላይ ፃፉ።",
        "step_6": "ናሙናውን ባዶ የሆነ በአካባቢ በካይ (Biohazard) ቦርሳ ውስጥ ከፈሳሽ የሚመጥ ጥጥ ጋር አስቀምጡ እና ክዳኑ ሙሉ በሙሉ መዘጋቱን አረጋግጡ።"
      }
    },
    "instructions_box_c": {
      "title": "የደም መሰብሰቢያ",
      "subtitle": "ለዚህ ምርመራ የሚከተሉት ቁሳቁሶች ያስፈልግዎታል፦",
      "step_1": "የራስዎን ስም፣ የትውልድ ቀን እና የተሰበሰበበትን ቀን በተቀመጡት ቦታዎች ላይ ይፃፉ። ወር/ቀን/አመተ ምህረት አፃፃፍን ይጠቀሙ።",
      "step_2": "በደም ካርዱ ላይ ሽፋኑን ግለጡ እና በደም መሰብሰቢያ ወረቀቱ ላይ ያሉትን ክቦች ክፍት አድርጉ። የደም መሰብሰቢያ ወረቀቱን እንዳትነኩ።",
      "step_3": "እጆችዎን ቢያንስ ለ 30 ሰከንዶች በሙቅ ውሀ ታጠቡ፣ ቀጥሎም ደም ወደ ጣቶችዎ እንዲዘዋወሩ እጆችዎን በሀይል ለ 15 ሰከንዶች ነቅንቁ።",
      "step_4": "የጣት ጫፍን በአልኮል ጥጥ አፅዱ። የበለጠ የሚጠቀሙት-ያልሆነ እጅዎን የመሀል ወይም የቀለበት ጣት ቢጠቀሙ በጣም ጥሩ ይሆናል።",
      "step_5": "የህክምና ምላጩን ያዙ እና ክዳኑን ጠምዝዛችሁ አውልቁ። የጣትዎ ጫፍ ላይ ትንሹን ጫፍ በደንብ ተጫኑት፣ መርፌው ቋ ብሎ እስኪወጣ ድረስ። ደም በሚወስዱበት ጊዜ እጅዎን ከልብዎ ስር አድርጉ፣ የደም ፍሰትን ለማመቻቸት ጣትዎን ከታች ወደ ጫፍ እሹት።",
      "step_5_subtitle": "የህክምና ምላጮች ለአንድ ጊዜ ብቻ ነው ጥቅም ላይ የሚውሉት። ሁሉም የህክምና ምላጮች እንዲወገዱ ወደ ላብራቶሪ ከናሙና ጋር መመለስ አለባቸው።",
      "step_6": "መሀል ላይ ጀምራችሁ፣ ከ 3-6 ጠብታዎችን መሀሉን ለመሙላት አድርጉ እና የመሰብሰቢያ ወረቀቱ ላይ አርጥቡት። ወረቀቱን በእጅዎ አይንኩት ምክንያቱም የደም ፍሰቱን ይገድበዋል። ክቡ ከሞላ በኋላ፣ ወደ ቀጣዩ ክብ ቀጥሉ። ደም ከመስመሩ ቢያልፍ ችግር የለውም፣ ነገር ግን የደም ጠብታዎቹ እርስ በርስ እንዲነካኩ አያድርጉ።",
      "step_6_subtitle": "ሙሉ ወይም ደረቅ ከሆነ በኋላ ተጨማሪ ደም ክቡ ላይ አትጨምሩ። የደም “ሽፋኑ” ስብሰባውን ያከሽፈዋል።",
      "step_7": "የደም ካርዱን ሳትዘጉ፣ ቀጥ ያለ ቦታ ላይ አስቀምጡት እና የደም መሰብሰቢያ ወረቀቱ በክፍል ሙቀት ደረጃ ላይ በአየር እንዲደርቅ አድርጉት፣ ቢያንስ ለ 30 ሰከንዶች። አታሙቁ፣ አታድርቁ፣ ወይም የደም መሰብሰቢያ ወረቀቱን ቀጥተኛ ፀሀይ እንዲያገኘው አታድርጉ። ሙቀት ናሙናውን ያበላሸዋል።",
      "step_7_subtitle": "የደም መሰብሰቢያ ወረቀቱን ጀርባ ፈትሹ። ደም ሙሉ በሙሉ ሊያርፍበት እና እያንዳንዱን የመሰብሰቢያ ወረቀቱን ክብ ሊሞላ ይገባል።",
      "step_8": "የደም መሰብሰቢያ ወረቀቱ ሲደርቅ፣ የደም ካርዱን በመሸፈኛው ዝጉት። የደም ካርዱን እና የህክምና ምላጩን በአካባቢ በካይ (biohazard) ቦርሳ ውስጥ ከማድረቂያ (desiccant) እሽግ ጋር አስቀምጡት። የአካባቢ በካይ (biohazard) ቦርሳው በደንብ መዘጋቱን አረጋግጡ።"
    },
    "test_tips": {
      "title": "ለተገቢ የደም አሰባሰብ ጠቃሚ መረጃዎች",
      "subtitle": "ምርጥ ውጤቶችን ለማግኘት፦",
      "step_1": "በሚሰበስቡበት ወቅት ውሀ እንዳልጠማዎት ያረጋግጡ። ውሀ በደንብ መጠጣት የደም ዝውውርን ያመቻቻል።",
      "step_2": "ካጨሱ በኋላ ወዲያውኑ፣ ደም አይሰብስቡ/አይቅዱ።",
      "step_3": "እጆችዎን መታጠብ እና ሞቃት ውሀ ስር ማሞቅ በእጆችዎ ውስጥ ደም እንዲዘዋወር ያመቻቻል።",
      "step_4": "ወደ መሬት እጆችዎን አድርገው በሀይል በመወዝወዝ ወደ ጣቶችዎ ጫፎች ደም እንዲፈስ አመቻቹ።",
      "step_5": "ደም በሚቀዱበት ወቅት የመሰብሰቢያ መሳሪያን እና እጆችዎን ከልብዎ ስር በማድረግ ደም በደምብ እንዲዘዋወር አድርጉ።",
      "step_6": "ከአንድ በላይ ጣት መውጋት ሊጠበቅባችሁ ይችላል። እያንዳንዱን ጣት ሲወጉ እነዚህን ጠቃሚ ምክሮች ይድገሙ።"
    },
    "shipping": {
      "header": "ኪትዎ ለመጓጓዝ/ለመላክ ዝግጁ ነው!",
      "text": "**Congrats!** You finished your health screening."
    },
    "pick_up": "ለእርስዎ የተመከረውን የምርመራ ኪት ከአካባቢው ሰራተኛ ይውሰዱ እና ከዚህ ስር ይመዝገቡ።",
    "short_display_name": {
      "A": "ክላሚዲያ እና ጨብጥ",
      "B": "ክላሚዲያ እና ጨብጥ",
      "C": "ቂጥኝ",
      "CC": "የኮሎሬክታል ካንሰር",
      "CE": "ሴሊያክ",
      "CR": "ክሬቲኒን",
      "D": "ኤች አይ ቪ ፣ ቂጥኝ ፣ ክላሚዲያ እና ጨብጥ",
      "DD": "የስኳር በሽታ",
      "E": "ኤች አይ ቪ እና ቂጥኝ",
      "H": "ኤች ፒቪ",
      "P": "ሄፓታይተስ ሲ",
      "HS": "ሄርፒስ ሲምፕሌክስ",
      "I": "ኤችአይቪ",
      "TT": "ቴስቶስትሮን",
      "VD": "ቫይታሚን ዲ",
      "VS": "የቫይረስ ክትትል"
    },
    "test_kit": "የምርመራ ኪት",
    "view_results": "ውጤቶችን ይመልከቱ",
    "recommended_kit_title": "የሚመከሩ የምርመራ ኪቶች",
    "instructions_box_h": {
      "title": "የሴት ብልት ናሙና መሰብሰብ",
      "subtitle": "የመሰብሰቢያ ብልቃጦች ፈሳሽ ሳይበላሽ ማቆያ ሊኖራቸው ይችላል። <span class=\"text-danger\">**ሳይበላሽ ማቆያ ፈሳሽ ካለ ከመሰብሰቢያ ብልቃጦች ውስጥ አፍስሰው ቦዶ አያድርጉት።**</span>",
      "step_1": "ናሙና መውሰጃ ጥጡን ፈዘዝ ያለው የነጥብ መስመር (ካለ) እና የናሙና መውሰጃው ጥጥ-ያለበት ጫፍ መሀል ላይ በአንድ እጅ ያዙት እና labia (በሴት ብልት መግቢያ ላይ ያልው የታጠፈ ቆዳ) አላቁ።",
      "step_2": "የናሙና መውሰጃውን ወደ የሴት ብልት መግቢያ 5 ሴንቲሜትር (2 ኢንች) አስገቡት። ቀስ ብላችሁ ለ 30 ሰከንድ የናሙና መሰብሰቢያውን ከሴት ብልት ግድግዳዎች ጋር በማሸት አዟዙሩት።",
      "step_3": "በጥንቃቄ ናሙና መውሰጃውን (swab) አውጡት እና ወደ መሰብሰቢያ ብልቃጥ ውስጥ ክተቱት፣ ይሄም ምልክት የተደረገበት <span class=\"text-danger\">**ቀይ “የሴት ብልት” ስያሜ**</span>። መሀል ያለው ነጥብ ጋር ናሙና መውሰጃውን (swab) ከመሰብሰቢያ ቱቡ ጫፍ ጋር በማጣመም ቁረጡት።",
      "step_4": "በመሰብሰቢያ ብልቃጥ ላይ ክዳኑን አድርጉ እና ተስተካክሎ እና ጠብቆ መዘጋቱን አረጋግጡ።",
      "step_5": "የእርስዎን የትውልድ ቀን ወር/ቀን/አመተ ምህረት አፃፃፍን ተጠቅማችሁ በቱቡ ላይ በተቀመጠው ቦታ ላይ ፃፉ (DOB)።",
      "step_6": "ናሙና የተወሰደበትን ቀን (የዛሬን ቀን) ወር/ቀን/አመተ ምህረት አፃፃፍን ተጠቅማችሁ በቱቡ ላይ በተቀመጠው ቦታ ላይ ፃፉ (Coll. Date)።",
      "step_7": "ናሙናውን ባዶ የሆነው በአካባቢ በካይ (Biohazard) ቦርሳ ውስጥ ከፈሳሽ የሚመጥ ጥጥ ጋር አስቀምጡ እና ክዳኑ ሙሉ በሙሉ መዘጋቱን አረጋግጡ።"
    },
    "packaging_box_h": {
      "step_3": "የእርስዎ **ትውልድ ቀን / date-of-birth** በናሙና መሰብሰቢያ ካርድ ላይ ወር/ቀን/አመተ ምህረት አፃፃፍን በመጠቀም ተፅፏል",
      "step_4": "**የተሰበሰበበት ቀን / collection date** በናሙና መሰብሰቢያ ካርድ ላይ ወር/ቀን/አመተ ምህረት አፃፃፍን በመጠቀም ተፅፏል",
      "step_6": "የአካባቢ በካይ (Biohazard) ቦርሳው በሳጥኑ ውስጥ ተቀምጧል"
    },
    "instructions_box_cc": {
      "title": "የሰገራ ስብሰባ",
      "step_1": "የእርስዎን ትውልድ ቀን እና የናሙና መሰብሰቢያ ቀን (የዛሬን ቀን) ወር/ቀን/አመተ ምህረት አፃፃፍን በመጠቀም በቱቡ ላይ ተለይቶ በተቀመጠው ቦታ ላይ ፃፉ።",
      "step_2": "የቀረበውን የመሰብሰቢያ ወረቀት በመፀዳጃ እቃ ውስጥ ከውሀ ላይ አስቀምጡት።",
      "step_3": "የሰገራ ናሙናውን በመሰብሰቢያ ወረቀቱ ላይ አስቀምጡ።",
      "step_4": "ወረቀቱ ከመስመጡ በፊት የሰገራ ናሙናው ውሀ ከመንካቱ በፊት ናሙናውን ሰብስቡ።",
      "step_5": "በናሙና መውሰጃው የሰገራውን ናሙና የላይኛውን ክፍል ጥረጉ።",
      "step_6": "የናሙና መውሰጃውን ማንሻ ክፍል ሙሉ በሙሉ በሰገራ ናሙና ሸፍኑ።",
      "step_7": "የናሙና መውሰጃውን በማስገባት እና አርንጓዴ ክዳኑን አጥብቃችሁ በመጠምዘዝ የናሙና ጠርሙሱን ዝጉ። ደግማችሁ እንዳትከፍቱት። ",
      "step_8": "ሽንት ቤቱን ውሀ ልቀቁበት። ሊበሰብስ የሚችል (Biodegradable) የመሰብሰቢያ ወረቀት ነው ስለዚህ የሽንት ቤት ቱቦውን ስርዓት አይጎዳውም።",
      "step_9": "የናሙና ጠርሙሱን በፈሳሽ የሚመጥ ጥጥ ይጠቅልሉ እና በአካባቢ በካይ (Biohazard) ቦርሳ ውስጥ አስገቡ።"
    },
    "contact_support": {
      "title": "እናስተካክለው",
      "text_1": "የሆነ ነገር እንደተበላሸ በመስማታችን አዝነናል!",
      "text_2": "ምን ችግር እንደተፈጠረ እንድናውቅ ከእኛ ጋር ይገናኙ እና የእርስዎን ኪት ለመቀየር እንረዳዎታለን።"
    },
    "contact_support_success": {
      "title": "መልእክትዎ ደርሶናል",
      "text_1": "ለእኛ ስላሳወቁ እናመሰግናለን።",
      "text_2": "የድጋፍ ሰጪ ቡድናችን አባል በቅርቡ ከእርስዎ ጋር ይገናኛል።",
      "button_text": "ወደ ዋናው ድረ-ገፅ ይመለሱ"
    },
    "kit_in_transit_to_patient": "ትዕዛዝህ እየመጣ ነው! አብዛኛዎቹ ትዕዛዞች በ2-5 የስራ ቀናት ውስጥ ይደርሳሉ። ኪትዎን አንዴ ከተቀበሉ፣ እንዴት እንደሚያነቃቁት እና መልሰው እንደሚልኩት ተጨማሪ መረጃ እንልክልዎታለን።\n\nየመከታተያ አገናኝ፡ https://tools.usps.com/go/TrackConfirmAction_input?strOrigTrackNum={{tracking_number}}",
    "kit_received_at_lab": "ላቦራቶሪው ኪትዎን ተቀብሏል! ውጤቶቹ ዝግጁ ሲሆኑ የታካሚዎን ፖርታል መግቢያ ለመፈተሽ ማሳወቂያ ይደርሰዎታል",
    "registration_confirmation": "ምዝገባዎ አሁን ተጠናቋል! የሙከራ ኪትዎን እንዲሰራ ለማድረግ የምዝገባ ማረጋገጫ ገጽዎን ይጎብኙ፡-",
    "kit_ordered_online": "የኪት ትእዛዝህ ደርሶናል እና አንዴ ከተላከ ማሣወቂያ እንልካለን! ትዕዛዝ #: {{order_number}} የትዕዛዝ ቀን፡ {{order_date}}",
    "kit_ordered_online_email": {
      "header": "ትእዛዞን ተቀብለናል",
      "p1": "ትእዛዝ ተቀብለናል! አንዴ ትዕዛዙ ከተላከ ሌላ ማሣወቂያ እንልካለን።",
      "order_number": "ትዕዛዝ #",
      "order_date": "የታዘዘበት ቀን"
    },
    "completed": "ተጠናቋል",
    "I": "ይህ የምርመራ እቃ ወይም ትቦ የኤችአይቪ ምርመራዎችን ያጠቃልላል።",
    "CC": "this kit includes tests for COVID-19.",
    "CE": "ይህ የምርመራ እቃ ወይም ትቦ የሴላሊክ በሽታ ምርመራዎችን ያካትታል።",
    "CR": "ይህ የምርመራ እቃ ወይም ትቦ የክሬቲኒን ምርመራዎችን ያካትታል።",
    "DD": "ይህ የምርመራ እቃ ወይም ትቦ የስኳር በሽታ ምርመራዎችን ያካትታል።",
    "H": "ይህ የምርመራ እቃ ወይም ትቦ የኤች ፒቪ በሽታ ምርመራዎችን ያካትታል.።",
    "P": "ይህ የምርመራ እቃ ወይም ትቦ የሄፐታይተስ ሲ ምርመራዎችን ያካትታል።",
    "HH": "this kit includes tests for COVID-19, Flu A/B, and RSV.",
    "HS": "ይህ የምርመራ እቃ ወይም ትቦ የኤች ሲቪ-2 ምርመራዎችን ያካትታል።",
    "TT": "ይህ የምርመራ እቃ ወይም ትቦ የቴስቶስትሮን ምርመራዎችን ያካትታል።",
    "VD": "ይህ የምርመራ እቃ ወይም ትቦ የቫይታሚን ዲ ምርመራዎችን ያካትታል።",
    "nasal_collection": {
      "title": "Nasal collection",
      "subtitle": "For this test you will need the following materials:",
      "step_1": "Write your Date of Birth and the Collection Date on your collection tube. Use MM/DD/YYYY format for the dates (i.e., 08/29/2023).",
      "step_2": "Wash your hands with soap and water thoroughly before proceeding and after completeing all steps.",
      "step_3": "Remove the swab from the wrapper with your clean hands. Hold the swab with the white tip up. Do not touch the tip of the swab to anything.",
      "step_4": "Holding the swab with one hand, gently insert the white tip of the swab about 1/2 to 3/4 inces (1 to 2 cm) into the opening of your nose. Rotate the swab for 15 to 30 seconds. Make sure the swab touches the insides of your nose. Remove the swab from your nose being careful not to touch your skin. Do not set the swab down. Repeat in the other nostril.",
      "step_5": "While still holding the swab, unscrew and remove the cap from the transport tube without setting the cap down. Place the swab into the tube with the white tip down, taking great care not to spill or splash the liquid in the collection tube.",
      "step_6": "Place lid onto the collection tube and ensure it is closed evenly and tightly.",
      "step_7": "Place specimen into the empty biohazard bag with the absobent pad and ensure the seal is closed completely. Return the biohazard bag to the kiosk drop box."
    },
    "NN": "this kit includes tests for COVID-19.",
    "YYYWW": "this kit includes tests for COVID-19, Flu A/B, and RSV.",
    "FFFWW": "this kit includes tests for COVID-19 and Flu A/B.",
    "HA": "this kit includes tests for COVID-19, Flu A/B, and RSV.",
    "drop_off": {
      "header": "Your kit is ready to drop off!"
    }
  },
  "copy_link": "የድረ-ገፁን ሊንክ ይገልብጡ",
  "copied_link": "ሊንኩ ተገልብጧል!",
  "vaccines": {
    "descriptions": {
      "vaccine_flu": "ከጉንፋን ቫይረሶች መከላከል",
      "vaccine_covid": "ከኮቪድ-19 ቫይረስ መከላከል",
      "tdap": "የቲዳፕ ክትባት ቴታነስን ይከላከላል",
      "polio": "ከፖሊዮ ቫይረስ መከላከል. ትምህርት ከመጀመራቸው በፊት ለልጆች የሚፈለግ.",
      "varicella": "ከኩፍኝ በሽታ የሚከላከል ክትባት",
      "shingles": "ሺንግልዝ የሚከላከለው ክትባት. ዝቅተኛው ዕድሜ 18 ነው።",
      "human_papillomavirus": "ከ ኤችፒቪ የሚከላከል ክትባት. ለ 11 አመት ህጻናት የሚመከር.",
      "meningococcal": "ክትባቱ ከአራት አይነት የማኒንጎኮካል ባክቴሪያ ይከላከላል።"
    }
  },
  "viral_surveillance": {
    "shipping": {
      "title": "Prepare to ship your sample:",
      "step_1": {
        "label": "Put the biohazard bag into box.",
        "substep_1": "Remove the return shipping mailer from your test kit.",
        "substep_2": "Place the biohazard bag into the original collection kit box and close the box."
      },
      "step_2": {
        "label": "Put box into return shipping mailer.",
        "substep_1": "Place the box into the return shipping mailer and seal the mailer.",
        "substep_2": "Do not fold or cover the UN3373 diamond symbol."
      },
      "step_3": {
        "label": "Return to Fedex location.",
        "substep_1": "Return kit on the same day of collection (before the last pickup) to a Fedex location.",
        "substep_2": "Call for a FedEx pickup at 1.800.GoFedEx."
      }
    },
    "overview": {
      "title": "Welcome to the Viral Sequencing Program!",
      "subtitle": "Your participation helps provide valuable information on which respiratory pathogen variants are circulating in your area. Please follow the instructions that came with your kit to get started."
    },
    "instructions": {
      "title": "Collect your sample:",
      "step_1": "Wash your hands.",
      "step_2": "Remove the cap of the collection tube and open the swab package.",
      "step_2a": "Remove the swab from the package. Do not touch the soft end with your hands or anything else.",
      "step_3": "Collect a nasal swab.",
      "step_3a": "Insert the entire soft end of the swab into your nostril no more than 3/4 of an inch (1/5cm) into your nose.",
      "step_3b": "Slowly rotate the swab",
      "step_3c": "Gently remove the swab.",
      "step_3d": "Using the same swab, repeat in your other nostril with the same end of the swab.",
      "step_4": "Put swab into the collection tube and cap the tube securely.",
      "step_5": "Put the collection tube into the biohazard bag and seal.",
      "step_6": "Apply even pressure to the ziplock to seal the bag shut."
    },
    "click_to_get_started_button": "Click to get started",
    "get_started_button": "Get started",
    "thank_you": {
      "title": "Thank you for using Primary.Health!",
      "p_3": "You may now close the browser.",
      "p_2": "Please make sure to drop off the viral surveillance kit at your closest FedEx location.",
      "p_1": "Your participation actively helps Public Health officials fight the spread of disease. If you would like to see the impact you are making on Public Health, please take a moment to explore more via [this link]({{link}}). You will not be contacted about your results, as they are anonymous."
    },
    "verify_dob_title": "First, let's confirm your details",
    "verify_dob_subtitle": "This helps us ensure the correct person is using the test kit.",
    "activate_kit": "Activate a kit:",
    "steps_complete": "Steps complete",
    "opt_in": {
      "section_1_title": "How it works",
      "section_1_text_1": "Sign up below and receive a viral sequencing test kit in 1-3 business days.",
      "section_1_text_2": "Upon receiving the test kit",
      "section_1_text_3": "Send the sample back per the instructions in the kit using our prepaid label.",
      "sign_up": "Sign me up!",
      "no_to_participate": "No",
      "section_2_title": "What is the impact of your participation?",
      "section_2": {
        "card_1_title": "Rapid response to new variants",
        "card_1_text": "Genetic sequencing helps track the spread and emergence of new respiratory pathogen variants, allowing public health systems to quickly ramp up preparedness efforts.",
        "card_2_title": "Track how Respiratory Pathogens are changing",
        "card_2_text": "Respiratory pathogen sequencing analyzes the “genetic blueprint” of the virus. This helps scientists trace how the virus changes, enabling it to spread more easily, make people more sick",
        "card_3_title": "You have complete privacy",
        "card_3_text": "The sequencing will be processed anonymously. The lab will never contact you about your results"
      },
      "title": "You’re eligible for California’s Free Respiratory Pathogen Viral Sequencing Project!",
      "subtitle": "You can help public health officials protect communities against respiratory pathogens! If you opt in to participate you will receive a free viral sequencing kit that will test what respiratory pathogen strain you are infected with. Results will help officials track the spread of respiratory pathogen variants. See below for more information and to sign up.",
      "continue_to_result": "Continue to my result",
      "create_page_title": "Thank you!",
      "create_page_text_1": "Your kit should arrive in the next 1-3 business days.",
      "create_page_text_2": "Your participation actively helps Public Health officials fight the spread of diseases. You will be notified when your test kit order has been approved and when it has shipped."
    }
  },
  "family_registrations": {
    "find_clinic": "ክሊኒክ ያግኙ ወይም ይፈሎጉ",
    "select_pin": "ዝርዝሮችን ለማየት ፒን ይምረጡ",
    "miles_shorten": "{{miles}}ማይል",
    "next_available": "ቀጥሎ ይገኛል።",
    "appointment_type": "የቀጠሮ አይነት",
    "individual_appointment": "የግለሰብ ቀጠሮ",
    "family_appointment": "የቤተሰብ ቀጠሮ",
    "vaccines": "ክትባቶች",
    "what_kind_appointment": "ምን አይነት ቀጠሮ መያዝ ይፈልጋሉ?",
    "select_family_or_individual": "ለብዙ የቤተሰብ አባላት በአንድ ጊዜ መርሐግብር ካዘጋጁ የቤተሰብ ቀጠሮ ይምረጡ።",
    "vaccines_selected": "ክትባቶች ተመርጠዋል",
    "where_care": "እንክብካቤ የት ማግኘት ይፈልጋሉ?",
    "select_vaccines_for_individual": "ለቀጠሮዎ ክትባቶችን ይምረጡ (አማራጭ)።",
    "select_vaccines_for_family": "ለቤተሰብ ቀጠሮዎ ክትባቶችን ይምረጡ (አማራጭ)።",
    "schedule_services": "ለአገልግሎቶችን ቀጠሮ ያስይዙ",
    "add_family": "የቤተሰብ አባላትን ያክሉ እና ከታች አገልግሎቶችን ይምረጡ።",
    "family_member": "የቤተሰብ አባል {{number}}",
    "morning": "ጠዋት",
    "afternoon": "ከሰአት",
    "slot_available": "{{number}} ይገኛል።",
    "within_x_miles": "በ {{miles}} ማይል ርቀት ውስጥ",
    "any_distance": "ማንኛውም ርቀት ከ",
    "partial_results": "ከፊል ውጤቶች",
    "partial_matches": "የሚከተሉት ክሊኒኮች እርስዎ የሚፈልጓቸውን ክትባቶች አንዳንዶቹን ይሰጣሉ ነገር ግን ሁሉንም አይደሉም",
    "no_matches": "ፍለጋዉ ምንም ተዛማጅ አላገኘም። ለተሻለ ውጤት ማጣሪያዎችዎን/filters ለመቀየር ይሞክሩ።",
    "no_clinics_found": "ከእርስዎ ፍለጋጎት ጋር የሚዛመዱ ክሊኒኮች የሉም።",
    "broaden_filters": "ለተሻለ ውጤት ማጣሪያዎቹን ለማስፋት ይሞክሩ።",
    "unavailable_vaccines": "የማይገኙ ክትባቶች፡-",
    "available_vaccines": "የሚገኙ ክትባቶች፡-",
    "select_date": "ቀን ይምረጡ",
    "available_appointments": "ለቀጠሮ ክፍት የሆኑ ቀናቶች",
    "appointment_scheduling_info": "በሚቀጥለው ደረጃ ለተወሰኑ የቤተሰብ አባላት የቀጠሮ ጊዜዎችን መመደብ ይችላሉ። ከመረጡ በተለያዩ ቀናት ቀጠሮ መያዝ ይችላሉ።",
    "hold_selected_appointments": "የመረጡትን ቀጠሮ ለ15 ደቂቃ እንይዛለን።",
    "appointments_selected": "ቀጠሮዎች ተመርጠዋል",
    "no_appointments_selected": "ቀጠሮ አልተመረጠም።",
    "vaccines_needed": "ክትባቶች ያስፈልጋሉ።",
    "select_x_appointments": "{{number}} ቀጠሮዎችን ይምረጡ።",
    "more": "ተጨማሪ",
    "less": "ያነሰ",
    "register_next_family_member": "ቀጣዩን የቤተሰብ አባል ይመዝገቡ",
    "successfully_registered_x_of_y_family_members": "በተሳካ ሁኔታ {{x}} ከ{{y}} የቤተሰብ አባላት አስመዝግበዋል",
    "next_family_member": "ቀጣዩ የቤተሰብ አባል",
    "appointments_abbreviated": "አፕስ(appts)",
    "register_for_clinic": "ለክሊኒክ ይመዝገቡ",
    "select_services": "የሚፈልጉትን አገልግሎት ይምረጡ",
    "person": "ግለሰብ {{number}}",
    "add_person": "ሌላ ሰው ይጨምሩ",
    "registration_confirmation": "የምዝገባ ማረጋገጫ"
  },
  "user_mailer": {
    "verify_email": "ኢሜልዎን እናረጋግጥ!",
    "hi_full_name": "ሰላም {{full_name}},",
    "verify_email_button": "ኢሜልዎን ያረጋግጡ",
    "please_verify_email": "እባክዎ ኢሜልዎን ለማረጋገጥ ከዚህ በታች ያለውን ቁልፍ ይጠቀሙ።"
  },
  "services": {
    "dptap_dt": "ዲታፕ/ተዳፕ/ቲዲ",
    "hepatitis_a": "ሄፓታይተስ ኤ",
    "hepatitis_b": "ሄፓታይተስ ቢ",
    "hepatitis_a_b": "ሄፓታይተስ ኤ እና ቢ",
    "hib": "ሂብ",
    "mmr": "ኩፍኝ፣ ደግፍ፣ ሩቤላ",
    "meningococcal": "ማኒንጎኮካል",
    "mpox": "ኤምፖክስ",
    "pneumococcal": "ፕነዉሞኮካል",
    "polio": "ፖሊዮ",
    "rsv": "የመተንፈሻ አካላት ተመሳሳይ ቫይረስ",
    "rotovirus": "ሮታቫይረስ",
    "zoster": "ዞስተር (ሺንግልዝ)",
    "tetanus_diptheria": "ቴታነስ እና ዲፍቴሪያ",
    "tdap": "ተዳፕ",
    "typhoid": "ታይፎይድ",
    "varicella": "ቫሪሴላ (የዶሮ በሽታ)",
    "covid-19": "ኮቪድ 19",
    "covid-19_adult": "ኮቪድ-19 (አዋቂ)",
    "covid-19_adult_description": "ዕድሜያቸው 12 ዓመት ወይም ከዚያ በላይ ለሆኑ ሰዎች።",
    "covid-19_children": "ኮቪድ-19 (ህጻናት)",
    "covid-19_children_description": "ከ4-11 አመት ለሆኑ ህፃናት.",
    "covid-19_infants": "ኮቪድ-19 (ጨቅላ)",
    "covid-19_infants_description": "ከ 6 ወር - 3 ዓመት የሆኑ ልጆች.",
    "influenza": "ኢንፍሉዌንዛ",
    "mmrv": "ኩፍኝ፣ ደግፍ፣ ሩቤላ እና ቫሪሴላ"
  }
}