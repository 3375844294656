import { STEPS } from "./StiFlow";
import {
    STEP_FORWARD,
    STEP_BACK,
    REVIEW_INSTRUCTION,
    CONTACT_SUPPORT,
    SHOW_SUCCESS,
    SKIP_INSTRUCTIONS,
    SET_CURRENT_STEP,
  } from './actions';

const reducer = (state, action) => {
    const { currentStep } = state;
    switch (action.type) {
        case STEP_FORWARD:
            if (currentStep === 'contentsStep' && state.testTips) {
                const nextStep = STEPS[currentStep]?.navigationActions.testTips
                return { ...state, currentStep: nextStep }
            }
            else if (currentStep === "instructionsStep") {
                if (state.instructions.length === state.currentInstruction + 1) {
                    const nextStep =
                        STEPS[currentStep]?.navigationActions.packagingStep;
                    return { ...state, currentStep: nextStep }
                } else {
                    return { ...state, currentInstruction: state.currentInstruction + 1 }
                }
            } else {
                const nextStep =
                    STEPS[currentStep]?.navigationActions.forward;
                return { ...state, currentStep: nextStep };
            }
        case STEP_BACK:
            const previousStep = STEPS[currentStep]?.navigationActions.back;
            return { ...state, currentStep: previousStep };
        case REVIEW_INSTRUCTION:
            const newInstructionStep = STEPS[currentStep]?.navigationActions.selectInstruction;
            const currentInstruction = action.payload.id;
            return { ...state, currentStep: newInstructionStep, currentInstruction: currentInstruction };
        case SKIP_INSTRUCTIONS:
            const nextStep = STEPS[currentStep]?.navigationActions.packagingStep;
            return { ...state, currentStep: nextStep };
        case CONTACT_SUPPORT:
            return { ...state, showContactSupport: action.payload }
        case SHOW_SUCCESS:
            return { ...state, showSuccess: action.payload }
        case SET_CURRENT_STEP:
            return { ...state, currentStep: action.payload, currentInstruction: 0 }
        default:
            throw new Error(`No matching action type: ${action.type}`);
    }
};

export default reducer;