export default {
  "date": {
    "order": [
      "year",
      "month",
      "day"
    ],
    "month": "月",
    "day": "日",
    "year": "年",
    "january": "1月",
    "february": "2月",
    "march": "3月",
    "april": "4月",
    "may": "5月",
    "june": "6月",
    "july": "7月",
    "august": "8月",
    "september": "9月",
    "october": "10月",
    "november": "11月",
    "december": "12月"
  },
  "user": {
    "send_appointment_confirmation_message": "{{name}}の予約を確認しました。",
    "landline_appointment_reminder_message": "こんにちは、{{full_name}}さん。これは、{{name}}での予定の通知です。あなたの予定は{{date}}の{{time}}の{{address}}にあります。",
    "send_at_home_visit_confirmation_message": "{{date}} 、 {{time}}過ぎ、あなたの予約を確認しました。",
    "send_mail_order_confirmation_message": "メールでのご注文を確認しました。",
    "send_waitlist_message": "{{name}}の順番待ちリストに登録されました。予約時間を確認するまでは来ないでください。",
    "verify_contact": {
      "phone": "次をクリックし、電話番号を確認してください：{{confirmation_link}}",
      "email": "{{confirmation_link}}"
    },
    "duplicate_user_continue_registering": "予定の登録を続ける：{{url}}",
    "on_demand_confirmation_message": "{{name}}の登録が完了しました。",
    "appointment_reminder": "{{start_time}}に予約する前に、リンクをクリックして未解決のアクションアイテムを完了し、確認をご覧してください。ご予約の15分以下前に到着する必要です。",
    "appointment_reminder_base": "{{text}}{{appt_text}}ここをクリック：{{link}}",
    "test_result_notifier": {
      "text_message": "{{first_name_with_last_initial}} の患者ポータルが更新されました： {{access_code}}",
      "email_subject": "患者ポータルの新着情報",
      "email_intro": "こんにちわ {{first_name_with_last_initial}}",
      "email_p1": " 新しいメッセージや患者の最新情報をお届けします。",
      "email_p2": "下のボタンをクリックするか、リンクを使用して患者ポータルを表示し、コードを使用してください。",
      "email_p3": " 患者ポータルを見る",
      "email_button": " ポータルを見る"
    },
    "landline_appointment_reminder_message_without_time": "こんにちは、{{full_name}}。これは、{{name}}での通知するものです。あなたの予定は{{date}}の{{address}}にあります。",
    "appointment_reminder_on_demand": "{{date}}に予約する前に、リンクをクリックし、未解決のアクションアイテムを完了し、確認を御覧ください。",
    "new_test_result_notifier": {
      "email_intro": "患者ポータルが更新されました。",
      "email_text_1": "下記の病歴ポータルにアクセスし、新患の記録や検査報告書をご覧ください。",
      "email_button": "メディカルヒストリー",
      "email_text_2": "このURLをブラウザにコピー＆ペーストすることもできます。"
    },
    "mailers": {
      "email_contact_us": "質問がある場合は、こちらにご連絡ください"
    }
  },
  "errors": {
    "generic": {
      "message": "何かが間違っていました。もう一度やり直してください"
    },
    "messages": {
      "blank": "空白にすることはできません"
    },
    "incorrect_credentials": "資格情報が正しくありません。もう一度お試しください。",
    "error_activating_test_kit": "テストキットの有効化中にエラーが発生しました。"
  },
  "type": "タイプ",
  "payment": {
    "continue_label": "支払いを続ける",
    "policy_text": "予約をキャンセルした場合、または予約に出席しなかった場合は、予約日の7日間後に支払いの80％が返金されます。 [利用規約]({{link}}）の全文をご覧いただけます",
    "card_number": "カード番号",
    "expiry_date": "有効期限",
    "pay_button": "支払い",
    "no_credit_card": "現地で支払います",
    "cash_payment_helper": "必ず**{{payment}}**を予約に持参してください",
    "invoice": "請求書",
    "pay_by_card": "カードで支払う",
    "cost": "料金",
    "total": "合計",
    "amount_paid": "払込金額",
    "balance": "残高",
    "pay_cash_at_appointment": "{{appointment}}での予約時に現金で支払います",
    "view_invoice": "請求書の表示・印刷",
    "refund_policy": "予約をキャンセルした場合、予約日の7日間後に支払いの{{amount}}が返金されます。 [利用規約]({{link}}）の全文をご覧いただけます",
    "refund_window_passed": "予約時間が過ぎているため、予約は返金できません。解決策については、サイトコーディネーターに直接お問い合わせください。"
  },
  "consent_waiver": {
    "upload_waiver_or_sign": "同意免除をアップロードするまたは下の署名ボックスに署名してください",
    "stop": "登録を完了するには同意が必要です",
    "parent_or_guardian_required_html": "登録を完了するには、親、保護者、または権限のある意思決定者が以下のフォームに同意し、名前に署名する必要があります。あなたは自分でこのサービスに<b>同意できません<b>。",
    "send_parent_or_guardian": "このリンクを親または保護者に送信するか、このページの下に署名してもらうことができます。",
    "consent_guardian_name_signature": "名",
    "consent_for_minor": "未成年者に代表し、同意書に署名する",
    "guardian_signature": "親、保護者、または認定された医療意思決定者の署名",
    "after_you_schedule": "{{age}}歳未満の参加者は、親または保護者が代理で署名した同意を得る必要があります。保護者に送信するか、扶養家族に署名するには、登録の最後まで続行してください。",
    "consent_guardian_last_name_signature": "苗字",
    "consent_first_and_last_name": "名と姓",
    "phone_number": "保護者の電話番号",
    "email": "保護者のメール",
    "consent_registrar_with_guardian_name_signature": "後見人の名",
    "consent_registrar_with_guardian_last_name_signature": "後見人の名字",
    "consent_registrar_first_name_signature": "登記官の名",
    "consent_registrar_last_name_signature": "登記官の名字",
    "consent_on_behalf": "{{first_name}} {{last_name}}の代理として署名しています。",
    "consent_on_behalf_registrar": "私は、{{first_name}} {{last_name}} に口頭で署名することを承認されました。",
    "consent_on_behalf_registrar_with_guardian": "{{first_name}} {{last_name}} の後見人から、彼らに代わって署名することを口頭で承認されました。",
    "registrar_phone": "登録者の電話番号",
    "registrar_email": "登録者のEメールアドレス",
    "consented_by_parent_guardian": "親/保護者",
    "consented_by_decision_maker": "公認の医療意志決定者",
    "consented_by_guardian_verbal": "保護者のいる登録者（口頭で同意済）",
    "consented_by_registrar": "登録者（口頭で同意済）",
    "full_name": "氏名",
    "print": "印刷"
  },
  "registration": {
    "contact_information": "名前と住所",
    "contact_information_additional": "コミュニケーションと結果のコミュニケーションと結果に関する親または保護者の連絡先情報",
    "personal_information": "属性情報",
    "address_placeholder": "自宅の住所を入力してください",
    "first_name_label": "名",
    "last_name_label": "姓",
    "errors": {
      "state": "状態は有効な2文字の状態コードである必要があります",
      "phone_number_invalid": "電話番号が無効です",
      "required": "この値は必須です",
      "field_required": "{{field}}には値が必要です",
      "email_domain": "エントリが無効です。再確認してください。登録は、スポンサー組織によって選択された参加者に限定されます。仕事やコミュニティのメールアドレスを使ってみてください。資格があると思われ、エラーが発生した場合は、サポートに連絡してください。",
      "age_requirement": "生年月日が年齢要件を満たしていない",
      "signature_required": "署名が必要です",
      "regex_invalid": "この値は無効のようです。",
      "date_invalid": "この日付は存在しません",
      "invalid_entry": "登録を続行しないでください。画面の指示に従うか、連絡先に連絡してください。",
      "city": "有効な都市である必要があります",
      "survey_unanswered": "*の付いた必須の質問がすべて回答されていることを確認してください。",
      "postal_code": "有効な5桁の郵便番号である必要があります",
      "option_required": "オプションの1つを選択する必要があります",
      "year_length_invalid": "生年月日を4桁の年（yyyy）で入力します",
      "invalid_day": "日は1〜31の間でなければなりません",
      "reached_max_chars": "最大文字数に達しました。",
      "chars_max": "最大文字数",
      "minimum_length": "少なくとも{{length}}文字以上で入力してください。"
    },
    "insurance_status": {
      "question": "健康保険はお持ちですか？",
      "have_health_insurance": "はい、健康保険に加入しています",
      "do_not_have_health_insurance": "いいえ、健康保険に加入していません"
    },
    "insurance_policy_holder": {
      "question": "保険契約者はどなたですか？",
      "i_am": "自分",
      "my_spouse": "配偶者またはパートナー",
      "my_parents": "両親",
      "someone_else": "他人",
      "policy_first_name": "保険契約者の名",
      "policy_last_name": "保険契約者の姓",
      "policy_dob": "保険契約者の生年月日",
      "name": "保険契約者名"
    },
    "insurance_information": {
      "title": "保険情報",
      "company_name": "保険会社名",
      "id_number": "会員識別番号",
      "group_number": "メンバーグループ番号",
      "secondary_insurance_label": "二次保険を持っています",
      "take_photo": "保険証の写真を撮ってください。写真を撮ると、一部の情報が自動的に入力されます。",
      "front_of_card": "カードの表面",
      "card_information": "保険証情報",
      "back_of_card": "カードの裏面"
    },
    "employment_information": {
      "label": "雇用",
      "address_1": "会社の住所",
      "address_2": "例：Suite 200",
      "phone_number": "会社の電話番号",
      "company": "会社名",
      "postal_code": "雇用主の郵便番号",
      "employed": "雇用された",
      "sole_prorietor": "個人事業主",
      "not_employed": "未就業の",
      "reporting_supervisor": "報告監督者",
      "reporting_department": "報告部門",
      "supervisor_name": "スーパーバイザー名",
      "supervisor_email": "スーパーバイザーメールアドレス"
    },
    "location_availability": "現在、これらの地域に住むコミュニティメンバーのテストが優先されています",
    "custom_survey": "アンケート",
    "confirmation": "レビュー",
    "waitlisted": "順番待ちリスト",
    "schedule_your_appointment": "スケジュール予約",
    "information": "情報",
    "consent": "同意",
    "location": "位置",
    "symptoms": "症状",
    "address_required": "住所を入力してください",
    "consent_required": "続行することに同意してください",
    "required_field": "必須フィールドを示します。",
    "phone_number": "携帯電話番号",
    "email": "メール",
    "date_of_birth": "生年月日",
    "minimum_age": "参加の最低年齢は{{year}}歳です。",
    "no_minimum_age": "登録の最低年齢はありません",
    "continue_button": "継続する",
    "email_required": "この値は有効な電子メールである必要があります。",
    "done": "完了",
    "signature": "サイン",
    "clear_button": "クリア",
    "back_button": "戻る",
    "choose_location": "場所を選択してください",
    "no_slots": "{{location}}で利用可能なスロットはこれ以上ありません",
    "choose_appointment": "{{location}}で予約時間を選択してください",
    "appointment_required": "予約時間を選択してください",
    "phone_number_required": "電話番号が無効です",
    "phone_number_label": "より迅速なテスト結果を得るために、テキストメッセージを受信できる番号を入力してください",
    "symptoms_experiencing": "現在発生している症状をクリックしてください。症状がない場合は、続行してください。",
    "household": "世帯員",
    "household_p1": "必要に応じて、テストを受ける必要のある世帯員の予約を作成します。",
    "add_dependent": "世帯員を追加する",
    "remove_dependent": "扶養家族を削除する",
    "dependent_consents": "同意",
    "submit": "完了",
    "add_waitlist": "順番待ちリストに追加",
    "address": "自宅の住所",
    "address_1": "住所欄1",
    "address_2": "アパート・スイート＃",
    "address_city": "街",
    "address_state": "州",
    "postal_code": "郵便番号",
    "race_ethnicity": "人種",
    "gender": "性同一性",
    "self_described_gender": "自己記述の性別",
    "interpreter": "通訳が必要ですか？「はい」の場合、どの言語ですか？",
    "consent_to_terms": "私はこれらの条件に同意します。",
    "reg_not_open": "登録は開始していません",
    "no_more_avail_spots": "選択したタイムスロットは使用できなくなります。もう一度やり直してください。",
    "consent_helper": "下のボックスをクリックしてマウスまたは指でドラッグし、署名します",
    "phone_number_unreachable_label": "固定電話？",
    "select": "選択する",
    "test_surveys": "予定の質問",
    "edit": "編集",
    "continue_to_registration": "登録を続行",
    "accounts": {
      "already_have_an_account": "すでにアカウントをお持ちですか？",
      "log_in": "ログイン",
      "sign_up": "サインアップ",
      "sign_up_description": "アカウントを作成し、将来の登録にかかる時間を節約するために、情報を入力してください。",
      "log_in_description": "メールアドレスとパスワードを入力するか、以下のGoogleまたはOutlookからログインしてください",
      "sign_in_with_label": "{{provider}}でサインインします",
      "password_must_match": "パスワードが一致する必要があります",
      "password_uppercase": "パスワードには、少なくとも（％s）大文字が含まれている必要があります。",
      "password_lowercase": "パスワードには、少なくとも（％s）小文字が含まれている必要があります。",
      "password_number": "パスワードには少なくとも（％s）の数字が含まれている必要があります。",
      "password_special": "パスワードには、少なくとも（％s）個の特殊文字が含まれている必要があります。"
    },
    "password": "パスワード",
    "password_confirmation": "パスワードの確認",
    "consent_for": "{{name}}の同意",
    "book_one_time_appointment": "1回限りの予約",
    "duplicate_users": {
      "exists": "あなたは既に登録されています",
      "overlapping_email_and_phone_p1": "あなたの電話番号とメールアドレスにメッセージを送信しました。",
      "p2": "メッセージを使用して、予定を変更したり、テスト結果を入力したりできます。",
      "overlapping_phone_and_email_p3": "詳細については、メールまたは電話で確認してください。",
      "overlapping_email_p3": "詳細はメールでご確認ください。",
      "overlapping_phone_p3": "詳細については、お使いの携帯電話を確認してください。",
      "overlapping_phone_p1": "あなたの電話番号にメッセージを送信しました。",
      "overlapping_email_p1": "あなたのメールアドレスにメッセージを送信しました。",
      "p4": "これがエラーだと思われる場合は、support@primary.healthまでご連絡ください。",
      "overlapping_email_and_phone_p3": "詳細は、メールまたは電話で確認してください。"
    },
    "duplicate_waitlist": {
      "exists": "あなたはすでに順番待ちリストに載っています",
      "overlapping_email_and_phone_p1": "あなたの電話番号とメールアドレスに別の確認を送信しました",
      "p2": "確認を使用して、追加情報を提供したり、順番待ちリストから自分を削除したりできます。",
      "overlapping_phone_and_email_p3": "詳細については、メールまたは電話で確認してください",
      "overlapping_email_p3": "詳細についてはメールを確認してください",
      "overlapping_phone_p3": "詳細はお使いの携帯電話を確認してください",
      "overlapping_email_p1": "別の確認メールを送信しました。"
    },
    "insist_guardian_consent": "私はに代わって同意を提供しています",
    "confirmation_section": {
      "title": "レビューして確認する",
      "p1": "登録を完了する前に、情報を確認してください。",
      "edit_information": "戻って編集する"
    },
    "iemodal": {
      "title": "サポートされていないブラウザ",
      "body": "現在、これらのブラウザで登録が可能です"
    },
    "show_other_locations": "クリックして他の場所を表示",
    "non_us_address": "これは国際住所です",
    "test_group_user_survey": "参加者スクリーニング",
    "self_consent": "私は自分自身に同意しています",
    "address_country": "国",
    "appointment": "予約",
    "employee_id": "従業員のID",
    "appointment_time": "予定時間",
    "appointment_location": "位置",
    "phone_or_email": "電話番号またはメール",
    "no_self_consent": "私は自分自身の同意を拒否します",
    "no_insist_guardian_consent": "{{full_name}}に代わって同意を拒否します",
    "additional_consents_helpertext": "追加の同意は後で要求される場合があります",
    "minimum_age_with_months": "参加の最低年齢は、{{year}}歳と{{month}}か月です。",
    "assistive_technology": "支援技術",
    "covid_vaccine_y_n": "この予約で新型コロナウィルスワクチン接種をご希望ですか？",
    "received_covid_vaccine_y_n": "以前に新型コロナウィルスワクチンを接種したことがありますか？",
    "covid_vaccinations": "新型コロナウィルスワクチン接種",
    "select_vaccine_dose": "受けたいワクチンの投与量を選択してください",
    "demographic_info": "人口統計情報",
    "additional_info": "追加情報",
    "self_described_race": "自称人種",
    "verify_contact_information": "検証",
    "verify_contact": {
      "we_sent_you_a_code": "{{contact}}に送信したコードを入力してください。",
      "confirmation_code": "確認番号",
      "code_resent": {
        "email": "別のコードがメールアドレスに送信されました。",
        "phone_number": "別のコードが携帯電話に送信されました。"
      },
      "did_not_receive_code": "確認コードが届かないでしょうか。",
      "verify_by": {
        "email": "メールアドレスで確認する",
        "phone_number": "電話番号で確認する"
      }
    },
    "preferred_method_of_communication": {
      "question": "希望される連絡方法",
      "helper_text": "予約の更新の際にご連絡させていただきます",
      "phone_number": "電話（ショートメッセージのみ。通信料が発生する場合があります）",
      "landline": "現在、固定電話はご利用いただけません、重要な連絡をするためのEメールアドレスをご記入ください。",
      "verification_method": "どの方法で認証コードを受け取りますか？",
      "verification_helper_text": "ご本人であることを確認するために、ログイン前にあなたにコードを送信します。"
    },
    "skip_for_now": "今は結構です",
    "default_information": {
      "title": "はい、このアドレスを利用します",
      "title_v2": "はい、この連絡先情報を使用します"
    },
    "middle_name_label": "ミドルネーム",
    "confirm_appointment": "予約を確認する",
    "dob": "生年月日",
    "different_appointment": "別の予約",
    "select_appointment_time": "予約の時間を選択",
    "decline_address": "住所の提供は拒否します。代わりにプログラムのアドレスを使用してください。",
    "patient_information": "患者情報",
    "scan_license": "運転免許証をスキャンする",
    "how_to_contact": "どのようにご連絡すればいいでしょうか?",
    "decline_email_or_phone": "メールアドレスや電話番号の提供を拒否します。プログラム管理者が私の結果を受け取ることを許可します。",
    "medical_screening": "健康診断",
    "show_service_at_this_location": "この場所でサービスを表示",
    "verbal_consent_provided": "アメリカ障害者法に従い、参加者またはその法的権限を有する意思決定者が口頭で同意しました。書面による同意は、参加者またはその法的権限を有する意思決定者から 24 時間以内に取得する必要がございます。",
    "unexpired_written_consent_present": "テストサイトには有効期限内の書面による同意書が保管されています。",
    "select_to_proceed": "続行するには上記のオプションを選択してください。",
    "guardians_information": "保護者の情報",
    "registrars_information": "登録者の情報",
    "optional": "オプション",
    "vfc_eligibility": "小児用ワクチン（VFC）の資格",
    "vfc_eligibility_subtext": "お子様は、CDC の小児用ワクチン (VFC) プログラムを通じて、VFC プログラムに登録されている医療提供者のオフィス、薬局、診療所で無償のワクチンを接種できる場合があります。"
  },
  "gender_key": {
    "male": "男性",
    "female": "女性",
    "cisgender": "シスジェンダー",
    "non_binary": "性別二元制またはノンバイナリー",
    "other": "他の",
    "prefer_to_self_describe": "記載されていないID",
    "prefer_not_to_disclose": "回答を謝絶する",
    "transgender_male": "トランス男性・トランスマン",
    "transgender_female": "トランス女性・トランスウーマン",
    "unknown": "わからない",
    "non_binary_only": "ノンバイナリー",
    "intersex": "間性",
    "transgender": "トランスジェンダー",
    "gender_fluid": "ジェンダーフルイド",
    "not_applicable": "該当なし"
  },
  "ethnicity": {
    "american_indian_alaska_native": "アメリカンインディアンまたはアラスカ先住民",
    "american_indian_central_america": "南アメリカまたは中央アメリカのアメリカンインディアン",
    "asian": "アジア人",
    "black": "黒人またはアフリカ系アメリカ人",
    "latinx": "ラテン系、ラテン系、またはヒスパニック",
    "middle_eastern_north_african": "中東または北アフリカ",
    "native_hawaiian_pacific_islander": "ハワイ先住民または太平洋諸島民",
    "white_european": "白人または白人",
    "unknown": "わからない",
    "prefer_not_to_disclose": "開示したくない",
    "asian_indian": "アジア系インド人",
    "filipino": "フィリピン人",
    "japanese": "日本",
    "korean": "韓国語",
    "vietnamese": "ベトナム語",
    "other_asian": "その他のアジア人",
    "native_hawaiian": "ハワイ先住民",
    "guamanian_or_chamorro": "グアムまたはチャモロ",
    "samoan": "サモア人",
    "other_pacific_islander": "他の太平洋諸島民",
    "chinese": "中国語",
    "help": "人種とは、人々のグループを区別するために使用される一連の身体的特徴を指します。人種は、国籍、または市民権を持っている国と一致する場合と一致しない場合があります。",
    "subtitle": "規制により、以下のすべての情報を収集することが義務付けられています。",
    "laotian": "ラオス人",
    "cambodian": "カンボジア人",
    "other": "その他",
    "bangladeshi": "バングラデシュ人",
    "hmong": "モン族",
    "indonesian": "インドネシア人",
    "malaysian": "マレーシア人",
    "pakistani": "パキスタン人",
    "sri_lankan": "スリランカ人",
    "thai": "タイ人",
    "taiwanese": "台湾人",
    "fijian": "フィジー人",
    "guamanian": "グアム人",
    "tongan": "トンガ人",
    "bahamian": "バハマ人"
  },
  "languages": {
    "en": "英語",
    "fr": "フランス語",
    "es": "スペイン語",
    "so": "ソマリ語",
    "hmn": "モン族",
    "asl": "アメリカ手話（ASL）",
    "kar": "カレン",
    "am": "アムハラ語",
    "ru": "ロシア語",
    "om": "オロモ"
  },
  "symptoms": {
    "fever": "発熱または悪寒",
    "cough": "咳",
    "muscle_aches": "筋肉痛",
    "severe_fatigue": "重度の倦怠感（通常より）",
    "trouble_breathing": "呼吸困難",
    "diarrhea": "下痢",
    "loss_of_smell": "においの喪失",
    "loss_of_taste": "味の喪失",
    "shortness_of_breath": "息切れまたは呼吸困難",
    "headache": "頭痛",
    "sore_throat": "喉の痛み",
    "congestion": "鼻づまりまたは鼻水。",
    "nausea": "吐き気または嘔吐",
    "close_contact": "感染者との緊密な接触*",
    "helper_text": {
      "close_contact": "* 新型コロナウィルスを確認した人と密接に接触しましたか（6フィート以内で15分以上）？"
    },
    "suspected_exposure": "暴露の疑い",
    "none": "症状なし"
  },
  "instructions": {
    "title": "登録が完了しました。以下の情報と次の手順を注意深く確認してください。",
    "p1": "このページには、予約に関する情報と予約バーコードが含まれています。",
    "appointment_scheduled": "あなたの予定が予定されています",
    "verify_info": {
      "title": "情報を確認する",
      "p1": "患者ポータルにすばやくアクセスできるように、連絡先情報を検証することが重要です。",
      "p2": "確認がメールに送信されました。提供されたリンクをクリックしてください。",
      "p3": "連絡先情報を変更する必要がある場合は、ヘルプボタンを押してください。"
    },
    "verified": "確認済み",
    "not_verified": "検証されていない",
    "resend_text": "リンクを受け取っていませんか？",
    "resend": "再送",
    "skip": "検証をスキップして確認を表示",
    "name": "名前",
    "appointment_details": "予定",
    "date_and_time": "日時",
    "add_to_calendar": "カレンダーに追加",
    "instructions": "一般的な手順",
    "successfully_flash": "予定を作成しました。",
    "success_flash_email": "正常に確認された電子メール",
    "success_flash_phone": "正常に確認された電話番号",
    "mail_order": "テストキットは1週間以内に郵送されます。",
    "at_home": "この頃、医療提供者があなたの家にやってくるでしょう",
    "at_home_instructions": "自宅での指示",
    "mail_order_instructions": "通信販売の説明",
    "request_additional_appointment": "フォローアップの予約",
    "book_additional_appointment": "別の予定を予約する",
    "confirmation_code": "確認コード",
    "completed": "完了",
    "appointment_barcode": "予定バーコード",
    "dependent_links": "世帯予約リンク",
    "on_demand_title": "登録が完了しました。以下の情報と次の手順を注意深く確認してください。",
    "save_link": "このリンクを保存",
    "verify_phone": "電話番号を確認する",
    "verify_phone_description": "記録にすばやくアクセスできるように、連絡先情報を検証することが重要です。確認のテキストメッセージがあなたの番号に送信されました。",
    "verify_email_description": "記録にすばやくアクセスできるように、連絡先情報を検証することが重要です。確認メールがあなたのメールアドレスに送信されました。",
    "information": "登録情報",
    "follow_up_appointment": "フォローアップの予定",
    "get_directions": "行き方を調べる",
    "cancel": "予約をキャンセルする",
    "reschedule_appointment": "予約を変更する",
    "reschedule_linked_appointments": "予定を変更する",
    "no_slots": "これ以上利用できる時間はありません",
    "check_results": "患者ポータルを確認する",
    "follow_up_modal_header": "2回目の投与をスケジュールしてください",
    "are_you_sure_you_want_to_cancel": "この予定をキャンセルしてもよろしいですか？",
    "please_choose_cancellation_reason": "以下のキャンセル理由を選択してください",
    "additional_details": "以下に詳細を追加してください",
    "errors": {
      "missing_cancellation_reason": "キャンセル理由をお選びください"
    },
    "verify_email": "あなたの電子メールを確認します",
    "redcap_url": "クリックして調査を完了する",
    "verify_contact_information": "連絡先情報を確認する",
    "please_also": "またお願いします",
    "new_title": "{{name}}の確認",
    "contact_method": "連絡方法",
    "next_steps": {
      "title": "今後のステップ",
      "p1": "検査キットを有効化する必要があります。検査を受ける準備ができたら、受信したEメール内のリンクをたどり、このページに戻ってきて、以下に従ってキットの有効化を行ってください。",
      "administered": "We will contact you when your kit reaches the lab and when your results are ready. Results typically take 24-48 hours after they reach the lab. You can view results from your [Medical history]({{link}}) page when they are ready.",
      "resulted": "結果はご自身の [メディカルヒストリー]({{link}}) ページに表示されます。",
      "shipping_information": "送付情報"
    },
    "save_this_page": {
      "title": "このページを保存する",
      "p1": "このページで自己検査を報告する、またはメディカルヒストリーページから結果を確認してください。",
      "p2": "今後このページにアクセスする際は、送られてきた確認メール、またはショートメッセージの文面にあるリンクをクリックしてください。",
      "p3": "お気に入り登録をすれば、このページを保存することもできます。ホーム画面に追加するか、またはリンクを安全な場所にコピーしておいてください。"
    },
    "show_my_barcode": "バーコードを表示する",
    "my_account": "マイアカウント",
    "register_another": "もう一人登録する",
    "update_vaccine": "ワクチン情報を更新する",
    "medical_history": "メディカルヒストリー",
    "upcoming_appointments": "今後の予約",
    "reschedule": "予約を変更する",
    "resend_confirmation": "確認メールを再送信",
    "appointment_details_v2": "予約の詳細",
    "confirm_cancellation": "キャンセルを確認する",
    "confirm_cancellation_question": "この予約をキャンセルしてもよろしいですか?",
    "select_new_appointment_time_below": "以下の新しい予約時間を選択してください。サービスまたは予約場所を変更する必要がある場合は、この予約をキャンセルして新しい予約をスケジュールしてください。",
    "no_take_me_back": "いいえ、前に戻る",
    "yes_cancel_appointment": "はい、予約をキャンセルする",
    "update_appointment": "予約を更新する",
    "select_new_appointment_time": "新しい予約時間を選択",
    "clinic": "診療所",
    "services": "サービス",
    "appointment_missed": "失念した予約",
    "appointment_canceled": "キャンセルした予約",
    "screening_complete_for": "Screening complete for {{name}}"
  },
  "result_page": {
    "errors": {
      "invalid_access_code": "無効なアクセスコード"
    },
    "landing": {
      "p1": "結果を表示するには、次のように入力してください。",
      "access_code_label": "アクセスコード",
      "submit_button": "送信",
      "recent_results": "最近の結果"
    },
    "view_results": {
      "headline": "{{name}}のテスト結果",
      "reregister": "再登録",
      "status": {
        "likely_positive": "結果は不確定",
        "test_not_performed": "再テストが必要",
        "results_ready": "結果ができました",
        "processing": "処理中",
        "test_status": "テストステータス",
        "test_result": "テスト結果",
        "administered": "管理された",
        "results_pending": "結果保留",
        "test_results": "検査結果"
      },
      "at": "{{address}}で"
    },
    "result_label": "結果",
    "result": {
      "flu_a": {
        "result_label": "インフルエンザの結果"
      },
      "flu_b": {
        "result_label": "インフルエンザBの結果"
      },
      "covid19": {
        "result_label": "新型コロナウィルスの結果"
      },
      "covid": {
        "result_label": "新型コロナウィルスの結果"
      },
      "sars": {
        "result_label": "SARSの結果"
      },
      "control": {
        "result_label": "コントロール"
      },
      "rsv": {
        "result_label": "RSVの結果"
      },
      "result": {
        "result_label": "結果"
      },
      "hba1c": {
        "normal": "正常",
        "warning": "前糖尿病",
        "danger": "糖尿病"
      },
      "lead_venous": {
        "danger": "異常",
        "normal": "正常"
      },
      "tc": {
        "result_label": "TC結果値"
      },
      "hdl": {
        "result_label": "HDL結果値"
      },
      "trg": {
        "result_label": "TRG結果値"
      },
      "ldl": {
        "result_label": "LDL結果値"
      },
      "non_hdl": {
        "result_label": "Non-HDL結果値"
      },
      "tc_hdl_ratio": {
        "result_label": "TC/HDL比"
      },
      "glu": {
        "result_label": "GLU結果値"
      },
      "alere_cholestech_ldx": {
        "danger": "危ない状態",
        "warning": "異常",
        "normal": "正常"
      },
      "lead": {
        "result_label": "鉛"
      },
      "zinc": {
        "result_label": "亜鉛"
      },
      "lead_standard_profile": {
        "danger": "異常",
        "normal": "正常"
      },
      "creatinine": {
        "danger": "正常",
        "normal": "異常"
      },
      "igg": {
        "result_label": "免疫グロブリンG結果"
      },
      "igm": {
        "result_label": "免疫グロブリンM結果"
      },
      "blood_glucose_fasted": {
        "normal": "正常",
        "warning": "高値",
        "danger": "高い",
        "hypoglycemia": "低血糖症",
        "prediabetes": "前糖尿病",
        "diabetes": "糖尿病"
      },
      "total_cholesterol_fasted": {
        "normal": "正常",
        "elevated": "高値",
        "high": "高い",
        "low": "低い",
        "abnormal": "異常"
      },
      "total_cholesterol_unfasted": {
        "normal": "正常",
        "elevated": "高値",
        "high": "高い"
      },
      "a1c_now": {
        "normal": "正常",
        "warning": "ユウジョウビョウ",
        "danger": "糖尿病"
      },
      "blood_glucose": {
        "warning": "低い",
        "normal": "普通",
        "prediabetes": "前糖尿病",
        "danger": "糖尿病",
        "hypoglycemia": "低血糖症",
        "medical_emergency": "医療緊急事態",
        "inconclusive_1": "結論が出ない 1",
        "inconclusive_2": "結論が出ない 2",
        "possible_diabetes": "糖尿病の可能性"
      },
      "triglycerides": {
        "result_label": "中性脂肪"
      },
      "blood_glucose_ucsf": {
        "low": "低い",
        "normal": "普通",
        "high": "高い"
      },
      "syphilis": {
        "result_label": "ばいどく の けっか"
      },
      "hepatitis_c": {
        "result_label": "Cがた かんえん の けっか"
      },
      "hiv": {
        "result_label": "HIV の けっか"
      },
      "rapid_hiv": {
        "positive": "Reactive (Preliminary positive)",
        "negative": "Non-reactive (Negative)",
        "did_not_result": "Invalid (Indeterminate)"
      },
      "rapid_hcv": {
        "did_not_result": "Invalid (Indeterminate)",
        "negative": "Non-reactive (Negative)",
        "positive": "Reactive (Preliminary positive)"
      },
      "rapid_syphilis": {
        "positive": "Reactive (Preliminary positive)",
        "negative": "Non-reactive (Negative)",
        "did_not_result": "Invalid (Indeterminate)"
      }
    },
    "documents": "ドキュメント",
    "self_administered": "{{location}}で取得した自己管理テスト",
    "patient": "患者",
    "medical_history": "メディカルヒストリー",
    "overview_title": "より詳しい内容や必要な追加アクション項目を見るには、検査またはサービスを選択してください。一部の検査結果がまだ出ていない場合や、医療提供者との面談を行った後にのみ結果が出される場合もあることにご留意ください。",
    "insurance_information": "保険の情報を記入してください",
    "contact_support": "サポートが必要な場合は、サポートにお問い合わせください。",
    "show": "表示する",
    "hide": "表示しない",
    "lab_report": "検査機関からの報告",
    "contact_provider": {
      "header": "陽性の結果がでました。",
      "description": "結果と治療について医師と話し合いたいでしょうか。",
      "yes_option_text": "はい、医師に話し合いたいです。",
      "no_option_text": "いいえ、結果を理解しました。プロバイダーと話をしたくありません。",
      "confirm_phone_number_header": "電話番号を確認する",
      "confirm_phone_number_description": "ご相談に最適な電話番号をご確認ください。",
      "confirm_button_text": "確認",
      "consultation_confirmed_header": "ご相談の確認が完了しました。",
      "consultation_confirmed_description": "プロバイダーが 2 ～ 3 営業日以内に {{phone_number}} に連絡いたします。",
      "acknowledgement_option_helper_text": "治療の重要性を説明し、気が変わった場合に電話の予約方法をユーザーに知らせるものである。",
      "acknowledgement_confirmed_header": "承認を確認しました",
      "acknowledgement_confirmed_description": "リンクされたリソースを使用して治療を受けることの重要性について。相談は完全に無料であり、医療提供者は感染症の解決に役立つ処方箋やその他のものを提供できることを再度通知します。",
      "acknowledgement_confirmed_change_mind_text": "気が変わった場合は、下の「相談を希望する」をクリックしてください。",
      "request_consultation_button_text": "相談を希望しています。"
    },
    "phone": "電話番号",
    "source": "Source"
  },
  "required": {
    "errors": {
      "address_not_in_bounding_box": "あなたの住所は、このプログラムのサイトの資格要件と一致しません。"
    }
  },
  "member": {
    "medical_history": "歴史",
    "view_instructions": "確認を表示",
    "next_appointment": "次の予定",
    "over_18": "私は18歳以上であることを証明します",
    "choose_test_configurations_title": "この予約にご希望のサービスを選択してください",
    "member_taken_error": "ユーザーはすでにアカウントを持っています",
    "choose_test_configurations_subtitle": "サービスはすべての世帯員に適用されます",
    "service": "サービス",
    "group_or_location": "グループ・場所",
    "details": "詳細",
    "see_all": "すべてを見る",
    "no_history": "現在、履歴がありません。",
    "medical_history_title_with_name": "{{name}}の履歴",
    "no_dashboard": "現在利用可能なものはございません",
    "product": "製品",
    "price": "価格",
    "quantity": "量",
    "total_services_selected": "選択したサービスの合計",
    "total_price": "合計金額"
  },
  "or": "また",
  "account": {
    "errors": {
      "must_be_13": "アカウントに登録するには13歳である必要があります"
    }
  },
  "self_administration": {
    "title": "自己管理システム",
    "self_administer_action": "自己管理人",
    "skip_to_link": "自己管理にスキップ",
    "select_person": "チェックアウトする人を選択してください",
    "adult": "大人",
    "child": "子供",
    "checked_out": "チェックアウトされた",
    "go_back": "戻る",
    "switch_to_scanner": "スキャナーに切り替える",
    "enter_barcode": "バーコードを入力してください",
    "scan_barcode": "バーコードをスキャンする",
    "cancel": "キャンセル",
    "barcode_for": "のバーコード",
    "enter_barcode_manually": "バーコードを手動で入力する",
    "instructions": "下の試験管にあるバーコードをスキャンまたは入力します。",
    "regex_prefix": "バーコードは次のもので構成されている必要があります",
    "all_completed": "完了：すべての世帯員がこのステップを完了しました",
    "errors": {
      "no_appointment": "予定の特定に問題があります",
      "barcode_in_use": "このバーコードは以前に使用されています。以前にこのキットを自己管理したことがない場合は、キットを提供したサイトのスタッフに相談して、新しいキットを依頼してください。それ以外の場合は、下のヘルプボタンをクリックしてください。",
      "test_error_general": "テスト作成の問題",
      "different_barcodes": "入力したバーコードが一致しません。"
    },
    "confirm_barcode_input": "確認のためにバーコードをもう一度入力してください",
    "click_to_self_test": "セルフテストするには、ここをクリックしてください",
    "or_enter_barcode_manually": "Or enter manually with all letters and numbers"
  },
  "location_details": {
    "next_appointment": {
      "waitlist": "順番待ちリスト",
      "unavailable": "利用可能な予定はありません"
    },
    "labels": {
      "address": "住所",
      "next_appointment": "利用可能な最も早い予定",
      "services": "利用可能なサービス"
    }
  },
  "waiting_room": {
    "header": "あなたは待合室にいます。スケジューリングシステムに接続するまでお待ちください。",
    "p1": "しばらくお待ちください。我々ははこのパンデミックを一緒に終わらせます。",
    "signature": "敬具",
    "p2": "新型コロナウィルスの経験は苛立たしいものになる可能性があることを我々が存じています。我々ががあなたをサイトに連れて行ってあなたの予定を予約するためにできる限り速く働く間、ここで待ってください。"
  },
  "users": {
    "send_appointment_confirmation_message": "こんにちは{{full_name}}. こちらは{{name}}での予約の通知です. あなたの予約は {{date}} {{time}} {{address}}にあります。",
    "send_registration_link": "こんにちは、{{full_name}}。このリンクをたどって、{{name}} {{registration_link}}に登録してください"
  },
  "follow_up": {
    "first_dose": "1回目接種",
    "previous_appointment": "過去の予約",
    "booked_appointment": "あなたの予約",
    "second_dose": "2回目の接種",
    "choose_second_location": "2番目の予約場所を選択します"
  },
  "cancellation_types": {
    "cant_get_to_location": "この場所に行く方法がありません",
    "timing_conflict": "タイミングが悪かった",
    "became_ill_with_covid19": "新型コロナウィルスで病気になりました",
    "received_service_elsewhere": "他の場所で同じサービスを受けました",
    "other": "その他",
    "duplicate_appointment": "これは重複した予定でした"
  },
  "translation": {
    "consent": {
      "type_name_instead": "代わりに名前を入力してください",
      "type_name": "署名をタイプ入力してください"
    }
  },
  "email": {
    "confirmation": {
      "your_access_code_is": "あなたのアクセスコードは",
      "please_confirm_your_email": "下のボタンをクリックしてメールを確認してください",
      "hello": "こんにちは",
      "click_to_reschedule": "クリックして手順・再スケジュールを表示",
      "click_to_reschedule_on_demand": "クリックして手順を表示"
    },
    "cancellation": {
      "your_appointment_has_been_canceled": "{{time}}の {{location}}での予約はキャンセルされました。間違った操作をした場合は、support@primary.healthまでメールでお知らせください。"
    }
  },
  "signature_lines": {
    "name": "参加者の名前",
    "date": "日付",
    "signature": "参加者の署名",
    "and_or": "および・または",
    "guardian_name": "親・保護者の名前",
    "guardian_signature": "親・保護者の署名"
  },
  "employer_testing": {
    "hello_name": "こんにちは{{name}}",
    "results": {
      "negative": "ネガティブ",
      "positive": "ポジティブ",
      "did_not_result": "無効",
      "unknown": "わからない",
      "invalid": "無効",
      "pending": "保留中"
    },
    "code_reader": {
      "scan_your_code": "テストカードのコードをスキャンします",
      "find_in_top_right_hand_corner": "スキャンコードはテストに固有のものであり、テストの右上隅にあります。",
      "center_code": "ここでコードを中央に配置します。",
      "already_used_error": "このテストバーコードはすでに使用されています。サポートについては、テストキットプロバイダーにお問い合わせください。",
      "click_to_scan": "クリックしてQRコードをスキャン",
      "scan_new_test_card": "新しいテストカードをスキャンするには、ここをクリックしてください。",
      "not_working": "動作していませんか？",
      "try_again": "ここをクリックして再試行してください。"
    },
    "continue": "継続する",
    "verify_date_of_birth": {
      "confirm_date_of_birth": "続行するには、参加者の生年月日を確認してください",
      "contact_administrator": "表示される情報が正しくない場合は、管理者に連絡して支援を求めてください",
      "error": "入力した生年月日が正しくありません。もう一度やり直すか、サイト管理者に連絡して更新してください。"
    },
    "result_entry": {
      "code_is_registered": "コードが登録されました。",
      "take_the_test": "試験を受ける",
      "follow_the_instructions": "テストに付属の説明書に従い、テストカードのバイアルに鼻腔スワブを入れたら15分のタイマーを開始します。",
      "cant_find_instructions": "手順が見つかりませんか？",
      "view_instructions": "BinaxNowの手順を表示する",
      "start_timer": "15分タイマーを開始（オプション）",
      "submit_within_15": "ヒント：15分以内に結果を送信してください",
      "enter_test_results": "テスト結果を入力する",
      "choose_a_result": "テストカードの結果を説明するオプションを選択してください",
      "submit": "送信",
      "must_log_result_and_photo": "続行するには、結果をログに記録し、テストの写真を撮る必要があります",
      "submit_within_15_v2": "ヒント：テストが15分間実行された後、結果を送信します",
      "click_to_take_photo": "写真を撮るにはクリックしてください",
      "results_may_be_invalid": "テスト結果が無効になる可能性があります"
    },
    "summary": {
      "title": "結果の概要",
      "negative_subtitle": "あなたは新型コロナウィルスに感染されました",
      "negative_message": "私たちはあなたが現場で仕事に戻ることを許可されたという通知をあなたの雇用主に送りました。",
      "positive_subtitle": "申し訳ありませんが、新型コロナウィルスに感染されました",
      "positive_message": "新型コロナウィルスに感染しているという通知を雇用主に送信しました。",
      "what_should_you_do": "あなたは何をするべきか：",
      "employer_name_resources": "雇用主名リソース：",
      "follow_cdc_guidelines": "CDCが推奨するガイドラインに従ってください"
    },
    "clarifying_results": {
      "title": "結果を明確にする",
      "which_did_your_result_most_look_like": "あなたの結果はどのように見えましたか？",
      "no_lines": "線が見られない",
      "blue_control_line_only": "青い制御線のみ",
      "pink_sample_line_only": "ピンクのサンプルラインのみ",
      "blue_control_line_and_pink_sample_line": "青のコントロールラインとピンク・紫のサンプルライン",
      "still_not_sure": "まだわからない",
      "submit_results": "結果を送信する",
      "thank_you": "ありがとうございました！",
      "clarification_received_message": "結果の説明を受け取りました。",
      "only_pink_line": "ピンクのラインのみ",
      "no_lines_v2": "行なし"
    },
    "return_to_confirmation": "確認ページに戻る"
  },
  "preferred_language": {
    "title": "ご希望の言語",
    "subtitle": "お好みの言語"
  },
  "appointment_type": {
    "initial_vaccine_appointment": "最初のワクチン接種の系",
    "one_dose": "特定の用量の予約をしてください。特に、これは私の",
    "title": "用量選択",
    "first_dose": "初回投与のみ",
    "second_dose": "2回投与のみ",
    "single_dose_title": "1回投与のみ",
    "additional": "免疫不全の追加用量",
    "booster": "ブースター用量",
    "supplemental": "補足用量",
    "third_dose": "3回投与のみ",
    "no_vaccine": "当てはまらない"
  },
  "activemodel": {
    "errors": {
      "models": {
        "importers/results/row": {
          "attributes": {
            "administered_at": {
              "invalid_datetime": "should have format {{datetime_format}}"
            },
            "results_at": {
              "invalid_datetime": "should have format {{datetime_format}}"
            },
            "uid": {
              "unknown_test": "test not found"
            }
          }
        },
        "importers/participants/row": {
          "attributes": {
            "access_code": {
              "not_allow_update_test_group_user": "user exists and not allowed to updated it"
            },
            "date_of_birth": {
              "invalid_date": "should have format {{date_format}}"
            },
            "ethnicity": {
              "unknown_ethnicities": {
                "one": "{{unknown_ethnicities}} does not exist, pick from the following acceptable values: {{ethnicities}}",
                "other": "{{unknown_ethnicities}} do not exist, pick from the following acceptable values: {{ethnicities}}"
              }
            },
            "gender": {
              "unknown_gender": "gender does not exist, pick from the following acceptable values: {{genders}}"
            },
            "location": {
              "unknown": "location does not exist"
            },
            "phone_number": {
              "invalid": "is an invalid number"
            },
            "population_name": {
              "unknown_population": "{{unknown_population}} does not exist, pick from the following acceptable values: {{population_names}}"
            },
            "self_described_gender": {
              "not_allowed": "cannot fill it if the gender is not \"prefer_to_self_describe\""
            },
            "sex": {
              "unknown_sex": "sex does not exist, pick from the following acceptable values: {{sexes}}"
            },
            "sexual_orientation": {
              "unknown_sexual_orientation": "sexual_orientation does not exist, pick from the following acceptable values: {{sexual_orientations}}"
            },
            "status": {
              "unknown_status": "status is invalid, pick from the following acceptable values: {{statuses}}"
            },
            "races": {
              "unknown_races": {
                "one": "{{unknown_races}} does not exist, pick from the following acceptable values: {{races}}",
                "other": "{{unknown_races}} do not exist, pick from the following acceptable values: {{races}}"
              }
            },
            "tag_action": {
              "unknown": "{{unknown_tag_action}} is an invalid value, pick from the following acceptable values: {{tag_actions}}"
            },
            "vaccination_status": {
              "unknown_vaccination_status": "vaccination status is invalid, pick from the following acceptable values: {{vaccination_statuses}}"
            }
          }
        },
        "importers/organization_user/row": {
          "attributes": {
            "role": {
              "insufficient_privileges": "insufficient privileges to set the role"
            },
            "user_id": {
              "unknown_user": "user not found"
            }
          }
        },
        "importers/additional_information/row": {
          "attributes": {
            "additional_information": {
              "invalid_json": "invalid JSON"
            },
            "user_id": {
              "unknown_user": "user not found"
            }
          }
        },
        "importers/emr/row": {
          "attributes": {
            "appointment_location": {
              "unknown_location": "appointment location not found"
            },
            "date_of_birth": {
              "invalid_date": "should have format {{date_format}}"
            },
            "appointment_date": {
              "invalid_date": "should have format {{date_format}}"
            },
            "email": {
              "duplicated": "is already used"
            },
            "ethnicity": {
              "unknown_ethnicities": {
                "one": "{{unknown_ethnicities}} does not exist, pick from the following acceptable values: {{ethnicities}}",
                "other": "{{unknown_ethnicities}} do not exist, pick from the following acceptable values: {{ethnicities}}"
              }
            },
            "gender": {
              "unknown_gender": "gender does not exist, pick from the following acceptable values: {{genders}}"
            },
            "phone_number": {
              "invalid": "is an invalid number"
            },
            "population": {
              "unknown_population": "{{unknown_population}} does not exist, pick from the following acceptable values: {{population_names}}"
            },
            "self_described_gender": {
              "not_allowed": "cannot fill it if the gender is not \"prefer_to_self_describe\""
            },
            "sex": {
              "unknown_sex": "sex does not exist, pick from the following acceptable values: {{sexes}}"
            },
            "sexual_orientation": {
              "unknown_sexual_orientation": "sexual_orientation does not exist, pick from the following acceptable values: {{sexual_orientations}}"
            },
            "races": {
              "unknown_races": {
                "one": "{{unknown_races}} does not exist, pick from the following acceptable values: {{races}}",
                "other": "{{unknown_races}} do not exist, pick from the following acceptable values: {{races}}"
              }
            },
            "tag_action": {
              "unknown": "{{unknown_tag_action}} is an invalid value, pick from the following acceptable values: {{tag_actions}}"
            }
          }
        },
        "importers/additional_consents/row": {
          "attributes": {
            "consent_form_id": {
              "unknown_consent_form": "consent_form not found"
            },
            "access_code": {
              "unknown_user": "user not found"
            }
          }
        },
        "importers/vaccine/row": {
          "attributes": {
            "date_of_birth": {
              "invalid_date": "should have format {{date_format}}"
            },
            "phone_number": {
              "invalid": "is an invalid number"
            },
            "email": {
              "duplicated": "is already used"
            },
            "races": {
              "unknown_races": {
                "one": "{{unknown_races}} does not exist, pick from the following acceptable values: {{races}}",
                "other": "{{unknown_races}} do not exist, pick from the following acceptable values: {{races}}"
              }
            },
            "gender": {
              "unknown_gender": "gender does not exist, pick from the following acceptable values: {{genders}}"
            },
            "sexual_orientation": {
              "unknown_sexual_orientation": "sexual_orientation does not exist, pick from the following acceptable values: {{sexual_orientations}}"
            },
            "self_described_gender": {
              "not_allowed": "cannot fill it if the gender is not \"prefer_to_self_describe\""
            },
            "sex": {
              "unknown_sex": "sex does not exist, pick from the following acceptable values: {{sexes}}"
            },
            "ethnicity": {
              "unknown_ethnicities": {
                "one": "{{unknown_ethnicities}} does not exist, pick from the following acceptable values: {{ethnicities}}",
                "other": "{{unknown_ethnicities}} do not exist, pick from the following acceptable values: {{ethnicities}}"
              }
            },
            "tag_action": {
              "unknown": "{{unknown_tag_action}} is an invalid value, pick from the following acceptable values: {{tag_actions}}"
            },
            "population": {
              "unknown_population": "{{unknown_population}} does not exist, pick from the following acceptable values: {{population_names}}"
            },
            "appointment_location": {
              "unknown_location": "appointment location not found"
            },
            "appointment_date": {
              "invalid_date": "should have format {{date_format}}"
            },
            "dose_type": {
              "invalid_dose": "{{unknown_dose}} is not a valid dose, pick from the following acceptable values: {{acceptable_doses}}",
              "unavailable_dose": "{{dose_type}} is not available within the vaccine_lot, pick from the following available values: {{available_doses}}"
            },
            "vis_version_date": {
              "invalid_date": "should have format {{date_format}}"
            }
          }
        },
        "importers/base/row": {
          "attributes": {
            "appointment_time": {
              "invalid_time": "should have format {{time_format}}"
            }
          }
        }
      }
    }
  },
  "activerecord": {
    "errors": {
      "models": {
        "test_location": {
          "attributes": {
            "metadata": {
              "wrong_json": "is not a valid JSON"
            },
            "name": {
              "taken": "and address are already being used in another existing Location"
            }
          }
        }
      }
    },
    "attributes": {
      "organization_user": {
        "organization_user_leads": {
          "one": "User",
          "other": "Users"
        }
      },
      "appointment_slot_group": {
        "organization_user_leads": {
          "one": "Location",
          "other": "Locations"
        }
      }
    }
  },
  "hispanic_questions": {
    "hispanic": "ヒスパニックまたはラテンアメリカ人・a",
    "not_hispanic": "ヒスパニックまたはラテンアメリカ人ではない・a",
    "hispanic_expanded": {
      "other": "別のヒスパニック、ラテンアメリカ人またはスペイン語の起源",
      "mexican": "メキシコ人、メキシコ系アメリカ人、チカーノ・ a",
      "puerto_rican": "プエルトリコ人",
      "cuban": "キューバ"
    },
    "subtitle": "規制により、以下の情報を収集することが義務付けられています。",
    "help": "民族性とは、言語、文化的慣習、宗教、および人々のグループを区別するために使用されるその他の特性など、一連の一般的な特性を指します。それは人種的アイデンティティとも一致するかもしれないし、一致しないかもしれません。",
    "unknown": "わからない",
    "title": "民族性"
  },
  "sex_at_birth": {
    "question": "性",
    "female": "女性",
    "male": "男性",
    "help": "性別とは、出生証明書に記載されている内容を指します。",
    "non_binary": "ノンバイナリー",
    "subtitle": "規制により、以下のすべての情報を収集することが義務付けられています。",
    "decline": "回答を謝絶する",
    "unknown": "わからない"
  },
  "gender": {
    "help": "性同一性とは、あなたが個人的に識別する方法を指します。それはあなたが出生時に割り当てられた性別と一致するかもしれないし、一致しないかもしれません。",
    "subtitle": "性同一性に関する情報を追加したい場合は、そうしてください。"
  },
  "sexual_orientation": {
    "title": "性的指向",
    "subtitle": "性的指向に関する情報を追加したい場合は、そうしてください。",
    "gay": "ゲイ、レズビアン、または同性愛者",
    "heterosexual": "異性愛者またはストレート",
    "bisexual": "バイセクシャル",
    "questioning": "質問・わからない・わからない",
    "prefer_not_to_disclose": "開示しないことを選択する",
    "unknown": "わからない",
    "orientation_not_listed": "オリエンテーションは記載されていません",
    "not_applicable": "該当なし",
    "pansexual": "パンセクシュアル",
    "queer": "クィア"
  },
  "pronouns": {
    "title": "希望する代名詞",
    "he": "He・him",
    "she": "She・her",
    "they": "They・them",
    "choose_not_to_disclose": "開示しないことを選択する",
    "prefer_to_self_describe": "自己記述を好む"
  },
  "no_insurance_information": {
    "driver_license_or_state_id": "運転免許証または州のID番号",
    "driver_license": "運転免許証",
    "use_ssn_instead": "代わりにSSNを使用する",
    "social_security_number": "社会保障番号",
    "state": "州",
    "upload_front_of_driver_license": "運転免許証または州のID番号の前部をアップロードします",
    "choose_file": "ファイルを選ぶ",
    "no_file_chosen": "ファイルが選択されていません",
    "no_identification": "身分証明書がありません",
    "insurance_card_has_a_back": "私の保険証には裏があります",
    "upload_front_of_insurance_card": "保険証の表紙をアップロードしてください。",
    "front_of_insurance_card_uploaded": "保険証の表面がアップロードされます。",
    "insurance_card": "保険証",
    "insurance_card_back": "保険証バック",
    "back_of_insurance_card_uploaded": "保険証の裏面がアップロードされます。"
  },
  "quidel": {
    "certify_text": "私は、このテストの結果を1回だけログに記録していることを証明します。",
    "entering_results": "結果を入力する",
    "review_instructions_1": "手順を注意深く確認してください",
    "review_instructions_2": "テストに付属の手順を確認したり、ステップバイステップガイドを読んだり、以下の説明ビデオを視聴したりできます。",
    "read_instructions": "指示を読む",
    "watch_video": "ビデオを見る",
    "timer": "チューブ内の綿棒をかき混ぜた後、タイマーを開始します。タイマーは1分後に停止します（チューブから綿棒を取り外すために）。注意：テストの結果を得るには、タイマーを再開して10分を開始する必要があります。",
    "start_timer": "タイマーを開始します（オプション）",
    "restart_timer": "停止して再起動します",
    "resume": "履歴書",
    "one_minute_countdown": "1分が経過しました！チューブから綿棒を取り外し、タイマーを再開します。",
    "take_photo": "テストストリップの写真を撮ります",
    "photo_warning": "続行するには写真の提供が必要です。",
    "enter_results": "テスト結果を入力する",
    "choose_result": "テストストリップを最もよく反映するオプションを選択してください。",
    "positive": "陽性",
    "negative": "陰性",
    "unknown": "わからない",
    "clarify": "結果を明確にする",
    "strip_question": "あなたのテストストリップはどれに最も似ていましたか？",
    "no_lines": "行なし",
    "pink_line": "ピンクのラインのみ",
    "not_sure": "まだわからない"
  },
  "ihealth": {
    "take_a_photo_of_your_test": "結果写真",
    "retake_photo": "写真を撮り直す",
    "capture_photo": "写真を取る",
    "confirm_information_correct": "上記の情報が正しいことを確認します。",
    "submit": "送信",
    "title": "{{name}}の結果を入力します",
    "subtitle": "iHealthの新型コロナウィルスアプリをダウンロードしたり使用したりする必要はありません。",
    "instruction_title": "iHealthの説明を見る",
    "instruction_guide": "指示を読む",
    "video_guide": "ビデオを見る",
    "add_photo": "写真を追加",
    "confirm_result": "結果を確認する",
    "result_warning": "送信するには結果を選択してください",
    "confirm_warning": "提出するために結果を確認する",
    "administered_at_label": "このテストはいつ受けましたか？",
    "instruction_subtitle": "テストに付属の手順を表示するか、ここで手順を確認してください。"
  },
  "public_test_groups": {
    "title": "新型コロナウィルス在宅テストキットのアクティベーション",
    "appointment_recoveries_title": "戻ってきますか？",
    "appointment_recoveries_button_text": "私のリンクを探す",
    "search_test_group_title": "初めてですか？",
    "search_test_group_button_text": "今すぐ登録",
    "title_2": "簡単な登録とレポート",
    "step_one": "アカウントの登録",
    "step_two": "唾液サンプルを収集して返送する",
    "step_three": "オンラインで結果を見る",
    "title_3": "登録してキットをアクティブ化する",
    "population_title": "登録",
    "population_button": "有効にする",
    "faq_subtitle": "もっと詳しく知る",
    "faq_title": "よくある質問",
    "faq_1": "テストキットを返品するにはどうすればよいですか？",
    "faq_1_footer": "唾液直接漏斗の説明を見る",
    "faq_2": "すでに登録していますが、アカウントを忘れてしまいました。",
    "faq_3": "キットをアクティブ化するにはどうすればよいですか？",
    "faq_2_subtitle": "リピーターは、上の'**Find my link**'ボタンを使用してレポートを確認できます。",
    "faq_3_subtitle": "以下のオプションを使用して、Primary.Healthアカウントプロセスを実行してください。",
    "footer_text_1": "この製品はFDAの認可または承認を受けていませんが、EUAの下でFDAによって緊急使用が許可されています。",
    "footer_text_2": "この製品は、SARS-CoV-2からの核酸の検出を支援するために唾液検体の収集と維持のみが許可されており、他のウイルスや病原体については許可されていません。",
    "footer_text_3": "この製品の緊急使用は、21 USCの連邦食品医薬品化粧品法のセクション564（b）（1）に基づく医療機器の緊急使用の許可を正当化する状況が存在するという宣言の期間中のみ許可されます。 §360bbb-3（b）（1）。ただし、宣言が終了するか、承認がより早く取り消される場合を除きます。",
    "description": "便宜上、下のボタンを使用してテストキットをアクティブ化できます。",
    "subtitle": "プライマリーは初めてですか？",
    "subtitle_1": "新型コロナウィルステストキットについて",
    "faq_1_1": "閉じたサンプルチューブを生体試料バッグに入れます",
    "faq_1_2": "生体試料バッグを、入っていたSalivaDirectラベルの付いたボックスに入れます。SalivaDirectラベルの付いたボックスをFedEx UN3373Pakに入れます。",
    "faq_1_3": "FedEx UN 3373 Pakの粘着ライナーを取り外し、しっかりと押し下げてPakをしっかりと密閉します。",
    "faq_1_4": "サンプルを収集したその日、最後の集荷時間の前にサンプルを降ろしてください。週末にサンプルをドロップボックスに届けないでください。",
    "faq_1_5": "FedExのWebサイト **[fedex.com/labreturns](https://www.fedex.com/labreturns)** にアクセスして、ドロップボックスの場所を表示し、スケジュールを確認してください。",
    "demo": {
      "title": "COVID-19 at-home test kit additional information & instructions",
      "online_instructions": "Online instructions",
      "download_instructions": "Download instruction sheet",
      "begin": "Begin",
      "instructions": {
        "welcome_title": "Welcome to the at-home COVID-19 testing portal.",
        "welcome_text_1": "Before you start testing, wash you hands or use hand sanitizer.",
        "welcome_text_2": "Make sure your hands are dry before starting.",
        "continue": "Continue",
        "back": "Back",
        "step_one_title": "Step 1: Check your test kit",
        "step_one_text_1": "Locate the kit components:",
        "step_one_text_2": "It is recommended gloves (not provided) also be used during testing.",
        "step_two_title": "Step 2: Place tube in the tube holder",
        "step_two_text_1": "Remove cap from one **TUBE** and place it in the **TUBE HOLDER.**",
        "step_two_text_2": "**NOTE:** Use of gloves is recommended.",
        "step_five_title": "Step 5: Remove swab from the tube",
        "step_five_text_1": "After **ONE MINUTE**, remove the swab from the **TUBE** by rubbing the swab head against the inside wall of the tube to squeeze out as much liquid as possible.",
        "step_five_text_2": "Dispose of swab in the trash.",
        "step_six_title": "Step 6: Open the test strip",
        "step_six_text_1": "Open the **TEST STRIP** pouch carefully at the slit and hold the **TEST STRIP** as indicated.",
        "step_seven_title": "Step 7: Place test strip in the tube",
        "step_seven_text_1": "Place the **TEST STRIP** into the **TUBE** with the arrows pointing down. Leave the strip in the **TUBE** for a **FULL TEN MINUTES**– do not handle or remove.",
        "step_eight_title": "Step 8: Remove test strip from the tube",
        "step_eight_text_1": "At **TEN MINUTES**, remove the **TEST STRIP** from the **TUBE**. Next, place the **TEST STRIP** on the outline in Steps 10-12. Ensure the **TEST STRIP** is on a flat surface in good lighting.",
        "step_eight_text_2": "**Note:** The test is intended to be read at 10 minutes. If the test is read before this or is read more than 5 minutes after the indicated read time, results may be inaccurate (false negative, false positive, or invalid) and the test should be repeated.",
        "step_nine_title": "Step 9: Check your results",
        "step_nine_text_1": "There are three type of results possible.",
        "step_nine_text_2": "Check for a Positive Result",
        "step_nine_text_3": "Check for a Negative Result",
        "step_nine_text_4": "Check for an Invalid Result",
        "step_ten_title": "Step 10: Remove test strip from the tube",
        "step_ten_text_1": "Place the **TEST STRIP** on the test strip outline below and compare with test result examples shown.",
        "step_three_title": "Step 3: Swab the nostrils",
        "step_three_text_1": "Remove the **SWAB** from its wrapper, being careful not to touch the **SWAB** head.",
        "step_three_text_2": "Gently insert the **SWAB** ½ to ¾ of an inch into the nostril, depending on the size of the person’s nose. Firmly rub the **SWAB** in a circular motion around the inside wall of EACH NOSTRIL at least 4 times.",
        "step_three_text_3": "Be sure to rub BOTH nostrils with the SAME SWAB.",
        "step_three_text_5": "**NOTE:** Failure to swab properly may cause false negative results.",
        "step_four_title": "Step 4: Place swab in the tube",
        "step_four_text_1": "Immediately place the **SWAB** into the liquid inside the **TUBE**, and ensure it is touching the bottom. Stir 3-4 times.",
        "step_four_text_2": "Leave the swab in the solution for **ONE MINUTE**.",
        "step_four_text_3": "**NOTE**: If the swab is in the solution for more than 10-minutes it should not be used.",
        "step_three_text_4": "**NOTE:** If you are swabbing others, please wear a face mask. With children, you may not need to insert the swab as far into the nostril. For very young children, you may need another person to steady the child’s head while swabbing."
      },
      "choose_language": "Choose your language"
    }
  },
  "self_resulting": {
    "report_result": "結果を報告する",
    "report_result_subtitle": "組織がセルフテストを提供していて、結果を報告する準備ができている場合は、下のボタンをクリックしてください。",
    "need_help": "ヘルプが必要ですか？",
    "assistance": "サポートが必要な場合、または連絡先情報を変更する場合は、サポートセンターにお問い合わせください。",
    "contact_support": "サポート問い合わせ先",
    "save_link": "このリンクを保存して、後で結果を報告します。",
    "send_via_email": "メールでリンクを送信",
    "send_via_text": "SMSテキストでリンクを送信する",
    "copy_link": "リンクをコピーする",
    "resend_email": "メール確認を再送信する",
    "phone_verified": "電話を確認しました",
    "add_vaccination_status": "新型コロナウィルスワクチン接種ステータスの追加・更新",
    "terms_of_service": "利用規約",
    "verify_contact_information": "結果と連絡を受け取ることができるように、連絡先情報を確認してください。連絡先情報を変更する必要がある場合は、サポートセンターにお問い合わせください。",
    "resend_text": "SMSテキスト検証を再送する",
    "loading": "読み込み中",
    "add_to_account": "アカウントに追加",
    "hi": "やあ",
    "email_verified": "メール確認済み",
    "go_to_my_account": "マイアカウントへ移動します",
    "activate_kit": "テストキットをアクティブ化する",
    "report_custom_result": "結果を報告する：{{test}}",
    "activate_custom_test": "テストをアクティブ化します：{{test}}",
    "update_vaccination_status": "予防接種状況を更新する",
    "order_a_test_kit": "検査キットを注文する"
  },
  "enter_information": {
    "contact_info": "連絡情報を入力してください",
    "label": "電話かメール",
    "invalid": "これは有効なメールアドレスまたは電話番号ではありません。",
    "send": "お使いの携帯電話またはメールにコードを送信します",
    "legal": "電話番号または電子メールを入力することにより、PrimaryHealthの**[利用規約](https://primary.health/terms-of-service/)**および** [プライバシーポリシー](https://primary.health/privacy-policy/)**。",
    "subtitle": "記録の検索やテスト結果を報告するためのリンクに役立つテキストまたはメールをお送りします。",
    "find_link": "登録済みでしょうか？リンクを見つかる",
    "not_registered": "未登録？サイトを検索する",
    "search_by_keyword": "名前、キーワード、都市、郵便番号、または組織コードで検索してください！",
    "search": "検索する",
    "register_here": "こちらで登録する"
  },
  "contact_support": {
    "invalid": "無効な情報",
    "invalid_explanation": "最近、Primary.Healthへのサインオンリンクをリクエストしました。でも、",
    "never_registered": "登録したことはありませんか？検査プロバイダーに連絡し、登録リンクを取得するか、または以下のサイトを見つけてください。",
    "get_help": "あなたはあなたの記録をあなたの連絡先情報と一致させる助けを得ることができます",
    "get_help_calling": "または電話で",
    "invalid_explanation_bold": "あなたが提供した情報は無効でした。",
    "get_help_label": "ヘルプを貰う",
    "mobile": {
      "invalid_explanation": "アカウントに追加できるユーザーは見つかりませんでした。",
      "get_help": "ご不明な点がございましたら、お問い合わせください。",
      "reason": "すべてのPrimary.Healthユーザーが新しいアカウント機能の対象となるわけではありません。資格を得ると、Primary.Healthまたはグループ管理者から招待状が届きます。"
    },
    "zen_name": "連絡先名",
    "ticket_type": "何かに関すること",
    "appointment_access_code": "確認コード（分かる場合）",
    "zen_desc": "メッセージ",
    "contact_us": "問い合わせ",
    "need_assistance": "ヘルプが必要な場合、または連絡先情報を変更される場合には、サポートまでご連絡ください。"
  },
  "show_appointments": {
    "welcome": "プロフィールを見つかる",
    "welcome_subtitle_1": "以下に結果を表示しています",
    "added_to_account": "アカウントに追加",
    "error": "この参加者はすでにアカウントを持っている可能性があります",
    "welcome_subtitle_2": "下記の氏名は見つからない場合",
    "welcome_not_logged_in": "サイトリンクの検索",
    "welcome_logged_in": "グループを選択",
    "welcome_subtitle_1_logged_in": "メンバーの追加を完了するには、ユーザーとグループを選択します。メンバーは 1 つのアカウントに割り当てられます。",
    "welcome_subtitle_2_logged_in": "上記にあなたのメンバーが表示されていない場合は、",
    "welcome_subtitle_1_not_logged_in": "サイトを見つける必要があるユーザーを選択するか、新しいメンバーを追加します。メンバーが見つかりませんか?",
    "welcome_subtitle_3_logged_in": "別の連絡方法を決定するのが可能",
    "welcome_subtitle_4_logged_in": "か、下記の連絡先にお問い合わせする",
    "welcome_subtitle_2_not_logged_in": "検索を更新する。",
    "welcome_subtitle_3": "検索結果を更新する"
  },
  "enter_code": {
    "code": "コードは何ですか？",
    "enter_code": "に送信されたコードを入力してください",
    "incorrect_code": "間違ったコードを入力しました。",
    "verify": "アカウントを確認します",
    "verified": "確認済み",
    "verify_account_information": "アカウントを確認する"
  },
  "general_self_checkout": {
    "choose_test": "どのようなテストを受けましたか？",
    "photo_required": "結果の写真が必要です",
    "find_your_test": "テストを探す"
  },
  "login": {
    "create_account": "アカウントを作成する",
    "create_account_help": "アカウントの作成は、検査や予防接種への登録とは異なります。登録したら",
    "create_account_help_schedule": "予定をスケジュールする",
    "create_account_help_record": "自宅でのテストを記録する",
    "create_account_help_match": "予防接種と検査の記録を管理します。",
    "continue": "ゲストとして続行",
    "already": "すでにアカウントをお持ちですか？クリック",
    "login": "ログインします。",
    "or_sign_in": "または、次のコマンドでサインインします。",
    "no_account": "アカウントをお持ちではありませんか？クリック",
    "signup": "サインアップします。",
    "here": "こちら",
    "email_address": "電子メールアドレス：",
    "password": "パスワード：",
    "complete_registration": "登録を完成する",
    "last_covid_test": "最後の新型コロナウィルステスト結果：",
    "no_record": "記録なし",
    "viewing_information": "{{first_name}}の情報を表示する",
    "download": "ダウンロード",
    "not_registered": "どのグループにも登録されていないようです",
    "please_follow_link": "Primary.Healthでスケジュールを設定し、情報にアクセスするには、電子メールの招待リンクをたどってください。",
    "log_results": "テスト結果をログに記録する",
    "book_appointment": "予約する",
    "no_new_appointments": "現在、新しい予定は受け付けていません",
    "upload_vaccinations": "予防接種をアップロードする",
    "vaccination": "ワクチン",
    "new_appointment": "新しい予定",
    "log_otc_results": "OTCテストの結果をログに記録する",
    "no_household_members": "まだ世帯員がいません。",
    "no_upcoming": "今後の予定はありません",
    "update_vaccination": "予防接種を更新する",
    "upload_vaccination": "予防接種証明書をアップロードする",
    "title": "Primary.Healthへようこそ",
    "sub_title": "登録する前に、アカウントを作成してください。",
    "p1": "アカウントを作成すると、次のことが可能になります。",
    "p2": "将来の予定をスケジュールする",
    "p3": "新型コロナウィルス在宅検査を記録する",
    "p4": "扶養家族の予防接種と検査記録を管理する",
    "p5": "my.primary.healthからの簡単なログインプロセス",
    "no_vaccination_record": "まだワクチン接種のデータが追加されていません",
    "vaccination_title_card": "新型コロナウィルス接種ステータス",
    "account_and_settings": "アカウント・設定",
    "delete_account": "アカウントを削除する",
    "language": "言語",
    "language_current": "現在の言語：{{language}}",
    "log_out": "ログアウト",
    "delete_account_title": "アカウントを削除するには宜しいでしょうか。",
    "new_encounter": "{{first_name}}の最初の遭遇",
    "new_encounter_subtitle": "この新しい遭遇はどのグループのためのものですか?",
    "no_programs": "どちらのプログラムにも参加されていないみたいです。",
    "edit_members": "メンバーを編集する",
    "edit_members_subtitle": "移動するダッシュボードを選択するか、別のメンバーをアカウントに追加してください。",
    "add_members": "メンバーを追加する",
    "delete_member": "{{full_name}}のユーザーアカウントを削除しますか？",
    "delete_member_subtitle": "ユーザーを削除すると、アカウントに関連の情報が完全に削除されます。",
    "select_member": "メンバー選択",
    "edit_appointment": "予約を編集",
    "route": {
      "me": "ダッシュボード",
      "history": "履歴",
      "programs": "検査を追加する",
      "contact_us": "ヘルプ",
      "choose_member": "メンバー",
      "settings": "設定",
      "help": "ヘルプ"
    },
    "user_dashboard": "{{first_name}}のダッシュボード",
    "view_full_results": "結果ポータルに表示",
    "required": "プライマリ・アプリを使用している場合は必須です。",
    "no_email": "メールアドレスを持っていません"
  },
  "vaccination_status": {
    "not_vaccinated": "ワクチン接種されていない",
    "fully_vaccinated": "完全ワクチン接種",
    "partially_vaccinated": "部分的にワクチン接種",
    "vaccination_records": "予防接種記録",
    "title": "あなたの 新型コロナウィルスワクチン接種状況は何でしょうか？",
    "definition_title": "予防接種状況の定義",
    "definition_cdc": "CDC ワクチン接種状況の定義",
    "definition_osha": "OSHAのワクチン接種状況の定義",
    "definition": {
      "fully_vaccinated": "Pfizer-BioNTech または Moderna の 2 回連続接種の最終接種、または Johnson & Johnson の Janssen ワクチンの 1 回接種の 2 週間後。",
      "partially_vaccinated": "初回シリーズの 2 回投与のうち 1 回のみを受けたか、2 回連続投与の最終投与後かまたは Johnson & Johnson の Janssen の 1 回投与後 2 週間以内。",
      "not_vaccinated": "新型コロナウィルスのワクチンは投与されていません。",
      "fully_vaccinated_with_one_booster": "Johnson & Johnson の Janssen ワクチンを全シリーズを受けたか、または 1 回接種した後、Pfizer-BioNTech または Moderna ワクチンの追加接種を受けた。",
      "fully_vaccinated_with_two_boosters": "Johnson & Johnson の Janssen ワクチンとブースター投与の全シリーズまたは投与後に、ファイザー-BioNTech またはモデルナ ワクチンの 2 回目の追加投与を受けた。"
    },
    "label": {
      "fully_vaccinated": "ワクチン接種済み",
      "partially_vaccinated": "一部予防接種済み",
      "not_vaccinated": "予防接種を受けていない",
      "fully_vaccinated_with_one_booster": "ワクチン接種済み + ブースター",
      "fully_vaccinated_with_two_boosters": "ワクチン接種済み + ブースター 2 回",
      "prefer_not_to_answer": "答えたくありません"
    },
    "progress_bar_title": "新型コロナウィルスの予防接種履歴",
    "upload_record_card": "新型コロナウィルスの予防接種記録カードをアップロードする",
    "show_example": "例を表示",
    "hide_example": "例を非表示",
    "take_photo": "クリックして写真を撮る。",
    "or": "また",
    "upload_image_or_pdf_instead": "代わりに画像・PDFファイルをアップロード",
    "select_doses_received": "これまでに受けた COVID-19 ワクチンの接種回数をすべて選択してください。",
    "first_dose": "初回投与",
    "second_dose": "2 回目の投与",
    "first_booster_dose": "最初のブースター投与",
    "second_booster_dose": "2 回目のブースター投与",
    "additional_dose": "追加投与",
    "first_dose_manufacturer": "新型コロナウィルスの初回投与のメーカーは誰ですか?",
    "first_dose_date": "最初の服用日はいつでしたか？",
    "second_dose_manufacturer": "2回目の新型コロナウィルスの投与の製造業者は何ですか?",
    "second_dose_date": "2回目の服用日はいつでしたか？",
    "first_booster_dose_manufacturer": "最初の新型コロナウィルスのブースター投与のメーカーは誰ですか?",
    "first_booster_dose_date": "最初のブースター投与の日付はいつでしたか?",
    "second_booster_dose_manufacturer": "2 回目の新型コロナウィルスブースター投与のメーカーは誰ですか?",
    "second_booster_dose_date": "2 回目のブースター投与の日付はいつでしたか?",
    "additional_dose_manufacturer": "新型コロナウィルスの追加投与の製造業者は誰ですか?",
    "additional_dose_date": "追加投与の日付はいつでしたか?",
    "completed_one": "完了しました",
    "completed_two": "予防接種の状況を更新していただきありがとうございます。",
    "progress_bar_complete": "完了",
    "upload_image": "画像をアップロード",
    "retake_photo": "写真を撮り直す",
    "other": "その他",
    "remove_first_dose": "初回投与履歴を削除",
    "remove_second_dose": "2 回目の投与履歴を削除する",
    "remove_first_booster_dose": "最初のブースター投与履歴を削除する",
    "remove_second_booster_dose": "2 回目のブースター投与履歴を削除する",
    "remove_additional_dose": "追加の被曝履歴を削除",
    "exemption": "控除はありますか？",
    "exempt": "私は免除されています",
    "not_exempt": "私は免除されていません",
    "enter_exemption": "免除を入力してください",
    "upload_exemption_documentation": "免除の書類をアップロードする",
    "remove_dose": "線量を削除",
    "continue": "継続する",
    "enter_credentials": "資格情報を入力してください",
    "incorrect_credentials": "認証情報が正しくありません。もう一度お試しください。",
    "add_photo": "写真を追加"
  },
  "mailers": {
    "appointment_mailer": {
      "payment_pending": "こんにちは{{name}}、{{org_name}}での{{group_name}}の予定のスケジュールが完了していないことに気付きました。予約を維持するには、支払い手順を完了する必要があります。このリンクをクリックして、プロセス{{complete_url}}を完了してください。予約をキャンセルしたい場合は、このリンク{{cancel_url}}をクリックしてください。ありがとうございました"
    }
  },
  "otc": {
    "record_results_for_abbott": "BinaxNOWテストの結果をテストして記録する",
    "record_results": "結果をテストして記録する",
    "activate_a_kit": "キットをアクティブ化する",
    "activate_a_kit_for_test": "{{test}}のキットをアクティブ化します",
    "todays_test_process": "今日のテストプロセス：",
    "date_of_birth_question": "{{first_name}}の生年月日は何ですか？",
    "confirm_dob_to_continue": "続行するには、参加者の生年月日を確認してください。",
    "incorrect_dob": "生年月日が正しくない",
    "file_too_large": "アップロードされたファイルのサイズが{{size}}よりも小さいことを確認してください",
    "enter_valid_date_in_last_x_days": "過去{{n}}日間の有効な日付を入力してください",
    "barcode_format_not_valid": "バーコード形式が無効です",
    "complete_button": "完了",
    "next_button": "次",
    "back_button": "戻る",
    "report_a_test": "テストを報告する：",
    "x_%_completed": "{{n}}％完了",
    "answer_survey": "調査の質問に答える",
    "steps": {
      "answer_survey": "調査回答する",
      "review_instructions": "手順を確認する",
      "scan_code": "スキャンコード",
      "enter_time": "時間を入力してください",
      "enter_result": "結果を入力",
      "take_photo": "写真を撮る",
      "confirm_result": "結果を確認する",
      "selest_test_kit": "テストタイプを選択してください",
      "enter_date": "日付を入力してください",
      "enter_date_and_time": "日付と時刻を入力してください"
    },
    "review_instructions": "テスト手順を確認する",
    "follow_instructions_or_below": "テストに付属の手順に従うか、以下の手順を確認してください。",
    "watch_video": "ビデオを見る",
    "view_instructions": "手順を表示する",
    "follow_instructions": "テストに付属の説明書に従ってください。",
    "scan_code": "スキャンテストQRコード",
    "qr_code_description": "QRコードはテストに固有のものであり、テストの右上隅にあります。",
    "show_example": "例を表示",
    "hide_example": "例を隠す",
    "barcode_scanned": "バーコードが正常にスキャンされました",
    "enter_barcode": "バーコードを入力してください",
    "scan_qr_code": "QRコードをスキャンする",
    "which_test_did_you_take": "どのテストを受けましたか？",
    "when_did_you_take_test": "このテストはいつ受けましたか？",
    "what_day_did_you_take_test": "このテストは何日に受けましたか？",
    "what_time_did_you_take_test": "このテストは何時に受けましたか？",
    "time": {
      "today": "今日",
      "yesterday": "昨日",
      "two_days_ago": "2日前"
    },
    "enter_current_time": "現在の時刻を入力してください",
    "enter_result": "テスト結果を入力してください",
    "choose_result_option": "テストカードの結果を説明するオプションを選択します。",
    "clarify_results": "結果を明確にする",
    "take_result_photo": "結果の写真を撮る",
    "take_result_photo_description": "完成した結果カードの写真を撮り、結果を記録します。",
    "my_result": "私の結果",
    "switch_camera": "カメラを切り替える",
    "confirm_result": "結果を確認する",
    "name": "名前：",
    "date": "日付：",
    "result": "結果：",
    "results": "結果",
    "test_submitted": "ありがとう、{{first_name}}！ {{test}}が正常に送信されました。",
    "dob": "生年月日：",
    "administered": "管理：",
    "or": "または",
    "upload": "アップロード",
    "change_uploaded_file": "アップロードしたファイルを変更する",
    "take_photo": "写真を撮る",
    "confirm_information": "情報を確認する",
    "barcode": "バーコード：",
    "scan_test_barcode": "テストバーコードをスキャンする",
    "barcode_description": "バーコードはテストに固有のものであり、テストで見つけることができます",
    "enter_date_in_last_30_days": "過去30日間の日付と、テストが行われたおおよその時間を入力します",
    "add_image": "画像を追加する",
    "change_image": "画像を変更する",
    "skip_barcode_reader": "コードをスキャンできませんか？とにかくテスト結果を報告するには、[次へ]をクリックします",
    "enter_date_and_time": "テストの日時を入力してください",
    "enter_date": "テストの日付を入力してください",
    "did_you_take_test_today": "今日は受験しましたか？",
    "record_results_for_generic": "{{name}}検査を受けて、結果を記録する",
    "choose_result_option_custom": "{{test_name}} の結果を説明するオプションを選択してください:"
  },
  "yes": "はい",
  "no": "いいえ",
  "event_token": {
    "title": "テストキットをディスペンスするには、以下のコードを自動販売機に入力してください",
    "loading_text": "読み込み中です。しばらくしてからもう一度確認してください"
  },
  "appointment_recovery": {
    "complete_title": "準備が出来ました！",
    "subtitle_1": "ダッシュボードに戻るために、iPhoneを使っている場合は、この画面の左上隅にある **完了** をタップしてください。",
    "subtitle_2": "アンドロイドを使っている場合は、この画面の左上隅にある **X** をタップしてください。",
    "subtitle_0": "続行するには、ブラウザーを終了してください。"
  },
  "primary": {
    "support_email": "support@primary.health"
  },
  "consented_relationship": "同意関係",
  "finish_registration": {
    "title": "登録を完了する",
    "subtitle": "予約の前に、次の情報を入力してください。",
    "button": "登録を完了する"
  },
  "arab_ethnicity": {
    "arab": "アラブ",
    "non_arab": "非アラブ人",
    "prefer_not_to_answer": "答えたくありません",
    "question": "アラブ人",
    "subtitle": "規制により、次の情報を収集する必要があります。",
    "help": "民族性とは、言語、文化的慣習、宗教、および人々のグループを区別するために使用されるその他の特徴など、一連の共通の特徴を指します。それは人種的のアイデンティティとも一致する場合があり、一致しない場合もあります。"
  },
  "custom_comms_quick_add": {
    "mobile_app_links": {
      "email_text": "ご利用のデバイスに応じて以下のリンクをクリックし、Primary Health Passをダウンロードしてください。"
    }
  },
  "stepper": {
    "next": "次",
    "back": "戻る",
    "complete": "完了"
  },
  "registration_direcory": {
    "keyword_search_label": "キーワード、都市、郵便番号、組織名",
    "keyword_search_label_mobile": "市区町村、郵便番号、組織名",
    "zero_results": {
      "title": "その検索用語に該当するプロバイダーは見つかりませんでした。",
      "sub_title": "次の方法でプロバイダーを検索してみてください：",
      "p1": "郵便番号",
      "p2": "市町村",
      "p3": "郡",
      "p4": "プロバイダーまたは組織名"
    }
  },
  "registration_flyer": {
    "open_camera": "端末のカメラを開いてください。",
    "point_to_qr_code": "QRコードを映して、リンクをタップしてください。",
    "complete_registration": "登録を完了する",
    "need_help": "ヘルプが必要ですか？こちらにお問い合わせください。"
  },
  "vaccine_info_sheets": {
    "vaccine_info_attestation": "ワクチン情報に関する宣誓書",
    "select_checkbox": "接種を行う前に以下の内容を読んで、適切なチェックボックスを選んでください。",
    "opportunity_to_read": "私は、接種しようとしているワクチンに関して提供されたワクチン情報書（VIS）、あるいは緊急利用承認（EUA）のファクトシートを読みました、または読んでもらいました：",
    "vaccine_info_sheet": "ワクチン情報シート",
    "vaccine_fact_sheet": "ワクチンのファクトシート",
    "vaccine_info_statement": "ワクチン情報声明"
  },
  "exemption": {
    "dtap_tdap": "ジフテリア、破傷風、百日咳ワクチン",
    "mmr": "麻疹、おたふく風邪、風疹ワクチン",
    "ipv_opv": "ポリオワクチン",
    "hib": "Hibワクチン",
    "hep_b": "B型肝炎ワクチン",
    "var": "みずぼうそうワクチン",
    "hep_a": "A型肝炎ワクチン",
    "pcv": "肺炎球菌ワクチン",
    "mcv": "髄膜炎菌ワクチン"
  },
  "test_strip": {
    "A": "このキットは、クラミジア、淋病の検査用です。",
    "B": "このキットは、クラミジア、淋病の検査用です。",
    "C": "このキットは、梅毒の検査用です。",
    "D": "this kit includes tests for HIV, syphilis, chlamydia and gonorrhea.",
    "E": "このキットは、HIVと梅毒の検査用です。",
    "box_type": "{{type}}ボックス",
    "administered_text": "あなたのキットが検査機関に届き、結果が出次第ご連絡します。",
    "activate_kit": "自分の検査キットを有効化する",
    "register_another_test": "別の検査を登録する",
    "credentials": {
      "title": "さあ始めましょう",
      "label": "連絡先",
      "p1": "まず、あなたについてお知らせください。",
      "location_step": {
        "p1": "検査キットはどの医療機関から受け取りましたか？",
        "load": "さらに読み込む"
      }
    },
    "checklist": {
      "not_urinated": "(あなたは）これまで1時間ほど尿を出していません。",
      "bathroom": "(あなたは）採尿ができるトイレに行くことが可能です。",
      "anal_swab": "(あなたは）肛門スワブ検査ができる個室空間があります。",
      "blood_extraction": "(あなたは）採血ができる清潔な環境が整っています。",
      "washed_hands": "(あなたは）ぬるま湯と石けんで30秒間手を洗いました。",
      "title": "検体採取を始める準備は出来ていますか？",
      "no_alcohol": "採取の日は、飲酒しないでください。",
      "menstruation": "生理中、またはセックスをしてから24時間以内は、スワブを使った膣内検体採取はしないでください。"
    },
    "confirm": {
      "title": "Now, begin collecting your sample",
      "p1": "Is this kit information correct?"
    },
    "display_name": {
      "A": "クラミジアと淋病（1箇所）",
      "B": "クラミジアと淋病（3箇所）",
      "C": "梅毒",
      "shortened": {
        "A": "クラミジアと淋病",
        "B": "クラミジアと淋病",
        "C": "梅毒"
      },
      "CC": "COVID-19",
      "CE": "Celiac Disease Screening",
      "CR": "Creatinine Test",
      "D": "4 Panel Test - HIV, Syphilis, Chlamydia & Gonorrhea (Single Site)",
      "DD": "Diabetes Screening (HbA1c)",
      "E": "HIV & Syphilis",
      "H": "HPV",
      "P": "Hepatitis C",
      "HS": "Herpes Simplex",
      "I": "HIV",
      "TT": "Testosterone Level",
      "VD": "Vitamin D",
      "VS": "Viral Surveillance",
      "AP": "Chlamydia & Gonorrhea",
      "NN": "COVID-19",
      "HH": "COVID-19, Flu A/B, & RSV",
      "FFFWW": "COVID-19 & Flu A/B",
      "YYYWW": "COVID-19, Flu A/B & RSV",
      "HA": "COVID-19, Flu A/B, & RSV"
    },
    "rescan": "再スキャン",
    "scanned": "{{type}}ボックスをスキャン済みです",
    "not_yet": "まだ終わっていません",
    "success_registration": "登録が完了しました！",
    "no_box": "ボックスがない場合は？",
    "faq_link": "FAQをご覧ください。",
    "welcome": "ようこそ！",
    "scan": {
      "title": "You're one step closer to a healthier you. Scan or enter the **kit bar code** on the side of your box.",
      "p1": "Please begin when you are ready to begin sample collection. Otherwise, return to this page when you are ready.",
      "unknown_barcode": "You’ve entered an unknown barcode. Please contact support for assistance."
    },
    "appointments": {
      "title": "患者＆医療機関を選んでください。",
      "p1": "あなたの連絡先情報に2つ以上の関連プロファイルが見つかりました。正しいプロファイルを選択してください。",
      "new": "新規の患者または医療提供者"
    },
    "verify": {
      "title": "Confirm your identity",
      "p1": "あなたの患者記録が見つかりました。"
    },
    "section_1": "キットの中身を確認する",
    "section_2": "検査用の採取",
    "section_3": "梱包",
    "section_4": "Return kit",
    "box_a": "ボックスA",
    "box_b": "ボックスB",
    "box_c": "ボックスC",
    "kit_flow": {
      "button_1": "梱包に進む",
      "button_2": "準備ができました",
      "button_3": "キットは完成して、梱包も終わりました",
      "button_4": "完了しました",
      "button_5": "次の検査",
      "button_6": "指示説明を省略する"
    },
    "contents": {
      "header": "次のステップです。キットの中身が全て揃っているかを確認しましょう。",
      "text_1": "Does your test kit box contain the following?",
      "blood_collection_card": "採血カード",
      "blood_collection_card_subtitle": "(検査キットによって1枚か2枚)",
      "alchohol_pads": "アルコール消毒パッド　2枚",
      "lancets": "使い捨てのランセット",
      "up_to_other": "(検査キットによって最大4個)",
      "adhesive_bandages": "絆創膏",
      "pipette": "ピペット",
      "urine_tube": "尿検体の容器",
      "urine_cup": "採尿用カップ",
      "oral_swab": "口腔用スワブ",
      "oral_tube": "口腔検体保存チューブ",
      "anal_swab": "肛門用スワブ",
      "anal_tube": "肛門検体保存チューブ",
      "vaginal_tube": "膣内検体保存チューブ1本",
      "vaginal_swab": "膣内用スワブ1本",
      "biodegradable_paper": "生物分解性採取用ペーパー",
      "bottle_and_probe": "検体用容器とスティック",
      "prepaid_envelope": "料金前払いの返送用封筒",
      "biohazard_subtitle": "(衝撃吸収パッドつき)",
      "biohazard_bag": "検体袋1枚",
      "biohazard_bag_plural": "医療専用袋({{count}})枚",
      "alcohol_pads_plural": "アルコール消毒パッド{{count}} 枚",
      "sterile_gauze_pad": "消毒ガーゼパッド1枚",
      "collection_tube": "1 collection tube",
      "collection_swabs": "2 collection swabs"
    },
    "packaging": {
      "title": "梱包チェックリスト",
      "subtitle": "Before you ship or drop your kit at a kiosk, make sure that:"
    },
    "packaging_box_a": {
      "step_1": "You have packed all test kit contents according to the instructions",
      "step_2": "You have used all collection materials contained in the kit to collect the required specimen",
      "step_3": "Your **date-of-birth** is written on the specimen collection tube using MM/DD/YYYY format",
      "step_4": "The **collection date** is written on the specimen collection tube using MM/DD/YYYY format",
      "step_5": "医療専用袋に検体保存チューブを入れて封をしています",
      "step_6": "検体は全てボックスの中に入っています",
      "step_7": "ボックスは料金前払いの返送用封筒に入っています",
      "step_8": "返送用封筒はしっかりと封がされています"
    },
    "packaging_box_b": {
      "step_1": "検査をしなかったものも含め、全ての種類の検査キットを梱包しています",
      "step_2": "必要な検体を採取する目的で、キットに入っていた採取用品を全て使いました",
      "step_3": "**生年月日**はMM/DD/YYYYの順で、全ての検体保存チューブに記入されています",
      "step_4": "**採取日**はMM/DD/YYYYの順で全ての検体保存チューブに記入されています",
      "step_5": "検体保存チューブは医療専用袋に個別に入れ、それぞれ封をしています（1枚の袋に入れる検体は **1つ**だけです）",
      "step_6": "検体は全てボックスの中に入っています",
      "step_7": "ボックスは料金前払いの返送用封筒に入っています",
      "step_8": "返送用封筒はしっかりと封がされています"
    },
    "packaging_box_c": {
      "step_1": "使用済みのランセットを含め、検査キットの内容物は全て梱包されています",
      "step_2": "**生年月日**はMM/DD/YYYYの順で採血カードに記入されています",
      "step_3": "**採取日**はMM/DD/YYYYの順で採血カードに記入されています",
      "step_4": "採血カードと使用済みランセットは全て医療専用袋の中に入っています",
      "step_5": "医療専用袋はボックスの中に入っています",
      "step_6": "ボックスは料金前払いの返送用封筒に入っています",
      "step_7": "返送用封筒はしっかりと封がされています"
    },
    "success": {
      "header_1": "お疲れ様でした。",
      "text_1": "最初の検体を採取する準備ができました。",
      "header_2": "こちらで完了です。",
      "text_2": "**次は：** キットを梱包して返送しましょう。",
      "header_3": "お疲れ様でした。",
      "text_3": "You’re one step closer to better health.",
      "header_4": "お疲れ様でした！",
      "text_4": "あと1つで完了です！",
      "text_5": "You're ready to collect your sample."
    },
    "instructions": {
      "title": "検査を選んで説明書を読んでください。",
      "subtitle": "検査",
      "button": "説明書",
      "pdf": "PDFの説明書"
    },
    "instructions_box_a": {
      "title": "採尿",
      "subtitle": "この検査には、以下の用品が必要です。",
      "step_1": "採取の1時間前は尿を出さないでください。朝起きてすぐの尿が一番望ましいです。",
      "step_2": "PeeCanterまたは採尿用カップの1/3～1/2くらいまで、コップの中に尿を入れてください。",
      "step_3": "PeeCanterから注ぐかピペットを使って、オレンジ色 <span style=\"color: #FF5000\">**\"URINE\"**</span>のラベルがついた尿検体の容器に尿を入れてください。",
      "step_4": "尿検体の容器内の液体が指定された容量になるまで尿を入れてください。",
      "step_4_sublabel": "尿は最大量の線を超えて容器に入れないでください、超えると検体の受け付けができません。",
      "step_5": "採尿容器に蓋をして、しっかりと平らに蓋がされているかを確認してください。",
      "step_6": "生年月日と検体採取日（本日の日付）をMM/DD/YYYYの順で容器の指定部分に記入してください。",
      "step_7": "空の医療専用袋の中に衝撃吸収パッドと検体を入れて、きっちりと封をしてください。"
    },
    "instructions_box_b": {
      "test_1": {
        "title": "採尿",
        "subtitle": "この検査では以下の用品が必要です。",
        "step_1": "採取の1時間前は尿を出さないでください。朝起きてすぐの尿が一番望ましいです。",
        "step_2": "PeeCanterまたは採尿用カップの1/3～1/2くらいまで、コップの中に尿を入れてください。",
        "step_3": "PeeCanterから注ぐかピペットを使って、オレンジ色 <span style=\"color: #FF5000\">**\"URINE\"**</span>のラベルがついた尿検体の容器に尿を入れてください。",
        "step_4": "尿検体の容器内の液体が指定された容量になるまで尿を入れてください。",
        "step_4_subtitle": "尿は最大量の線を超えて容器に入れないでください、超えると検体の受け付けができません。",
        "step_5": "採尿容器に蓋をして、しっかりと平らに蓋がされているかを確認してください。",
        "step_6": "生年月日と検体採取日（本日の日付）をMM/DD/YYYYの順で容器の指定部分に記入してください。",
        "step_7": "空の医療専用袋の中に衝撃吸収パッドと検体を入れて、きっちりと封をしてください。"
      },
      "test_2": {
        "title": "口腔用スワブ",
        "subtitle": "この検査では以下の用品が必要です。",
        "step_1": "スワブの薄い表示線（ある場合）と、綿製の頭部の間を指で持ちます。",
        "step_2": "口の中にスワブを入れて、10秒間ほどスワブ先端で喉の奥をこすってください。",
        "step_3": "慎重にスワブを引き出し、青色<span style=\"color: #001F70\">**\"ORAL\"**</span>のラベルがついた口腔検体保存チューブにスワブを入れてください。保存チューブの端を使って表示線の所でスワブを折ってください。",
        "step_4": "保存チューブの蓋をして、しっかりと平らに蓋がされているかを確認してください。",
        "step_5": "生年月日と検体採取日（本日の日付）をMM/DD/YYYYの順で容器の指定部分に記入してください。",
        "step_6": "空の医療専用袋の中に衝撃吸収パッドと検体を入れて、きっちりと封をしてください。"
      },
      "test_3": {
        "title": "肛門用スワブ",
        "subtitle": "この検査では以下の用品が必要です。",
        "step_1": "スワブの薄い表示線（ある場合）と、綿製の頭部の間を指で持ちます。",
        "step_2": "肛門の中にスワブを1～2インチ（約3～5センチ）ほど挿入してください。そのまま5～10秒間ほどぐるぐる中で回して、潜在的な有機体を採取してください。",
        "step_3": "慎重にスワブを引き出し、緑色の<span style=\"color: #00C08C\">**“ANAL”**</span> のラベルがついた検体保存チューブの中にスワブを入れてください。保存チューブの端を使って表示線の所でスワブを折ってください。",
        "step_4": "保存チューブの蓋をして、しっかりと平らに蓋がされているかを確認してください。",
        "step_5": "生年月日と検体採取日（本日の日付）をMM/DD/YYYYの順で容器の指定部分に記入してください。",
        "step_6": "空の医療専用袋の中に衝撃吸収パッドと検体を入れて、きっちりと封をしてください。"
      }
    },
    "instructions_box_c": {
      "title": "採血",
      "subtitle": "この検査では以下の用品が必要です。",
      "step_1": "氏名、生年月日、採取日を指定箇所に記入してください。MM/DD/YYYYの順序で書いてください。",
      "step_2": "血液カードをめくって、採血ろ紙に記された丸印が見えるようにしてください。採血ろ紙には触れないでください。",
      "step_3": "少なくとも30秒間、ぬるま湯で手を洗ってください、そして指に血が集まるよう、15秒ほど両手をしっかりと握り合わせてください。",
      "step_4": "指先をアルコールパッドで消毒します。利き手ではない方の手の中指か薬指を使うのが一番良いでしょう。",
      "step_5": "ランセットを手に持ち、蓋をねじって開けます。カチッという音がして針が出てくるまで、小さくとがった先端部分を指先にしっかりと押しつけます。採血中は、指は心臓より下の高さに置いておき、指の付け根から先端にかけてマッサージして、血流を促します。",
      "step_5_subtitle": "ランセットは使い捨てです。ランセットは全て血液サンプルと一緒に検査機関に返送してください。検査機関で破棄をします。",
      "step_6": "円の中央部分に血液を3～6滴垂らし、採血ろ紙に染みこませます。血流が悪くなるので、指で採血ろ紙を触らないでください。円の中が十分いっぱいになったら、次の円に移ってください。血が円の縁からはみ出ても問題ありませんが、血の円が互いに重なり合わないようにしてください。",
      "step_6_subtitle": "一旦円に血を垂らして固まったら、追加で血を足さないでください。血の「層」ができると、採血が無効になります。",
      "step_7": "血液カードを閉じずに、少なくとも30分間は平らなところに置いて、室温で採血ろ紙が自然乾燥するようにしてください。熱が加わると検体が変質するため、採血ろ紙を加熱したり、風を当てて乾かしたり、または直射日光に当てたりしないようにしてください。",
      "step_7_subtitle": "採血ろ紙の裏側まで血がしみ出ていて、それぞれの円が血で埋まっているのが確認できると思います。",
      "step_8": "採血ろ紙が乾いたら、採血カードを閉じて平らにします。採血カードとランセットを、乾燥剤パックと一緒に医療専用袋の中に入れます。医療専用袋の口がしっかりと封されているかを確認してください。"
    },
    "test_tips": {
      "title": "適切な採血のためのヒント",
      "subtitle": "最善の結果を出すために：",
      "step_1": "採血時に水分不足の状態にならないようにしてください。水分によって血流が良くなります。",
      "step_2": "喫煙後すぐに採血しないでください。",
      "step_3": "ぬるま湯で手を洗って暖めると、手の血流が良くなります。",
      "step_4": "指の血流がよくなるよう、床に向けてしっかりと両手を握り合わせてください。",
      "step_5": "血流を良くするため、採血中は採血器具と手が心臓より下の位置にくるようにしてください。",
      "step_6": "2本以上の指を刺すことが必要になる場合があります。指を刺す毎にこの手順を繰り返してください。"
    },
    "shipping": {
      "header": "キットを返送する準備ができました！",
      "text": "**Congrats!** You finished your health screening."
    },
    "pick_up": "現場スタッフに推奨された検査キットを受け取って、以下で登録してください。",
    "short_display_name": {
      "A": "Chlamydia & Gonorrhea",
      "B": "Chlamydia & Gonorrhea",
      "C": "Syphilis",
      "CC": "Colorectal Cancer",
      "CE": "Celiac",
      "CR": "Creatinine",
      "D": "HIV, Syphilis, Chlamydia & Gonorrhea",
      "DD": "Diabetes",
      "E": "HIV & Syphilis",
      "H": "HPV",
      "P": "Hepatitis C",
      "HS": "Herpes Simplex",
      "I": "HIV",
      "TT": "Testosterone",
      "VD": "Vitamin D",
      "VS": "Viral Surveillance"
    },
    "test_kit": "検査キット",
    "view_results": "結果を見る",
    "recommended_kit_title": "推奨された検査キット",
    "instructions_box_h": {
      "title": "膣内スワブ採取",
      "subtitle": "検体保存チューブに保存液が入っている場合があります。 <span class=\"text-danger\">**その場合、保存液を保存チューブから出さないでください。**</span>",
      "step_1": "スワブの薄い表示線（ある場合）と、綿製の頭部の間を片手で持ち、陰唇（膣の入口付近のひだ状の皮膚）を広げます。",
      "step_2": "スワブを膣の入口からおよそ2インチ（5センチ）ほど中へ挿入します。スワブを30秒ほど中でゆっくり回して、膣内の壁に当ててこすります。",
      "step_3": "慎重にスワブを引き出し、 <span class=\"text-danger\">**RED \"VAGINAL” LABEL**</div>がついた検体保存チューブの中にスワブを入れてください。保存チューブの端を使って表示線の所でスワブを折ってください。",
      "step_4": "保存チューブの蓋をして、しっかりと平らに蓋がされているかを確認してください。",
      "step_5": "生年月日をMM/DD/YYYYの順でチューブの指定部分（DOB）に記入してください。",
      "step_6": "検体採取日（本日の日付）をMM/DD/YYYYの順でチューブの指定部分(Coll. Date)に記入してください。",
      "step_7": "空の医療専用袋の中に衝撃吸収パッドと検体を入れて、きっちりと封をしてください。"
    },
    "packaging_box_h": {
      "step_3": "**生年月日**をMM/DD/YYYYの順で保存チューブに記入しました",
      "step_4": "**採取日**をMM/DD/YYYYの順で保存チューブに記入しました",
      "step_6": "医療専用袋を箱の中に入れます"
    },
    "instructions_box_cc": {
      "title": "検便",
      "step_1": "生年月日と便の採取日（本日の日付）をMM/DD/YYYYの順で容器の指定された場所に記入してください。緑のキャップをねじり、上に引っ張って開けてください。",
      "step_2": "便器の中に、トイレットペーパーの上に重ねて指定の採取用ペーパーを置いてください。",
      "step_3": "採取用ペーパーの上に検査用の便を出してください。",
      "step_4": "紙が沈んで便が水に濡れる前に、検査用の便を採取してください。",
      "step_5": "採取用スティックを使って便の表面をこすり取ってください。",
      "step_6": "採取用スティックの溝部分が完全に便で覆われるように、便を採取してください。",
      "step_7": "採取用スティックを採取容器の中に入れ、緑のキャップをきっちり閉めてください。",
      "step_8": "トイレの水を流します。採取用の紙は生物分解されるので、下水道に悪影響はありません。",
      "step_9": "検便サンプル容器を吸収パッドに包んで、医療専用袋に入れてください。"
    },
    "contact_support": {
      "title": "ここで解決しましょう",
      "text_1": "ご迷惑をおかけして申し訳ありません。",
      "text_2": "当社にご連絡の上、不良点をお知らせください、キットの交換をさせていただきます。"
    },
    "contact_support_success": {
      "title": "メッセージを受け取りました",
      "text_1": "ご連絡ありがとうございます。",
      "text_2": "サポートチームの担当者から追ってご連絡致します。",
      "button_text": "ホームに戻る"
    },
    "kit_in_transit_to_patient": "Your order is on its way! Most orders are delivered within 2-5 business days. Once you receive your kit, we will send you more information on how to activate it and send it back.\n\nTracking Link: https://tools.usps.com/go/TrackConfirmAction_input?strOrigTrackNum={{tracking_number}}",
    "kit_received_at_lab": "The lab received your kit! You'll receive a notification to check your patient portal when results are ready",
    "registration_confirmation": "Your registration is now complete! Visit your registration confirmation page to activate your test kit:",
    "kit_ordered_online": "We've received your kit order and we will send an update once it ships! \n\nOrder #: {{order_number}} \nOrder date: {{order_date}}",
    "kit_ordered_online_email": {
      "header": "We received your order!",
      "p1": "We've received your order! Once your order ships, we'll send you another update.",
      "order_number": "Order #",
      "order_date": "Order date"
    },
    "completed": "Completed",
    "I": "this kit includes tests for HIV.",
    "CC": "this kit includes tests for COVID-19.",
    "CE": "this kit includes tests for celiac disease.",
    "CR": "this kit includes tests for creatinine.",
    "DD": "this kit includes tests for diabetes.",
    "H": "this kit includes tests for HPV.",
    "P": "this kit includes tests for hepatitis C.",
    "HH": "this kit includes tests for COVID-19, Flu A/B, and RSV.",
    "HS": "this kit includes tests for HSV-2.",
    "TT": "this kit includes tests for testosterone.",
    "VD": "this kit includes tests for vitamin D.",
    "nasal_collection": {
      "title": "Nasal collection",
      "subtitle": "For this test you will need the following materials:",
      "step_1": "Write your Date of Birth and the Collection Date on your collection tube. Use MM/DD/YYYY format for the dates (i.e., 08/29/2023).",
      "step_2": "Wash your hands with soap and water thoroughly before proceeding and after completeing all steps.",
      "step_3": "Remove the swab from the wrapper with your clean hands. Hold the swab with the white tip up. Do not touch the tip of the swab to anything.",
      "step_4": "Holding the swab with one hand, gently insert the white tip of the swab about 1/2 to 3/4 inces (1 to 2 cm) into the opening of your nose. Rotate the swab for 15 to 30 seconds. Make sure the swab touches the insides of your nose. Remove the swab from your nose being careful not to touch your skin. Do not set the swab down. Repeat in the other nostril.",
      "step_5": "While still holding the swab, unscrew and remove the cap from the transport tube without setting the cap down. Place the swab into the tube with the white tip down, taking great care not to spill or splash the liquid in the collection tube.",
      "step_6": "Place lid onto the collection tube and ensure it is closed evenly and tightly.",
      "step_7": "Place specimen into the empty biohazard bag with the absobent pad and ensure the seal is closed completely. Return the biohazard bag to the kiosk drop box."
    },
    "NN": "this kit includes tests for COVID-19.",
    "YYYWW": "this kit includes tests for COVID-19, Flu A/B, and RSV.",
    "FFFWW": "this kit includes tests for COVID-19 and Flu A/B.",
    "HA": "this kit includes tests for COVID-19, Flu A/B, and RSV.",
    "drop_off": {
      "header": "Your kit is ready to drop off!"
    }
  },
  "copy_link": "ページリンクをコピーする",
  "copied_link": "リンクをコピーしました！",
  "vaccines": {
    "descriptions": {
      "vaccine_flu": "一般的なインフルエンザウイルスに対する予防",
      "vaccine_covid": "新型コロナウイルスに対する防御",
      "tdap": "Tdapワクチンは破傷風を予防できる",
      "polio": "ポリオウイルスに対する予防。就学前の子供に必要です。",
      "varicella": "水痘を予防するワクチン",
      "shingles": "帯状疱疹を予防するワクチン。最低年齢は 18 歳である。",
      "human_papillomavirus": "HPV から保護するワクチン。11 歳の子供に推奨されます。",
      "meningococcal": "ワクチンは4種類の髄膜炎菌から保護します。"
    }
  },
  "viral_surveillance": {
    "shipping": {
      "title": "Prepare to ship your sample:",
      "step_1": {
        "label": "Put the biohazard bag into box.",
        "substep_1": "Remove the return shipping mailer from your test kit.",
        "substep_2": "Place the biohazard bag into the original collection kit box and close the box."
      },
      "step_2": {
        "label": "Put box into return shipping mailer.",
        "substep_1": "Place the box into the return shipping mailer and seal the mailer.",
        "substep_2": "Do not fold or cover the UN3373 diamond symbol."
      },
      "step_3": {
        "label": "Return to Fedex location.",
        "substep_1": "Return kit on the same day of collection (before the last pickup) to a Fedex location.",
        "substep_2": "Call for a FedEx pickup at 1.800.GoFedEx."
      }
    },
    "overview": {
      "title": "Welcome to the Viral Sequencing Program!",
      "subtitle": "Your participation helps provide valuable information on which respiratory pathogen variants are circulating in your area. Please follow the instructions that came with your kit to get started."
    },
    "instructions": {
      "title": "Collect your sample:",
      "step_1": "Wash your hands.",
      "step_2": "Remove the cap of the collection tube and open the swab package.",
      "step_2a": "Remove the swab from the package. Do not touch the soft end with your hands or anything else.",
      "step_3": "Collect a nasal swab.",
      "step_3a": "Insert the entire soft end of the swab into your nostril no more than 3/4 of an inch (1/5cm) into your nose.",
      "step_3b": "Slowly rotate the swab",
      "step_3c": "Gently remove the swab.",
      "step_3d": "Using the same swab, repeat in your other nostril with the same end of the swab.",
      "step_4": "Put swab into the collection tube and cap the tube securely.",
      "step_5": "Put the collection tube into the biohazard bag and seal.",
      "step_6": "Apply even pressure to the ziplock to seal the bag shut."
    },
    "click_to_get_started_button": "Click to get started",
    "get_started_button": "Get started",
    "thank_you": {
      "title": "Thank you for using Primary.Health!",
      "p_3": "You may now close the browser.",
      "p_2": "Please make sure to drop off the viral surveillance kit at your closest FedEx location.",
      "p_1": "Your participation actively helps Public Health officials fight the spread of disease. If you would like to see the impact you are making on Public Health, please take a moment to explore more via [this link]({{link}}). You will not be contacted about your results, as they are anonymous."
    },
    "verify_dob_title": "First, let's confirm your details",
    "verify_dob_subtitle": "This helps us ensure the correct person is using the test kit.",
    "activate_kit": "Activate a kit:",
    "steps_complete": "Steps complete",
    "opt_in": {
      "section_1_title": "How it works",
      "section_1_text_1": "Sign up below and receive a viral sequencing test kit in 1-3 business days.",
      "section_1_text_2": "Upon receiving the test kit",
      "section_1_text_3": "Send the sample back per the instructions in the kit using our prepaid label.",
      "sign_up": "Sign me up!",
      "no_to_participate": "No",
      "section_2_title": "What is the impact of your participation?",
      "section_2": {
        "card_1_title": "Rapid response to new variants",
        "card_1_text": "Genetic sequencing helps track the spread and emergence of new respiratory pathogen variants, allowing public health systems to quickly ramp up preparedness efforts.",
        "card_2_title": "Track how Respiratory Pathogens are changing",
        "card_2_text": "Respiratory pathogen sequencing analyzes the “genetic blueprint” of the virus. This helps scientists trace how the virus changes, enabling it to spread more easily, make people more sick",
        "card_3_title": "You have complete privacy",
        "card_3_text": "The sequencing will be processed anonymously. The lab will never contact you about your results"
      },
      "title": "You’re eligible for California’s Free Respiratory Pathogen Viral Sequencing Project!",
      "subtitle": "You can help public health officials protect communities against respiratory pathogens! If you opt in to participate you will receive a free viral sequencing kit that will test what respiratory pathogen strain you are infected with. Results will help officials track the spread of respiratory pathogen variants. See below for more information and to sign up.",
      "continue_to_result": "Continue to my result",
      "create_page_title": "Thank you!",
      "create_page_text_1": "Your kit should arrive in the next 1-3 business days.",
      "create_page_text_2": "Your participation actively helps Public Health officials fight the spread of diseases. You will be notified when your test kit order has been approved and when it has shipped."
    }
  },
  "family_registrations": {
    "find_clinic": "医院を探す",
    "select_pin": "詳細を表示するにはピンを選択してください",
    "miles_shorten": "{{miles}} マイル",
    "next_available": "次に利用可能の予約",
    "appointment_type": "予約の種類",
    "individual_appointment": "個人的の予約",
    "family_appointment": "家族の予約",
    "vaccines": "ワクチン",
    "what_kind_appointment": "どのような予約をご希望ですか?",
    "select_family_or_individual": "一度に複数の家族の予定をスケジュールする場合は、家族の予定を選択します。",
    "vaccines_selected": "選択済みのワクチン",
    "where_care": "どこでケアを受けたいですか?",
    "select_vaccines_for_individual": "予約時に接種するワクチンを選択してください（任意）。",
    "select_vaccines_for_family": "家族の予約に必要なワクチンを選択してください（任意）。",
    "schedule_services": "サービスの予約をする",
    "add_family": "家族を追加し、以下のサービスを選択してください。",
    "family_member": "家族の人数{{number}}名",
    "morning": "午前",
    "afternoon": "午後",
    "slot_available": "予約可能 {{number}}",
    "within_x_miles": "{{miles}}マイル以内",
    "any_distance": "任意の距離から",
    "partial_results": "部分的の結果",
    "partial_matches": "以下のクリニックでは、探しているワクチンの一部を提供していますが、すべてを提供しているわけではありません。",
    "no_matches": "検索に一致するものは見つかりませんでした。より良い結果を得るには、フィルターを変更してみてください。",
    "no_clinics_found": "検索条件に一致する診療所はありません。",
    "broaden_filters": "より良い結果を得るために、フィルターを広げてみてください。",
    "unavailable_vaccines": "入手不可のワクチン：",
    "available_vaccines": "入手可能のワクチン：",
    "select_date": "日付を選択",
    "available_appointments": "予約可能な時間",
    "appointment_scheduling_info": "次のステップでは、特定の家族に予約時間を割り当てることが可能です。必要に応じて、別の日に予約をスケジュールすることも可能です。",
    "hold_selected_appointments": "選択された予約は 15 分間保持されます。",
    "appointments_selected": "選択した予約",
    "no_appointments_selected": "予約が選択されていません",
    "vaccines_needed": "ワクチンが必要",
    "select_x_appointments": "{{number}} 予約を選択",
    "more": "もっと見る",
    "less": "隠す",
    "register_next_family_member": "次の家族メンバーを登録する",
    "successfully_registered_x_of_y_family_members": "{{y}}の家族メンバー{{x}}の登録は完成しました。",
    "next_family_member": "次の家族メンバー",
    "appointments_abbreviated": "予約",
    "register_for_clinic": "診療所二登録する",
    "select_services": "サービスを選択",
    "person": "人数 {{number}}",
    "add_person": "他人を追加する",
    "registration_confirmation": "登録の確認"
  },
  "user_mailer": {
    "verify_email": "メールアドレスを確認しましょう。",
    "hi_full_name": "こんにちは、{{full_name}}様、",
    "verify_email_button": "メールアドレスを確認する",
    "please_verify_email": "メールアドレスを確認するために、こちらのボタンを使ってください。"
  },
  "services": {
    "dptap_dt": "ジフテリア、破傷風、百日咳",
    "hepatitis_a": "A型肝炎",
    "hepatitis_b": "B型肝炎",
    "hepatitis_a_b": "AとB型肝炎",
    "hib": "ヘモフィルスインフルエンザ菌B型",
    "mmr": "麻疹、おたふくかぜ、風疹",
    "meningococcal": "髄膜炎菌性疾患",
    "mpox": "エムポックス",
    "pneumococcal": "肺炎レンサ球菌",
    "polio": "急性灰白髄炎",
    "rsv": "呼吸器合胞体ウイルス",
    "rotovirus": "ロタウイルス感染症",
    "zoster": "帯状疱疹",
    "tetanus_diptheria": "破傷風とジフテリア",
    "tdap": "破傷風、ジフテリア、百日咳",
    "typhoid": "腸チフス",
    "varicella": "水痘",
    "covid-19": "新型コロナウイルス",
    "covid-19_adult": "新型コロナウイルス（大人）",
    "covid-19_adult_description": "12歳以上の方対象。",
    "covid-19_children": "新型コロナウイルス（小児）",
    "covid-19_children_description": "4〜11歳のお子様対象。",
    "covid-19_infants": "新型コロナウイルス（幼児）",
    "covid-19_infants_description": "6ヶ月〜3歳のお子様対象。",
    "influenza": "インフルエンザ",
    "mmrv": "麻疹、おたふく風邪、風疹、水痘"
  },
  "deep_archived": {
    "admin": "データ保護上の理由で、グループ {{test_group_name}} ({{slug}}) のデータはアーカイブされてお​​り、このページからは表示も変更もできなくなりました。サポートが必要な場合は、アカウント マネージャーまたは support@primary.health にお問い合わせください。",
    "participant": "このページは利用不可能になりました。健康キャンペーンの連絡先 {{test_group_name}} に連絡してください。古い記録を表示できない場合は、support@primary.health に連絡してサポートを受けてください。"
  }
}