import React, { useState, useEffect, useRef, Fragment } from 'react';
import Loader from "react-loader-spinner";
import { Tooltip, OverlayTrigger, Button } from 'react-bootstrap';
import FloatingLabelInput from '../../common/components/FloatingLabelInput';
import FormSelect from '../../common/components/FormSelect';
import AsyncMultiSelect from '../../common/components/AsyncMultiSelect';
import SideBar, { Body } from '../../common/components/SearchFilters/SideBar';
import { FilterChipsContainer } from "../../common/components/SearchFilters/FilterArea";
import { filtersToDisplay, findLabel } from '../../common/components/SearchFilters/helpers/utils';

const SearchFilters = ({
  testGroup,
  params,
  locationOptions,
  consentedToFilterOptions,
  consentTypeFilterOptions,
  consentFlipperEnabled,
  selectedTags,
  tagOptionsUrl,
  populationOptions,
}) => {
  const [form, setForm] = useState({
    include_archived: "false",
    q: params.q,
    location: params.location,
    archived: params.archived,
    consented_to: params.consented_to,
    consent_type: params.consent_type,
    sort_by: params.sort_by,
    tags: params.tags,
    populations: params.populations,
  });
  const initialForm = useRef(form);
  const [isExpanded, setIsExpanded] = useState(false);
  const [searchInput, setSearchInput] = useState(params.q);
  const [searchInput2, setSearchInput2] = useState(params.q);
  const [consentType, setConsentType] = useState(params.consent_type);
  const [isLoading, setIsLoading] = useState(false);
  const sortOptions = [{label: "Last name, ASC", value: "last_name_asc"}, {label: "Last name, DESC", value: "last_name_desc"}]

  const toggleIsExpanded = () => {
    setIsExpanded(!isExpanded);
  }

  const updateTags = (tags) => {
    let formTags = tags.map(tag => tag.value).join(",");
    let tagLabels = tags.map(tag => tag.label).join(", ");
    setForm({...form, tags: formTags});
    setSelectedFilters({...selectedFilters, tags: tagLabels});
  }

  const filterTestGroupUsers = () => {
    setIsLoading(true);
    const query = {};
    Object.keys(form).forEach(key => {
      if (form[key]) {
        query[key] = form[key];
      }
    });
    const url = `/test_groups/${testGroup.slug}/test_group_users?` + new URLSearchParams(query).toString();
    window.location.href = url
  }

  const renderConsentTooltip = (props) => (
    consentType ?
      <span></span>
    :
    <Tooltip {...props}>
      Available only if Consent type is selected above
    </Tooltip>
  );

  const [selectedFilters, setSelectedFilters] = useState({
    q: params.q,
    location: findLabel(locationOptions, params.location),
    consented_to: findLabel(consentedToFilterOptions, params.consented_to),
    consent_type: findLabel(consentTypeFilterOptions, params.consent_type),
    sort_by: findLabel(sortOptions, params.sort_by),
    tags: selectedTags && selectedTags.map(tag => tag.friendly_name).join(", "),
    populations: findLabel(populationOptions, params.populations),
  });

  const updateForm = (key, value, label) => {
    setForm({...form, [key]: value});
    setSelectedFilters({...selectedFilters, [label]: label});
  }

  const clearAll = () => {
    setForm({});
    setSelectedFilters({});
  }

  const MainFilters = ({containerClass}) => (
    <>
      { testGroup.on_demand_only && (
        <div className={containerClass}>
          <FormSelect
            fieldName="location"
            prompt="Location"
            options={locationOptions}
            selected={form.location}
            onChange={(id) => updateForm("location", id, findLabel(locationOptions, id))}
          />
        </div>
      )}
      { !consentFlipperEnabled && (
        <div className={containerClass}>
          <FormSelect
            fieldName="consented_to"
            prompt="Consent"
            options={consentedToFilterOptions}
            selected={form.consented_to}
            onChange={(value) => updateForm("consented_to", value, findLabel(consentedToFilterOptions, value))}
          />
        </div>
      )}
      <div className={containerClass}>
        <FormSelect
          fieldName="sort_by"
          prompt="Sort name"
          options={sortOptions}
          selected={form.sort_by}
          onChange={(value) => updateForm("sort_by", value, findLabel(sortOptions, value))}
        />
      </div>
    </>
  )

  useEffect(() => {
    if (initialForm.current !== form) filterTestGroupUsers();
  }, [form]);

  useEffect(() => {
    if (consentFlipperEnabled && !consentType && form.consented_to) setForm({...form, consent_type: consentType, consented_to: undefined});
  }, [consentType]);

  return (
    <Fragment>
      {isLoading && (
        <div className="spinner-container">
          <Loader
            type="ThreeDots"
            color="#2862FA"
            height={200}
            width={200}
          />
        </div>
      )}
      <div className="d-sm-block d-md-flex align-items-center flex-wrap mb-3">
        <FloatingLabelInput
          label="Search by name, email, or phone"
          value={searchInput}
          onChange={(e) => setSearchInput(e.target.value)}
          onKeyPress={(e) => e.key === "Enter" && updateForm('q', e.target.value, e.target.value)}
          onKeyDown={(e) => e.key === "Tab" && updateForm('q', e.target.value, e.target.value)}
          ariaLabel="Search by name, email, or phone"
          className="d-sm-block flex-md-fill mb-3 mb-md-0"
          style={{minWidth: "305px"}}
          prependIcon="search"
        />
        <MainFilters containerClass="d-sm-block d-md-inline-block mb-3 mb-md-0 ms-md-3" />
        <a className="text-primary inter semibold mx-1 mx-md-3"
          type="button"
          data-bs-toggle="offcanvas"
          data-bs-target="#filterSideBar"
          aria-controls="filterSideBar"
          aria-label="Toggle filters"
          onClick={toggleIsExpanded}
        >
          <i className="fa-regular fa-bars-filter"></i>
        </a>
        <SideBar
          title="Filters"
          id="filterSideBar"
          placement="end"
          extraClasses="transition-none"
        >
          <Body>
            <FloatingLabelInput
              label="Search by name, email, or phone"
              value={searchInput2}
              onChange={(e) => setSearchInput2(e.target.value)}
              onKeyPress={(e) => e.key === "Enter" && updateForm('q', e.target.value, e.target.value)}
              onKeyDown={(e) => e.key === "Tab" && updateForm('q', e.target.value, e.target.value)}
              ariaLabel="Search by name, email, or phone"
              className="d-sm-block flex-md-fill mb-3 mb-md-0"
              style={{minWidth: "305px"}}
              prependIcon="search"
            />
            <MainFilters containerClass="my-3" />
            <div className="my-3">
              <AsyncMultiSelect
                name= "Tags"
                selected={selectedTags || []}
                optionsUrl={tagOptionsUrl}
                onSelect={(value) => updateTags(value)}
              />
            </div>
            <div className="my-3">
              <FormSelect
                fieldName="populations"
                prompt="Population"
                options={populationOptions}
                selected={form.populations}
                onChange={(value) => updateForm("populations", value, findLabel(populationOptions, value))}
              />
            </div>
            {consentFlipperEnabled && (
              <>
                <div className="my-3">
                  <FormSelect
                    fieldName="consent_type"
                    prompt="Consent type"
                    options={consentTypeFilterOptions}
                    selected={consentType}
                    onChange={(value) => setConsentType(value)}
                  />
                </div>
                <div className="my-3">
                  <OverlayTrigger overlay={renderConsentTooltip}>
                    <span>
                      <FormSelect
                        fieldName="consented_to"
                        prompt="Consent"
                        isDisabled={!consentType}
                        options={consentedToFilterOptions}
                        selected={form.consented_to}
                        onChange={(value) => setForm({...form, consented_to: value, consent_type: consentType})}
                        containerClassName={consentType ? "" : "disabled-pointer"}
                      />
                    </span>
                  </OverlayTrigger>
                </div>
              </>
            )}
          </Body>
        </SideBar>
      </div>
      {filtersToDisplay(selectedFilters, setForm, form).length > 0 && (
        <FilterChipsContainer
          displayFilters={filtersToDisplay(selectedFilters, setForm, form)}
          clearAll={clearAll}
        />
      )}
    </Fragment>
  )
 };
export default SearchFilters;
