import React, { useState } from 'react';
import { Table, Button, Form, Row, Col } from 'react-bootstrap';
import CaseReportRow from './CaseReportRow';
import { assignTests, assignTo, caseComplete, sendSmsEmail } from './Actions';

const CaseReports = ({ tests, assignable_users, current_user_id }) => {
  const [selection, setSelection] = useState(
    tests.reduce((hash, { id }) => {
      hash[id] = false;
      return hash;
    }, {}),
  );
  const [allSelected, setAllSelected] = useState(false);
  const [selectedUnassign, setSelectedUnassign] = useState(false);

  const updateSelection = (id, selected) => {
    setSelection(
      Object.entries(selection).reduce((h, [k, v]) => {
        parseInt(k, 10) === id ? (h[k] = selected) : (h[k] = v);
        return h;
      }, {}),
    );
  };

  const selectAll = () => {
    setSelection(
      Object.entries(selection).reduce((h, [k, _v]) => {
        h[parseInt(k, 10)] = !allSelected;
        return h;
      }, {}),
    );
    setAllSelected(!allSelected);
  };

  const isBulkSelected = () => Object.values(selection).some((el) => el);
  const bulkActionStyle = () => {
    if (isBulkSelected()) {
      $('nav ul.pagination').addClass('mb-8');
      return {
        display: 'block',
        position: 'fixed',
        bottom: 0,
        background: 'white',
        zIndex: 9,
        border: '1px solid #ccc',
        borderRadius: '6px',
      };
    }

    $('nav ul.pagination').removeClass('mb-8');
    return { display: 'none' };
  };

  const checkSelectedUnassign = () =>
    setSelectedUnassign(
      $('select#assign_to_user option:checked').val() === '-1',
    );

  return (
    <div className={`table-responsive ${isBulkSelected() ? 'mb-2' : null}`}>
      <section className="fabrx-tables-light">
        <Table responsive className="table-lg">
          <thead>
            <tr>
              <th style={{ paddingLeft: '1.185rem' }}>
                <input
                  type="checkbox"
                  className="form-check-input"
                  alt="Select All"
                  value="Select All"
                  onClick={() => selectAll()}
                />
              </th>
              <th>Barcode</th>
              <th>Status</th>
              <th>Assignment</th>
              <th>Status</th>
              <th>Result</th>
              <th>Previously positive</th>
              <th>Resulted at</th>
              <th>Symptoms</th>
              <th></th>
              <th>Name</th>
              <th>State</th>
              <th>Phone number</th>
              <th>Update</th>
              <th>Case report</th>
              <th>External links</th>
            </tr>
          </thead>
          <tbody>
            {tests.length > 0 ? (
              tests.map((test, i) => (
                <CaseReportRow
                  key={`${i}_${test.id}`}
                  test={test}
                  current_user_id={current_user_id}
                  isSelected={selection[test.id]}
                  updateSelection={updateSelection}
                />
              ))
            ) : (
              <tr>
                <td colspan="15" align="center">
                  <em>
                    We could not find any tests matching your search criteria.
                  </em>
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </section>

      <div className="mt-6" style={bulkActionStyle()}>
        <hr className="mt-0" />
        <p className="ms-3">
          <strong>Bulk:</strong>
        </p>
        <div className="mb-4 ms-3 me-3 d-flex">
          <div>
            <Button
              onClick={() =>
                window.confirm(
                  'Are you sure you assign those cases to yourself?',
                ) && assignTests(tests, selection)
              }
            >
              Assign selected to me
            </Button>
          </div>
          <div className="ms-3 ps-3" style={{ borderLeft: '1px solid #ccc' }}>
            <Form>
              <Form.Group as={Row}>
                <Col>
                  <Form.Control
                    as="select"
                    id="assign_to_user"
                    onChange={() => checkSelectedUnassign()}
                  >
                    <option value="">Assign to</option>
                    <option value="-1">(Unassign)</option>
                    {assignable_users.map(({ id, full_name }) =>
                      full_name && full_name !== ' ' ? (
                        <option key={id} value={id}>
                          {full_name}
                        </option>
                      ) : null,
                    )}
                  </Form.Control>
                </Col>
                <Col>
                  <Button onClick={() => assignTo(tests, selection)}>
                    {selectedUnassign ? 'Unassign' : 'Assign'}
                  </Button>
                </Col>
              </Form.Group>
            </Form>
          </div>
          <div className="ms-3 ps-3" style={{ borderLeft: '1px solid #ccc' }}>
            <Button onClick={() => caseComplete(tests, selection)}>
              Case complete
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CaseReports;
