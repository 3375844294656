import React, { useEffect, useReducer, useState } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';

import NewSectionsSidebar from '../../Registration/components/NewSidebar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import kitInformation, {
  CONTENTS,
  INSTRUCTIONS,
  PACKAGING,
  SHIPPING,
  successSteps,
  mobileProgressBarSteps,
} from './data/kit_information';
import Header from '../../Registration/components/Header';
import ContentsStep from './components/ContentsStep';
import {
  FooterButtons,
  MobileHeaderButtons,
} from './components/Buttons';
import InstructionsStep from './components/InstructionsStep';
import SuccessStep from './components/SuccessStep';
import PackagingStep from './components/PackagingStep';
import ShippingStep from './components/ShippingStep';
import TestTipsStep from './components/TestTipsStep';
import ReviewInstructionsStep from './components/ReviewInstructionsStep';
import ContactSupport from './components/ContactSupport';
import { Container } from 'react-bootstrap';
import { ProgressBar } from '../../common/components/Wizard';
import { useMediaQuery } from 'react-responsive';
import { KitBackground, KitCard, KitContainer } from '../components/styled';
import ConfirmIdentityStep from '../components/landing_page_steps/ConfirmIdentityStep';
import RegistrationSuccessStep from '../components/landing_page_steps/RegistrationSuccessStep';
import CustomConsent from '../../common/components/CustomConsent';
import CustomFinishRegistration from '../../common/components/CustomFinishRegistration';
import {
  STEP_FORWARD,
  STEP_BACK,
  REVIEW_INSTRUCTION,
  CONTACT_SUPPORT,
  SHOW_SUCCESS,
  SKIP_INSTRUCTIONS,
  SET_CURRENT_STEP,
} from './actions';
import reducer from './reducer';
import { extractKitType } from '../components/utils';

export const STEPS = {
  successRegStep: {
    component: RegistrationSuccessStep,
    navigationActions: {
      forward: 'confirmIdentityStep',
    },
    headerStep: CONTENTS,
  },
  confirmIdentityStep: {
    component: ConfirmIdentityStep,
    navigationActions: {
      forward: 'contentsStep',
      packagingStep: 'packagingStep',
    },
    headerStep: CONTENTS,
  },
  contentsStep: {
    component: ContentsStep,
    navigationActions: {
      forward: 'instructionsStep',
      testTips: 'testTipsStep',
      back: 'confirmIdentityStep',
    },
    headerStep: CONTENTS,
    showSuccess: true,
  },
  testTipsStep: {
    component: TestTipsStep,
    navigationActions: {
      forward: 'instructionsStep',
      back: 'contentsStep',
    },
    headerStep: INSTRUCTIONS,
  },
  instructionsStep: {
    component: InstructionsStep,
    navigationActions: {
      packagingStep: 'packagingStep',
      nextInstruction: 'instructionsStep',
      back: 'contentsStep',
    },
    headerStep: INSTRUCTIONS,
    showSuccess: true,
  },
  reviewInstructionsStep: {
    component: ReviewInstructionsStep,
    navigationActions: {
      forward: 'packagingStep',
      selectInstruction: 'instructionsStep',
    },
    headerStep: INSTRUCTIONS,
  },
  packagingStep: {
    component: PackagingStep,
    navigationActions: {
      forward: 'shippingStep',
      back: 'reviewInstructionsStep',
    },
    headerStep: PACKAGING,
    showSuccess: true,
  },
  shippingStep: {
    component: ShippingStep,
    headerStep: SHIPPING,
    showSuccess: true,
  },
};

const HEADER_NAMES = [CONTENTS, INSTRUCTIONS, PACKAGING, SHIPPING];

const administerTestKit = async (appointment, barcode) => {
  const processedBarcode = barcode.trim().toUpperCase();
  const response = await axios.put(`/kits/${processedBarcode}`, {
    access_code: appointment.access_code,
  });

  return response.data.success;
};

export default function ({
  test_group,
  appointment,
  user,
  finishRegistrationProps,
}) {
  const params = new URLSearchParams(document.location.search);
  const barcode = params.get('barcode');
  const kitType = extractKitType(barcode);
  const [signedConsent, setSignedConsent] = useState(
    appointment.consent_signed,
  );
  const [finishRegistration, setFinishRegistration] = useState(
    appointment.request_more_information_yes,
  );

  const initialState = {
    currentStep: kitInformation[kitType].initialStep || 'successRegStep',
    showSuccess: false,
    showContactSupport: false,
    showReviewInstructions: false,
    contents: kitInformation[kitType].contents,
    testTips: kitInformation[kitType]?.testTips,
    instructions: kitInformation[kitType].instructions,
    packaging: kitInformation[kitType].packaging,
    kitReturn: kitInformation[kitType].kitReturn,
    currentInstruction: 0,
  };

  const [state, dispatch] = useReducer(reducer, initialState);
  const { t } = useTranslation();
  const currentStep = state.currentStep;
  const currentHeaderStep = HEADER_NAMES.indexOf(
    STEPS[currentStep]?.headerStep,
  );
  const isMobile = useMediaQuery({ query: '(max-width: 576px)' });
  const CurrentComponent = STEPS[currentStep].component;

  const { steps: progressBarSteps, totalSteps: totalProgressBarSteps } =
    mobileProgressBarSteps(kitType);
  const calculateCompletedSteps = () => {
    switch (currentStep) {
      case 'instructionsStep':
        return `INSTRUCTIONS.${state.currentInstruction}`;
      case 'testTipsStep':
        return 'INSTRUCTIONS.0';
      case 'reviewInstructionsStep':
        return PACKAGING;
      default:
        return STEPS[currentStep].headerStep;
    }
  };
  const currentProgressBarStep = progressBarSteps.indexOf(
    calculateCompletedSteps(),
  );

  const kitSections = [
    {
      header: t('test_strip.section_1'),
      Icon: ({ color }) => <FontAwesomeIcon icon="fa-light fa-clipboard-list" size="lg" style={{ color }} />,
      step: 'contentsStep',
    },
    {
      header: `${t('test_strip.section_2')} ${
        currentStep === 'instructionsStep' && state.instructions.length !== 1
          ? `(${parseInt(state.currentInstruction) + parseInt(1)}/${
              state.instructions.length
            })`
          : ''
      }`,
      Icon: ({ color }) => <FontAwesomeIcon icon="fa-light fa-flask" size="lg" style={{ color }} />,
      step: 'instructionsStep',
    },
    {
      header: t('test_strip.section_3'),
      Icon: ({ color }) => <FontAwesomeIcon icon="fa-light fa-box-open" size="lg" style={{ color }} />,
      step: 'packagingStep',
    },
    {
      header: t('test_strip.section_4'),
      Icon: ({ color }) => <FontAwesomeIcon icon="fa-light fa-box" size="lg" style={{ color }} />,
      step: 'shippingStep',
    },
  ];

  const stepForward = () => {
    if (STEPS[currentStep].showSuccess) {
      dispatch({ type: SHOW_SUCCESS, payload: true });
      setTimeout(() => {
        dispatch({ type: STEP_FORWARD });
        dispatch({ type: SHOW_SUCCESS, payload: false });
      }, 1800);
    } else {
      dispatch({ type: STEP_FORWARD });
    }
  };

  const stepBackward = () => {
    dispatch({ type: STEP_BACK });
  };

  const setCurrentStep = (index) => {
    dispatch({ type: SET_CURRENT_STEP, payload: kitSections[index].step });
  };

  const skipInstructions = () => {
    dispatch({ type: SKIP_INSTRUCTIONS });
  };

  const contactSupport = (showContactSupport = true) => {
    dispatch({ type: CONTACT_SUPPORT, payload: showContactSupport });
  };

  const reviewInstruction = (id) => {
    dispatch({ type: REVIEW_INSTRUCTION, payload: { id } });
  };

  const completeKitActivation = async () => {
    const administerIsValid = await administerTestKit(appointment, barcode);
    if (administerIsValid) {
      dispatch({ type: STEP_FORWARD });
      setTimeout(() => {
        location.href = `/t/${test_group.slug}/u/${appointment.access_code}`;
      }, 3000);
    } else {
      console.log('Something went wrong administering this test strip!');
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [currentStep, state.currentInstruction]);

  const stepProps = {
    stepForward,
    stepBackward,
    completeKitActivation,
    reviewInstruction,
    skipInstructions,
    contactSupport,
    user,
    kitType,
    barcode,
    isMobile,
    appointment,
    t,
    test_group,
    ...state,
  };

  if (!signedConsent) {
    return (
      <KitContainer>
        <Header testGroup={{ locales: ['en', 'es'] }} borderBottom={false} />
        <KitBackground>
          <KitCard>
            <CustomConsent
              testGroup={test_group}
              appointment={appointment}
              user={user}
              {...stepProps}
              onSuccess={() => {
                setSignedConsent(true);
              }}
            />
          </KitCard>
        </KitBackground>
      </KitContainer>
    );
  }

  if (finishRegistration) {
    return (
      <KitContainer>
        <Header testGroup={{ locales: ['en', 'es'] }} borderBottom={false} />
        <KitBackground>
          <KitCard>
            <CustomFinishRegistration
              {...finishRegistrationProps}
              onSuccess={() => {
                setFinishRegistration(false);
              }}
            />
          </KitCard>
        </KitBackground>
      </KitContainer>
    );
  }

  if (
    currentStep === 'successRegStep' ||
    currentStep === 'confirmIdentityStep'
  ) {
    return (
      <KitContainer>
        <Header testGroup={{ locales: ['en', 'es'] }} borderBottom={false} />
        <KitBackground>
          <KitCard>
            <CurrentComponent {...stepProps} />
          </KitCard>
        </KitBackground>
      </KitContainer>
    );
  }

  if (state.showContactSupport) {
    return (
      <KitContainer>
        <Header testGroup={{ locales: ['en', 'es'] }} borderBottom={false} />
        <KitBackground>
          <KitCard>
            <ContactSupport {...stepProps} />
          </KitCard>
        </KitBackground>
      </KitContainer>
    );
  }

  // TODO: refactor this
  if (state.showSuccess) {
    return (
      <>
        <div className={`sticky-top ${!isMobile && 'mb-3'}`}>
          <Header testGroup={{ locales: ['en', 'es'] }}></Header>
        </div>
        <div className="kit-margin">
          {isMobile && (
            <>
              <ProgressBar
                totalSteps={totalProgressBarSteps}
                completedSteps={currentProgressBarStep + 1}
                capitalizeTitle
              />
            </>
          )}
          <Container className="kit-container poppins regular">
            {!isMobile && (
              <NewSectionsSidebar
                sections={kitSections}
                currentRegistrationStep={currentHeaderStep}
                setCurrentRegistrationStep={setCurrentStep}
              />
            )}
            <SuccessStep
              data={
                currentStep === 'contentsStep'
                  ? successSteps(kitType)[0]
                  : successSteps(kitType)[
                      parseInt(state.currentInstruction) + 1
                    ]
              }
              {...stepProps}
            />
          </Container>
        </div>
      </>
    );
  }

  return (
    <>
      <div className={`sticky-top ${!isMobile && 'mb-3'}`}>
        <Header testGroup={{ locales: ['en', 'es'] }}></Header>
        {isMobile && <MobileHeaderButtons {...stepProps} />}
      </div>
      <div className="kit-margin">
        {isMobile && (
          <>
            <ProgressBar
              totalSteps={totalProgressBarSteps}
              completedSteps={currentProgressBarStep}
              capitalizeTitle
            />
          </>
        )}
        <Container className="kit-container poppins regular">
          {!isMobile && (
            <NewSectionsSidebar
              sections={kitSections}
              currentRegistrationStep={currentHeaderStep}
              setCurrentRegistrationStep={setCurrentStep}
            />
          )}
          <CurrentComponent {...stepProps} />
          <FooterButtons {...stepProps} />
        </Container>
      </div>
    </>
  );
}
