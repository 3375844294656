export default {
  "date": {
    "order": [
      "year",
      "month",
      "day"
    ],
    "month": "महिना",
    "day": "दिन",
    "year": "वर्ष",
    "january": "जनवरी",
    "february": "फेब्रुअरी",
    "march": "मार्च",
    "april": "अप्रिल",
    "may": "सक्छ",
    "june": "जून",
    "july": "जुलाई",
    "august": "अगस्ट",
    "september": "सेप्टेम्बर",
    "october": "कात्तिक",
    "november": "नोभेम्बर",
    "december": "डिसेम्बर"
  },
  "user": {
    "send_appointment_confirmation_message": "तपाईको अपोइन्टमेन्ट {{name}} को लागि पुष्टि भएको छ।",
    "landline_appointment_reminder_message": "नमस्कार, {{full_name}}। यो {{name}} मा तपाइँको नियुक्ति को लागी एक अनुस्मारक हो। तपाइँको नियुक्ति {{date}} मा {{time}} मा {{address}} मा छ।",
    "send_at_home_visit_confirmation_message": "तपाईको अपोइन्टमेन्ट {{date}} मा {{time}} पछि पुष्टि भयो।",
    "send_mail_order_confirmation_message": "तपाईंको मेल अर्डर पुष्टि भयो",
    "send_waitlist_message": "तपाइँ {{name}} को लागी प्रतीक्षा सूचीमा हुनुहुन्छ। जब सम्म हामी एक अपोइन्टमेन्ट समय को पुष्टि गर्दैनौं तब सम्म नदेखाउनुहोस्।",
    "verify_contact": {
      "phone": "कृपया क्लिक गरेर आफ्नो फोन नम्बर पुष्टि गर्नुहोस्: {{confirmation_link}}",
      "email": "{{confirmation_link}}"
    },
    "duplicate_user_continue_registering": "यहाँ एक नियुक्ति को लागी दर्ता जारी राख्नुहोस्: {{url}}",
    "on_demand_confirmation_message": "तपाईंले {{name}} को लागि सफलतापूर्वक दर्ता गर्नुभएको छ।",
    "appointment_reminder": "{{start_time}} मा तपाइँको अपोइन्टमेन्ट भन्दा पहिले, कृपया कुनै पनि बकाया कार्य कार्यहरु पूरा गर्न र तपाइँको पुष्टिकरण हेर्न लिंक मा क्लिक गर्नुहोस्। कृपया तपाइँको अपोइन्टमेन्ट भन्दा पहिले १५ मिनेट भन्दा धेरै नआउनुहोस्।",
    "appointment_reminder_base": "{{text}} यहाँ क्लिक गर्नुहोस्: {{link}}",
    "test_result_notifier": {
      "text_message": "{{first_name_with_last_initial}} को बिरामी पोर्टल अपडेट गरिएको छ। {{url}} मा जानुहोस् र कोड प्रयोग गर्नुहोस्: {{access_code}}",
      "email_subject": "तपाईंको बिरामी पोर्टलमा नयाँ अपडेटहरू",
      "email_intro": "नमस्कार {{first_name_with_last_initial}}",
      "email_p1": "तपाईंको लागि नयाँ सन्देश वा बिरामी अपडेट उपलब्ध छ।",
      "email_p2": "तलको बटनमा क्लिक गर्नुहोस् वा तपाईंको बिरामी पोर्टल हेर्न र कोड प्रयोग गर्न लिङ्क प्रयोग गर्नुहोस्",
      "email_p3": "विरामी पोर्टलमा हेर्नुहोस्:'",
      "email_button": "पोर्टल हेर्नुहोस्"
    },
    "landline_appointment_reminder_message_without_time": "नमस्कार, {{full_name}}। यो {{name}} मा तपाइँको नियुक्ति को लागी एक अनुस्मारक हो। तपाइँको नियुक्ति {{date}} मा {{address}} मा छ।",
    "appointment_reminder_on_demand": "{{date}} मा तपाइँको अपोइन्टमेन्ट भन्दा पहिले कृपया कुनै पनि बकाया कार्य कार्यहरु पूरा गर्न को लागी लि क मा क्लिक गर्नुहोस् र तपाइँको पुष्टि हेर्नुहोस्।",
    "new_test_result_notifier": {
      "email_intro": "तपाईंको बिरामी पोर्टल अपडेट गरिएको छ।",
      "email_text_1": "कुनै पनि नयाँ बिरामी रेकर्ड वा प्रयोगशाला रिपोर्टहरू हेर्नको लागि तलको आफ्नो मेडिकल इतिहास पोर्टलमा जानुहोस्।",
      "email_button": "चिकित्सा इतिहास",
      "email_text_2": "तपाइँ यो URL लाई तपाइँको ब्राउजरमा प्रतिलिपि गरेर टाँस्न सक्नुहुन्छ:"
    },
    "mailers": {
      "email_contact_us": "केही प्रश्न छ? हामीलाई यहाँ सम्पर्क गर्नुहोस्"
    }
  },
  "errors": {
    "generic": {
      "message": "केहि गलत भयो, कृपया फेरि प्रयास गर्नुहोस्"
    },
    "messages": {
      "blank": "खाली हुन सक्दैन"
    },
    "incorrect_credentials": "तपाईंको परिचय प्रमाणहरू गलत थिए, कृपया पुन: प्रयास गर्नुहोस्।",
    "error_activating_test_kit": "परीक्षण किट सक्रिय गर्दा त्रुटि भयो।"
  },
  "type": "प्रकार",
  "payment": {
    "continue_label": "भुक्तानी गर्न जारी राख्नुहोस्",
    "policy_text": "यदि तपाइँ रद्द गर्नुहुन्छ वा तपाइँको अपोइन्टमेन्ट को लागी देखाउनुहुन्न, हामी तपाइँको भुक्तानी को ८० फिर्ता गर्नेछौं, तपाइँको नियुक्ति मिति को ७ डायस दिन पछि। तपाइँ पूरा [सेवा सर्तहरु] ({{link}}) हेर्न सक्नुहुन्छ।",
    "card_number": "कार्ड नम्बर",
    "expiry_date": "समाप्ति मिति",
    "pay_button": "तिर्नुहोस्",
    "no_credit_card": "म साइटमा भुक्तानी गर्नेछु",
    "cash_payment_helper": "कृपया तपाइँको नियुक्तिमा **{{payment}} ** ल्याउन निश्चित गर्नुहोस्।",
    "invoice": "बीजक",
    "pay_by_card": "कार्ड द्वारा तिर्नुहोस्",
    "cost": "लागत",
    "total": "कुल",
    "pay_cash_at_appointment": "मँ {{appointment}} मा मेरो नियुक्ति को समयमा नगद संग तिर्नेछु।",
    "view_invoice": "हेर्नुहोस्/प्रिन्ट इनभ्वाइस",
    "refund_policy": "यदि तपाईंले तपाईंको अपोइन्टमेन्ट रद्द गर्नुभयो भने, तपाईं तपाईंको भुक्तान को {{amount}} फिर्ता गर्नेछौं, तपाईंको अपोइन्टमेन्ट मिति 7 दिन पछि हुनेछ। तपाईंले पूर्ण [सेवा सर्तहरू]({{link}}) हेर्न सक्नुहुन्छ",
    "refund_window_passed": "अपोइन्टमेन्ट समय बितिसकेको हुनाले तपाईंको अपोइन्टमेन्ट फिर्ताको लागि योग्य छैन। कृपया कुनै पनि रिजोल्युसनको लागि सिधै साइट संयोजकहरूसँग सम्पर्क गर्नुहोस्।",
    "amount_paid": "तिरीएको रकम",
    "balance": "सन्तुलन"
  },
  "consent_waiver": {
    "upload_waiver_or_sign": "एक सहमति माफी अपलोड गर्नुहोस् वा तल हस्ताक्षर बक्समा हस्ताक्षर गर्नुहोस्।",
    "stop": "दर्ता पूरा गर्न सहमति आवश्यक छ।",
    "parent_or_guardian_required_html": "क्रम मा तपाइँको दर्ता को अंतिम रूप मा, तपाइँको आमा बुबा, अभिभावक, वा अधिकृत निर्णय निर्माता तल फारम मा सहमति प्रदान र आफ्नो नाम हस्ताक्षर गर्नु पर्छ। तपाइँ <b> यो गर्न सक्नुहुन्न </b> यो सेवा को लागी तपाइँको आफ्नै।",
    "send_parent_or_guardian": "तपाइँ तपाइँको आमा बुबा वा अभिभावक यो लि क पठाउन सक्नुहुन्छ वा उनीहरुलाई यस पृष्ठमा तल हस्ताक्षर गर्न सक्नुहुन्छ।",
    "consent_guardian_name_signature": "पहिलो नाम",
    "consent_for_minor": "नाबालिगको तर्फबाट सहमतिमा हस्ताक्षर गर्नुहोस्।",
    "guardian_signature": "अभिभावक, अभिभावक, वा अधिकृत चिकित्सा निर्णयकर्ताको हस्ताक्षर।",
    "after_you_schedule": "{{age}} बर्ष भन्दा कम उमेरका सहभागीहरुलाई उनीहरुको तर्फबाट अभिभावक वा अभिभावक द्वारा हस्ताक्षर गरिएको सहमति हुनु पर्छ। कृपया तपाइँको अभिभावक वा तपाइँको आश्रित को लागी हस्ताक्षर गर्न को लागी पंजीकरण को अन्त्य सम्म जारी राख्नुहोस्।",
    "consent_guardian_last_name_signature": "अन्तिम नाम",
    "consent_first_and_last_name": "पहिलो र अन्तिम नाम",
    "phone_number": "अभिभावकको फोन नम्बर",
    "email": "अभिभावक ईमेल",
    "consent_registrar_with_guardian_name_signature": "अभिभावकको पहिलो नाम",
    "consent_registrar_with_guardian_last_name_signature": "अभिभावकको अन्तिम नाम",
    "consent_registrar_first_name_signature": "रजिस्ट्रार पहिलो नाम",
    "consent_registrar_last_name_signature": "रजिस्ट्रारको अन्तिम नाम",
    "consent_on_behalf": "म {{first_name}} {{last_name}} को तर्फबाट साइन गर्दैछु।",
    "consent_on_behalf_registrar": "मलाई {{first_name}} {{last_name}} द्वारा मौखिक रूपमा तिनीहरूको तर्फबाट हस्ताक्षर गर्न अधिकार दिइएको छ।",
    "consent_on_behalf_registrar_with_guardian": "मलाई {{first_name}} {{last_name}} को अभिभावकद्वारा तिनीहरूको तर्फबाट हस्ताक्षर गर्न मौखिक रूपमा अधिकार दिइएको छ।",
    "registrar_phone": "रजिस्ट्रारको फोन नम्बर",
    "registrar_email": "रजिस्ट्रारको इमेल",
    "consented_by_parent_guardian": "अभिभावक / संरक्षक ",
    "consented_by_decision_maker": "अधिकृत चिकित्सा निर्णय निर्माता",
    "consented_by_guardian_verbal": "संरक्षक संग रजिस्ट्रार (मौखिक सहमति प्राप्त)",
    "consented_by_registrar": "रजिस्ट्रार (मौखिक सहमति प्राप्त)",
    "full_name": "संरक्षक संग रजिस्ट्रार (मौखिक सहमति प्राप्त)",
    "print": "पूरा नाम"
  },
  "registration": {
    "contact_information": "नाम र ठेगाना",
    "contact_information_additional": "अभिभावक वा अभिभावक सम्पर्क सूचना र परिणाम को लागी सम्पर्क जानकारी।",
    "personal_information": "जनसांख्यिकीय जानकारी",
    "address_placeholder": "कृपया आफ्नो घरको ठेगाना प्रविष्ट गर्नुहोस्",
    "first_name_label": "पहिलो नाम",
    "last_name_label": "अन्तिम नाम",
    "errors": {
      "state": "राज्य एक मान्य २ वर्ण राज्य कोड हुनु पर्छ।",
      "phone_number_invalid": "फोन नम्बर अमान्य छ।",
      "required": "यो मान आवश्यक छ।",
      "email_domain": "तपाइँको प्रविष्टि अमान्य छ; कृपया दोहोरो जाँच गर्नुहोस्। दर्ता प्रायोजक संगठन द्वारा चयन गरिएका सहभागीहरु लाई सीमित छ। कृपया तपाइँको काम वा सामुदायिक ईमेल को उपयोग गरी हेर्नुहोस्। यदि तपाइँ महसुस गर्नुहुन्छ कि तपाइँ योग्य हुनुहुन्छ र त्यहाँ एक त्रुटि छ, कृपया समर्थनलाई सम्पर्क गर्नुहोस्।",
      "age_requirement": "जन्म मिति उमेर आवश्यकता पूरा गर्दैन",
      "signature_required": "हस्ताक्षर आवश्यक छ",
      "regex_invalid": "यो मान अमान्य देखिन्छ।",
      "date_invalid": "यो मिति अवस्थित छैन।",
      "invalid_entry": "कृपया दर्ता संग जारी नगर्नुहोस्। पर्दा मा निर्देशनहरु पालना गर्नुहोस् वा सम्पर्क को आफ्नो बिन्दु सम्म पुग्न।",
      "city": "एक मान्य शहर हुनु पर्छ।",
      "survey_unanswered": "कृपया जाँच गर्नुहोस् कि सबै आवश्यक प्रश्न * को साथ मा उत्तर दिईएको छ।",
      "postal_code": "एक मान्य ५ अंकको हुलाक कोड हुनु पर्छ।",
      "field_required": "{{field}} मान हुनुपर्छ",
      "option_required": "तपाईंले एउटा विकल्प छनौट गर्नुपर्छ",
      "year_length_invalid": "चार अक्षर वर्षसँगै जम मिति हान्नुहोस्(वर्ष)",
      "invalid_day": "दिन 1 - 31 बीचको हुनुपर्छ",
      "reached_max_chars": "तपाईं वर्णहरूको अधिकतम संख्यामा पुग्नुभएको छ।",
      "chars_max": "वर्ण अधिकतम",
      "minimum_length": "इनपुट कम्तिमा {{length}} वर्ण लामो हुनुपर्छ।"
    },
    "insurance_status": {
      "question": "के तपाइँसँग स्वास्थ्य बीमा छ?",
      "have_health_insurance": "हो, मसँग स्वास्थ्य बीमा छ।",
      "do_not_have_health_insurance": "हैन, मसँग स्वास्थ्य बीमा छैन।"
    },
    "insurance_policy_holder": {
      "question": "नीति धारक को हो?",
      "i_am": "म हो",
      "my_spouse": "मेरो पति वा साझेदार",
      "my_parents": "मेरा अभिभावकहरू",
      "someone_else": "अरु कोहि",
      "policy_first_name": "नीति धारक को पहिलो नाम",
      "policy_last_name": "नीति धारक को अन्तिम नाम",
      "policy_dob": "नीति धारक को जन्म मिति",
      "name": "नीति धारकको नाम"
    },
    "insurance_information": {
      "title": "बीमा जानकारी",
      "company_name": "बीमा कम्पनी को नाम",
      "id_number": "सदस्य पहिचान नम्बर",
      "group_number": "सदस्य समूह संख्या",
      "secondary_insurance_label": "मसँग माध्यमिक बीमा छ",
      "take_photo": "कृपया आफ्नो बीमा कार्डको फोटो खिच्नुहोस्। फोटो खिच्दा केही जानकारी स्वतः भरिनेछ।",
      "front_of_card": "कार्डको अगाडि",
      "card_information": "बीमा कार्ड जानकारी",
      "back_of_card": "कार्डको पछाडि"
    },
    "employment_information": {
      "label": "रोजगार",
      "address_1": "कम्पनीको ठेगाना",
      "address_2": "उदाहरण: सुइट २००",
      "phone_number": "कम्पनी फोन नम्बर",
      "company": "कम्पनीको नाम",
      "postal_code": "रोजगारदाता हुलाक कोड",
      "employed": "कार्यरत",
      "sole_prorietor": "एकल मालिक",
      "not_employed": "रोजगार छैन",
      "reporting_supervisor": "रिपोर्टिङ पर्यवेक्षक",
      "reporting_department": "रिपोर्टिङ विभाग",
      "supervisor_name": "सुपरभाइजरको नाम",
      "supervisor_email": "सुपरभाइजरको इमेल"
    },
    "location_availability": "परीक्षण हाल यी क्षेत्रहरु मा बस्ने समुदाय को सदस्यहरु को लागी प्राथमिकतामा छ।",
    "custom_survey": "प्रश्नावली",
    "confirmation": "समीक्षा",
    "waitlisted": "प्रतीक्षा सूचीमा",
    "schedule_your_appointment": "अनुसूची नियुक्ति",
    "information": "जानकारी",
    "consent": "सहमति",
    "location": "स्थान",
    "symptoms": "लक्षण",
    "address_required": "कृपया तपाइँको ठेगाना प्रविष्ट गर्नुहोस्",
    "consent_required": "कृपया जारी राख्न सहमति दिनुहोस्",
    "required_field": "आवश्यक क्षेत्र संकेत",
    "phone_number": "मोबाइल फोन नम्बर",
    "email": "इमेल",
    "date_of_birth": "जन्म मिति",
    "minimum_age": "सहभागिता को लागी न्यूनतम उमेर {{year}} वर्ष हो।",
    "no_minimum_age": "त्यहाँ परीक्षण को लागी कुनै न्यूनतम उमेर छैन।",
    "continue_button": "जारी राख्न",
    "email_required": "यो मान एक मान्य ईमेल हुनुपर्छ।",
    "done": "पूरा भयो",
    "signature": "हस्ताक्षर",
    "clear_button": "खाली",
    "back_button": "फिर्ता",
    "choose_location": "एक स्थान छान्नुहोस्",
    "no_slots": "{{location}} मा कुनै थप स्लट उपलब्ध छैन।",
    "choose_appointment": "{{location}} मा भेट्ने समय छान्नुहोस्।",
    "appointment_required": "एक नियुक्ति समय चयन गर्नुहोस्।",
    "phone_number_required": "फोन नम्बर अमान्य छ।",
    "phone_number_label": "कृपया एक नम्बर प्रविष्ट गर्नुहोस् जुन छिटो परीक्षण परिणामहरु को लागी पाठ सन्देशहरु प्राप्त गर्न सक्नुहुन्छ।",
    "symptoms_experiencing": "कृपया तपाइँ हाल अनुभव गरिरहनुभएको लक्षणहरु मा क्लिक गर्नुहोस्। यदि तपाइँसँग कुनै लक्षण छैन, कृपया जारी राख्नुहोस्।",
    "household": "घरेलु सदस्यहरु",
    "household_p1": "वैकल्पिक रूपमा, परिवारका सदस्यहरु को लागी जो पनि परीक्षण प्राप्त गर्न को लागी एक नियुक्ति सिर्जना गर्नुहोस्।",
    "add_dependent": "घरेलु सदस्य जोड्नुहोस्",
    "remove_dependent": "निर्भर हटाउनुहोस्",
    "dependent_consents": "सहमति",
    "submit": "पूरा",
    "add_waitlist": "प्रतीक्षा सूचीमा जोड्नुहोस्",
    "address": "घरको ठेगाना",
    "address_1": "ठेगाना लाइन १",
    "address_2": "अपार्टमेन्ट / सुइट #",
    "address_city": "शहर",
    "address_state": "राज्य",
    "postal_code": "हुलाक कोड",
    "race_ethnicity": "दौड",
    "gender": "लिंग पहिचान",
    "self_described_gender": "स्व वर्णित लिंग",
    "interpreter": "अनुवादक चाहिन्छ? यदि हो भने, कुन भाषा?",
    "consent_to_terms": "म यी सर्तहरुमा सहमत छु।",
    "reg_not_open": "दर्ता खोलिएको छैन।",
    "no_more_avail_spots": "तपाइँले चयन गर्नुभएको समय स्लट अब उपलब्ध छैन। फेरि प्रयास गर्नुहोस।",
    "consent_helper": "क्लिक गर्नुहोस् र साइन गर्न तल बक्समा मार्फत आफ्नो माउस वा औंला तान्नुहोस्।",
    "phone_number_unreachable_label": "ल्याण्डलाइन?",
    "select": "चयन गर्नुहोस्",
    "test_surveys": "नियुक्ति प्रश्न",
    "edit": "सम्पादन गर्नुहोस्",
    "continue_to_registration": "दर्ता गर्न जारी राख्नुहोस्।",
    "accounts": {
      "already_have_an_account": "पहिले नै एउटा खाता छ?",
      "log_in": "लग - इन",
      "sign_up": "साइन अप",
      "sign_up_description": "कृपया तपाइँको खाता बनाउन को लागी तपाइँको जानकारी प्रविष्ट गर्नुहोस् र भविष्य को पंजीकरण मा समय बचाउनुहोस्।",
      "log_in_description": "कृपया तपाइँको ई-मेल र पासवर्ड प्रविष्ट गर्नुहोस्, वा तल गुगल वा आउटलुक मार्फत साइन इन गर्नुहोस्।",
      "sign_in_with_label": "{{provider}} को साथ साइन इन गर्नुहोस्",
      "password_must_match": "पासवर्ड मिल्नु पर्छ",
      "password_uppercase": "तपाइँको पासवर्ड मा कम्तीमा (%s) अपरकेस अक्षर हुनु पर्छ।",
      "password_lowercase": "तपाइँको पासवर्ड मा कम्तीमा (%s) सानो अक्षर हुनु पर्छ।",
      "password_number": "तपाइँको पासवर्ड कम्तीमा (%s) नम्बर हुनु पर्छ।",
      "password_special": "तपाइँको पासवर्ड कम्तीमा (%s) विशेष वर्ण हुनु पर्छ।"
    },
    "password": "पासवर्ड",
    "password_confirmation": "पासवर्ड पुष्टि",
    "consent_for": "{{name}} को सहमति",
    "book_one_time_appointment": "एक समय नियुक्ति बुक",
    "duplicate_users": {
      "exists": "तपाइँ पहिले नै दर्ता हुनुहुन्छ",
      "overlapping_email_and_phone_p1": "हामीले तपाइँको फोन नम्बर र ईमेल ठेगानामा एउटा सन्देश पठायौं।",
      "p2": "तपाइँको सन्देश को उपयोग गरेर, तपाइँ तपाइँको नियुक्ति परिवर्तन गर्न सक्नुहुन्छ वा परीक्षण परिणामहरु प्रविष्ट गर्नुहोस्।",
      "overlapping_phone_and_email_p3": "विवरण को लागी कृपया तपाइँको ईमेल वा फोन जाँच गर्नुहोस्।",
      "overlapping_email_p3": "विवरण को लागी कृपया तपाइँको ईमेल जाँच गर्नुहोस्।",
      "overlapping_phone_p3": "विवरण को लागी कृपया तपाइँको फोन जाँच गर्नुहोस्।",
      "overlapping_phone_p1": "हामीले तपाइँको फोन नम्बरमा एउटा सन्देश पठायौं।",
      "overlapping_email_p1": "हामीले तपाइँको ईमेल ठेगानामा एउटा सन्देश पठायौं।",
      "p4": "यदि तपाइँ यो एक त्रुटि हो भन्ने लाग्छ, support@primary.health मा हामीलाई सम्पर्क गर्नुहोस्",
      "overlapping_email_and_phone_p3": "विवरण को लागी कृपया तपाइँको ईमेल वा फोन जाँच गर्नुहोस्।"
    },
    "duplicate_waitlist": {
      "exists": "तपाइँ पहिले नै प्रतीक्षा सूचीमा हुनुहुन्छ।",
      "overlapping_email_and_phone_p1": "हामीले तपाइँको फोन नम्बर र ईमेल ठेगानामा अर्को पुष्टिकरण पठायौं।",
      "p2": "तपाइँको पुष्टिकरण को उपयोग गरेर, तपाइँ अतिरिक्त जानकारी प्रदान गर्न सक्नुहुन्छ वा प्रतीक्षा सूची बाट आफैलाई हटाउन सक्नुहुन्छ।",
      "overlapping_phone_and_email_p3": "विवरण को लागी कृपया तपाइँको ईमेल वा फोन जाँच गर्नुहोस्।",
      "overlapping_email_p3": "विवरण को लागी कृपया तपाइँको ईमेल जाँच गर्नुहोस्।",
      "overlapping_phone_p3": "विवरण को लागी कृपया तपाइँको फोन जाँच गर्नुहोस्।",
      "overlapping_email_p1": "हामीले तपाइँको ईमेल मा अर्को पुष्टिकरण पठायौं।"
    },
    "insist_guardian_consent": "म तर्फ बाट सहमति प्रदान गर्दै छु",
    "confirmation_section": {
      "title": "समीक्षा र पुष्टि गर्नुहोस्",
      "p1": "कृपया दर्ता पूरा गर्नु अघि तपाइँको जानकारी को समीक्षा गर्नुहोस्।",
      "edit_information": "फिर्ता जानुहोस् र सम्पादन गर्नुहोस्"
    },
    "iemodal": {
      "title": "असमर्थित ब्राउजर",
      "body": "दर्ता हाल यी ब्राउजरहरुमा सम्भव छ।"
    },
    "show_other_locations": "अन्य स्थानहरु देखाउन क्लिक गर्नुहोस्",
    "non_us_address": "यो एक अन्तर्राष्ट्रिय ठेगाना हो",
    "test_group_user_survey": "सहभागी स्क्रीनिंग",
    "self_consent": "म मेरो लागी सहमत छु।",
    "address_country": "देश",
    "appointment": "एपोइन्टमेन्ट",
    "employee_id": "कामदार आइडी",
    "appointment_time": "एपोइन्टमेन्ट समय",
    "appointment_location": "ठेगाना",
    "phone_or_email": "फोन नम्बर वा इमेल",
    "no_self_consent": "म मेरो लागि सहमति अस्वीकार गर्छु",
    "no_insist_guardian_consent": "म {{full_name}} को तर्फबाट सहमति अस्वीकार गर्छु",
    "additional_consents_helpertext": "थप सहमतिहरू पछि अनुरोध गर्न सकिन्छ",
    "minimum_age_with_months": "भाग लिनको लागि न्यूनतम उमेर {{year}} वर्ष र {{month}} महिना पुरानो हो।",
    "assistive_technology": "सहायक प्रविधि",
    "covid_vaccine_y_n": "के तपाईं यो बुकिङमार्फत COVID-19 खोप लगाउन चाहनुहुन्छ?",
    "received_covid_vaccine_y_n": "के तपाईंले पहिले नै कोभिड-१९ को खोप लिनुभएको छ?",
    "covid_vaccinations": "Covid-19 खोपहरू",
    "select_vaccine_dose": "कृपया तपाईंले प्राप्त गर्न चाहनुभएको खोपको खुराक चयन गर्नुहोस्",
    "demographic_info": "जनसाङ्ख्कीय जानकारी",
    "additional_info": "अतिरिक्त जानकारी",
    "self_described_race": "आत्म वर्णन रेस",
    "verify_contact_information": "प्रमाणीकरण",
    "verify_contact": {
      "we_sent_you_a_code": "कृपया हामीले {{contact}} मा पठाएको कोड प्रविष्ट गर्नुहोस्",
      "confirmation_code": "कन्फरमेसन / पुष्टि कोड",
      "code_resent": {
        "email": "तपाईंको इमेलमा अर्को कोड पठाइएको छ",
        "phone_number": "तपाईंको फोनमा अर्को कोड पठाइएको छ"
      },
      "did_not_receive_code": "कन्फरमेसन / पुष्टि कोड प्राप्त गर्नुभएन ?",
      "verify_by": {
        "email": "यसको सट्टा इमेल मार्फत प्रमाणित गर्नुहोस्",
        "phone_number": "यसको सट्टा फोन नम्बर मार्फत प्रमाणित गर्नुहोस्"
      }
    },
    "preferred_method_of_communication": {
      "question": "मनपर्ने संचार विधि",
      "helper_text": "हामी तपाईंलाई अपोइन्टमेन्ट अधवैधिकताको साथ सम्पर्क गर्नेछौं",
      "phone_number": "फोन (SMS सन्देश मात्र र डाटा दरहरू लागू हुन सक्छ)",
      "landline": "हामी यस समयमा ल्यान्डलाइनहरूलाई समर्थन गर्न सक्दैनौं। कृपया महत्त्वपूर्ण सञ्चारको लागि एउटा इमेल उपलब्ध गराउनुहोस् ।",
      "verification_method": "तपाईं आफ्नो प्रमाणीकरण कोड कसरी प्राप्त गर्न चाहनुहुन्छ?",
      "verification_helper_text": "लग इन गर्नु अघि तपाईको पहिचान प्रमाणित गर्नको लागि तपाईलाई कोड पठाइनेछ।"
    },
    "skip_for_now": "अहिलेको लागि छोड्नुहोस्",
    "default_information": {
      "title": "ठीक छ, हामी यो ठेगाना प्रयोग गर्नेछौं",
      "title_v2": "ठीक छ, हामीले यो सम्पर्क जानकारी प्रयोग गर्नेछौं"
    },
    "middle_name_label": "बीचको नाम",
    "confirm_appointment": "नियुक्ति पुष्टि गर्नुहोस्",
    "dob": "जन्म तिथि",
    "different_appointment": "फरक नियुक्ति",
    "select_appointment_time": "नियुक्तिको समय चयन गर्नुहोस् ",
    "decline_address": "म ठेगाना दिन अस्वीकार गर्छु। यसको सट्टा कार्यक्रमको ठेगाना प्रयोग गर्नुहोस्।",
    "patient_information": "रोगीको जानकारी",
    "scan_license": "चालकको अनुमतिपत्र स्क्यान गर्नुहोस्",
    "how_to_contact": "हामीले तपाईलाई कसरी सम्पर्क गर्नुपर्छ?",
    "decline_email_or_phone": "म इमेल वा फोन नम्बर प्रदान गर्न अस्वीकार गर्छु। म मेरो नतिजाहरू प्राप्त गर्न कार्यक्रम प्रशासकहरूलाई अधिकृत गर्छु।",
    "medical_screening": "चिकित्सा स्क्रीनिंग",
    "show_service_at_this_location": "यो स्थानमा सेवा देखाउनुहोस्",
    "verbal_consent_provided": "अमेरिकी असक्षमता ऐन अनुसार, सहभागी वा तिनीहरूको कानूनी रूपमा अधिकृत निर्णय निर्माताले मौखिक सहमति प्रदान गरे। लिखित सहमति 24 घण्टा भित्र सहभागी वा तिनीहरूको कानूनी रूपमा अधिकृत निर्णय निर्माताबाट प्राप्त गरिनेछ।",
    "unexpired_written_consent_present": "परीक्षण साइट संग फाइलमा म्याद समाप्त नभएको लिखित सहमति छ।",
    "select_to_proceed": "अगाडि बढ्नको लागि माथिको एक विकल्प चयन गर्नुहोस्।",
    "guardians_information": "संरक्षकको जानकारी",
    "registrars_information": "रजिस्ट्रारको जानकारी",
    "optional": "वैकल्पिक",
    "vfc_eligibility": "बालबालिकाका लागि खोप (VFC) योग्यता",
    "vfc_eligibility_subtext": "तपाईंको बच्चाले VFC कार्यक्रममा भर्ना भएका स्वास्थ्य सेवा प्रदायक कार्यालयहरू, औशधि पसलहरु र स्वास्थ्य क्लिनिकहरूमा CDC को भ्याक्सिन फर चिल्ड्रेन (VFC) कार्यक्रम मार्फत निशुल्क खोपहरू प्राप्त गर्न सक्षम हुन सक्छन्।"
  },
  "gender_key": {
    "male": "पुरुष",
    "female": "महिला",
    "cisgender": "सीआईएस लिङ्ग",
    "non_binary": "Genderqueer वा गैर बाइनरी",
    "other": "अन्य",
    "prefer_to_self_describe": "पहिचान सूचीबद्ध छैन",
    "prefer_not_to_disclose": "जवाफ दिन अस्वीकार गर्नुहोस्",
    "transgender_male": "ट्रान्स पुरुष/ ट्रान्सम्यान",
    "transgender_female": "ट्रान्स महिला/ ट्रान्स महिला",
    "unknown": "अज्ञात",
    "non_binary_only": "नन्-बाइनरी",
    "intersex": "इन्टरसेक्स",
    "transgender": "ट्रान्सजेन्डर",
    "gender_fluid": "जेंडर फ्लूइड",
    "not_applicable": "लागू हुँदैन"
  },
  "ethnicity": {
    "american_indian_alaska_native": "अमेरिकी भारतीय या अलास्का मूल निवासी",
    "american_indian_central_america": "दक्षिण वा मध्य अमेरिका बाट अमेरिकी भारतीय",
    "asian": "एसियन",
    "black": "कालो वा अफ्रीकी अमेरिकी",
    "latinx": "ल्याटिनो, ल्याटिनक्स वा हिस्पैनिक",
    "middle_eastern_north_african": "मध्य पूर्वी वा उत्तरी अफ्रिकी",
    "native_hawaiian_pacific_islander": "मूल निवासी हवाईयन वा प्रशान्त टापु",
    "white_european": "सेतो वा कोकेशियान",
    "unknown": "अज्ञात",
    "prefer_not_to_disclose": "खुलाउन मन नपर्ने",
    "asian_indian": "एशियाई भारतीय",
    "filipino": "फिलिपिनो",
    "japanese": "जापानी",
    "korean": "कोरियाली",
    "vietnamese": "भियतनामी",
    "other_asian": "अन्य एसियाली",
    "native_hawaiian": "मूल निवासी हवाईयन",
    "guamanian_or_chamorro": "गुआमानियन वा चामोरो",
    "samoan": "सामोन",
    "other_pacific_islander": "अन्य प्रशान्त टापु",
    "chinese": "चिनियाँ",
    "help": "दौड शारीरिक विशेषताहरु को एक समूह को बीच फरक गर्न को लागी प्रयोग गरीन्छ। दौड राष्ट्रियता संग प क्तिबद्ध हुन सक्छ वा हुन सक्दैन, वा देश जसमा तपाइँसँग नागरिकता छ।",
    "subtitle": "नियमहरु को आवश्यकता छ कि हामी निम्न जानकारी को सबै स कलन।",
    "laotian": "लाओटियन",
    "cambodian": "कम्बोडियन",
    "other": "अन्य",
    "bangladeshi": "बंगलादेशी",
    "hmong": "ह्मोंग",
    "indonesian": "इन्डोनेसियाली",
    "malaysian": "मलेसियाली",
    "pakistani": "पाकिस्तानी",
    "sri_lankan": "श्रीलंकाली",
    "thai": "थाई",
    "taiwanese": "ताइवानी",
    "fijian": "फिजियन",
    "guamanian": "गुआमियन",
    "tongan": "टोंगन",
    "bahamian": "बहामियन"
  },
  "languages": {
    "en": "अंग्रेजी",
    "fr": "फ्रेन्च",
    "es": "इस्पानोल",
    "so": "सुमाली",
    "hmn": "ह्मोंग",
    "asl": "अमेरिकी सांकेतिक भाषा (ASL)",
    "kar": "करेन",
    "am": "अम्हारिक",
    "ru": "रूसी",
    "om": "ओरोमो"
  },
  "symptoms": {
    "fever": "ज्वरो वा चिसो",
    "cough": "खोकी",
    "muscle_aches": "मांसपेशी दुख्छ",
    "severe_fatigue": "गम्भीर थकान (सामान्य भन्दा बढी)",
    "trouble_breathing": "सास फेर्न समस्या",
    "diarrhea": "पखाला",
    "loss_of_smell": "गन्ध को हानि",
    "loss_of_taste": "स्वाद को हानि",
    "shortness_of_breath": "सास फेर्न वा सास फेर्न कठिनाई",
    "headache": "टाउको दुखाई",
    "sore_throat": "दुखेको घाँटी",
    "congestion": "भीड वा नाक बग्ने",
    "nausea": "वाकवाकी लाग्ने वा बान्ता हुने",
    "close_contact": "एक संक्रमित व्यक्ति संग नजिक सम्पर्क*",
    "helper_text": {
      "close_contact": "*के तपाइँसँग कोभिड - १९  को पुष्टि भएको कसैसँग घनिष्ठ सम्पर्क छ (कम्तीमा १५ मिनेट को लागी फिट भित्र)?"
    },
    "suspected_exposure": "संदिग्ध एक्सपोजर",
    "none": "कुनै लक्षण छैन"
  },
  "instructions": {
    "title": "तपाइँ भर्ना हुनुहुन्छ तर तपाइँ जानु भन्दा पहिले ...",
    "p1": "यो पृष्ठ तपाइँको नियुक्ति को रूप मा साथै तपाइँको नियुक्ति बारकोड को बारे मा जानकारी छ।",
    "appointment_scheduled": "तपाइँको नियुक्ति अनुसूचित छ",
    "verify_info": {
      "title": "तपाइँको जानकारी प्रमाणित गर्नुहोस्",
      "p1": "यो तपाइँको सम्पर्क जानकारी को मान्य गर्न को लागी महत्वपूर्ण छ ताकि तपाइँ तपाइँको बिरामी पोर्टल लाई छिटो पहुँच गर्न सक्नुहुन्छ।",
      "p2": "एक पुष्टिकरण तपाइँको ईमेल पठाइएको छ। कृपया प्रदान गरिएको लिंक क्लिक गर्नुहोस्।",
      "p3": "यदि तपाइँ तपाइँको सम्पर्क जानकारी परिवर्तन गर्न चाहानुहुन्छ, कृपया मद्दत बटन थिच्नुहोस्।"
    },
    "verified": "प्रमाणित",
    "not_verified": "प्रमाणित छैन",
    "resend_text": "लिङ्क प्राप्त भएको छैन?",
    "resend": "पुन: पठाउनुहोस्",
    "skip": "प्रमाणीकरण छोड्नुहोस् र पुष्टि पुष्टि गर्नुहोस्",
    "name": "नाम",
    "appointment_details": "नियुक्ति",
    "date_and_time": "मिति र समय",
    "add_to_calendar": "क्यालेन्डरमा थप्नुहोस्",
    "instructions": "सामान्य निर्देश",
    "successfully_flash": "सफलतापूर्वक नियुक्ति बनाईयो!",
    "success_flash_email": "ईमेल सफलतापूर्वक प्रमाणित भयो",
    "success_flash_phone": "फोन नम्बर सफलतापूर्वक प्रमाणित भयो",
    "mail_order": "तपाइँ हप्ता भित्र मेल मा तपाइँको परीक्षण किट प्राप्त गर्नुहुनेछ।",
    "at_home": "एक चिकित्सा प्रदायक यस समय को आसपास तपाइँको घर मा आउनेछ",
    "at_home_instructions": "गृह निर्देश मा",
    "mail_order_instructions": "मेल आदेश निर्देशन",
    "request_additional_appointment": "बुक फलो-अप नियुक्ति",
    "book_additional_appointment": "अर्को नियुक्ति बुक गर्नुहोस्",
    "confirmation_code": "पुष्टि कोड",
    "completed": "पूरा भयो",
    "appointment_barcode": "नियुक्ति बारकोड",
    "dependent_links": "घरेलु नियुक्ति लिंक",
    "on_demand_title": "तपाइँ भर्ना हुनुहुन्छ तर तपाइँ जानु भन्दा पहिले ...",
    "save_link": "यो लिंक सेव",
    "verify_phone": "आफ्नो फोन नम्बर प्रमाणित गर्नुहोस्",
    "verify_phone_description": "यो तपाइँको सम्पर्क जानकारी मान्य गर्न को लागी महत्वपूर्ण छ ताकि तपाइँ तपाइँको रेकर्ड छिटो पहुँच गर्न सक्नुहुन्छ। एक प्रमाणिकरण पाठ सन्देश तपाइँको नम्बर पठाइएको छ।",
    "verify_email_description": "यो तपाइँको सम्पर्क जानकारी मान्य गर्न को लागी महत्वपूर्ण छ ताकि तपाइँ तपाइँको रेकर्ड छिटो पहुँच गर्न सक्नुहुन्छ। एक प्रमाणिकरण ईमेल तपाइँको ईमेल ठेगाना पठाइएको छ।",
    "information": "दर्ता जानकारी",
    "follow_up_appointment": "अपोइन्टमेन्ट पछ्याउनुहोस्",
    "get_directions": "दिशा पाउनुहोस्",
    "cancel": "नियुक्ति रद्द गर्नुहोस्",
    "reschedule_appointment": "पुनः कार्यसूची बनाउनु नियुक्ति",
    "reschedule_linked_appointments": "भेटघाट पुनः अनुसूची गर्नुहोस्",
    "no_slots": "अब कुनै पनी उपलब्ध छैन",
    "check_results": "रोगी पोर्टल जाँच गर्नुहोस्",
    "follow_up_modal_header": "कृपया तपाइँको दोस्रो खुराक अनुसूची गर्नुहोस्",
    "are_you_sure_you_want_to_cancel": "के तपाइँ पक्का यो भेट रद्द गर्न चाहनुहुन्छ?",
    "please_choose_cancellation_reason": "कृपया तल रद्द कारण चयन",
    "additional_details": "कृपया तल कुनै अतिरिक्त जानकारी थप्नुहोस्",
    "errors": {
      "missing_cancellation_reason": "कृपया रद्द गर्ने कारण छान्नुहोस्"
    },
    "verify_email": "तपाइँको ईमेल प्रमाणित गर्नुहोस्",
    "redcap_url": "तपाइँको सर्वेक्षण पूरा गर्न क्लिक गर्नुहोस्",
    "verify_contact_information": "तपाइँको सम्पर्क जानकारी प्रमाणित गर्नुहोस्",
    "please_also": "कृपया",
    "new_title": "{{name}} को लागि पुष्टिकरण",
    "contact_method": "सम्पर्क विधि",
    "next_steps": {
      "title": "अर्को चरणहरू",
      "p1": "तपाईंसँग सक्रिय गर्न परीक्षण किट छ। जब तपाईं आफ्नो परीक्षा दिन तयार हुनुहुन्छ, यो पृष्ठमा फर्कन र तल आफ्नो किट सक्रिय गर्न तपाईंको इमेलमा पठाइएको लिङ्क पछ्याउनुहोस्।",
      "administered": "We will contact you when your kit reaches the lab and when your results are ready. Results typically take 24-48 hours after they reach the lab. You can view results from your [Medical history]({{link}}) page when they are ready.",
      "resulted": "नतिजाहरू तपाईंको [मेडिकल इतिहास]({{link}}) पृष्ठमा उपलब्ध छन्।",
      "shipping_information": "ढुवानी जानकारी"
    },
    "save_this_page": {
      "title": "यो पृष्ठ बचत गर्नुहोस्",
      "p1": "एक आत्म परीक्षण रिपोर्ट गर्न वा चिकित्सा इतिहास मार्फत परिणाम हेर्न यो पृष्ठ प्रयोग गर्नुहोस्।",
      "p2": "भविष्यमा यो पृष्ठ पहुँच गर्न तपाईंको पुष्टिकरण इमेल वा SMS पाठमा लिङ्कमा क्लिक गर्नुहोस्।",
      "p3": "तपाईंले यो पृष्ठलाई बुकमार्क गरेर, गृह स्क्रिनमा थपेर वा सुरक्षित ठाउँमा लिङ्क प्रतिलिपि गरेर सुरक्षित गर्न सक्नुहुन्छ।"
    },
    "show_my_barcode": "मेरो बारकोड देखाउनुहोस्",
    "my_account": "मेरो खाता",
    "register_another": "अर्को सहभागी दर्ता गर्नुहोस्",
    "update_vaccine": "खोप जानकारी अद्यावधिक गर्नुहोस्",
    "medical_history": "चिकित्सा इतिहास",
    "upcoming_appointments": "आगामी अपोइन्टमेन्टहरु",
    "reschedule": "पुन: तालिका",
    "resend_confirmation": "पुष्टिकरण पुन: पठाउनुहोस्",
    "appointment_details_v2": "अपोइन्टमेन्ट विवरण",
    "confirm_cancellation": "रद्द पुष्टि गर्नुहोस्",
    "confirm_cancellation_question": "के तपाइँ यो अपोइन्टमेन्ट रद्द गर्न निश्चित हुनुहुन्छ?",
    "select_new_appointment_time_below": "तलको नयाँ अपोइन्टमेन्ट समय चयन गर्नुहोस्। यदि तपाईंलाई सेवाहरू वा अपोइन्टमेन्ट स्थान परिमार्जन गर्न आवश्यक छ भने, कृपया यो अपोइन्टमेन्ट रद्द गर्नुहोस् र नयाँ समय तालिका बनाउनुहोस्।",
    "no_take_me_back": "होइन, मलाई फिर्ता लिनुहोस्",
    "yes_cancel_appointment": "हो, अपोइन्टमेन्ट रद्द गर्नुहोस्",
    "update_appointment": "अपोइन्टमेन्ट अद्यावधिक गर्नुहोस्",
    "select_new_appointment_time": "नयाँ अपोइन्टमेन्ट समय चयन गर्नुहोस्",
    "clinic": "क्लिनिक",
    "services": "सेवाहरू",
    "appointment_missed": "अपोइन्टमेन्ट छुटेको छ",
    "appointment_canceled": "अपोइन्टमेन्ट रद्द गरियो",
    "screening_complete_for": "Screening complete for {{name}}"
  },
  "result_page": {
    "errors": {
      "invalid_access_code": "अमान्य पहुँच कोड"
    },
    "landing": {
      "p1": "तपाइँको नतिजा हेर्न को लागी, कृपया प्रविष्ट गर्नुहोस्:",
      "access_code_label": "पहुँच कोड",
      "submit_button": "पेस गर्नुहोस्",
      "recent_results": "तपाइँको भर्खरको परिणाम"
    },
    "view_results": {
      "headline": "{{name}} को लागी परीक्षण परिणाम",
      "reregister": "पुन: दर्ता गर्नुहोस्",
      "status": {
        "likely_positive": "परिणाम अनिश्चित",
        "test_not_performed": "पुन: परीक्षण आवश्यक छ",
        "results_ready": "नतिजा तयार छ",
        "processing": "प्रशोधन",
        "test_status": "परीक्षण स्थिति",
        "test_result": "परीक्षण परिणाम",
        "administered": "प्रबन्ध गरिएको",
        "results_pending": "नतिजा विचाराधीन छ",
        "test_results": "परीक्षण परिणामहरू"
      },
      "at": "{{address}} मा"
    },
    "result_label": "परिणाम",
    "result": {
      "flu_a": {
        "result_label": "फ्लू A रिजल्ट"
      },
      "flu_b": {
        "result_label": "फ्लू B रिजल्ट"
      },
      "covid19": {
        "result_label": "COVID-19 रिजल्ट"
      },
      "covid": {
        "result_label": "COVID-19 रिजल्ट"
      },
      "sars": {
        "result_label": "SARS को नतिजा"
      },
      "control": {
        "result_label": "नियन्त्रण"
      },
      "rsv": {
        "result_label": "RSV परिणाम"
      },
      "result": {
        "result_label": "नतिजा"
      },
      "hba1c": {
        "normal": "सामान्य",
        "warning": "पूर्व मधुमेह",
        "danger": "मधुमेह"
      },
      "lead_venous": {
        "danger": "असामान्य",
        "normal": "सामान्य"
      },
      "tc": {
        "result_label": "TC परिणाम"
      },
      "hdl": {
        "result_label": "HDL परिणाम"
      },
      "trg": {
        "result_label": "TRG परिणाम"
      },
      "ldl": {
        "result_label": "LDL परिणाम"
      },
      "non_hdl": {
        "result_label": "गैर-HDL परिणाम"
      },
      "tc_hdl_ratio": {
        "result_label": "TC/HDL अनुपात"
      },
      "glu": {
        "result_label": "GLU परिणाम"
      },
      "alere_cholestech_ldx": {
        "danger": "जोखिममा",
        "warning": "असामान्य",
        "normal": "सामान्य"
      },
      "lead": {
        "result_label": "सीसा"
      },
      "zinc": {
        "result_label": "जस्ता"
      },
      "lead_standard_profile": {
        "danger": "असामान्य",
        "normal": "सामान्य"
      },
      "creatinine": {
        "danger": "असामान्य",
        "normal": "सामान्य"
      },
      "igg": {
        "result_label": "IgG परिणाम"
      },
      "igm": {
        "result_label": "IgM परिणाम"
      },
      "blood_glucose_fasted": {
        "normal": "सामान्य",
        "warning": "उच्च",
        "danger": "उच्च",
        "hypoglycemia": "हाइपोग्लाइसेमिया",
        "prediabetes": "पूर्व मधुमेह",
        "diabetes": "मधुमेह"
      },
      "total_cholesterol_fasted": {
        "normal": "सामान्य",
        "elevated": "उच्च",
        "high": "उच्च",
        "low": "निम्न",
        "abnormal": "असामान्य"
      },
      "total_cholesterol_unfasted": {
        "normal": "सामान्य",
        "elevated": "उच्च",
        "high": "उच्च"
      },
      "a1c_now": {
        "normal": "सामान्य",
        "warning": "पूर्व-मधुमेह",
        "danger": "मधुमेह"
      },
      "blood_glucose": {
        "warning": "कम",
        "normal": "सामान्य",
        "prediabetes": "पूर्व मधुमेह",
        "danger": "मधुमेह",
        "hypoglycemia": "हाइपोग्लाइसेमिया",
        "medical_emergency": "चिकित्सा आपतकाल",
        "inconclusive_1": "निचोडमा पुगिएन 1",
        "inconclusive_2": "निचोडमा पुगिएन 2",
        "possible_diabetes": "सम्भावित मधुमेह"
      },
      "triglycerides": {
        "result_label": "ट्राइग्लिसराइड्स"
      },
      "blood_glucose_ucsf": {
        "low": "कम",
        "normal": "सामान्य",
        "high": "उच्च"
      },
      "syphilis": {
        "result_label": "सिफिलिस परिणाम"
      },
      "hepatitis_c": {
        "result_label": "हेपाटाइटिस C परिणाम"
      },
      "hiv": {
        "result_label": "HIV परिणाम"
      },
      "rapid_hiv": {
        "positive": "प्रतिक्रियाशील (प्रारम्भिक सकारात्मक)",
        "negative": "गैर-प्रतिक्रियाशील (नकारात्मक)",
        "did_not_result": "अमान्य (अविनिश्चित)"
      },
      "rapid_hcv": {
        "positive": "प्रतिक्रियाशील (प्रारम्भिक सकारात्मक)",
        "negative": "गैर-प्रतिक्रियाशील (नकारात्मक)",
        "did_not_result": "अमान्य (अविनिश्चित)"
      },
      "rapid_syphilis": {
        "positive": "प्रतिक्रियाशील (प्रारम्भिक सकारात्मक)",
        "negative": "गैर-प्रतिक्रियाशील (नकारात्मक)",
        "did_not_result": "अमान्य (अविनिश्चित)"
      }
    },
    "documents": "कागजातहरू",
    "self_administered": "स्वयम् प्रबन्ध गरिएको जाँच {{location}} मा गरिएको थियो",
    "patient": "बिरामी",
    "medical_history": "चिकित्सा इतिहास",
    "overview_title": "थप विवरणहरू र आवश्यक पर्ने कुनै पनि अतिरिक्त कार्य वस्तुहरू हेर्न परीक्षण वा सेवा चयन गर्नुहोस्। ध्यान दिनुहोस् कि केहि परीक्षण परिणामहरू अझै उपलब्ध नहुन सक्छ वा प्रदायकसँग कुरा गरेपछि मात्र उपलब्ध हुनेछ।",
    "insurance_information": "बीमा जानकारी भर्नुहोस्",
    "contact_support": "यदि तपाईंलाई सहयोग चाहिन्छ भने, कृपया समर्थनलाई सम्पर्क गर्नुहोस्।",
    "show": "देखाउनुहोस्",
    "hide": "लुकाउनुहोस्",
    "lab_report": "प्रयोगशाला रिपोर्ट",
    "contact_provider": {
      "header": "तपाईंको परिणाम सकारात्मक छ\r\n",
      "description": "के तपाइँ तपाइँको नतिजा र उपचार बारे छलफल गर्न प्रदायकसँग कुरा गर्न चाहनुहुन्छ?",
      "yes_option_text": "हो, म प्रदायकसँग कुरा गर्न चाहन्छु",
      "no_option_text": "होइन, मैले मेरा नतिजाहरू पढेको र बुझेको छु, र प्रदायकसँग कुरा गर्न चाहन्न",
      "confirm_phone_number_header": "आफ्नो फोन नम्बर पुष्टि गर्नुहोस्",
      "confirm_phone_number_description": "कृपया तपाईंको परामर्शको लागि उत्तम फोन नम्बर पुष्टि गर्नुहोस्।",
      "confirm_button_text": "पुष्टि गर्नुहोस्",
      "consultation_confirmed_header": "परामर्श पुष्टि भयो",
      "consultation_confirmed_description": "एक प्रदायकले तपाईंलाई 2-3 व्यापार दिन भित्र {{phone_number}} मा कल गर्नेछ।",
      "acknowledgement_option_helper_text": "केहि चीज जसले उपचारको महत्त्वलाई बताउँछ र प्रयोगकर्तालाई उनीहरूको मन परिवर्तन गरेमा कल कसरी अनुसूचित गर्ने भनेर थाहा दिन्छ।\r\n",
      "acknowledgement_confirmed_header": "स्वीकृति पुष्टि भयो",
      "acknowledgement_confirmed_description": "लिङ्क गरिएका स्रोतहरूसँग उपचार पाउनुको महत्त्वको बारेमा केहि कुरा। सम्झाउनुहोस् कि परामर्श पूर्णतया नि: शुल्क छ र प्रदायकले तिनीहरूलाई संक्रमण समाधान गर्न मद्दत गर्नको लागि सिफारिस गर्न वा अरू जे पनि गर्न सक्छ।",
      "acknowledgement_confirmed_change_mind_text": "यदि तपाईंले आफ्नो मन परिवर्तन गर्नुभयो भने, तलको \"मलाई परामर्श चाहिए\" मा क्लिक गर्नुहोस्।",
      "request_consultation_button_text": "म परामर्श चाहन्छु"
    },
    "phone": "फोन",
    "source": "Source"
  },
  "required": {
    "errors": {
      "address_not_in_bounding_box": "तपाइँको ठेगाना यस कार्यक्रम मा साइटहरु को लागी योग्यता आवश्यकताहरु संग मेल खाँदैन।"
    }
  },
  "member": {
    "medical_history": "हिस्ट्री",
    "view_instructions": "पुष्टिकरण हेर्नुहोस्",
    "next_appointment": "अर्को नियुक्ति",
    "over_18": "म प्रमाणित गर्दछु कि म  १८  बर्ष वा माथिको छु",
    "choose_test_configurations_title": "कृपया सेवा (हरू) तपाइँ यो नियुक्ति को लागी चाहानुहुन्छ चयन गर्नुहोस्",
    "member_taken_error": "प्रयोगकर्ता पहिले नै एक खाता छ",
    "choose_test_configurations_subtitle": "सेवा (हरू) घरका सबै सदस्यहरुलाई लागू गरिनेछ",
    "service": "सेवा",
    "group_or_location": "समुह/ठेगाना",
    "details": "विवरण",
    "see_all": "पुरै हेर्नुहोस्",
    "no_history": "यस समयमा कुनै इतिहास उपलब्ध छैन।",
    "medical_history_title_with_name": "{{name}} को हिस्ट्री",
    "no_dashboard": "अहिले केही उपलब्ध छैन",
    "product": "उत्पादन",
    "price": "मूल्य",
    "quantity": "मात्रा",
    "total_services_selected": "कुल सेवाहरू चयन गरियो",
    "total_price": "कुल रकम"
  },
  "or": "वा",
  "account": {
    "errors": {
      "must_be_13": "एक खाता को लागी दर्ता गर्न को लागी १३ बर्ष को हुनु पर्छ"
    }
  },
  "self_administration": {
    "title": "स्व-प्रशासन",
    "self_administer_action": "स्व-प्रशासक",
    "skip_to_link": "स्व-प्रशासनमा जानुहोस्",
    "select_person": "जाँच गर्न को लागी एक व्यक्ति छान्नुहोस्",
    "adult": "वयस्क",
    "child": "बच्चा",
    "checked_out": "चेक आउट",
    "go_back": "पछि जान्नुहोस्",
    "switch_to_scanner": "स्क्यानरमा स्विच गर्नुहोस्",
    "enter_barcode": "बारकोड इन्टर गर्नुहोस्",
    "scan_barcode": "बारकोड स्कान गर्नुहोस्",
    "cancel": "रद्द",
    "barcode_for": "बारकोडको",
    "enter_barcode_manually": "म्यानुअल रूपमा बारकोड इन्टर गर्नुहोस्",
    "instructions": "तपाईंको टेस्ट ट्यूब तल रहेको बारकोड स्क्यान वा इन्टर गर्नुहोस्",
    "regex_prefix": "बारकोड पुरा समावेश",
    "all_completed": "हुनुपर्छ: सबै घरपरिवारको सदस्यले यो चरण पुरा गर्नुपर्छ",
    "errors": {
      "no_appointment": "एपोइन्टमेन्ट पत्ता लगाउँदा समस्या आयो",
      "barcode_in_use": "यो बारकोड पहिल्लानै प्रयोग भइसकेको छ। यदि तपाईंले पहिले यो किटको साथ् स्वय प्रशासन गर्नुभएन भने कृपया तपाईंलाई किट दिने साइटमा कर्मचारीहरूसँग कुरा गर्नुहोस् र नयाँ किटको लागि सोध्नुहोस्। नत्र, हेल्प बटनमा क्लिक गर्नुहोस्।",
      "test_error_general": "टेस्ट बनाउँदा समस्या भयो",
      "different_barcodes": "तपाईंले इन्टर गरेको बारकोड मिलेन। "
    },
    "confirm_barcode_input": "पक्का गर्न पुन बारकोड इन्टर गर्नुहोस्",
    "click_to_self_test": "स्वय परिक्षण गर्न यहाँ क्लिक गर्नुहोस्",
    "or_enter_barcode_manually": "Or enter manually with all letters and numbers"
  },
  "location_details": {
    "next_appointment": {
      "waitlist": "प्रतिक्षा सूची",
      "unavailable": "कुनै नियुक्ति उपलब्ध छैन"
    },
    "labels": {
      "address": "ठेगाना",
      "next_appointment": "अर्को उपलब्ध नियुक्ति",
      "services": "सेवाहरु उपलब्ध छन्"
    }
  },
  "waiting_room": {
    "header": "तपाइँ पर्खाइ कोठा मा हुनुहुन्छ; कृपया पर्खनुहोस् जब हामी तपाइँलाई अनुसूची प्रणाली संग जोड्छौं।",
    "p1": "कृपया धैर्य गर्नुहोस्। हामी मिलेर यो महामारीको अन्त्य गर्नेछौं।",
    "signature": "भवदीय",
    "p2": "हामीलाई थाहा छ COVID-19   अनुभव निराशाजनक हुन सक्छ। कृपया यहाँ पर्खनुहोस् जब हामी छिटो भन्दा छिटो काम गर्न को लागी हामी तपाइँको साइट मा तपाइँको नियुक्ति बुक गर्न को लागी गर्न सक्छौं।"
  },
  "users": {
    "send_appointment_confirmation_message": "नमस्कार {{full_name}}। यो {{name}} मा तपाइँको नियुक्ति को लागी एक अनुस्मारक हो। तपाइँको नियुक्ति {{date}} मा {{time}} मा {{address}} मा छ।",
    "send_registration_link": "नमस्कार, {{full_name}}। यो लिंक पालना आफ्नो {{name}} {{registration_link}} लागि दर्ता"
  },
  "follow_up": {
    "first_dose": "पहिलो खुराक",
    "previous_appointment": "विगत नियुक्ति",
    "booked_appointment": "तपाइँको नियुक्ति",
    "second_dose": "दोस्रो खुराक",
    "choose_second_location": "एक दोस्रो नियुक्ति स्थान छान्नुहोस्"
  },
  "cancellation_types": {
    "cant_get_to_location": "मसँग समयको द्वन्द्व थियो",
    "timing_conflict": "म COVID-19 बाट बिरामी परें",
    "became_ill_with_covid19": "मैले यो सेवा अन्यत्र पाएँ",
    "received_service_elsewhere": "अन्य",
    "other": "बरु नाम टाइप गर्नुहोस्",
    "duplicate_appointment": "यो डुप्लिकेट अपोइन्टमेन्ट थियो"
  },
  "translation": {
    "consent": {
      "type_name_instead": "नाम टाइप गर्नुहोस्",
      "type_name": "बरु हस्ताक्षर टाइप गर्नुहोस्"
    }
  },
  "email": {
    "confirmation": {
      "your_access_code_is": "तपाइँको पहुँच कोड छ",
      "please_confirm_your_email": "कृपया तलको बटन क्लिक गरेर आफ्नो ईमेल पुष्टि गर्नुहोस्",
      "hello": "नमस्कार",
      "click_to_reschedule": "निर्देशन/पुनः अनुसूची देखाउन क्लिक गर्नुहोस्",
      "click_to_reschedule_on_demand": "निर्देशन देखाउन क्लिक गर्नुहोस्"
    },
    "cancellation": {
      "your_appointment_has_been_canceled": "तपाईंको {{time}} {{location}} मा अपोइन्टमेन्ट रद्द गरिएको छ। यदि यो गल्ती हो भने, कृपया support@primary.health मा इमेल गर्नुहोस्"
    }
  },
  "signature_lines": {
    "name": "सहभागी को नाम",
    "date": "मिति",
    "signature": "सहभागीको हस्ताक्षर",
    "and_or": "र/वा",
    "guardian_name": "अभिभावक/अभिभावक को नाम",
    "guardian_signature": "अभिभावक/अभिभावक को हस्ताक्षर"
  },
  "employer_testing": {
    "hello_name": "नमस्कार {{name}}",
    "results": {
      "negative": "नकारात्मक",
      "positive": "सकारात्मक",
      "did_not_result": "अज्ञात",
      "unknown": "अज्ञात",
      "invalid": "इनव्यालिड",
      "pending": "विचाराधीन"
    },
    "code_reader": {
      "scan_your_code": "तपाइँको परीक्षण कार्ड मा कोड स्क्यान गर्नुहोस्",
      "find_in_top_right_hand_corner": "स्क्यान कोड तपाइँको परीक्षण को लागी अद्वितीय छ र तपाइँको परीक्षण को शीर्ष दाहिने हात कुनामा पाउन सकिन्छ",
      "center_code": "यहाँ कोड केन्द्र।",
      "already_used_error": "यो परीक्षण बारकोड पहिले नै प्रयोग गरीएको छ। समर्थन को लागी तपाइँको टेस्ट किट प्रदायक लाई सम्पर्क गर्नुहोस्।",
      "click_to_scan": "QR कोड स्क्यान गर्न क्लिक गर्नुहोस्",
      "scan_new_test_card": "नयाँ टेस्ट कार्ड स्क्यान गर्न यहाँ क्लिक गर्नुहोस्।",
      "not_working": "काम गरिरहेको छैन?",
      "try_again": "फेरि प्रयास गर्न यहाँ क्लिक गर्नुहोस्।"
    },
    "continue": "जारी राख्नुहोस्",
    "verify_date_of_birth": {
      "confirm_date_of_birth": "कृपया जारी राख्न को लागी सहभागी को जन्म मिति पुष्टि गर्नुहोस्।",
      "contact_administrator": "यदि प्रदर्शित जानकारी गलत छ, सहायता को लागी एक प्रशासक लाई सम्पर्क गर्नुहोस्।",
      "error": "प्रविष्ट गरिएको जन्म मिति गलत छ - कृपया पुन: प्रयास गर्नुहोस् वा सम्पर्क गर्नुहोस्। तपाइँको साइट प्रशासक यो अपडेट गर्न को लागी।"
    },
    "result_entry": {
      "code_is_registered": "तपाइँको कोड दर्ता छ।",
      "take_the_test": "परिक्षा लिनुहोस्",
      "follow_the_instructions": "निर्देशनहरु पालना गर्नुहोस् कि तपाइँको परीक्षण संग आउनुभयो र तपाइँको १५ मिनेट टाइमर शुरू एक पटक तपाइँ परीक्षण कार्ड मा शीशी मा नाक स्वाब प्रविष्ट गर्नुभयो।",
      "cant_find_instructions": "निर्देशन पाउन सक्नुहुन्न?",
      "view_instructions": "Binax अब निर्देशन हेर्नुहोस्",
      "start_timer": "सुरु १५ मिनेट टाइमर (वैकल्पिक)",
      "submit_within_15": "सुझाव: १५ मिनेट भित्र आफ्नो नतिजा पेस गर्नुहोस्",
      "enter_test_results": "परीक्षण परिणाम प्रविष्ट गर्नुहोस्",
      "choose_a_result": "विकल्प छान्नुहोस् कि तपाइँको परीक्षण कार्ड परिणाम वर्णन गर्दछ",
      "submit": "पेस गर्नुहोस्",
      "must_log_result_and_photo": "तपाइँ एक नतिजा लग इन गर्नुहोस् र जारी राख्न को लागी तपाइँको परीक्षण को एक फोटो लिनु पर्छ",
      "submit_within_15_v2": "सुझाव: परीक्षण १५ मिनेट को लागी चलेको छ पछि तपाइँको नतिजा सबमिट गर्नुहोस्",
      "click_to_take_photo": "फोटो लिनको लागी क्लिक गर्नुहोस्",
      "results_may_be_invalid": "परीक्षण नतिजा अमान्य हुन सक्छ"
    },
    "summary": {
      "title": "परिणाम सारांश",
      "negative_subtitle": "तपाइँसँग COVID-19 छैन",
      "negative_message": "हामीले तपाइँको रोजगारदाता लाई एउटा सूचना पठाइसकेका छौ कि तपाइँ अनसाइट मा काम गर्न को लागी फिर्ता आउन को लागी सफा हुनुहुन्छ।",
      "positive_subtitle": "हामी क्षमाप्रार्थी छौं, तर तपाइँसँग COVID-19 छ",
      "positive_message": "हामीले तपाइँको रोजगारदातालाई एउटा सूचना पठायौं कि तपाइँसँग COVID-19 छ।",
      "what_should_you_do": "तपाइँ के गर्नु पर्छ:",
      "employer_name_resources": "रोजगारदाता नाम संसाधन:",
      "follow_cdc_guidelines": "सीडीसी सिफारिश दिशानिर्देश पालना गर्नुहोस्"
    },
    "clarifying_results": {
      "title": "तपाइँको नतिजाहरु स्पष्ट पार्दै",
      "which_did_your_result_most_look_like": "तपाइँको नतिजा कुन जस्तो लाग्यो?",
      "no_lines": "कुनै लाइन देखिएन",
      "blue_control_line_only": "नीलो नियन्त्रण लाइन मात्र",
      "pink_sample_line_only": "गुलाबी नमूना लाइन मात्र",
      "blue_control_line_and_pink_sample_line": "नीलो नियन्त्रण रेखा र गुलाबी/बैजनी नमूना लाइन",
      "still_not_sure": "अझै पक्का छैन",
      "submit_results": "नतिजा पेस गर्नुहोस्",
      "thank_you": "धन्यवाद!",
      "clarification_received_message": "तपाइँको परिणाम स्पष्टीकरण प्राप्त भएको छ।",
      "only_pink_line": "पिङ्क लाइन मात्र",
      "no_lines_v2": "लाइन छैन्"
    },
    "return_to_confirmation": "पुष्टिकरण पृष्ठ मा फर्कनुहोस्"
  },
  "preferred_language": {
    "title": "मनपर्ने भाषा",
    "subtitle": "तपाईंलाई कुन भाषा मन पर्छ?"
  },
  "appointment_type": {
    "initial_vaccine_appointment": "प्रारम्भिक खोप श्रृंखला",
    "one_dose": "एक विशिष्ट खुराक को लागी एक नियुक्ति बुक गर्नुहोस्। विशेष गरी, यो मेरो हो",
    "title": "खुराक चयन",
    "first_dose": "पहिलो खुराक",
    "second_dose": "दोस्रो खुराक",
    "single_dose_title": "एकल खुराक",
    "additional": "थप रूपमा",
    "booster": "बूस्टर",
    "supplemental": "सप्ल्लीमेन्टल",
    "third_dose": "तेस्रो मात्रा मात्र",
    "no_vaccine": "माथिको कुनै पनि छैन"
  },
  "activemodel": {
    "errors": {
      "models": {
        "importers/results/row": {
          "attributes": {
            "administered_at": {
              "invalid_datetime": "ढाँचा {{datetime_format}} हुनु पर्छ"
            },
            "results_at": {
              "invalid_datetime": "ढाँचा {{datetime_format}} हुनु पर्छ"
            },
            "uid": {
              "unknown_test": "परीक्षण भेटिएन"
            }
          }
        },
        "importers/participants/row": {
          "attributes": {
            "access_code": {
              "not_allow_update_test_group_user": "प्रयोगकर्ता अवस्थित छ र यसलाई अपडेट गर्न को लागी अनुमति छैन"
            },
            "date_of_birth": {
              "invalid_date": "ढाँचा {{date_format}} हुनु पर्छ"
            },
            "ethnicity": {
              "unknown_ethnicities": {
                "one": "{{unknown_ethnicities}} अवस्थित छैन, निम्न स्वीकार्य मानहरु बाट छान्नुहोस्: {{ethnicities}}",
                "other": "{{unknown_ethnicities}} अवस्थित छैन, निम्न स्वीकार्य मानहरु बाट छनौट गर्नुहोस्: {{ethnicities}}"
              }
            },
            "gender": {
              "unknown_gender": "लिंग अवस्थित छैन, निम्न स्वीकार्य मानहरु बाट छान्नुहोस्: {{genders}}"
            },
            "phone_number": {
              "invalid": "एक अवैध संख्या हो"
            },
            "population_name": {
              "unknown_population": "{{unknown_population}} अवस्थित छैन, निम्न स्वीकार्य मानहरु बाट छान्नुहोस्: {{population_names}}"
            },
            "self_described_gender": {
              "not_allowed": "यो भर्न सक्दैन यदि लि \"prefer_to_self_describe\" छैन।"
            },
            "sex": {
              "unknown_sex": "सेक्स अवस्थित छैन, निम्न स्वीकार्य मानहरु बाट छान्नुहोस्: {{sexes}}"
            },
            "sexual_orientation": {
              "unknown_sexual_orientation": "यौन अभिविन्यास अवस्थित छैन, निम्न स्वीकार्य मानहरु बाट छान्नुहोस्: {{sexual_orientations}}"
            },
            "status": {
              "unknown_status": "स्थिति अमान्य छ, निम्न स्वीकार्य मानहरु बाट छान्नुहोस्: {{statuses}}"
            },
            "races": {
              "unknown_races": {
                "one": "{{unknown_races}} अवस्थित छैन, निम्न स्वीकार्य मानहरु बाट छान्नुहोस्: {{races}}",
                "other": "{{unknown_races}} अवस्थित छैन, निम्न स्वीकार्य मानहरु बाट छान्नुहोस्: {{races}}"
              }
            },
            "vaccination_status": {
              "unknown_vaccination_status": "vaccination status is invalid, pick from the following acceptable values: {{vaccination_statuses}}"
            }
          }
        },
        "importers/organization_user/row": {
          "attributes": {
            "role": {
              "insufficient_privileges": "भूमिका सेट गर्न अपर्याप्त विशेषाधिकार"
            },
            "user_id": {
              "unknown_user": "प्रयोगकर्ता भेटिएन"
            }
          }
        },
        "importers/additional_information/row": {
          "attributes": {
            "additional_information": {
              "invalid_json": "अमान्य JSON"
            },
            "user_id": {
              "unknown_user": "प्रयोगकर्ता भेटिएन"
            }
          }
        },
        "importers/emr/row": {
          "attributes": {
            "appointment_location": {
              "unknown_location": "भेट्ने स्थान भेटिएन"
            },
            "date_of_birth": {
              "invalid_date": "ढाँचा {{date_format}} हुनु पर्छ"
            },
            "email": {
              "duplicated": "पहिले नै प्रयोग गरीएको छ"
            },
            "ethnicity": {
              "unknown_ethnicities": {
                "one": "{{unknown_ethnicities}} अवस्थित छैन, निम्न स्वीकार्य मानहरु बाट छान्नुहोस्: {{ethnicities}}",
                "other": "{{unknown_ethnicities}} अवस्थित छैन, निम्न स्वीकार्य मानहरु बाट छनौट गर्नुहोस्: {{ethnicities}}"
              }
            },
            "gender": {
              "unknown_gender": "लिंग अवस्थित छैन, निम्न स्वीकार्य मानहरु बाट छान्नुहोस्: {{genders}}"
            },
            "phone_number": {
              "invalid": "एक अवैध संख्या हो"
            },
            "population": {
              "unknown_population": "{{unknown_population}} अवस्थित छैन, निम्न स्वीकार्य मानहरु बाट छान्नुहोस्:  {{population_names}}"
            },
            "self_described_gender": {
              "not_allowed": "यो भर्न सक्दैन यदि लि \"prefer_to_self_describe\" छैन।"
            },
            "sex": {
              "unknown_sex": "सेक्स अवस्थित छैन, निम्न स्वीकार्य मानहरु बाट छान्नुहोस्: {{sexes}}"
            },
            "sexual_orientation": {
              "unknown_sexual_orientation": "यौन अभिविन्यास अवस्थित छैन, निम्न स्वीकार्य मानहरु बाट छान्नुहोस्: {{sexual_orientations}}"
            },
            "races": {
              "unknown_races": {
                "one": "{{unknown_races}} अवस्थित छैन, निम्न स्वीकार्य मानहरु बाट छान्नुहोस्: {{races}}",
                "other": "{{unknown_races}} अवस्थित छैन, निम्न स्वीकार्य मानहरु बाट छान्नुहोस्: {{races}}"
              }
            }
          }
        },
        "importers/additional_consents/row": {
          "attributes": {
            "consent_form_id": {
              "unknown_consent_form": "consent_form not found"
            },
            "access_code": {
              "unknown_user": "user not found"
            }
          }
        },
        "importers/vaccine/row": {
          "attributes": {
            "date_of_birth": {
              "invalid_date": "should have format {{date_format}}"
            },
            "phone_number": {
              "invalid": "is an invalid number"
            },
            "email": {
              "duplicated": "is already used"
            },
            "races": {
              "unknown_races": {
                "one": "{{unknown_races}} does not exist, pick from the following acceptable values: {{races}}",
                "other": "{{unknown_races}} do not exist, pick from the following acceptable values: {{races}}"
              }
            },
            "gender": {
              "unknown_gender": "gender does not exist, pick from the following acceptable values: {{genders}}"
            },
            "sexual_orientation": {
              "unknown_sexual_orientation": "sexual_orientation does not exist, pick from the following acceptable values: {{sexual_orientations}}"
            },
            "self_described_gender": {
              "not_allowed": "cannot fill it if the gender is not \"prefer_to_self_describe\""
            },
            "sex": {
              "unknown_sex": "sex does not exist, pick from the following acceptable values: {{sexes}}"
            },
            "ethnicity": {
              "unknown_ethnicities": {
                "one": "{{unknown_ethnicities}} does not exist, pick from the following acceptable values: {{ethnicities}}",
                "other": "{{unknown_ethnicities}} do not exist, pick from the following acceptable values: {{ethnicities}}"
              }
            },
            "tag_action": {
              "unknown": "{{unknown_tag_action}} is an invalid value, pick from the following acceptable values: {{tag_actions}}"
            },
            "population": {
              "unknown_population": "{{unknown_population}} does not exist, pick from the following acceptable values: {{population_names}}"
            },
            "appointment_location": {
              "unknown_location": "appointment location not found"
            },
            "appointment_date": {
              "invalid_date": "should have format {{date_format}}"
            },
            "dose_type": {
              "invalid_dose": "{{unknown_dose}} is not a valid dose, pick from the following acceptable values: {{acceptable_doses}}",
              "unavailable_dose": "{{dose_type}} is not available within the vaccine_lot, pick from the following available values: {{available_doses}}"
            },
            "vis_version_date": {
              "invalid_date": "should have format {{date_format}}"
            }
          }
        },
        "importers/base/row": {
          "attributes": {
            "appointment_time": {
              "invalid_time": "should have format {{time_format}}"
            }
          }
        }
      }
    }
  },
  "activerecord": {
    "errors": {
      "models": {
        "test_location": {
          "attributes": {
            "metadata": {
              "wrong_json": "is not a valid JSON"
            },
            "name": {
              "taken": "र ठेगाना पहिले नै अर्को अवस्थित स्थान मा प्रयोग गरीरहेको छ"
            }
          }
        }
      }
    },
    "attributes": {
      "organization_user": {
        "organization_user_leads": {
          "one": "User",
          "other": "Users"
        }
      },
      "appointment_slot_group": {
        "organization_user_leads": {
          "one": "Location",
          "other": "Locations"
        }
      }
    }
  },
  "hispanic_questions": {
    "hispanic": "हिस्पैनिक वा ल्याटिनो/एक",
    "not_hispanic": "हिस्पैनिक वा ल्याटिनो/ए",
    "hispanic_expanded": {
      "other": "अर्को हिस्पैनिक, ल्याटिनो/एक वा स्पेनिश मूल",
      "mexican": "मेक्सिकन, मेक्सिकन अमेरिकी, चिकानो / एक",
      "puerto_rican": "पोर्टो रिकन",
      "cuban": "क्युबा"
    },
    "subtitle": "नियमहरु को आवश्यकता छ कि हामी निम्न जानकारी स कलन",
    "help": "जातीयता सामान्य लक्षण जस्तै भाषा, सांस्कृतिक रीतिरिवाज, धर्म, र अन्य विशेषताहरु को एक समूह को फरक गर्न को लागी प्रयोग गरीन्छ को एक समूह लाई बुझाउँछ। यो हुन सक्छ वा हुन सक्दैन एक जातीय पहिचान संग प क्तिबद्ध।",
    "unknown": "अज्ञात",
    "title": "जातीयता"
  },
  "sex_at_birth": {
    "question": "सेक्स",
    "female": "महिला",
    "male": "पुरुष",
    "help": "सेक्स तपाइँको जन्म प्रमाणपत्र मा के छ जनाउँछ।",
    "non_binary": "गैर बाइनरी",
    "subtitle": "नियमहरु को आवश्यकता छ कि हामी निम्न जानकारी को सबै स कलन।",
    "decline": "जवाफ दिन अस्वीकार गर्नुहोस्",
    "unknown": "अज्ञात"
  },
  "gender": {
    "help": "लि Id्गको पहिचानले तपाइँ कसरी व्यक्तिगत रूपमा पहिचान गर्नुहुन्छ भनेर बुझाउँछ। यो हुन सक्छ वा सेक्स संग प क्तिबद्ध हुन सक्दैन तपाइँ जन्म मा असाइन गरीएको थियो।",
    "subtitle": "यदि तपाइँ तपाइँको लि ग पहिचान को बारे मा जानकारी थप्न चाहानुहुन्छ कृपया त्यसो गर्नुहोस्।"
  },
  "sexual_orientation": {
    "title": "यौन अभिविन्यास",
    "subtitle": "यदि तपाइँ तपाइँको यौन अभिविन्यास को बारे मा जानकारी थप्न चाहानुहुन्छ, कृपया त्यसो गर्नुहोस्।",
    "gay": "समलैंगिक, समलैंगिक, वा समलैंगिक",
    "heterosexual": "विषमलैंगिक वा सीधा",
    "bisexual": "उभयलिंगी",
    "questioning": "प्रश्न/अनिश्चित/थाहा छैन",
    "prefer_not_to_disclose": "खुलाउन नछोड्नुहोस्",
    "unknown": "अज्ञात",
    "orientation_not_listed": "अभिमुखीकरण सूचीबद्ध छैन",
    "not_applicable": "लागू हुँदैन",
    "pansexual": "पानसेक्सुअल",
    "queer": "क्विअर"
  },
  "pronouns": {
    "title": "मनपर्ने सर्वनाम",
    "he": "उहाँ/उहाँ",
    "she": "उनी/उनी",
    "they": "तिनीहरूले / तिनीहरूलाई",
    "choose_not_to_disclose": "खुलाउन नछोड्नुहोस्",
    "prefer_to_self_describe": "स्व-वर्णन गर्न मनपर्छ"
  },
  "no_insurance_information": {
    "driver_license_or_state_id": "चालक लाइसेन्स वा राज्य आईडी नम्बर",
    "driver_license": "चालक लाइसेन्स",
    "use_ssn_instead": "यसको सट्टा SSN प्रयोग गर्नुहोस्",
    "social_security_number": "सामाजिक सुरक्षा नम्बर",
    "state": "राज्य",
    "upload_front_of_driver_license": "तपाईंको ड्राइभर इजाजतपत्र वा राज्य आईडी नम्बरको अगाडि अपलोड गर्नुहोस्",
    "choose_file": "फाइल छान्नुहोस्",
    "no_file_chosen": "कुनै फाइल चयन गरिएको छैन",
    "no_identification": "मसँग परिचय छैन",
    "insurance_card_has_a_back": "मेरो बीमा कार्डको पछाडि छ",
    "upload_front_of_insurance_card": "तपाईंको बीमा कार्डको अगाडि अपलोड गर्नुहोस्।",
    "front_of_insurance_card_uploaded": "तपाईंको बीमा कार्डको अगाडि अपलोड गरिएको छ।",
    "insurance_card": "बीमा कार्ड",
    "insurance_card_back": "बीमा कार्ड फिर्ता",
    "back_of_insurance_card_uploaded": "तपाईंको बीमा कार्डको पछाडि अपलोड गरिएको छ।"
  },
  "quidel": {
    "certify_text": "म प्रमाणित गर्दछु कि म मात्र एक पटक यो परीक्षण को लागी नतिजाहरु लगि्ग छु।",
    "entering_results": "तपाइँको नतिजा प्रविष्ट गर्दै",
    "review_instructions_1": "ध्यान दिएर निर्देशहरुको समीक्षा गर्नुहोस्",
    "review_instructions_2": "तपाइँ तपाइँको परीक्षण संग आएको निर्देशनहरु को समीक्षा गर्न सक्नुहुन्छ, एक चरण-दर-चरण गाइड पढ्नुहोस्, वा तल एक निर्देशात्मक भिडियो हेर्नुहोस्:",
    "read_instructions": "निर्देशन पढ्नुहोस्",
    "watch_video": "भिडियो हेर",
    "timer": "ट्यूब मा हलचल पछि टाइमर सुरु गर्नुहोस्। टाइमर १ मिनेट पछि बन्द हुनेछ (तपाइँ ट्यूब बाट स्वाब हटाउन को लागी)। कृपया ध्यान दिनुहोस्: तपाइँ तपाइँको परीक्षण को परिणाम को लागी १० मिनेट शुरू गर्न को लागी टाइमर पुनः सुरु गर्नु पर्छ।",
    "start_timer": "टाइमर सुरु गर्नुहोस् (वैकल्पिक)",
    "restart_timer": "स्टप र पुन: सुरु गर्नुहोस्",
    "resume": "रिजुम",
    "one_minute_countdown": "१ मिनेट बित्यो! ट्यूब बाट आफ्नो झाडु हटाउनुहोस् र टाइमर पुनः सुरु गर्नुहोस्।",
    "take_photo": "तपाइँको टेस्ट स्ट्रिप को एक फोटो लिनुहोस्",
    "photo_warning": "एक तस्वीर प्रदान जारी राख्न को लागी आवश्यक छ।",
    "enter_results": "परीक्षण परिणाम प्रविष्ट गर्नुहोस्",
    "choose_result": "विकल्प छान्नुहोस् कि सबै भन्दा राम्रो तपाइँको परीक्षण पट्टी प्रतिबिम्बित गर्दछ। सहयोग चाहियो? उदाहरण नतिजा हेर्न यहाँ क्लिक गर्नुहोस्।",
    "positive": "सकारात्मक",
    "negative": "नकारात्मक",
    "unknown": "अज्ञात",
    "clarify": "तपाइँको नतिजाहरु स्पष्ट पार्दै",
    "strip_question": "तपाइँको परीक्षण पट्टी कुन जस्तो लाग्यो?",
    "no_lines": "कुनै लाइन छैन",
    "pink_line": "केवल गुलाबी रेखा",
    "not_sure": "अझै पनि छैन पक्का"
  },
  "ihealth": {
    "take_a_photo_of_your_test": "रिजेल्ट फोटो",
    "retake_photo": "पुन फोटो लिनुहोस्",
    "capture_photo": "फोटो खिच्नुहोस्",
    "confirm_information_correct": "म माथिको जानकारी सही छ भनेर पुष्टि गर्छु।",
    "submit": "बुझउनुहोस्",
    "title": "{{name}} का लागि परिणामहरू हान्नुहोस्",
    "subtitle": "तपाईंले आईहेल्थ COVID-19 एप डाउनलोड वा प्रयोग गर्नु पर्दैन।",
    "instruction_title": "आईहेल्थ निर्देशनहरू हेर्नुहोस्",
    "instruction_guide": "निर्देशनहरू पढ्नुहोस्",
    "video_guide": "भिडियो हेर्नुहोस्",
    "add_photo": "फोटो थप्नुहोस्",
    "confirm_result": "परिणाम पुष्टि गर्नुहोस्",
    "result_warning": "पेश गर्नको लागि परिणाम छनौट गर्नुहोस्",
    "confirm_warning": "पेश गर्नको लागि परिणाम पुष्टि गर्नुहोस्",
    "administered_at_label": "तपाईंले यो परीक्षा कहिले दिनुभयो?",
    "instruction_subtitle": "तपाईंको परीक्षणको साथ आएका निर्देशनहरू हेर्नुहोस् वा यहाँ निर्देशनहरूको समीक्षा गर्नुहोस्।"
  },
  "public_test_groups": {
    "title": "COVID-19 परीक्षण किटहरू",
    "appointment_recoveries_title": "फर्कदै हुनुहुन्छ?",
    "appointment_recoveries_button_text": "मेरो लिङ्क पत्ता लगाउनुहोस्",
    "search_test_group_title": "पहिल्लो पटक हो?",
    "search_test_group_button_text": "दर्ता गर्नुहोस्",
    "title_2": "सरल दर्ता र रिपोर्टिङ",
    "step_one": "एकाउन्ट दर्ता",
    "step_two": "फिर्ता पठाउन लार नमूना सङ्कलन",
    "step_three": "नतिजा अनलाइनबाट हेर्नुहोस्",
    "title_3": "आफ्नो किट सक्रिय गर्न दर्ता गर्नुहोस्",
    "population_title": "दर्ता",
    "population_button": "सक्रिय",
    "faq_subtitle": "थप सिक्नुहोस्",
    "faq_title": "प्राय सोधिने प्रश्नहरू",
    "faq_1": "म कसरी मेरो परीक्षण किट फर्काउन सक्छु?",
    "faq_1_footer": "लार प्रत्यक्ष फनेल निर्देशनहरू हेर्नुहोस्",
    "faq_2": "म पहिले नै दर्ता भएको छु तर मैले मेरो खाता बिर्सेको छु।",
    "faq_3": "म कसरी मेरो किट सक्रिय गर्न सक्छु?",
    "faq_2_subtitle": "फर्कने प्रयोगकर्ताको रूपमा, तपाईंले माथिको '**मेरो लिङ्क पत्ता लगाउनुहोस्**' बटन प्रयोग गरेर आफ्नो रिपोर्ट पुष्टि गर्न सक्नुहुन्छ।",
    "faq_3_subtitle": "कृपया तलका विकल्पहरू प्रयोग गरी प्राथमिक स्वास्थ्य खाता प्रक्रियामा जानुहोस्:",
    "footer_text_1": "यो उत्पादन FDA खाली वा स्वीकृत गरिएको छैन, तर EUA अन्तर्गत FDA द्वारा आपतकालीन प्रयोगको लागि अधिकृत गरिएको छ;",
    "footer_text_2": "यो उत्पादनलाई SARS-CoV-2 बाट न्यूक्लिक एसिड पत्ता लगाउन सहयोगको रूपमा लार नमूनाहरूको सङ्कलन र मर्मतका लागि मात्र अधिकार दिइएको छ, अन्य कुनै भाइरस वा रोगजनकहरूको लागि होइन;",
    "footer_text_3": "यस उत्पादनको आपतकालीन प्रयोगलाई संघीय खाद्य, औषधि र सौन्दर्य सम्बन्धी ऐन, 21 USC § को धारा 564(b)(1) अन्तर्गत मेडिकल उपकरणहरूको आपतकालीन प्रयोगको अधिकारलाई औचित्य दिने परिस्थितिहरू अवस्थित रहेको घोषणाको अवधिको लागि मात्र अधिकृत छ 360bbb-3(b)(1), जबसम्म घोषणा समाप्त हुँदैन वा प्राधिकरण चाँडै खारेज हुन्छ।.",
    "description": "तपाईंको सुविधाको लागि, तपाईंले तलका बटनहरू प्रयोग गरेर आफ्नो परीक्षण किट सक्रिय गर्न सक्नुहुन्छ:",
    "subtitle": "पहिलो पटक प्राथमिकमा?",
    "subtitle_1": "तपाईंको COVID-19 परीक्षण किटहरू बारे",
    "faq_1_1": "बन्द नमूना ट्यूबलाई बायोस्पेसिमेन झोलामा राख्नुहोस्",
    "faq_1_2": "बायोस्पेसिमेन झोलालाई लार डायरेक्ट-लेबल गरिएको बक्समा राख्नुहोस्। लार डायरेक्ट-लेबल गरिएको बक्सलाई FedEx UN 3373 Pak मा राख्नुहोस्।",
    "faq_1_3": "FedEx UN 3373 Pak को टाँसिने लाइनर हटाउनुहोस् र बलियो रूपमा पाकलाई सील गर्न तल थिच्नुहोस्।",
    "faq_1_4": "कृपया तपाईंले यसलाई सङ्कलन गरेको दिन र अन्तिम पिकअप समय अघि आफ्नो नमूना छोड्नुहोस्। सप्ताहन्तमा तपाईंको नमूना ड्रप बक्समा नपुग्नुहोस्।",
    "faq_1_5": "ड्रपबक्स स्थानहरू र पिक-अप तालिकाहरू हेर्नको लागि **[fedex.com/labreturns](https://www.fedex.com/labreturns)** मा FedEx वेबसाइटमा जानुहोस्।",
    "demo": {
      "title": "COVID-19 at-home test kit additional information & instructions",
      "online_instructions": "Online instructions",
      "download_instructions": "Download instruction sheet",
      "begin": "Begin",
      "instructions": {
        "welcome_title": "Welcome to the at-home COVID-19 testing portal.",
        "welcome_text_1": "Before you start testing, wash you hands or use hand sanitizer.",
        "welcome_text_2": "Make sure your hands are dry before starting.",
        "continue": "Continue",
        "back": "Back",
        "step_one_title": "Step 1: Check your test kit",
        "step_one_text_1": "Locate the kit components:",
        "step_one_text_2": "It is recommended gloves (not provided) also be used during testing.",
        "step_two_title": "Step 2: Place tube in the tube holder",
        "step_two_text_1": "Remove cap from one **TUBE** and place it in the **TUBE HOLDER.**",
        "step_two_text_2": "**NOTE:** Use of gloves is recommended.",
        "step_five_title": "Step 5: Remove swab from the tube",
        "step_five_text_1": "After **ONE MINUTE**, remove the swab from the **TUBE** by rubbing the swab head against the inside wall of the tube to squeeze out as much liquid as possible.",
        "step_five_text_2": "Dispose of swab in the trash.",
        "step_six_title": "Step 6: Open the test strip",
        "step_six_text_1": "Open the **TEST STRIP** pouch carefully at the slit and hold the **TEST STRIP** as indicated.",
        "step_seven_title": "Step 7: Place test strip in the tube",
        "step_seven_text_1": "Place the **TEST STRIP** into the **TUBE** with the arrows pointing down. Leave the strip in the **TUBE** for a **FULL TEN MINUTES**– do not handle or remove.",
        "step_eight_title": "Step 8: Remove test strip from the tube",
        "step_eight_text_1": "At **TEN MINUTES**, remove the **TEST STRIP** from the **TUBE**. Next, place the **TEST STRIP** on the outline in Steps 10-12. Ensure the **TEST STRIP** is on a flat surface in good lighting.",
        "step_eight_text_2": "**Note:** The test is intended to be read at 10 minutes. If the test is read before this or is read more than 5 minutes after the indicated read time, results may be inaccurate (false negative, false positive, or invalid) and the test should be repeated.",
        "step_nine_title": "Step 9: Check your results",
        "step_nine_text_1": "There are three type of results possible.",
        "step_nine_text_2": "Check for a Positive Result",
        "step_nine_text_3": "Check for a Negative Result",
        "step_nine_text_4": "Check for an Invalid Result",
        "step_ten_title": "Step 10: Remove test strip from the tube",
        "step_ten_text_1": "Place the **TEST STRIP** on the test strip outline below and compare with test result examples shown.",
        "step_three_title": "Step 3: Swab the nostrils",
        "step_three_text_1": "Remove the **SWAB** from its wrapper, being careful not to touch the **SWAB** head.",
        "step_three_text_2": "Gently insert the **SWAB** ½ to ¾ of an inch into the nostril, depending on the size of the person’s nose. Firmly rub the **SWAB** in a circular motion around the inside wall of EACH NOSTRIL at least 4 times.",
        "step_three_text_3": "Be sure to rub BOTH nostrils with the SAME SWAB.",
        "step_three_text_5": "**NOTE:** Failure to swab properly may cause false negative results.",
        "step_four_title": "Step 4: Place swab in the tube",
        "step_four_text_1": "Immediately place the **SWAB** into the liquid inside the **TUBE**, and ensure it is touching the bottom. Stir 3-4 times.",
        "step_four_text_2": "Leave the swab in the solution for **ONE MINUTE**.",
        "step_four_text_3": "**NOTE**: If the swab is in the solution for more than 10-minutes it should not be used.",
        "step_three_text_4": "**NOTE:** If you are swabbing others, please wear a face mask. With children, you may not need to insert the swab as far into the nostril. For very young children, you may need another person to steady the child’s head while swabbing."
      },
      "choose_language": "Choose your language"
    }
  },
  "self_resulting": {
    "report_result": "परिणाम रिपोर्ट गर्नुहोस्",
    "report_result_subtitle": "यदि तपाईंको संगठनले आत्म-परीक्षण प्रस्ताव गर्दछ, र तपाईं परिणाम रिपोर्ट गर्न तयार हुनुहुन्छ भने, कृपया तलको बटनमा क्लिक गर्नुहोस्।",
    "need_help": "सहयोग चाहियो?",
    "assistance": "यदि तपाईंलाई सहयोग चाहिन्छ भने, वा तपाईंको सम्पर्क जानकारी परिवर्तन गर्न, कृपया समर्थन केन्द्रमा सम्पर्क गर्नुहोस्।",
    "contact_support": "समर्थन सम्पर्क गर्नुहोस्",
    "save_link": "पछि परिणाम रिपोर्ट गर्न यो लिङ्क सुरक्षित गर्नुहोस्।",
    "send_via_email": "इमेल मार्फत लिङ्क पठाउनुहोस्",
    "send_via_text": "SMS पाठ मार्फत लिङ्क पठाउनुहोस्",
    "copy_link": "लिङ्क प्रतिलिपि गर्नुहोस्",
    "resend_email": "इमेल प्रमाणीकरण पुन: पठाउनुहोस्",
    "phone_verified": "फोन प्रमाणित भयो",
    "add_vaccination_status": "COVID-19 खोपको स्थिति थप्नुहोस्/अपडेट गर्नुहोस्",
    "terms_of_service": "सेवाका सर्तहरु",
    "verify_contact_information": "आफ्नो सम्पर्क जानकारी प्रमाणित गर्नुहोस् ताकि तपाईं परिणाम र संचार प्राप्त गर्न सक्नुहुन्छ। यदि तपाईंलाई आफ्नो सम्पर्क जानकारी परिवर्तन गर्न आवश्यक छ भने, कृपया हाम्रो समर्थन केन्द्रमा सम्पर्क गर्नुहोस्।",
    "resend_text": "SMS पाठ प्रमाणीकरण पुन: पठाउनुहोस्",
    "loading": "लोड गर्दै",
    "add_to_account": "खातामा थप्नुहोस्",
    "hi": "नमस्ते",
    "email_verified": "इमेल प्रमाणित भयो",
    "go_to_my_account": "मेरो खातामा जानुहोस्",
    "activate_kit": " टेस्ट किट सक्रिय गर्नुहोस्",
    "report_custom_result": "परिणाम रिपोर्ट गर्नुहोस्: {{test}}",
    "activate_custom_test": "टेस्ट सक्रिय गर्नुहोस्: {{test}}",
    "update_vaccination_status": "खोपको स्थिति अद्यावधिक गर्नुहोस्",
    "order_a_test_kit": "परीक्षण किट अर्डर गर्नुहोस्"
  },
  "enter_information": {
    "contact_info": "आफ्नो सम्पर्क जानकारी प्रविष्ट गर्नुहोस्",
    "label": "फोन वा इमेल",
    "invalid": "यो मान्य इमेल वा फोन नम्बर होइन।",
    "send": "हामी तपाईंको फोन वा इमेलमा कोड पठाउने छौँ",
    "legal": "आफ्नो फोन नम्बर वा इमेल प्रविष्ट गरेर, तपाईं प्राथमिक स्वास्थ्यको **[सेवाका सर्तहरू](https://primary.health/terms-of-service/)** र **[गोपनीयता नीति](https://primary.health/privacy-policy/) मा सहमत हुनुहुन्छ। **।",
    "subtitle": "हामी तपाइँलाई तपाइँको रेकर्ड फेला पार्न मद्दत गर्नको लागि परीक्षण परिणाम रिपोर्ट गर्न लिङ्क! वा पाठ वा इमेल पठाउनेछौं ",
    "find_link": "दर्ता भयो? आफ्नो लिङ्क खोज्नुहोस्",
    "not_registered": "दर्ता छैन? आफ्नो साइट खोज्नुहोस्",
    "search_by_keyword": "नाम, कीवर्ड, शहर, जिप, वा संगठन कोड द्वारा खोजी गर्नुहोस्!",
    "search": "खोज्नुहोस्",
    "register_here": "यहाँ दर्ता गर्नुहोस्"
  },
  "contact_support": {
    "invalid": "अवैध जानकारी",
    "invalid_explanation": "तपाईंले भर्खरै Primary.Health मा साइन-अन लिङ्क अनुरोध गर्नुभयो। तर,",
    "never_registered": "पहिले कहिल्यै दर्ता गर्नुभएको छैन? कृपया दर्ता लिङ्कको लागि आफ्नो परीक्षण प्रदायकलाई सम्पर्क गर्नुहोस्, वा तल आफ्नो साइट फेला पार्नुहोस्।",
    "get_help": "तपाईं तपाईंको सम्पर्क जानकारीसँग तपाईंको रेकर्ड मिलाउन मद्दत प्राप्त गर्न सक्नुहुन्छ",
    "get_help_calling": "वा कल गरेर",
    "invalid_explanation_bold": "तपाईंले प्रदान गर्नुभएको जानकारी अमान्य थियो।",
    "get_help_label": "मद्दत प्राप्त गर्नुहोस्",
    "mobile": {
      "invalid_explanation": "तपाईंको खातामा थप्न सकिने कुनै पनि प्रयोगकर्ता फेला पार्न सकिएन।",
      "get_help": "तपाईंसँग अतिरिक्त प्रश्नहरू छन् भने कृपया यहाँ सम्पर्क गर्नुहोस्",
      "reason": "सबै प्राथमिक होइनन्। स्वास्थ्यका प्रयोगकर्ताहरू हाम्रा नयाँ खाताहरूको सुविधाका लागि योग्य हुन्छन्। तपाईं योग्य हुनुभएपछि तपाईंले प्राथमिकबाट निमन्त्रणा प्राप्त गर्नु हुने छ । स्वास्थ्य वा तपाईंको समूहको प्रशासकबाट।"
    },
    "zen_name": "सम्पर्क नाम",
    "ticket_type": "यस सम्बन्धमा के हो",
    "appointment_access_code": "पुष्टि कोड (यदि थाहा छ)",
    "zen_desc": "सन्देश",
    "contact_us": "हामीलाई सम्पर्क गर्नुहोस",
    "need_assistance": "यदि तपाईंलाई सहयोग चाहिन्छ भने, वा तपाईंको सम्पर्क जानकारी परिवर्तन गर्न, कृपया समर्थनलाई सम्पर्क गर्नुहोस्।"
  },
  "show_appointments": {
    "welcome": "तलको नाम नदेखेको लागि",
    "welcome_subtitle_1": "तल नतिजाहरू देखाउँदै",
    "added_to_account": "खातामा थपियो",
    "error": "यो सहभागीको पहिले नै खाता हुन सक्छ",
    "welcome_subtitle_2": "आफ्नो प्रोफाइल फेला पार्नुहोस्?",
    "welcome_not_logged_in": "साइट लिङ्क खोज्दै",
    "welcome_logged_in": "आफ्नो समूह चयन गर्नुहोस्",
    "welcome_subtitle_1_logged_in": "सदस्य थप्न समाप्त गर्न, प्रयोगकर्ता र समूह चयन गर्नुहोस्। सदस्यहरूलाई एउटा खातामा तोकिएको छ।",
    "welcome_subtitle_2_logged_in": "यदि तपाइँ माथि सूचीबद्ध भएको तपाइँको सदस्य देख्नुहुन्न भने, तपाइँ सक्नुहुन्छ",
    "welcome_subtitle_1_not_logged_in": "कसलाई आफ्नो साइट पत्ता लगाउन वा नयाँ सदस्य थप्न आवश्यक छ चयन गर्नुहोस्। सदस्य फेला परेन?",
    "welcome_subtitle_3_logged_in": "फरक सम्पर्क विधि प्रयास गर्न सक्नुहुन्छ",
    "welcome_subtitle_4_logged_in": "वा हामीलाई खोज अद्यावधिक गर्नुहोस्",
    "welcome_subtitle_2_not_logged_in": "मा सम्पर्क गर्नुहोस्।",
    "welcome_subtitle_3": "आफ्नो खोज अद्यावधिक गर्नुहोस्"
  },
  "enter_code": {
    "code": "कोड के हो?",
    "enter_code": "पठाइएको कोड प्रविष्ट गर्नुहोस्",
    "incorrect_code": "तपाईंले गलत कोड प्रविष्ट गर्नुभएको छ।",
    "verify": "खाता प्रमाणित गर्नुहोस्",
    "verified": "प्रमाणित",
    "verify_account_information": "आफ्नो खाता प्रमाणित गर्नुहोस्"
  },
  "general_self_checkout": {
    "choose_test": "तपाईले कस्तो परीक्षा दिनुभयो?",
    "photo_required": "नतिजाको फोटो आवश्यक छ",
    "find_your_test": "आफ्नो परीक्षण पत्ता लगाउनुहोस्"
  },
  "login": {
    "create_account": "खाता खोल्नुहोस्",
    "create_account_help": "खाता सिर्जना गर्नु परीक्षण वा खोपहरूको लागि दर्ता गर्नु जस्तै होइन। तपाईंले दर्ता गरेपछि",
    "create_account_help_schedule": "एपोइन्टमेन्ट निर्धारण गर्नुहोस्",
    "create_account_help_record": "घरमा परीक्षणहरू रेकर्ड गर्नुहोस्",
    "create_account_help_match": "खोप र परीक्षण रेकर्डहरू प्रबन्ध गर्नुहोस्।",
    "continue": "अतिथिको रूपमा जारी राख्नुहोस्",
    "already": "पहिले नै खाता छ? क्लिक गर्नुहोस्",
    "login": "लगइन गर्न।",
    "or_sign_in": "वा यससँग साइन इन गर्नुहोस्:",
    "no_account": "खाता छैन? क्लिक गर्नुहोस्",
    "signup": "साइन अप गर्न।",
    "here": "यहाँ",
    "email_address": "इ - मेल ठेगाना:",
    "password": "पासवर्ड:",
    "complete_registration": "पूरा दर्ता",
    "last_covid_test": "पछिल्लो COVID-19 परीक्षण परिणाम",
    "no_record": "कुनै रेकर्ड छैन",
    "viewing_information": "{{first_name}} को जानकारी हेर्दै",
    "download": "डाउनलोड गर्नुहोस्",
    "not_registered": "तपाईंले कुनै पनि समूहमा दर्ता गर्नुभएको छैन जस्तो देखिन्छ",
    "please_follow_link": "Primary.Health सँग आफ्नो जानकारी समयतालिका र पहुँच गर्नको लागि कृपया आफ्नो इमेलको निमन्त्रणा लिङ्क पछ्याउनुहोस्।",
    "log_results": "मेरो परीक्षण परिणामहरू लग गर्नुहोस्",
    "book_appointment": "बुक अपोइन्टमेन्ट",
    "no_new_appointments": "हाल नयाँ नियुक्तिहरू स्वीकार गर्दैन",
    "upload_vaccinations": "खोपहरू अपलोड गर्नुहोस्",
    "vaccination": "खोप",
    "new_appointment": "नयाँ नियुक्ति",
    "log_otc_results": "OTC परीक्षणको लागि नतिजा लगाउनुहोस्",
    "no_household_members": "तपाईंसँग अहिलेसम्म घरपरिवारका सदस्यहरू छैनन्।",
    "no_upcoming": "कुनै आगामी भेटघाट छैन",
    "update_vaccination": "खोप अपडेट गर्नुहोस्",
    "upload_vaccination": "खोप अपलोड गर्नुहोस्",
    "title": "प्राथमिक स्वास्थ्यमा स्वागत छ",
    "sub_title": "Before registering please create an account.",
    "p1": "एउटा खाता सिर्जना गर्नाले तपाईंलाई अनुमति दिन्छ:",
    "p2": "भविष्यका भेटघाटहरूको तालिका बनाउनुहोस्",
    "p3": "घरमै COVID-19 परीक्षणहरू रेकर्ड गर्नुहोस्",
    "p4": "आश्रितहरू खोप र परीक्षण रेकर्डहरू प्रबन्ध गर्नुहोस्",
    "p5": "my.primary.health बाट सरल लगइन प्रक्रिया",
    "no_vaccination_record": "अझै खोप थपिएको छैन",
    "vaccination_title_card": "Covid-19 खोपको स्थिति",
    "account_and_settings": "खाता सेटिङ",
    "delete_account": "खाता मेटाउनुहोस्",
    "language": "भाषा",
    "language_current": "हालको भाषा: {{language}}",
    "log_out": "बाहिर निस्कनु",
    "delete_account_title": "के तपाईं तपाईंको खाता मेटाउन निश्चित हुनुहुन्छ?",
    "new_encounter": "{{first_name}} को नयाँ भेट",
    "new_encounter_subtitle": "यो नयाँ भिडन्त कुन समूहको लागि हो ?",
    "no_programs": "यस्तो देखिन्छ कि तपाईं कुनै पनि कार्यक्रमहरूमा भर्ना हुनुहुन्न",
    "edit_members": "सदस्यहरू सम्पादन गर्नुहोस्",
    "edit_members_subtitle": "तपाईं कुन ड्यासबोर्डमा जान चाहनुहुन्छ वा तपाईंको खातामा अर्को सदस्य थप्न चाहनुहुन्छ चयन गर्नुहोस्",
    "add_members": "सदस्य थप्नुहोस्",
    "delete_member": "{{full_name}} को प्रयोगकर्ता खाता मेटाउने हो?",
    "delete_member_subtitle": "प्रयोगकर्तालाई हटाउनाले खातासँग सम्बन्धित सबै जानकारीहरू मेटिनेछ।",
    "select_member": "सदस्य चयन",
    "edit_appointment": "भेटघाट सम्पादन गर्नुहोस्",
    "route": {
      "me": "ड्यासबोर्ड",
      "history": "इतिहास",
      "programs": "परीक्षण थप्नुहोस्",
      "contact_us": "मद्दत गर्नुहोस्",
      "choose_member": "सदस्य",
      "settings": "सेटिङहरू",
      "help": "मद्दत गर्नुहोस्"
    },
    "user_dashboard": "{{first_name}} को ड्यासबोर्ड",
    "view_full_results": "नतिजा पोर्टलमा हेर्नुहोस्",
    "required": "यदि तपाईं प्राथमिक एप प्रयोग गर्दै हुनुहुन्छ भने आवश्यक छ।",
    "no_email": "मसँग इमेल ठेगाना छैन"
  },
  "vaccination_status": {
    "not_vaccinated": "खोप लगाइएको छैन",
    "fully_vaccinated": "पूर्ण खोप लगाइयो",
    "partially_vaccinated": "आंशिक रूपमा खोप लगाइयो",
    "vaccination_records": "खोपका रेकर्डहरू",
    "title": "तपाईंको COVID-19 खोपको स्थिति कस्तो छ?",
    "definition_title": "खोप स्थिति परिभाषा",
    "definition_cdc": "CDC खोप स्थिति परिभाषा",
    "definition_osha": "OSHA टीकाकरण स्थिति परिभाषा",
    "definition": {
      "fully_vaccinated": "Pfizer-BioNTech वा Moderna को 2 डोज शृङ्खलामा अन्तिम डोज वा Johnson & Johnson's Janssen खोपको 1 डोजको 2 हप्ता पछि।",
      "partially_vaccinated": "प्राथमिक शृङ्खलामा 2 डोजमध्ये 1मात्र प्राप्त भयो, वा 2 हप्ताभन्दा कम समयपछि २ डोज सिरिज वा Johnson & Johnson's Janssen को 1 डोज प्राप्त भयो।",
      "not_vaccinated": "COVID-19 को खोप लगाइएको छैन।",
      "fully_vaccinated_with_one_booster": "Pfizer-BioNTech वा Moderna खोपहरूको बूस्टर डोज पूर्ण शृङ्खला वा Johnson & Johnson's Janssen खोपको 1 डोज प्राप्त गरेपछि।",
      "fully_vaccinated_with_two_boosters": "फाइजर-बायोएनटेक वा मोडेर्ना भ्याक्सिनहरूको दोस्रो बूस्टर डोज प्राप्त भयो वा जॉनसन एन्ड जोन्सनको जेन्सेन खोप र बूस्टर डोजको पूर्ण श्रृंखला वा डोज पछि हो।"
    },
    "label": {
      "fully_vaccinated": "पूर्ण खोप लगाइयो",
      "partially_vaccinated": "आंशिक रूपमा खोप लगाइयो",
      "not_vaccinated": "खोप लगाइएको छैन",
      "fully_vaccinated_with_one_booster": "पूर्ण रूपमा खोप लगाइएको + बूस्टर",
      "fully_vaccinated_with_two_boosters": "पूर्ण रूपमा खोप लगाइएको + 2 बूस्टरहरू",
      "prefer_not_to_answer": "जवाफ दिन रुचाउनुहोस्"
    },
    "progress_bar_title": "COVID-19 खोप इतिहास",
    "upload_record_card": "आफ्नो COVID-19 खोप रेकर्ड कार्ड अपलोड गर्नुहोस्",
    "show_example": "उदाहरण देखाउनुहोस्",
    "hide_example": "उदाहरण लुकाउनुहोस्",
    "take_photo": "फोटो लिन क्लिक गर्नुहोस्",
    "or": "वा",
    "upload_image_or_pdf_instead": "यसको सट्टा छवि/पीडीएफ अपलोड गर्नुहोस्",
    "select_doses_received": "तपाईंले प्राप्त गर्नुभएको सबै COVID-19 खोपको खुराकहरू चयन गर्नुहोस्।",
    "first_dose": "पहिलो डोज",
    "second_dose": "दोस्रो डोज",
    "first_booster_dose": "पहिलो बूस्टर डोज",
    "second_booster_dose": "दोस्रो बूस्टर डोज",
    "additional_dose": "अतिरिक्त डोज",
    "first_dose_manufacturer": "तपाईंको पहिलो डोजको लागि COVID-19 निर्माता को थियो?",
    "first_dose_date": "तपाईंको पहिलो डोज को मिति के थियो?",
    "second_dose_manufacturer": "तपाईंको दोस्रो डोजको लागि COVID-19 निर्माता को थियो?",
    "second_dose_date": "तपाईको दोस्रो डोजको मिति के थियो?",
    "first_booster_dose_manufacturer": "तपाईंको पहिलो बुस्टर डोजको लागि COVID-19 निर्माता को थिए?",
    "first_booster_dose_date": "तपाईंको पहिलो बूस्टर डोजको मिति के थियो?",
    "second_booster_dose_manufacturer": "तपाईंको दोस्रो बूस्टर डोजको लागि COVID-19 निर्माता को थियो?",
    "second_booster_dose_date": "तपाईंको दोस्रो बूस्टर डोज को मिति के थियो?",
    "additional_dose_manufacturer": "तपाईंको अतिरिक्त डोजको लागि COVID-19 निर्माता को थियो?",
    "additional_dose_date": "तपाईंको अतिरिक्त डोज को मिति के थियो?",
    "completed_one": "पूरा भयो",
    "completed_two": "आफ्नो खोप स्थिति अपडेट गर्नुभएकोमा धन्यवाद!",
    "progress_bar_complete": "पूरा",
    "upload_image": "फोटो अपलोड गर्नुहोस्",
    "retake_photo": "फोटो पुन: खिच्नुहोस्",
    "other": "अन्य",
    "remove_first_dose": "पहिलो डोज इतिहास हटाउनुहोस्",
    "remove_second_dose": "दोस्रो डोज इतिहास हटाउनुहोस्",
    "remove_first_booster_dose": "पहिलो बूस्टर डोज इतिहास हटाउनुहोस्",
    "remove_second_booster_dose": "दोस्रो बूस्टर डोज इतिहास हटाउनुहोस्",
    "remove_additional_dose": "अतिरिक्त डोज इतिहास हटाउनुहोस्",
    "exemption": "के तपाईसँग छुट छ?",
    "exempt": "म छुट छु",
    "not_exempt": "म छुट छैन",
    "enter_exemption": "छूट प्रविष्ट गर्नुहोस्",
    "upload_exemption_documentation": "तपाईंको छुटको लागि कागजातहरू अपलोड गर्नुहोस्",
    "remove_dose": "डोज हटाउनुहोस्",
    "continue": "जारी राख्नुहोस्",
    "enter_credentials": "कृपया आफ्नो प्रमाणहरू प्रविष्ट गर्नुहोस्",
    "incorrect_credentials": "गलत प्रमाणहरू, कृपया पुन: प्रयास गर्नुहोस्।",
    "add_photo": "फोटो थप्नुहोस्"
  },
  "mailers": {
    "appointment_mailer": {
      "payment_pending": "नमस्ते {{name}}, हामीले याद गर्यौं कि तपाईंले {{org_name}} सँग आफ्नो {{group_name}} भेटघाटको समयतालिका पूरा गर्नुभएको छैन। आफ्नो अपोइन्टमेन्ट राख्नको लागि, तपाईंले भुक्तानी चरण पूरा गर्न आवश्यक छ। प्रक्रिया {{complete_url}} पूरा गर्न कृपया यो लिङ्कमा क्लिक गर्नुहोस्। यदि तपाईं आफ्नो अपोइन्टमेन्ट रद्द गर्न चाहनुहुन्छ भने, कृपया यो लिङ्कमा क्लिक गर्नुहोस् {{cancel_url}}। धन्यवाद"
    }
  },
  "otc": {
    "record_results_for_abbott": "BinaxNOW परीक्षणको लागि आफ्नो नतिजाहरू परीक्षण र रेकर्ड गर्नुहोस्",
    "record_results": "परीक्षण र आफ्नो परिणाम रेकर्ड",
    "activate_a_kit": "किट सक्रिय गर्नुहोस्",
    "activate_a_kit_for_test": "{{test}} को लागि किट सक्रिय गर्नुहोस्",
    "todays_test_process": "आजको परीक्षण प्रक्रिया:",
    "date_of_birth_question": "{{first_name}} को जन्म मिति के हो?",
    "confirm_dob_to_continue": "जारी राख्न कृपया सहभागीको जन्म मिति पुष्टि गर्नुहोस्:",
    "incorrect_dob": "गलत जन्म मिति",
    "file_too_large": "कृपया अपलोड गरिएको फाइलको साइज {{size}} भन्दा सानो छ भनी सुनिश्चित गर्नुहोस्।",
    "enter_valid_date_in_last_x_days": "पछिल्लो {{n}} दिनमा मान्य मिति प्रविष्ट गर्नुहोस्",
    "barcode_format_not_valid": "बारकोड ढाँचा मान्य छैन",
    "complete_button": "पूरा",
    "next_button": "अर्को",
    "back_button": "पछाडि",
    "report_a_test": "परीक्षण रिपोर्ट गर्नुहोस्:",
    "x_%_completed": "{{n}}% पुरा",
    "answer_survey": "सर्वेक्षण प्रश्नहरूको जवाफ दिनुहोस्",
    "steps": {
      "answer_survey": "सर्वेक्षणको जवाफ दिनुहोस्",
      "review_instructions": "निर्देशनहरूको समीक्षा गर्नुहोस्",
      "scan_code": "स्क्यान कोड",
      "enter_time": "समय प्रविष्ट गर्नुहोस्",
      "enter_result": "परिणाम प्रविष्ट गर्नुहोस्",
      "take_photo": "फोटो खिच्नुहोस्",
      "confirm_result": "परिणाम पुष्टि गर्नुहोस्",
      "selest_test_kit": "टेस्ट प्रकार छान्नुहोस्",
      "enter_date": "मिति प्रविष्ट गर्नुहोस्",
      "enter_date_and_time": "मिति र समय प्रविष्ट गर्नुहोस्"
    },
    "review_instructions": "परीक्षण निर्देशनहरू समीक्षा गर्नुहोस्",
    "follow_instructions_or_below": "तपाईंको परीक्षणको साथ आएका निर्देशनहरू पालना गर्नुहोस् वा तलका निर्देशनहरूको समीक्षा गर्नुहोस्।",
    "watch_video": "भिडियो हेर्नुहोस",
    "view_instructions": "निर्देशनहरू हेर्नुहोस्",
    "follow_instructions": "तपाईंको परीक्षणको साथ आएका निर्देशनहरू पालना गर्नुहोस्।",
    "scan_code": "स्क्यान परीक्षण QR कोड",
    "qr_code_description": "QR कोड तपाईंको परीक्षणको लागि अद्वितीय छ र तपाईंको परीक्षणको शीर्ष-दायाँ कुनामा फेला पार्न सकिन्छ",
    "show_example": "उदाहरण देखाउनुहोस्",
    "hide_example": "उदाहरण छोप्नुहोस्",
    "barcode_scanned": "बारकोड सफलतापूर्वक स्क्यान गरियो",
    "enter_barcode": "बारकोड प्रविष्ट गर्नुहोस्",
    "scan_qr_code": "QR कोड स्क्यान गर्नुहोस्",
    "which_test_did_you_take": "तपाईले कुन परीक्षा दिनुभयो?",
    "when_did_you_take_test": "तपाईंले यो परीक्षा कहिले दिनुभयो?",
    "what_day_did_you_take_test": "तपाईंले यो परीक्षा कुन दिन दिनुभयो?",
    "what_time_did_you_take_test": "तपाईंले यो परीक्षा कति बजे दिनुभयो?",
    "time": {
      "today": "आज",
      "yesterday": "हिजो",
      "two_days_ago": "2 दिन पहिल्ला"
    },
    "enter_current_time": "हालको समय प्रविष्ट गर्नुहोस्",
    "enter_result": "परीक्षण परिणाम प्रविष्ट गर्नुहोस्",
    "choose_result_option": "तपाईँको परीक्षण कार्डको नतिजा वर्णन गर्ने विकल्प छान्नुहोस्:",
    "clarify_results": "आफ्नो परिणाम स्पष्ट गर्नुहोस्",
    "take_result_photo": "नतिजाको फोटो लिनुहोस्",
    "take_result_photo_description": "आफ्नो परिणाम रेकर्ड गर्न पूरा परिणाम कार्डको फोटो खिच्नुहोस्।",
    "my_result": "मेरो नतिजा",
    "switch_camera": "क्यामेरा स्विच गर्नुहोस्",
    "confirm_result": "परिणाम पुष्टि गर्नुहोस्",
    "name": "नाम:",
    "date": "मिति:",
    "result": "नतिजा:",
    "results": "परिणामहरू",
    "test_submitted": "धन्यवाद, {{first_name}}! तपाईको {{test}} सफलतापूर्वक पेश गरिएको छ।",
    "dob": "जन्म मिति:",
    "administered": "प्रशासित:",
    "or": "वा",
    "upload": "अपलोड गर्नुहोस्",
    "change_uploaded_file": "अपलोड गरिएको फाइल परिवर्तन गर्नुहोस्",
    "take_photo": "फोटो खिच्नुहोस्",
    "confirm_information": "जानकारी पुष्टि गर्नुहोस्",
    "barcode": "बारकोड:",
    "scan_test_barcode": "परीक्षण बारकोड स्क्यान गर्नुहोस्",
    "barcode_description": "बारकोड तपाईको परीक्षणको लागि अद्वितीय छ र तपाईको परीक्षणमा फेला पार्न सकिन्छ",
    "enter_date_in_last_30_days": "पछिल्लो 30 दिनको मिति र परीक्षण लिइएको अनुमानित समय प्रविष्ट गर्नुहोस्",
    "add_image": "फोटो थप्नुहोस्",
    "change_image": "फोटो परिवर्तन गर्नुहोस्",
    "skip_barcode_reader": "कोड स्क्यान गर्न असमर्थ? जे भए पनि परीक्षण परिणाम रिपोर्ट गर्न अर्को क्लिक गर्नुहोस्",
    "enter_date_and_time": "परीक्षणको मिति र समय प्रविष्ट गर्नुहोस्",
    "enter_date": "परीक्षणको मिति प्रविष्ट गर्नुहोस्",
    "did_you_take_test_today": "तपाईंले आज जाँच गराउनुभयो?",
    "record_results_for_generic": "{{name}} परीक्षणको लागि आफ्नो नतिजा परीक्षण र रेकर्ड गर्नुहोस्",
    "choose_result_option_custom": "तपाईको {{test_name}} नतिजाको वर्णन गर्ने विकल्प छान्नुहोस्:"
  },
  "yes": "हो",
  "no": "छैन",
  "event_token": {
    "title": "तपाईंको परीक्षण किट वितरण गर्नको लागि कृपया तलको कोड भेन्डिङ मेसिनमा प्रविष्ट गर्नुहोस्",
    "loading_text": "लोड हुँदै छ, कृपया एक मिनेटमा फेरि जाँच गर्नुहोस्"
  },
  "appointment_recovery": {
    "complete_title": "तपाईं पूर्ण रूपमा तयार हुनुहुन्छ!",
    "subtitle_1": "यदि तपाईं iPhone प्रयोग गर्दै हुनुहुन्छ भने, कृपया आफ्नो ड्यासबोर्डमा फर्कन यस स्क्रिनको माथिल्लो बायाँ कुनामा रहेको **सम्पन्न** मा ट्याप गर्नुहोस्।",
    "subtitle_2": "यदि तपाईं एन्ड्रोइड प्रयोग गर्दै हुनुहुन्छ भने, यो स्क्रिनको शीर्ष बायाँ कुनामा रहेको **X** मा ट्याप गर्नुहोस्।",
    "subtitle_0": "जारी राख्न ब्राउजरबाट बाहिर निस्कनुहोस्।"
  },
  "primary": {
    "support_email": "support@primary.health"
  },
  "consented_relationship": "सहमति भएको सम्बन्ध",
  "finish_registration": {
    "title": "आफ्नो दर्ता पूरा गर्नुहोस्",
    "subtitle": "कृपया तपाईंको अपोइन्टमेन्ट अघि निम्न जानकारी पूरा गर्नुहोस्:",
    "button": "दर्ता समाप्त गर्नुहोस्"
  },
  "arab_ethnicity": {
    "arab": "अरब",
    "non_arab": "गैर-अरब",
    "prefer_not_to_answer": "उत्तर नदिन रुचाउनुहोस्",
    "question": "अरब जाति",
    "subtitle": "नियमहरूले निम्न जानकारी सङ्कलन गर्न आवश्यक छ।",
    "help": "जातीयता भनेको भाषा, सांस्कृतिक चलन, धर्म, र मानिसहरूका समूहहरूलाई छुट्याउन प्रयोग गरिने अन्य विशेषताहरू जस्ता सामान्य विशेषताहरूको सेटलाई जनाउँछ। यो जातीय पहिचान संग पङ्क्तिबद्ध हुन सक्छ वा हुन सक्छ।"
  },
  "custom_comms_quick_add": {
    "mobile_app_links": {
      "email_text": "तपाईंको यन्त्रमा निर्भर गर्दै, प्राथमिक स्वास्थ्य पास डाउनलोड गर्न तलको लिङ्कमा क्लिक गर्नुहोस्।"
    }
  },
  "stepper": {
    "next": "अर्को",
    "back": "पछाडि",
    "complete": "पूरा"
  },
  "registration_direcory": {
    "keyword_search_label": "कीवर्ड, शहर, जिप कोड, संगठन नाम",
    "keyword_search_label_mobile": "सहर, जिप कोड, संस्थाको नाम",
    "zero_results": {
      "title": "हामीले त्यो खोज शब्दको साथ कुनै पनि प्रदायकहरू फेला पारेनौं।",
      "sub_title": "यसद्वारा आफ्नो प्रदायक खोज्ने प्रयास गर्नुहोस्:",
      "p1": "जिप कोड",
      "p2": "सहर वा सहरको नाम",
      "p3": "जिल्लाको नाम",
      "p4": "प्रदायक वा संस्थाको नाम"
    }
  },
  "registration_flyer": {
    "open_camera": "आफ्नो यन्त्रमा क्यामेरा एप खोल्नुहोस्",
    "point_to_qr_code": "QR कोडमा पोइन्ट गर्नुहोस् र लिङ्कमा ट्याप गर्नुहोस्",
    "complete_registration": "पूरा दर्ता",
    "need_help": "सहयोग चाहियो? हामीलाई सम्पर्क गर्नुहोस्"
  },
  "vaccine_info_sheets": {
    "vaccine_info_attestation": "खोप जानकारी प्रमाणीकरण",
    "select_checkbox": "कृपया निम्न समीक्षा गर्नुहोस् र अगाडि बढ्नु अघि तलको उपयुक्त चेकबक्स चयन गर्नुहोस्।",
    "opportunity_to_read": "मैले खोप सूचना (हरू) (\"VIS\") वा आकस्मिक प्रयोग प्राधिकरण (\"EUA\") खोप (हरू) प्रशासित गर्नका लागि उपलब्ध गराएको तथ्य पाना पढ्ने मौका पाएको छु वा मेरो लागि पढी दिने पाएको छु :",
    "vaccine_info_sheet": "खोप जानकारी पाना",
    "vaccine_fact_sheet": "खोप तथ्य पाना",
    "vaccine_info_statement": "खोप जानकारी कथन"
  },
  "exemption": {
    "dtap_tdap": "DTaP/TDap",
    "mmr": "MMR",
    "ipv_opv": "पोलियो",
    "hib": "HIB",
    "hep_b": "HEPB",
    "var": "भेरिसेला",
    "hep_a": "HEPA",
    "pcv": "PCV",
    "mcv": "MCV"
  },
  "test_strip": {
    "A": "this kit includes tests for chlamydia and gonorrhea.",
    "B": "this kit includes tests for chlamydia and gonorrhea.",
    "C": "this kit includes tests for syphilis.",
    "D": "this kit includes tests for HIV, syphilis, chlamydia and gonorrhea.",
    "E": "this kit includes tests for HIV and syphilis.",
    "box_type": "Box {{type}}",
    "administered_text": "We will contact you when your kit reaches the lab and when your results are ready.",
    "activate_kit": "Activate my test kit",
    "register_another_test": "Register another test",
    "credentials": {
      "title": "Let's get started",
      "label": "Contact",
      "p1": "We'll start with some info about you.",
      "location_step": {
        "p1": "Who provided your test kit?",
        "load": "Load more"
      }
    },
    "checklist": {
      "not_urinated": "You have not urinated in the last hour.",
      "bathroom": "You have access to a bathroom for urine collection.",
      "anal_swab": "You have access to a private place to perform an anal swab.",
      "blood_extraction": "You have access to a clean environment for blood extraction.",
      "washed_hands": "You have washed your hands with soap and warm water for 30 seconds.",
      "title": "Are you ready to begin sample collection?",
      "no_alcohol": "Do not drink alcohol on the day of collection.",
      "menstruation": "Do not collect vaginal swab specimen during menstruation or within 24 hours of intercourse."
    },
    "confirm": {
      "title": "Now, begin collecting your sample",
      "p1": "Is this kit information correct?"
    },
    "display_name": {
      "A": "Chlamydia & Gonorrhea (Single Site)",
      "B": "Chlamydia & Gonorrhea (3 Site)",
      "C": "Syphilis",
      "shortened": {
        "A": "Chlamydia & Gonorrhea",
        "B": "Chlamydia & Gonorrhea",
        "C": "Syphilis"
      },
      "CC": "COVID-19",
      "CE": "Celiac Disease Screening",
      "CR": "Creatinine Test",
      "D": "4 Panel Test - HIV, Syphilis, Chlamydia & Gonorrhea (Single Site)",
      "DD": "Diabetes Screening (HbA1c)",
      "E": "HIV & Syphilis",
      "H": "HPV",
      "P": "Hepatitis C",
      "HS": "Herpes Simplex",
      "I": "HIV",
      "TT": "Testosterone Level",
      "VD": "Vitamin D",
      "VS": "Viral Surveillance",
      "AP": "Chlamydia & Gonorrhea",
      "NN": "COVID-19",
      "HH": "COVID-19, Flu A/B, & RSV",
      "FFFWW": "COVID-19 & Flu A/B",
      "YYYWW": "COVID-19, Flu A/B & RSV",
      "HA": "COVID-19, Flu A/B, & RSV"
    },
    "rescan": "Rescan",
    "scanned": "You've scanned Box {{type}}",
    "not_yet": "Not Yet",
    "success_registration": "Registration success!",
    "no_box": "Don't have a box?",
    "faq_link": "Visit FAQs",
    "welcome": "Welcome!",
    "scan": {
      "title": "You're one step closer to a healthier you. Scan or enter the **kit bar code** on the side of your box.",
      "p1": "Please begin when you are ready to begin sample collection. Otherwise, return to this page when you are ready.",
      "unknown_barcode": "You’ve entered an unknown barcode. Please contact support for assistance."
    },
    "appointments": {
      "title": "Select the patient & provider",
      "p1": "We found more than one profile associated with your contact information. Select the correct profile:",
      "new": "New patient or provider"
    },
    "verify": {
      "title": "Confirm your identity",
      "p1": "We found your patient record."
    },
    "section_1": "Confirm kit contents",
    "section_2": "Test collection",
    "section_3": "Packaging",
    "section_4": "Return kit",
    "box_a": "Box A",
    "box_b": "Box B",
    "box_c": "Box C",
    "kit_flow": {
      "button_1": "Continue to packaging",
      "button_2": "I'm ready",
      "button_3": "My kit is complete and packed",
      "button_4": "I'm finished",
      "button_5": "Next test",
      "button_6": "Skip instructions"
    },
    "contents": {
      "header": "Great! Let's make sure your kit is complete",
      "text_1": "Does your test kit box contain the following?",
      "blood_collection_card": "Blood collection card",
      "blood_collection_card_subtitle": "(1 or 2 depending on test kit)",
      "alchohol_pads": "2 alcohol pads",
      "lancets": "Single use lancets",
      "up_to_other": "(Up to 4 depending on test kit)",
      "adhesive_bandages": "Adhesive bandages",
      "pipette": "Pipette",
      "urine_tube": "Urine collection tube",
      "urine_cup": "Urine collection cup",
      "oral_swab": "Oral swab",
      "oral_tube": "Oral collection tube",
      "anal_swab": "Anal swab",
      "anal_tube": "Anal collection tube",
      "vaginal_tube": "1 vaginal collection tube",
      "vaginal_swab": "1 vaginal swab",
      "biodegradable_paper": "Biodegradable collection paper",
      "bottle_and_probe": "Sampling bottle and probe",
      "prepaid_envelope": "Pre-paid shipping envelope",
      "biohazard_subtitle": "(with absorbent pad)",
      "biohazard_bag": "1 specimen bag",
      "biohazard_bag_plural": "({{count}}) Biohazard bags",
      "alcohol_pads_plural": "{{count}} alcohol pads",
      "sterile_gauze_pad": "1 sterile gauze pad",
      "collection_tube": "1 collection tube",
      "collection_swabs": "2 collection swabs"
    },
    "packaging": {
      "title": "Packaging checklist",
      "subtitle": "Before you ship or drop your kit at a kiosk, make sure that:"
    },
    "packaging_box_a": {
      "step_1": "You have packed all test kit contents according to the instructions",
      "step_2": "You have used all collection materials contained in the kit to collect the required specimen",
      "step_3": "Your **date-of-birth** is written on the specimen collection tube using MM/DD/YYYY format",
      "step_4": "The **collection date** is written on the specimen collection tube using MM/DD/YYYY format",
      "step_5": "The specimen collection tube is sealed in its biohazard bag",
      "step_6": "All specimens are placed into the box",
      "step_7": "The box is placed into the pre-paid shipping envelope",
      "step_8": "The return shipping envelope is completely sealed"
    },
    "packaging_box_b": {
      "step_1": "You have packed all test kit contents, even if you skipped the test(s)",
      "step_2": "You have used all collection materials contained in the kit to collect the required specimens",
      "step_3": "Your **date-of-birth** is written on all specimen collection tubes using MM/DD/YYYY format",
      "step_4": "The **collection date** is written on all specimen collection tubes using MM/DD/YYYY format",
      "step_5": "Each specimen collection tube is sealed in its own biohazard bag (only **one** specimen per bag)",
      "step_6": "All specimens are placed into the box",
      "step_7": "The box is placed into the pre-paid shipping envelope",
      "step_8": "The return shipping envelope is completely sealed"
    },
    "packaging_box_c": {
      "step_1": "You have packed all test kit contents, including used lancets.",
      "step_2": "Your **date-of-birth** is written on the blood collection card using MM/DD/YYYY format",
      "step_3": "The **collection date** is written on the blood collection card using MM/DD/YYYY format",
      "step_4": "The blood collection card and all used lancets are placed in the biohazard bag",
      "step_5": "The biohazard bag is placed into the box",
      "step_6": "The box is placed into the pre-paid shipping envelope",
      "step_7": "The return shipping envelope is completely sealed"
    },
    "success": {
      "header_1": "Great!",
      "text_1": "You're ready to collect your first sample.",
      "header_2": "You did it!",
      "text_2": "**Next up:** package your kit to send back.",
      "header_3": "Great job!",
      "text_3": "You’re one step closer to better health.",
      "header_4": "Nice job!",
      "text_4": "Just one more and you’re done!",
      "text_5": "You're ready to collect your sample."
    },
    "instructions": {
      "title": "Select a test to view instructions:",
      "subtitle": "Test",
      "button": "Instructions",
      "pdf": "PDF instructions"
    },
    "instructions_box_a": {
      "title": "Urine collection",
      "subtitle": "For this test you will need the following materials:",
      "step_1": "Do not urinate for at least 1 hour prior to collection. Using first morning urine is best.",
      "step_2": "Urinate directly into the PeeCanter or collection cup, filling between 1/3 and 1/2.",
      "step_3": "Pour from the PeeCanter or use the pipette to transfer urine from the collection cup into the collection tube(s) marked with an orange <span style=\"color: #FF5000\">**\"URINE\"**</span> label.",
      "step_4": "Fill the collection tube(s) until the combined liquid is between the designated fill lines.",
      "step_4_sublabel": "Do not fill tube past the maximum fill line or specimen will be rejected.",
      "step_5": "Place lid onto the collection tube and ensure it is closed evenly and tightly.",
      "step_6": "Write your date of birth and the specimen collection date (today’s date) in MM/DD/YYYY format onto the tube in the designated area.",
      "step_7": "Place specimen into an empty biohazard bag with the absorbent pad and ensure the seal is closed completely."
    },
    "instructions_box_b": {
      "test_1": {
        "title": "Urine collection",
        "subtitle": "For this test you will need the following materials:",
        "step_1": "Do not urinate for at least 1 hour prior to collection. Using first morning urine is best.",
        "step_2": "Urinate directly into the PeeCanter or collection cup, filling between 1/3 and 1/2.",
        "step_3": "Pour from the PeeCanter or use the pipette to transfer urine from the collection cup into the collection tube(s) marked with an orange <span style=\"color: #FF5000\">**\"URINE\"**</span> label.",
        "step_4": "Fill the collection tube(s) until the combined liquid is between the designated fill lines.",
        "step_4_subtitle": "Do not fill tube past the maximum fill line or specimen will be rejected.",
        "step_5": "Place lid onto the collection tube and ensure it is closed evenly and tightly.",
        "step_6": "Write your date of birth and the specimen collection date (today’s date) in MM/DD/YYYY format onto the tube in the designated area.",
        "step_7": "Place specimen into an empty biohazard bag with the absorbent pad and ensure the seal is closed completely."
      },
      "test_2": {
        "title": "Oral swab",
        "subtitle": "For this test you will need the following materials:",
        "step_1": "Hold the swab between the faint score line (if present) and the cotton-topped portion of the swab.",
        "step_2": "Insert the swab into your mouth and rub the swab tip against the back of your throat for 10 seconds.",
        "step_3": "Carefully withdraw the swab and place into the collection tube marked with a blue <span style=\"color: #001F70\">**\"ORAL\"**</span> label. Break the swab at the score line by bending against the collection tube.",
        "step_4": "Place lid onto the collection tube and ensure it is closed evenly and tightly.",
        "step_5": "Write your date of birth and the specimen collection date (today’s date) in MM/DD/YYYY format onto the tube in the designated area.",
        "step_6": "Place specimen into an empty biohazard bag with the absorbent pad and ensure the seal is closed completely."
      },
      "test_3": {
        "title": "Anal swab",
        "subtitle": "For this test you will need the following materials:",
        "step_1": "Hold the swab between the faint score line (if present) and the cotton-topped portion of the swab.",
        "step_2": "Insert the swab 3 - 5 cm (1 - 2”) into the anal canal. Gently turn the swab for 5 - 10 seconds to collect any potential organisms.",
        "step_3": "Carefully withdraw the swab and place into the collection tube marked with a green <span style=\"color: #00C08C\">**“ANAL”**</span> label. Break the swab at the score line by bending against the collection tube.",
        "step_4": "Place lid onto the collection tube and ensure it is closed evenly and tightly.",
        "step_5": "Write your date of birth and the specimen collection date (today’s date) in MM/DD/YYYY format onto the tube in the designated area.",
        "step_6": "Place specimen into an empty biohazard bag with the absorbent pad and ensure the seal is closed completely."
      }
    },
    "instructions_box_c": {
      "title": "Blood collection",
      "subtitle": "For this test you will need the following materials:",
      "step_1": "Write your name, date of birth, and the date of collection in the designated fields. Use MM/DD/YYYY format.",
      "step_2": "Open blood card flap to expose the circles on the blood collection paper. Do not touch the blood collection paper.",
      "step_3": "Wash hands with warm water for at least 30 seconds, then shake hands vigorously for 15 seconds to encourage blood flow to your fingers.",
      "step_4": "Clean fingertip with alcohol pad. It is best to use the middle or ring finger of your non-dominant hand.",
      "step_5": "Take the lancet and twist off the cap. Press the small tip firmly into the tip of your finger, until the needle ejects with a click. Keeping your hand below your heart during collection, massage finger from base to tip to encourage blood flow.",
      "step_5_subtitle": "Lancets are single use. All lancets need to be returned with your sample to the laboratory for disposal.",
      "step_6": "Starting at the center, apply 3-6 drops to fill the circle and soak through the collection paper. Do not touch the paper with your finger as this will restrict blood flow. Once circle is full, move on to next circle. It is okay for blood to extend beyond lines, but do not let blood spots spread into each other.",
      "step_6_subtitle": "Do not add additional blood to a circle once completed or dry. The “layering” of blood will invalidate the collection.",
      "step_7": "Without closing the blood card, lay it on a flat surface and allow the blood collection paper to air dry at room temperature, for at least 30 minutes. Do not heat, blow dry, or expose the blood collection paper to direct sunlight. Heat will damage the specimen.",
      "step_7_subtitle": "Check the back side of blood collection paper. Blood should saturate all the way through and fill each circle of the collection paper.",
      "step_8": "When blood collection paper is dry, close blood card by tucking flap. Place the blood card and lancets into biohazard bag with the desiccant pack. Ensure biohazard bag is properly sealed."
    },
    "test_tips": {
      "title": "Tips for Proper Blood Collection",
      "subtitle": "For best results:",
      "step_1": "Be sure you are not dehydrated while performing collection. Hydration promotes blood flow.",
      "step_2": "Do not perform collection immediately after smoking.",
      "step_3": "Washing and warming your hands under warm water will help promote blood flow in your hands.",
      "step_4": "Shake hands vigorously towards the floor to encourage blood flow to your fingers.",
      "step_5": "Keep collection device and hands below your heart during collection for best blood flow.",
      "step_6": "You may need more than one finger prick. Repeat these tips between each finger prick."
    },
    "shipping": {
      "header": "Your kit is ready to ship!",
      "text": "**Congrats!** You finished your health screening."
    },
    "pick_up": "Pick up your recommended test kit from the site staff and register below",
    "short_display_name": {
      "A": "Chlamydia & Gonorrhea",
      "B": "Chlamydia & Gonorrhea",
      "C": "Syphilis",
      "CC": "Colorectal Cancer",
      "CE": "Celiac",
      "CR": "Creatinine",
      "D": "HIV, Syphilis, Chlamydia & Gonorrhea",
      "DD": "Diabetes",
      "E": "HIV & Syphilis",
      "H": "HPV",
      "P": "Hepatitis C",
      "HS": "Herpes Simplex",
      "I": "HIV",
      "TT": "Testosterone",
      "VD": "Vitamin D",
      "VS": "Viral Surveillance"
    },
    "test_kit": "Test Kit",
    "view_results": "View results",
    "recommended_kit_title": "Recommended Test Kits",
    "instructions_box_h": {
      "title": "Vaginal Swab Collection",
      "subtitle": "Collection tubes may contain a liquid preservative. <span class=\"text-danger\">**Do not empty liquid preservative from the collection tubes if present.**</span>",
      "step_1": "Hold the swab between the faint score line (if present) and the cotton-tipped portion of the swab in one hand and separate the labia (folds of skin around the vaginal opening).",
      "step_2": "Insert the swab 5cm (2in) into the vaginal opening. Gently turn the swab for 30 seconds while rubbing the swab against the walls of the vagina.",
      "step_3": "Carefully withdraw the swab and place into the collection tube marked with a <span class=\"text-danger\">**RED “VAGINAL” LABEL**</div>. Break the swab by bending against the collection tube.",
      "step_4": "Place lid onto the collection tube and ensure it is closed evenly and tightly.",
      "step_5": "Write your date of birth in MM/DD/YYYY format onto the tube in the designated area (DOB).",
      "step_6": "Write the specimen collection date (today’s date) in MM/DD/YYYY format onto the tube in the designated area (Coll. Date).",
      "step_7": "Place specimen into the empty biohazard bag with the absorbent pad and ensure the seal is closed completely."
    },
    "packaging_box_h": {
      "step_3": "Your **date-of-birth** is written on the specimen collection tube using MM/DD/YYYY format",
      "step_4": "Your **collection date** is written on the specimen collection tube using MM/DD/YYYY format",
      "step_6": "The biohazard bag is placed into the box"
    },
    "instructions_box_cc": {
      "title": "Stool collection",
      "step_1": "Write your date of birth and the specimen collection date (today’s date) in MM/DD/YYYY format onto the tube in the designated area. Open green cap by twisting and lifting.",
      "step_2": "Place supplied collection paper into toilet bowl on top of water.",
      "step_3": "Deposit stool sample on top of collection paper.",
      "step_4": "Collect sample from stool before paper sinks and stool sample touches water.",
      "step_5": "Scrape the surface of the fecal sample with the sample probe.",
      "step_6": "Cover the grooved portion of the sample probe completely with stool sample.",
      "step_7": "Close sampling bottle by inserting sample probe and snapping green cap on tightly. Do not reopen.",
      "step_8": "Flush. Collection paper is biodegradable and will not harm septic systems.",
      "step_9": "Wrap sampling bottle in absorbent pad and insert in to  the biohazard bag."
    },
    "contact_support": {
      "title": "Let's fix this",
      "text_1": "We are sorry to hear that something is wrong!",
      "text_2": "Please contact us to let us know what’s wrong and we will help to replace your kit."
    },
    "contact_support_success": {
      "title": "We received your message",
      "text_1": "Thanks for contacting us.",
      "text_2": "A member of our support team will be in touch soon.",
      "button_text": "Return home"
    },
    "kit_in_transit_to_patient": "Your order is on its way! Most orders are delivered within 2-5 business days. Once you receive your kit, we will send you more information on how to activate it and send it back.\n\nTracking Link: https://tools.usps.com/go/TrackConfirmAction_input?strOrigTrackNum={{tracking_number}}",
    "kit_received_at_lab": "The lab received your kit! You'll receive a notification to check your patient portal when results are ready",
    "registration_confirmation": "Your registration is now complete! Visit your registration confirmation page to activate your test kit:",
    "kit_ordered_online": "We've received your kit order and we will send an update once it ships! \n\nOrder #: {{order_number}} \nOrder date: {{order_date}}",
    "kit_ordered_online_email": {
      "header": "We received your order!",
      "p1": "We've received your order! Once your order ships, we'll send you another update.",
      "order_number": "Order #",
      "order_date": "Order date"
    },
    "completed": "Completed",
    "I": "this kit includes tests for HIV.",
    "CC": "this kit includes tests for COVID-19.",
    "CE": "this kit includes tests for celiac disease.",
    "CR": "this kit includes tests for creatinine.",
    "DD": "this kit includes tests for diabetes.",
    "H": "this kit includes tests for HPV.",
    "P": "this kit includes tests for hepatitis C.",
    "HH": "this kit includes tests for COVID-19, Flu A/B, and RSV.",
    "HS": "this kit includes tests for HSV-2.",
    "TT": "this kit includes tests for testosterone.",
    "VD": "this kit includes tests for vitamin D.",
    "nasal_collection": {
      "title": "Nasal collection",
      "subtitle": "For this test you will need the following materials:",
      "step_1": "Write your Date of Birth and the Collection Date on your collection tube. Use MM/DD/YYYY format for the dates (i.e., 08/29/2023).",
      "step_2": "Wash your hands with soap and water thoroughly before proceeding and after completeing all steps.",
      "step_3": "Remove the swab from the wrapper with your clean hands. Hold the swab with the white tip up. Do not touch the tip of the swab to anything.",
      "step_4": "Holding the swab with one hand, gently insert the white tip of the swab about 1/2 to 3/4 inces (1 to 2 cm) into the opening of your nose. Rotate the swab for 15 to 30 seconds. Make sure the swab touches the insides of your nose. Remove the swab from your nose being careful not to touch your skin. Do not set the swab down. Repeat in the other nostril.",
      "step_5": "While still holding the swab, unscrew and remove the cap from the transport tube without setting the cap down. Place the swab into the tube with the white tip down, taking great care not to spill or splash the liquid in the collection tube.",
      "step_6": "Place lid onto the collection tube and ensure it is closed evenly and tightly.",
      "step_7": "Place specimen into the empty biohazard bag with the absobent pad and ensure the seal is closed completely. Return the biohazard bag to the kiosk drop box."
    },
    "NN": "this kit includes tests for COVID-19.",
    "YYYWW": "this kit includes tests for COVID-19, Flu A/B, and RSV.",
    "FFFWW": "this kit includes tests for COVID-19 and Flu A/B.",
    "HA": "this kit includes tests for COVID-19, Flu A/B, and RSV.",
    "drop_off": {
      "header": "Your kit is ready to drop off!"
    }
  },
  "copy_link": "पृष्ठ लिङ्क प्रतिलिपि गर्नुहोस्",
  "copied_link": "लिंक प्रतिलिपि गरियो!",
  "vaccines": {
    "descriptions": {
      "vaccine_flu": "सामान्य फ्लू विषाणु विरुद्ध सुरक्षा",
      "vaccine_covid": "कोविड -19 विषाणु विरुद्ध सुरक्षा",
      "tdap": "Tdap खोपले टिटानसलाई रोक्न सक्छ",
      "polio": "पोलियो भाइरस विरुद्ध सुरक्षा। विद्यालय सुरु गर्नु अघि बच्चाहरूको लागि आवश्यक छ।",
      "varicella": "खोप जसले ठेउलाबाट बचाउँछ",
      "shingles": "खोप जसले जनैइ खटिराबाट  जोगाउँछ। न्यूनतम उमेर 18 हो।",
      "human_papillomavirus": "खोप जसले HPV विरुद्ध सुरक्षा गर्छ। 11 वर्षका बच्चाहरूको लागि सिफारिस गरिएको।",
      "meningococcal": "खोपले चार प्रकारका मेनिन्गोकोकल ब्याक्टेरियाबाट जोगाउँछ।"
    }
  },
  "viral_surveillance": {
    "shipping": {
      "title": "Prepare to ship your sample:",
      "step_1": {
        "label": "Put the biohazard bag into box.",
        "substep_1": "Remove the return shipping mailer from your test kit.",
        "substep_2": "Place the biohazard bag into the original collection kit box and close the box."
      },
      "step_2": {
        "label": "Put box into return shipping mailer.",
        "substep_1": "Place the box into the return shipping mailer and seal the mailer.",
        "substep_2": "Do not fold or cover the UN3373 diamond symbol."
      },
      "step_3": {
        "label": "Return to Fedex location.",
        "substep_1": "Return kit on the same day of collection (before the last pickup) to a Fedex location.",
        "substep_2": "Call for a FedEx pickup at 1.800.GoFedEx."
      }
    },
    "overview": {
      "title": "Welcome to the Viral Sequencing Program!",
      "subtitle": "Your participation helps provide valuable information on which respiratory pathogen variants are circulating in your area. Please follow the instructions that came with your kit to get started."
    },
    "instructions": {
      "title": "Collect your sample:",
      "step_1": "Wash your hands.",
      "step_2": "Remove the cap of the collection tube and open the swab package.",
      "step_2a": "Remove the swab from the package. Do not touch the soft end with your hands or anything else.",
      "step_3": "Collect a nasal swab.",
      "step_3a": "Insert the entire soft end of the swab into your nostril no more than 3/4 of an inch (1/5cm) into your nose.",
      "step_3b": "Slowly rotate the swab",
      "step_3c": "Gently remove the swab.",
      "step_3d": "Using the same swab, repeat in your other nostril with the same end of the swab.",
      "step_4": "Put swab into the collection tube and cap the tube securely.",
      "step_5": "Put the collection tube into the biohazard bag and seal.",
      "step_6": "Apply even pressure to the ziplock to seal the bag shut."
    },
    "click_to_get_started_button": "Click to get started",
    "get_started_button": "Get started",
    "thank_you": {
      "title": "Thank you for using Primary.Health!",
      "p_3": "You may now close the browser.",
      "p_2": "Please make sure to drop off the viral surveillance kit at your closest FedEx location.",
      "p_1": "Your participation actively helps Public Health officials fight the spread of disease. If you would like to see the impact you are making on Public Health, please take a moment to explore more via [this link]({{link}}). You will not be contacted about your results, as they are anonymous."
    },
    "verify_dob_title": "First, let's confirm your details",
    "verify_dob_subtitle": "This helps us ensure the correct person is using the test kit.",
    "activate_kit": "Activate a kit:",
    "steps_complete": "Steps complete",
    "opt_in": {
      "section_1_title": "How it works",
      "section_1_text_1": "Sign up below and receive a viral sequencing test kit in 1-3 business days.",
      "section_1_text_2": "Upon receiving the test kit",
      "section_1_text_3": "Send the sample back per the instructions in the kit using our prepaid label.",
      "sign_up": "Sign me up!",
      "no_to_participate": "No",
      "section_2_title": "What is the impact of your participation?",
      "section_2": {
        "card_1_title": "Rapid response to new variants",
        "card_1_text": "Genetic sequencing helps track the spread and emergence of new respiratory pathogen variants, allowing public health systems to quickly ramp up preparedness efforts.",
        "card_2_title": "Track how Respiratory Pathogens are changing",
        "card_2_text": "Respiratory pathogen sequencing analyzes the “genetic blueprint” of the virus. This helps scientists trace how the virus changes, enabling it to spread more easily, make people more sick",
        "card_3_title": "You have complete privacy",
        "card_3_text": "The sequencing will be processed anonymously. The lab will never contact you about your results"
      },
      "title": "You’re eligible for California’s Free Respiratory Pathogen Viral Sequencing Project!",
      "subtitle": "You can help public health officials protect communities against respiratory pathogens! If you opt in to participate you will receive a free viral sequencing kit that will test what respiratory pathogen strain you are infected with. Results will help officials track the spread of respiratory pathogen variants. See below for more information and to sign up.",
      "continue_to_result": "Continue to my result",
      "create_page_title": "Thank you!",
      "create_page_text_1": "Your kit should arrive in the next 1-3 business days.",
      "create_page_text_2": "Your participation actively helps Public Health officials fight the spread of diseases. You will be notified when your test kit order has been approved and when it has shipped."
    }
  },
  "family_registrations": {
    "find_clinic": "एक क्लिनिक खोज्नुहोस्",
    "select_pin": "विवरणहरू हेर्न पिन चयन गर्नुहोस्",
    "miles_shorten": "%{माइल} माइल",
    "next_available": "अर्को उपलब्ध छ",
    "appointment_type": "अपोइन्टमेन्ट प्रकार",
    "individual_appointment": "व्यक्तिगत नियुक्ति",
    "family_appointment": "पारिवारिक भेटघाट",
    "vaccines": "खोपहरु",
    "what_kind_appointment": "तपाई कस्तो प्रकारको अपोइन्टमेन्ट बुक गर्न चाहनुहुन्छ?",
    "select_family_or_individual": "यदि तपाइँ एकै पटक धेरै परिवारका सदस्यहरूको लागि समय तालिका बनाउँदै हुनुहुन्छ भने पारिवारिक भेटघाट चयन गर्नुहोस्।",
    "vaccines_selected": "खोपहरू चयन गरियो",
    "where_care": "तपाईं कहाँ हेरचाह प्राप्त गर्न चाहनुहुन्छ?",
    "select_vaccines_for_individual": "तपाईंको अपोइन्टमेन्टको लागि खोपहरू चयन गर्नुहोस् (वैकल्पिक)।",
    "select_vaccines_for_family": "तपाईंको पारिवारिक भेटघाटको लागि खोपहरू चयन गर्नुहोस् (वैकल्पिक)।",
    "schedule_services": "सेवाहरूको तालिका बनाउनुहोस्",
    "add_family": "परिवारका सदस्यहरू थप्नुहोस् र तलका सेवाहरू चयन गर्नुहोस्।",
    "family_member": "परिवार सदस्य {{number}}",
    "morning": "बिहान",
    "afternoon": "दिउँसो",
    "slot_available": "{{number}} उपलब्ध छ",
    "within_x_miles": "{{miles}} माइल भित्र",
    "any_distance": "बाट कुनै पनि दूरी",
    "partial_results": "आंशिक परिणामहरू",
    "partial_matches": "निम्न क्लिनिकहरूले तपाईंले खोजिरहनुभएका केही तर सबै खोपहरू प्रस्ताव गर्दैनन्",
    "no_matches": "त्यो खोजले कुनै पनि मेल फेला पारेन। राम्रो परिणामहरूको लागि आफ्नो फिल्टरहरू परिवर्तन गर्ने प्रयास गर्नुहोस्।",
    "no_clinics_found": "तपाईंको खोजसँग मेल खाने कुनै पनि क्लिनिकहरू छैनन्।",
    "broaden_filters": "राम्रो परिणामहरूका लागि आफ्नो फिल्टरहरू फराकिलो बनाउने प्रयास गर्नुहोस्।",
    "unavailable_vaccines": "अनुपलब्ध खोपहरू:",
    "available_vaccines": "उपलब्ध खोपहरू:",
    "select_date": "मिति चयन गर्नुहोस्",
    "available_appointments": "उपलब्ध अपोइन्टमेन्टहरू",
    "appointment_scheduling_info": "तपाईं अर्को चरणमा परिवारका विशिष्ट सदस्यहरूलाई भेट्ने समय तोक्न सक्षम हुनुहुनेछ। यदि तपाईंले छनौट गर्नुभयो भने, तपाईंले विभिन्न दिनहरूमा भेटघाटहरू अनुसूचित गर्न सक्नुहुन्छ।",
    "hold_selected_appointments": "हामी १५ मिनेटको लागि तपाइँका चयन गरिएका अपोइन्टमेन्टहरू राख्नेछौं।",
    "appointments_selected": "नियुक्तिहरू चयन गरियो",
    "no_appointments_selected": "कुनै भेटघाट चयन गरिएको छैन",
    "vaccines_needed": "खोप आवश्यक छ",
    "select_x_appointments": "{{number}} अपोइन्टमेन्टहरू चयन गर्नुहोस्।",
    "more": "थप",
    "less": "कम",
    "register_next_family_member": "अर्को परिवार सदस्य दर्ता गर्नुहोस्",
    "successfully_registered_x_of_y_family_members": "तपाईंले सफलतापूर्वक {{x}} {{y}} परिवार सदस्यहरू दर्ता गर्नुभएको छ",
    "next_family_member": "परिवारको अर्को सदस्य",
    "appointments_abbreviated": "नियुक्तिहरू\r\n",
    "register_for_clinic": "क्लिनिकको लागि दर्ता गर्नुहोस्",
    "select_services": "सेवाहरू चयन गर्नुहोस्",
    "person": "व्यक्ति {{number}}",
    "add_person": "अर्को व्यक्ति थप्नुहोस्",
    "registration_confirmation": "दर्ता पुष्टि"
  },
  "user_mailer": {
    "verify_email": "तपाईंको इमेल प्रमाणित गरौं!",
    "hi_full_name": "नमस्ते {{full_name}},",
    "verify_email_button": "इमेल प्रमाणित गर्नुहोस्",
    "please_verify_email": "आफ्नो इमेल प्रमाणित गर्न तलको बटन प्रयोग गर्नुहोस्।"
  },
  "services": {
    "dptap_dt": "DTaP/Tdap/Td",
    "hepatitis_a": "हेपाटाइटिस ए",
    "hepatitis_b": "हेपाटाइटिस बी",
    "hepatitis_a_b": "हेपाटाइटिस ए र बी",
    "hib": "Hib",
    "mmr": "दादुरा, गलफुलो, रुबेला",
    "meningococcal": "मेनिन्गोकोकल",
    "mpox": "Mpox",
    "pneumococcal": "न्यूमोकोकल",
    "polio": "पोलियो",
    "rsv": "श्वासप्रश्वासमा असर गर्ने भाइरस",
    "rotovirus": "रोटाभाइरस",
    "zoster": "जोस्टर (जनैई खटिरा )",
    "tetanus_diptheria": "टिटानस र डिप्थेरिया",
    "tdap": "Tdap",
    "typhoid": "टाइफाइड",
    "varicella": "भेरिसेला (चिकनपक्स)",
    "covid-19": "कोविड-19 ",
    "covid-19_adult": "कोविड -19  (वयस्क)",
    "covid-19_adult_description": "12 वर्ष वा माथिका व्यक्तिहरूको लागि।",
    "covid-19_children": "कोविड -19  (बाल)",
    "covid-19_children_description": "4 - 11 वर्ष उमेरका बच्चाहरूको लागि।",
    "covid-19_infants": "कोविड -19 (शिशु)",
    "covid-19_infants_description": "बच्चाहरु को लागि 6 महिना - 3 वर्ष पुरानो।",
    "influenza": "इन्फ्लुएन्जा",
    "mmrv": "दादुरा, गलफुलो, रुबेला र ठेउला"
  },
  "deep_archived": {
    "admin": "डाटा सुरक्षा उद्देश्यका लागि, समूह {{test_group_name}} ({{slug}}) मा डाटा संग्रह गरिएको छ र अब यो पृष्ठबाट हेर्न वा परिवर्तन गर्न योग्य छैन। यदि तपाईंलाई सहयोग चाहिन्छ भने कृपया आफ्नो खाता प्रबन्धक वा support@primary.health मा सम्पर्क गर्नुहोस्।",
    "participant": "यो पृष्ठ अब उपलब्ध छैन। कृपया स्वास्थ्य अभियानबाट आफ्ना सम्पर्कहरूमा सम्पर्क गर्नुहोस्: {{test_group_name}}। यदि तपाईंलाई पुरानो रेकर्ड हेर्न समस्या भइरहेको छ भने, कृपया सहायताको लागि support@primary.health मा सम्पर्क गर्नुहोस्।"
  }
}