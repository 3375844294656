import { Button, Card, Col, Row } from 'react-bootstrap';
import React, { useState } from 'react';
import SectionHeader from '../SectionHeader';
import { SectionWrapper } from '../styled';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { kitTypeDisplayLetters } from '../utils';
import { faCircleCheck } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
export const CONFIRM = "CONFIRM";

export default function ConfirmIdentityStep({
  user,
  kitType,
  barcode,
  stepForward,
  isMobile,
  appointment,
  skipInstructions,
}) {
  const { t } = useTranslation();
  const destroyKit = async () => {
    await axios.delete(`/kits/${barcode}?user_id=${user.id}`).catch(err => err.response)
    window.location.href = `/kits?access_code=${appointment.access_code}`
  }
  const [informationConfirmed, setInformationConfirmed] = useState(false);
  return (
    <SectionWrapper>
      <SectionHeader title={t("test_strip.confirm.title")} includeIcon={false} />
      <div style={{marginBottom: '48px'}}>
        <Card style={{height: "fit-content"}}>
          <Card.Body style={{padding: "18px 32px"}}>
            <div className='body big mb-3'>
              <b className="big">{t('instructions.name')}:</b> {user.first_name} {user.last_name}
            </div>
            <div className='body big'>
              <b className="big">{t('registration.date_of_birth')}:</b> {user.date_of_birth.label}
            </div>
          </Card.Body>
        </Card>
        <Row className='mt-4 mb-2 justify-content-center'>
          <div className="kit-body-text pt-2">
            {t('test_strip.confirm.p1')}
            {informationConfirmed && (
              <FontAwesomeIcon
                icon={faCircleCheck}
                color="var(--bs-success)"
                className="ms-2 my-auto"
              />
            )}
          </div>
          <Col xs="auto"><img src="/images/sti-programs/test-kit-2.png" width={isMobile ? '90' : '128' }></img></Col>
          <Col className='body big' style={isMobile ? {} : {inlineSize: 500, overflowWrap: "break-word", alignSelf: "center"}}>
            <b className='body big' style={{color: "#2E948E"}}>{t('test_strip.scanned', {type: kitTypeDisplayLetters(kitType)})}</b>
            <span className="big">: {t(`test_strip.${kitType}`)}</span>
            <div className='mt-2 body big'>
              <b className="big">{t('otc.barcode')}</b> {barcode.toUpperCase()}
            </div>
          </Col>
        </Row>
      </div>
      <div className={`text-center ${isMobile && "d-flex"} ${informationConfirmed ? "d-none" : ""}`} style={{minHeight: "min-content"}}>
        <Button variant="danger" block={isMobile} onClick={destroyKit} data-test-hook="rescan">{t('test_strip.rescan')}</Button>
        <Button className='ms-3' block={isMobile} onClick={() => setInformationConfirmed(true)} data-test-hook="confirm">{t('yes')}</Button>
      </div>
      <div className={`text-center ${isMobile && "d-flex"} ${informationConfirmed ? "" : "d-none"}`} style={{minHeight: "min-content"}}>
        <Button
          variant="outline-primary"
          block={isMobile}
          onClick={skipInstructions}
          data-test-hook="skip-instructions"
        >
          {t('test_strip.kit_flow.button_6')}
        </Button>
        <Button className='ms-3' block={isMobile} onClick={stepForward} data-test-hook="submit">{t('otc.view_instructions')}</Button>
      </div>
    </SectionWrapper>
  );
}
