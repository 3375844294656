export default {
  "date": {
    "order": [
      "year",
      "month",
      "day"
    ],
    "month": "月",
    "day": "日",
    "year": "年",
    "january": "一月",
    "february": "二月",
    "march": "三月",
    "april": "四月",
    "may": "五月",
    "june": "六月",
    "july": "七月",
    "august": "八月",
    "september": "九月",
    "october": "十月",
    "november": "十一月",
    "december": "十二月"
  },
  "user": {
    "send_appointment_confirmation_message": "確認 %{姓名} 您的預約",
    "landline_appointment_reminder_message": "您好, {{full_name}}. 这是对您在 {{name}} 预约的提醒. 您的预约是在 {{date}} 号 {{time}} 时间 {{address}} 地址.",
    "send_at_home_visit_confirmation_message": "您的預約在 {{date}} {{time}} 確認。",
    "send_mail_order_confirmation_message": "確認您的郵購訂單",
    "send_waitlist_message": "您已被列入 {{name}} 的等待名单. 在我们确认预约时间前请不要出现.",
    "verify_contact": {
      "phone": "请通过点击链接：{{confirmation_link}} 确认您的电话号码",
      "email": "{{confirmation_link}}"
    },
    "duplicate_user_continue_registering": "继续在这里登记预约: {{url}}",
    "on_demand_confirmation_message": "您已成功註冊 %{名稱}。",
    "appointment_reminder": "在您预约时间 {{start_time}}之前, 请点击链接完成任何未完成的行动项目并查看您的确认信. 请不要在您的预约前超过15分钟到达.",
    "appointment_reminder_base": "{{text}} 点击这里: {{link}}",
    "test_result_notifier": {
      "text_message": "{{first_name_with_last_initial}} 的患者门户已更新。转至 {{url}} 并使用代码：{{access_code}}",
      "email_subject": "患者门户中的新更新",
      "email_intro": "你好{{first_name_with_last_initial}}",
      "email_p1": "您可以收到新消息或患者更新信息。",
      "email_p2": "单击下面的按钮或使用链接查看您的患者门户并使用代码",
      "email_p3": "查看患者门户：",
      "email_button": "查看门户"
    },
    "landline_appointment_reminder_message_without_time": "您好, {{full_name}}. 这是对您在 {{name}}预约的提醒. 您的预约日期是 {{date}} 地址是 {{address}}.",
    "appointment_reminder_on_demand": "在您 {{date}}的预约前 请点击链接完成任何未完成的行动项目并查看您的确认信.",
    "new_test_result_notifier": {
      "email_intro": "您的患者门户已更新。",
      "email_text_1": "请访问下面的病史门户，查看任何新的患者记录或实验室报告。",
      "email_button": "病史",
      "email_text_2": "您亦可將此URL複製並貼上到瀏覽器："
    },
    "mailers": {
      "email_contact_us": "有問題？ 在此聯絡我們："
    }
  },
  "errors": {
    "generic": {
      "message": "有出错，请重试"
    },
    "messages": {
      "blank": "此处不能为空白"
    },
    "incorrect_credentials": "您的登入資料不正確，請重試。",
    "error_activating_test_kit": "啟動檢測套件時發生錯誤。"
  },
  "type": "打字",
  "payment": {
    "continue_label": "继续至付款",
    "policy_text": "如果您取消或没有出现在您的预约，我们会退回您所付款的80%，在您预约日期的七天后。您可以查看完整的《服务条款》({{link}})",
    "card_number": "卡号",
    "expiry_date": "到期日",
    "pay_button": "付款",
    "no_credit_card": "我会现场支付",
    "cash_payment_helper": "请确保将您 **{{payment}}**的付款方式带到您的预约处",
    "invoice": "发票",
    "pay_by_card": "刷卡支付",
    "cost": "费用",
    "total": "总计",
    "pay_cash_at_appointment": "我会在我{{appointment}}预约时用现金支付",
    "view_invoice": "查看/打印发票",
    "refund_policy": "如果您取消您的预约，我们会在您预约日期的七天后退回您所支付的 {{amount}}。您可以在这里查看完整的 [服务条款]({{link}})",
    "refund_window_passed": "由于预约时间已过，您的预约无法退款。请直接联络网站协调员寻求解决方案。",
    "amount_paid": "已付款项",
    "balance": "余额"
  },
  "consent_waiver": {
    "upload_waiver_or_sign": "上传同意弃权声明或在下方签名栏中签名",
    "stop": "必须同意以完成登记",
    "parent_or_guardian_required_html": "为了最终确认您的登记，您的家长，监护人，或经授权的决定人必须提供对下列表格的同意并签名。您<b>不能</b>独自同意这项服务。 ",
    "send_parent_or_guardian": "您可以将此链接发送给您的家长或监护人或请他们在此页下方签名",
    "consent_guardian_name_signature": "名",
    "consent_for_minor": "代表未成年人签署同意",
    "guardian_signature": "家长，监护人或经授权医疗决策者的签名",
    "after_you_schedule": "年龄在{{age}} 以下的参与者必须由一位家长或监护人代表他们签署同意书。请继续至登记末尾来发送给您的监护人或为您的受抚养人签名",
    "consent_guardian_last_name_signature": "姓",
    "consent_first_and_last_name": "姓名",
    "phone_number": "监护人的电话号码",
    "email": "监护人的电子邮箱",
    "consent_registrar_with_guardian_name_signature": "监护人名字",
    "consent_registrar_with_guardian_last_name_signature": "监护人姓氏",
    "consent_registrar_first_name_signature": "登记人名字",
    "consent_registrar_last_name_signature": "登记人姓氏",
    "consent_on_behalf": "本人代表{{first_name}} {{last_name}}签署。",
    "consent_on_behalf_registrar": "本人获得{{first_name}} {{last_name}}的口头授权代为签署。",
    "consent_on_behalf_registrar_with_guardian": "本人获得{{first_name}} {{last_name}}的监护人的口头授权代为签署。",
    "registrar_phone": "註冊電話號碼",
    "registrar_email": "註冊電郵",
    "consented_by_parent_guardian": "家長/監護人",
    "consented_by_decision_maker": "授權醫療決策者",
    "consented_by_guardian_verbal": "與監護人註冊（已獲得口頭同意）",
    "consented_by_registrar": "註冊者（已獲得口頭同意）",
    "full_name": "全名",
    "print": "打印"
  },
  "registration": {
    "contact_information": "姓名與地址",
    "contact_information_additional": "父母或监护人的联系信息以进行预约交流及结果",
    "personal_information": "人口統計資訊",
    "address_placeholder": "请输入您的家庭住址",
    "first_name_label": "名",
    "last_name_label": "姓",
    "errors": {
      "state": "州 必须是有效的两个字的州代码",
      "phone_number_invalid": "电话号码无效",
      "required": "此项必填",
      "email_domain": "您的输入无效；请再次检查。登记仅限于赞助组织选择的参与者。请尝试使用您的工作或社区邮件。如果您认为您有资格但登记出错，请联系帮助。",
      "age_requirement": "出生日期不满足年龄要求",
      "signature_required": "需要签名",
      "regex_invalid": "此项似乎无效",
      "date_invalid": "该日期不存在",
      "invalid_entry": "请不要继续登记。照屏幕上指示或联系您的联络点",
      "city": "必须是有效城市名",
      "survey_unanswered": "请检查所有带*的必填问题都已填完",
      "postal_code": "必须是有效的五位数字邮政编码",
      "field_required": "{{field}} 必须有一个值",
      "option_required": "您必须选择一个选项",
      "year_length_invalid": "输入包括四位数年份的出生日期",
      "invalid_day": "日期必须在1-31之间",
      "reached_max_chars": "您已经达到字数上限。",
      "chars_max": "字数上限",
      "minimum_length": "輸入資料需要至少 {{length}}字。"
    },
    "insurance_status": {
      "question": "您有医疗保险吗",
      "have_health_insurance": "是的，我有医疗保险",
      "do_not_have_health_insurance": "不是，我没有医疗保险"
    },
    "insurance_policy_holder": {
      "question": "谁是保单持有人？",
      "i_am": "我本人",
      "my_spouse": "我的配偶或伴侣",
      "my_parents": "我的父母",
      "someone_else": "其他人",
      "policy_first_name": "保单持有人的名",
      "policy_last_name": "保单持有人的姓",
      "policy_dob": "保单持有人的出生日期",
      "name": "保单持有人姓名"
    },
    "insurance_information": {
      "title": "保险信息",
      "company_name": "保险公司名字",
      "id_number": "成员身份号码",
      "group_number": "成员小组号码",
      "secondary_insurance_label": "我有第二份保险",
      "take_photo": "请将您的保险卡片拍照。拍照上传后将会自动填充部分信息。",
      "front_of_card": "卡片正面",
      "card_information": "保险卡片信息",
      "back_of_card": "卡片背面"
    },
    "employment_information": {
      "label": "工作",
      "address_1": "公司地址",
      "address_2": "例如：套间200号",
      "phone_number": "公司电话号码",
      "company": "公司名字",
      "postal_code": "雇主邮政编码",
      "employed": "受雇的",
      "sole_prorietor": "独资经营者",
      "not_employed": "未就业",
      "reporting_supervisor": "报告监管者",
      "reporting_department": "报告部门",
      "supervisor_name": "监管者姓名",
      "supervisor_email": "监管者电子邮箱"
    },
    "location_availability": "检测目前优先住在这些区域的社区成员",
    "custom_survey": "调查问卷",
    "confirmation": "复查",
    "waitlisted": "等待名单上",
    "schedule_your_appointment": "安排预约",
    "information": "信息",
    "consent": "同意",
    "location": "位置",
    "symptoms": "症状",
    "address_required": "请输入您的地址",
    "consent_required": "请同意以继续",
    "required_field": "指示必填区域",
    "phone_number": "手机号码",
    "email": "邮件",
    "date_of_birth": "出生日期",
    "minimum_age": "参与者最低年龄是{{year}}岁",
    "no_minimum_age": "检测没有最低年龄限制",
    "continue_button": "继续",
    "email_required": "此项应为有效邮件",
    "done": "完成",
    "signature": "签名",
    "clear_button": "清除",
    "back_button": "后退",
    "choose_location": "选择一个位置",
    "no_slots": "{{location}}（该地点）没有空位了",
    "choose_appointment": "在{{location}}选择一个预约时间",
    "appointment_required": "请选择一个预约时间",
    "phone_number_required": "手机号码无效",
    "phone_number_label": "请输入一个可以接收短信以更快获取测试结果的号码",
    "symptoms_experiencing": "请点击您目前经历的症状。如果您没有症状，请继续。",
    "household": "家庭成员",
    "household_p1": "视情况，为也需要被测试的家庭成员建立一个预约",
    "add_dependent": "添加家庭成员",
    "remove_dependent": "移除受抚养人",
    "dependent_consents": "同意书",
    "submit": "完成",
    "add_waitlist": "添加到等待名单",
    "address": "家庭住址",
    "address_1": "住址行一",
    "address_2": "公寓/套间号",
    "address_city": "城市",
    "address_state": "州",
    "postal_code": "邮政编码",
    "race_ethnicity": "种族",
    "gender": "性别身份",
    "self_described_gender": "自我描述性别",
    "interpreter": "需要口译者？如果是，何种语言？",
    "consent_to_terms": "我同意这些条款",
    "reg_not_open": "登记尚未打开",
    "no_more_avail_spots": "您选择的时间已无法获取。请重试。",
    "consent_helper": "点击并拖动您的鼠标或用手指在下方栏中签名",
    "phone_number_unreachable_label": "固定电话？",
    "select": "选择",
    "test_surveys": "预约问题",
    "edit": "编辑",
    "continue_to_registration": "继续登记",
    "accounts": {
      "already_have_an_account": "已有账号？",
      "log_in": "登录",
      "sign_up": "注册",
      "sign_up_description": "请输入您的信息来创建账户并为未来登记节省时间",
      "log_in_description": "请输入您的邮件和密码，或通过下面的谷歌或Outlook软件登录",
      "sign_in_with_label": "通过{{provider}}登录",
      "password_must_match": "密码必须匹配",
      "password_uppercase": "您的密码必须包含至少(%s)个大写字母",
      "password_lowercase": "您的密码必须包含至少(%s)个小写字母",
      "password_number": "您的密码必须包含至少(%s)个数字",
      "password_special": "您的密码必须包含至少(%s)个特殊字符"
    },
    "password": "密码",
    "password_confirmation": "密码确认",
    "consent_for": "{{name}}的同意书",
    "book_one_time_appointment": "预定一次性预约",
    "duplicate_users": {
      "exists": "您已登记",
      "overlapping_email_and_phone_p1": "我们向您的手机号码和邮箱地址发送了一条信息",
      "p2": "使用您的信息，您可以修改您的预约或输入检测结果",
      "overlapping_phone_and_email_p3": "请检查您的邮箱或手机以获取细节",
      "overlapping_email_p3": "请检查您的邮箱以获取细节",
      "overlapping_phone_p3": "请检查您的手机以获取细节",
      "overlapping_phone_p1": "我们向您的手机号码发送了一条信息",
      "overlapping_email_p1": "我们向您的邮箱地址发送了一条信息",
      "p4": "如果您认为这是一条错误，请通过support@primary.health联系我们",
      "overlapping_email_and_phone_p3": "请检查您的邮箱或手机以获取细节"
    },
    "duplicate_waitlist": {
      "exists": "您已列入等待名单",
      "overlapping_email_and_phone_p1": "我们向您的手机号码和邮箱地址发送了另一封确认信",
      "p2": "使用您的确认信，您可以提供额外信息或将您自己移除等待名单",
      "overlapping_phone_and_email_p3": "请检查您的邮箱或手机以获取细节",
      "overlapping_email_p3": "请检查您的邮箱以获取细节",
      "overlapping_phone_p3": "请检查您的手机以获取细节",
      "overlapping_email_p1": "我们向您的邮箱发送了另一封确认信"
    },
    "insist_guardian_consent": "我代表       提供同意",
    "confirmation_section": {
      "title": "审查并确认",
      "p1": "请在完成登记前复查您的信息",
      "edit_information": "后退并编辑"
    },
    "iemodal": {
      "title": "不支持的浏览器",
      "body": "登记目前可能在这些浏览器上使用"
    },
    "show_other_locations": "点击以显示其他位置",
    "non_us_address": "这是一个国际地址",
    "test_group_user_survey": "参与者筛选",
    "self_consent": "我代表自己同意",
    "address_country": "国家",
    "appointment": "预约",
    "employee_id": "雇员身份证",
    "appointment_time": "预约时间",
    "appointment_location": "地点",
    "phone_or_email": "手机号码或电子邮箱",
    "no_self_consent": "我自己拒绝同意",
    "no_insist_guardian_consent": "我代表 {{full_name}} 拒绝同意",
    "minimum_age_with_months": "参与的最低年龄为 {{year}} 岁零 {{month}} 个月。",
    "additional_consents_helpertext": "稍后可能要求额外许可",
    "assistive_technology": "辅助技术",
    "covid_vaccine_y_n": "您希望预约接种新冠肺炎疫苗吗?",
    "received_covid_vaccine_y_n": "您是否曾接种过新冠肺炎疫苗",
    "covid_vaccinations": "新冠肺炎疫苗接种",
    "select_vaccine_dose": "请选择您希望接种的疫苗",
    "demographic_info": "人口信息",
    "additional_info": "额外信息",
    "self_described_race": "自我描述的种族",
    "verify_contact_information": "验证",
    "verify_contact": {
      "we_sent_you_a_code": "请输入我们发送至 {{contact}} 的代码",
      "confirmation_code": "验证码",
      "code_resent": {
        "email": "另一个验证码已发送到您的电子邮件",
        "phone_number": "另一个验证码已发送到您的手机"
      },
      "did_not_receive_code": "没有收到验证码？",
      "verify_by": {
        "email": "代用电子邮件验证",
        "phone_number": "代用手机号码验证"
      }
    },
    "preferred_method_of_communication": {
      "question": "首選溝通模式",
      "helper_text": "我們會跟您溝通預約最新資訊",
      "phone_number": "電話（僅限短信。可能需要支付數據費）",
      "landline": "我們目前無法支援座機。請提供電郵地址已進行溝通。",
      "verification_method": "您想如何接收驗證碼？",
      "verification_helper_text": "您在登入前會收到驗證碼以確認您的身分。"
    },
    "skip_for_now": "暫時跳過",
    "default_information": {
      "title": "好的，我們會使用這個地址",
      "title_v2": "好的，我们会使用这些联系信息"
    },
    "middle_name_label": "中間名字",
    "confirm_appointment": "確認預約",
    "dob": "出生日期",
    "different_appointment": "另外的預約",
    "select_appointment_time": "選擇預約時間",
    "decline_address": "我拒絕提供地址。 請改用該計劃的地址。",
    "patient_information": "患者資訊",
    "scan_license": "掃描駕駛執照",
    "how_to_contact": "我們應該如何與您聯繫？",
    "decline_email_or_phone": "我拒絕提供電郵地址或電話號碼。我授權該計劃的員工接收我的結果。",
    "medical_screening": "醫療檢查",
    "show_service_at_this_location": "在此地区显示服务",
    "verbal_consent_provided": "根据《美国残疾人法》(Americans with Disabilities Act)，参与者或其合法授权的决策者已提供口头同意书。书面同意书应在24小时内由参与者或其合法授权的决策者提供。",
    "unexpired_written_consent_present": "检测站已保存且未过期的书面同意书",
    "select_to_proceed": "请从以上选项中选择一个并继续",
    "guardians_information": "监护人信息",
    "registrars_information": "注册人信息",
    "optional": "可选",
    "vfc_eligibility": "儿童疫苗计划 (VFC)资格条件",
    "vfc_eligibility_subtext": "您的孩子有机会通过美国疾病控制与预防中心 (CDC) 的儿童疫苗计划 (VFC) 在参与该计划的医疗机构、药房和健康诊所获得免费疫苗"
  },
  "gender_key": {
    "male": "男性",
    "female": "女性",
    "cisgender": "顺性别者",
    "non_binary": "中性或非二元性者",
    "other": "其他",
    "prefer_to_self_describe": "身份未列出",
    "prefer_not_to_disclose": "拒绝回答",
    "transgender_male": "跨性别男性",
    "transgender_female": "跨性别女性",
    "unknown": "未知",
    "non_binary_only": "非二元性",
    "intersex": "间性别者",
    "transgender": "跨性别者",
    "gender_fluid": "性别流动",
    "not_applicable": "不适用"
  },
  "ethnicity": {
    "american_indian_alaska_native": "美国印第安人或阿拉斯加土著",
    "american_indian_central_america": "来自南美或中美的美洲印第安人",
    "asian": "亚洲人",
    "black": "黑人或非洲裔美国人",
    "latinx": "拉丁美洲，拉丁裔或西班牙裔",
    "middle_eastern_north_african": "中东或北非人",
    "native_hawaiian_pacific_islander": "夏威夷土著或太平洋群岛人",
    "white_european": "白人或高加索人",
    "unknown": "未知",
    "prefer_not_to_disclose": "倾向于不透露",
    "asian_indian": "亚裔印第安人",
    "filipino": "菲律宾人",
    "japanese": "日本人",
    "korean": "韩国/朝鲜人",
    "vietnamese": "越南人",
    "other_asian": "其他亚洲人",
    "native_hawaiian": "本地夏威夷人",
    "guamanian_or_chamorro": "关岛或查莫罗人",
    "samoan": "萨摩亚人",
    "other_pacific_islander": "其他太平洋群岛人",
    "chinese": "中国人",
    "help": "种族指用来区分不同人群的一系列身体特征。种族不一定与国籍、或您拥有公民身份的国家相一致。",
    "subtitle": "法规要求我们收集下列所有信息",
    "laotian": "老挝语",
    "cambodian": "柬埔寨语",
    "other": "其他",
    "bangladeshi": "孟加拉国人",
    "hmong": "Hmong族",
    "indonesian": "印尼人",
    "malaysian": "马来西亚人",
    "pakistani": "巴基斯坦人",
    "sri_lankan": "斯里兰卡人",
    "thai": "泰国人",
    "taiwanese": "台湾人",
    "fijian": "斐济人",
    "guamanian": "关岛人",
    "tongan": "汤加人",
    "bahamian": "巴哈马人"
  },
  "languages": {
    "en": "英语",
    "fr": "法语",
    "es": "西语",
    "so": "索马里语",
    "hmn": "苗语",
    "asl": "美语手语（ASL）",
    "kar": "克伦语",
    "am": "阿姆哈拉语",
    "ru": "俄语",
    "om": "奥罗莫语"
  },
  "symptoms": {
    "fever": "发热或发寒",
    "cough": "咳嗽",
    "muscle_aches": "肌肉痛",
    "severe_fatigue": "严重疲劳（胜于以往）",
    "trouble_breathing": "呼吸困难",
    "diarrhea": "拉稀",
    "loss_of_smell": "失去嗅觉",
    "loss_of_taste": "失去味觉",
    "shortness_of_breath": "呼吸急促或困难",
    "headache": "头痛",
    "sore_throat": "咽喉酸痛",
    "congestion": "鼻塞或流鼻涕",
    "nausea": "恶心或呕吐",
    "close_contact": "密切接触过一位受感染者*",
    "helper_text": {
      "close_contact": "*您是否近距离（六英尺内至少15分钟）接触过被确诊有新冠病毒的人？"
    },
    "suspected_exposure": "疑似接触",
    "none": "无症状"
  },
  "instructions": {
    "title": "您已加入，在您离开前……",
    "p1": "此页包含关于您的预约及您预约码的信息",
    "appointment_scheduled": "您的预约已安排",
    "verify_info": {
      "title": "确认您的信息",
      "p1": "验证您联系信息的有效很重要，这样您可以快速进入您的患者门户网站",
      "p2": "一封确认信已被发送到您的邮箱。请点击提供的链接。",
      "p3": "如果您需要修改您的联系信息，请按帮助按钮"
    },
    "verified": "已确认",
    "not_verified": "未确认",
    "resend_text": "还没有收到链接？",
    "resend": "重新发送",
    "skip": "跳过验证并显示确认信",
    "name": "名字",
    "appointment_details": "预约",
    "date_and_time": "日期和时间",
    "add_to_calendar": "加入日历",
    "instructions": "一般指示",
    "successfully_flash": "成功创建预约！",
    "success_flash_email": "成功验证邮箱",
    "success_flash_phone": "成功验证电话号码",
    "mail_order": "您将会在本周内收到邮寄的试剂盒",
    "at_home": "一位医护人员将会在这个时间左右来到您家里",
    "at_home_instructions": "居家指南",
    "mail_order_instructions": "邮寄订单指南",
    "request_additional_appointment": "预定下一场预约",
    "book_additional_appointment": "预定另一场预约",
    "confirmation_code": "确认码",
    "completed": "完成",
    "appointment_barcode": "预约码",
    "dependent_links": "家庭预约链接",
    "on_demand_title": "您已加入，在您离开前……",
    "save_link": "保存这个链接",
    "verify_phone": "验证您的电话号码",
    "verify_phone_description": "验证您的联系信息非常重要，这样您就可以快速访问您的记录。 一条验证短信已发送到您的号码。",
    "verify_email_description": "验证您的联系信息非常重要，这样您就可以快速访问您的记录。 验证电子邮件已发送到您的电子邮件地址。",
    "information": "登记信息",
    "follow_up_appointment": "复诊",
    "get_directions": "获取路线",
    "cancel": "取消预约",
    "reschedule_appointment": "重新安排预约",
    "reschedule_linked_appointments": "重新安排预约",
    "no_slots": "没有更多的可用时间",
    "check_results": "检查患者门户网站",
    "follow_up_modal_header": "请安排您的第二次剂量",
    "are_you_sure_you_want_to_cancel": "您确定要取消这个预约吗？",
    "please_choose_cancellation_reason": "请在下方选择取消原因",
    "additional_details": "请在下面添加任何其他详细信息",
    "errors": {
      "missing_cancellation_reason": "请选择取消原因"
    },
    "verify_email": "验证您的电子邮件",
    "redcap_url": "点击完成您的调查",
    "verify_contact_information": "验证您的联系信息",
    "please_also": "请也",
    "new_title": "確認 %{姓名}",
    "contact_method": "聯絡方式",
    "next_steps": {
      "title": "下一步",
      "p1": "您有一個需要啟動的測試包。當您準備好檢測時，請點擊發送到您電子郵件的連結返回此頁面並啟動測試包。",
      "administered": "We will contact you when your kit reaches the lab and when your results are ready. Results typically take 24-48 hours after they reach the lab. You can view results from your [Medical history]({{link}}) page when they are ready.",
      "resulted": "結果可在您的[病史]({{link}}) 頁面中找到。",
      "shipping_information": "運送資訊"
    },
    "save_this_page": {
      "title": "儲存此頁面",
      "p1": "使用此頁面報告自行檢測或透過病史查看結果。",
      "p2": "要將來訪問此頁面，請點擊確認電子郵件或簡訊中的連結。",
      "p3": "您可以透過新增書籤、將其新增至主畫面或將連結複製到安全位置來儲存此頁面。"
    },
    "show_my_barcode": "顯示條碼",
    "my_account": "我的帳戶",
    "register_another": "註冊另一名參與者",
    "update_vaccine": "更新疫苗訊息",
    "medical_history": "病史",
    "upcoming_appointments": "后续预约安排",
    "reschedule": "重新预约",
    "resend_confirmation": "重新发送确认信息",
    "appointment_details_v2": "预约详情",
    "confirm_cancellation": "确认取消",
    "confirm_cancellation_question": "请问您是否确认取消本次预约？",
    "select_new_appointment_time_below": "请选择以下新的预约时间。如需更改服务或预约地点，请取消此次预约并重新预约。",
    "no_take_me_back": "不，返回上页。",
    "yes_cancel_appointment": "是，取消预约",
    "update_appointment": "更新预约信息",
    "select_new_appointment_time": "选择一个新的预约时间",
    "clinic": "诊所",
    "services": "服务",
    "appointment_missed": "错过预约",
    "appointment_canceled": "取消预约",
    "screening_complete_for": "Screening complete for {{name}}"
  },
  "result_page": {
    "errors": {
      "invalid_access_code": "无效的访问代码"
    },
    "landing": {
      "p1": "要查看您的结果，请输入：",
      "access_code_label": "访问代码",
      "submit_button": "提交",
      "recent_results": "您最近的结果"
    },
    "view_results": {
      "headline": "{{name}} 的测试结果",
      "reregister": "重新注册",
      "status": {
        "likely_positive": "结果不确定",
        "test_not_performed": "需要重新测试",
        "results_ready": "结果已出",
        "processing": "处理中",
        "test_status": "测试状态",
        "test_result": "测试结果",
        "administered": "已执行",
        "results_pending": "結果待定",
        "test_results": "檢測結果"
      },
      "at": "在（地址）{{address}}"
    },
    "result_label": "结果",
    "result": {
      "flu_a": {
        "result_label": "甲型流感测试结果"
      },
      "flu_b": {
        "result_label": "乙型流感测试结果"
      },
      "covid19": {
        "result_label": "新冠肺炎结果"
      },
      "covid": {
        "result_label": "新冠肺炎结果"
      },
      "sars": {
        "result_label": "非典型肺炎（SARS）结果"
      },
      "control": {
        "result_label": "控制"
      },
      "rsv": {
        "result_label": "RSV结果"
      },
      "result": {
        "result_label": "结果"
      },
      "hba1c": {
        "normal": "正常",
        "warning": "糖尿病前期",
        "danger": "糖尿病"
      },
      "lead_venous": {
        "danger": "不正常",
        "normal": "正常"
      },
      "tc": {
        "result_label": "TC 結果"
      },
      "hdl": {
        "result_label": "HDL 結果"
      },
      "trg": {
        "result_label": "TRG 結果"
      },
      "ldl": {
        "result_label": "LDL 結果"
      },
      "non_hdl": {
        "result_label": "非-HDL 結果"
      },
      "tc_hdl_ratio": {
        "result_label": "TC/HDL 比率"
      },
      "glu": {
        "result_label": "GLU 結果"
      },
      "alere_cholestech_ldx": {
        "danger": "有風險",
        "warning": "不正常",
        "normal": "正常"
      },
      "lead": {
        "result_label": "鉛"
      },
      "zinc": {
        "result_label": "鋅"
      },
      "lead_standard_profile": {
        "danger": "不正常",
        "normal": "正常"
      },
      "creatinine": {
        "danger": "不正常",
        "normal": "正常"
      },
      "igg": {
        "result_label": "IgG结果"
      },
      "igm": {
        "result_label": "IgM结果"
      },
      "blood_glucose_fasted": {
        "normal": "正常",
        "warning": "升高",
        "danger": "高",
        "hypoglycemia": "低血糖",
        "prediabetes": "糖尿病前期",
        "diabetes": "糖尿病"
      },
      "total_cholesterol_fasted": {
        "normal": "正常",
        "elevated": "升高",
        "high": "高",
        "low": "低",
        "abnormal": "异常"
      },
      "total_cholesterol_unfasted": {
        "normal": "正常",
        "elevated": "升高",
        "high": "高"
      },
      "a1c_now": {
        "normal": "正常",
        "warning": "预糖尿病",
        "danger": "糖尿病"
      },
      "blood_glucose": {
        "warning": "低",
        "normal": "正常",
        "prediabetes": "前驱糖尿病",
        "danger": "糖尿病",
        "hypoglycemia": "低血糖",
        "medical_emergency": "医疗紧急情况",
        "inconclusive_1": "未定论 1",
        "inconclusive_2": "未定论 2",
        "possible_diabetes": "可能的糖尿病"
      },
      "triglycerides": {
        "result_label": "甘油三酯"
      },
      "blood_glucose_ucsf": {
        "low": "低",
        "normal": "正常",
        "high": "高"
      },
      "syphilis": {
        "result_label": "梅毒结果"
      },
      "hepatitis_c": {
        "result_label": "丙型肝炎结果"
      },
      "hiv": {
        "result_label": "艾滋病病毒结果"
      },
      "rapid_hiv": {
        "positive": "反应性 (初步阳性)",
        "negative": "无反应性 (阴性)",
        "did_not_result": "无效 (不确定)"
      },
      "rapid_hcv": {
        "positive": "反应性 (初步阳性)",
        "negative": "无反应性 (阴性)",
        "did_not_result": "无效 (不确定)"
      },
      "rapid_syphilis": {
        "positive": "反应性 (初步阳性)",
        "negative": "无反应性 (阴性)",
        "did_not_result": "无效 (不确定)"
      }
    },
    "documents": "文件",
    "self_administered": "在 {{location}} 获取的自我执行的检测",
    "patient": "病人",
    "medical_history": "病史",
    "overview_title": "選擇測試或服務以查看更多詳細資訊以及所需的任何其他操作項目。請注意某些測試結果可能尚未提供，或者只有在與提供者交談後才能提供。",
    "insurance_information": "填寫保險資訊",
    "contact_support": "如需协助，请联络客服团队",
    "show": "顯示",
    "hide": "隱藏",
    "lab_report": "實驗報告",
    "contact_provider": {
      "header": "您測試呈陽性",
      "description": "您想與醫療服務提供者討論您的結果和治療嗎？",
      "yes_option_text": "是的，我想與醫療提供者討論",
      "no_option_text": "不用了，我已閱讀並理解我的測驗結果，並不用跟醫療提供者討論",
      "confirm_phone_number_header": "確認您的電話號碼",
      "confirm_phone_number_description": "請確認最適合與您溝通諮詢的電話號碼。",
      "confirm_button_text": "確認",
      "consultation_confirmed_header": "覺人諮詢",
      "consultation_confirmed_description": "提供者將在 2-3 個工作天內致電 {{phone_number}} 給您打電話。",
      "acknowledgement_option_helper_text": "解釋治療的重要性，並讓使用者知道如果他們改變主意如何安排通話。",
      "acknowledgement_confirmed_header": "確認",
      "acknowledgement_confirmed_description": "關於利用相關資源進行治療的重要性。提醒一下，諮詢是完全免費的，提供者可以開處方或其他任何東西來幫助他們解決感染。",
      "acknowledgement_confirmed_change_mind_text": "如果您改變主意，只需點擊「我想要諮詢」。",
      "request_consultation_button_text": "我想要諮詢"
    },
    "phone": "电话",
    "source": "Source"
  },
  "required": {
    "errors": {
      "address_not_in_bounding_box": "您的地址不符合这个计划的地点资格要求。"
    }
  },
  "member": {
    "medical_history": "历史",
    "view_instructions": "查看确认信",
    "next_appointment": "下一个预约",
    "over_18": "我证明我已满18周岁",
    "choose_test_configurations_title": "请选择您此次预约所想要的服务",
    "member_taken_error": "用户已有账号",
    "choose_test_configurations_subtitle": "服务将适用于所有家庭成员",
    "service": "服务",
    "group_or_location": "群 组或位置",
    "details": "细节",
    "see_all": "查看全部",
    "no_history": "目前没有可用的历史",
    "medical_history_title_with_name": "{{name}}的病史",
    "no_dashboard": "目前没有任何可用的东西",
    "product": "产品",
    "price": "价格",
    "quantity": "数量",
    "total_services_selected": "已选择的服务总数",
    "total_price": "总价"
  },
  "or": "或者",
  "account": {
    "errors": {
      "must_be_13": "必须年满13岁才能注册帐户"
    }
  },
  "self_administration": {
    "title": "自我给药",
    "self_administer_action": "自我给药",
    "skip_to_link": "跳到自我给药",
    "select_person": "选择要结账的人",
    "adult": "成人",
    "child": "孩子",
    "checked_out": "已结账",
    "go_back": "回去",
    "switch_to_scanner": "切换到扫描机",
    "enter_barcode": "输入条形码",
    "scan_barcode": "扫描条形码",
    "cancel": "取消",
    "barcode_for": "    的条形码",
    "enter_barcode_manually": "手动输入条形码",
    "instructions": "扫描或输入下方位于您测试试管上的条形码",
    "regex_prefix": "条形码必须由……组成",
    "all_completed": "已完成：所有家庭成员已完成此步骤",
    "errors": {
      "no_appointment": "定位预约时出现问题",
      "barcode_in_use": "该条形码之前已被使用过。如果您之前没有用这个工具箱自我给药，请与给你工具箱的在场工作人员交流，请求一个新的工具箱。否则点击下方的帮助按钮。",
      "test_error_general": "创建测试时出现问题",
      "different_barcodes": "您输入的条形码不匹配"
    },
    "confirm_barcode_input": "再次输入条形码以确认",
    "click_to_self_test": "点击此处进行自我测试",
    "or_enter_barcode_manually": "Or enter manually with all letters and numbers"
  },
  "location_details": {
    "next_appointment": {
      "waitlist": "等待名单",
      "unavailable": "没有可用的预约"
    },
    "labels": {
      "address": "地址",
      "next_appointment": "下个可用的预约",
      "services": "可用的服务"
    }
  },
  "waiting_room": {
    "header": "你在等待室；请稍候，我们正在将您连接到安排系统。",
    "p1": "请耐心等待。 我们将共同结束这场流行病。",
    "signature": "真挚地，",
    "p2": "我们知道新冠病毒的经历会令人感到沮丧。 请在此等候，我们会尽快让您进入网站进行预约。"
  },
  "users": {
    "send_appointment_confirmation_message": "您好 {{full_name}}。这是您在 {{name}} 预约的提醒。您的预约是 {{date}} 号，{{time}}时间 在 {{address}}地址。",
    "send_registration_link": "您好，{{full_name}}。 点击这个链接注册您的 {{name}} {{registration_link}}"
  },
  "follow_up": {
    "first_dose": "第一剂",
    "previous_appointment": "以前的预约",
    "booked_appointment": "您的预约",
    "second_dose": "第二剂",
    "choose_second_location": "选择第二个预约地点"
  },
  "cancellation_types": {
    "cant_get_to_location": "我有时间冲突",
    "timing_conflict": "我感染了新冠病毒",
    "became_ill_with_covid19": "我在别处接受过这项服务",
    "received_service_elsewhere": "其他",
    "other": "选择打印姓名",
    "duplicate_appointment": "此乃重复预约"
  },
  "translation": {
    "consent": {
      "type_name_instead": "打印姓名",
      "type_name": "改為鍵入簽名"
    }
  },
  "email": {
    "confirmation": {
      "your_access_code_is": "您的访问代码是",
      "please_confirm_your_email": "请点击下面的按钮确认您的电子邮件",
      "hello": "您好",
      "click_to_reschedule": "点击以显示说明/重新安排",
      "click_to_reschedule_on_demand": "点击以显示说明"
    },
    "cancellation": {
      "your_appointment_has_been_canceled": "您在 {{time}} {{location}} 的預約已取消。 如果這是一個錯誤，請發送電郵至 support@primary.health"
    }
  },
  "signature_lines": {
    "name": "参加者姓名",
    "date": "日期",
    "signature": "参加者签名",
    "and_or": "和/或",
    "guardian_name": "家长/监护人姓名",
    "guardian_signature": "家长/监护人签名"
  },
  "employer_testing": {
    "hello_name": "您好 {{name}}",
    "results": {
      "negative": "阴性",
      "positive": "阳性",
      "did_not_result": "未知",
      "unknown": "未知",
      "invalid": "无效",
      "pending": "待定"
    },
    "code_reader": {
      "scan_your_code": "扫描测试卡上的代码",
      "find_in_top_right_hand_corner": "扫描代码是您唯一的测试代码，可以在您测试的右上角找到",
      "center_code": "将代码置于此处。",
      "already_used_error": "此测试条码已被使用。 请联系您的测试盒提供者以获得帮助。",
      "click_to_scan": "点击来扫描二维码",
      "scan_new_test_card": "点击这里扫描新的测试卡。",
      "not_working": "不起作用？",
      "try_again": "点击这里重试。"
    },
    "continue": "继续",
    "verify_date_of_birth": {
      "confirm_date_of_birth": "请确认参加者的出生日期以继续",
      "contact_administrator": "如果显示的信息不正确，请与管理员联系以获得帮助",
      "error": "输入的出生日期不正确 - 请重试或联系您的地点管理员更新此信息。"
    },
    "result_entry": {
      "code_is_registered": "您的代码已注册。",
      "take_the_test": "做测试",
      "follow_the_instructions": "按照测试随附的说明，并在您将鼻拭子放入测试卡里的小瓶后开始15分钟计时器。",
      "cant_find_instructions": "找不到说明吗？",
      "view_instructions": "查看Binax Now测试试剂盒说明",
      "start_timer": "开始15分钟计时器（可选）",
      "submit_within_15": "建议：在15分钟内提交您的结果",
      "enter_test_results": "输入测试结果",
      "choose_a_result": "选择描述您的测试卡结果的选项",
      "submit": "提交",
      "must_log_result_and_photo": "您必须记录结果并给测试拍照才能继续",
      "submit_within_15_v2": "建议：在测试运行15分钟后提交结果",
      "click_to_take_photo": "点击来拍照",
      "results_may_be_invalid": "测试结果可能无效"
    },
    "summary": {
      "title": "结果总结",
      "negative_subtitle": "您没有新冠病毒",
      "negative_message": "我们已向您的雇主发送通知，告知您已获准返回现场工作",
      "positive_subtitle": "我们很抱歉，您患有新冠肺炎",
      "positive_message": "我们已向您的雇主发送通知，告知他们您患有新冠肺炎。",
      "what_should_you_do": "您应该怎么做：",
      "employer_name_resources": "雇主名称资源：",
      "follow_cdc_guidelines": "遵循疾控中心（CDC）推荐的指南"
    },
    "clarifying_results": {
      "title": "澄清您的结果",
      "which_did_your_result_most_look_like": "您的结果最像哪个？",
      "no_lines": "没有看到线条",
      "blue_control_line_only": "仅蓝色控制线条",
      "pink_sample_line_only": "仅粉色样品线条",
      "blue_control_line_and_pink_sample_line": "蓝色控制线和粉色/紫色样品线",
      "still_not_sure": "还是不确定",
      "submit_results": "提交结果",
      "thank_you": "谢谢！",
      "clarification_received_message": "您的结果澄清已收到。",
      "only_pink_line": "只有粉色线",
      "no_lines_v2": "没有线"
    },
    "return_to_confirmation": "返回到确认页面"
  },
  "preferred_language": {
    "title": "首选语言",
    "subtitle": "您想用什么语言？"
  },
  "appointment_type": {
    "initial_vaccine_appointment": "初始疫苗序列",
    "one_dose": "预定特定剂量的预约。 特别地，这是我的",
    "title": "剂量选择",
    "first_dose": "第一剂",
    "second_dose": "第二剂",
    "single_dose_title": "单剂量",
    "additional": "额外",
    "booster": "加强针",
    "supplemental": "补充",
    "third_dose": "仅第三剂",
    "no_vaccine": "以上都不是"
  },
  "activemodel": {
    "errors": {
      "models": {
        "importers/results/row": {
          "attributes": {
            "administered_at": {
              "invalid_datetime": "应该有的格式 {{datetime_format}}"
            },
            "results_at": {
              "invalid_datetime": "应该有的格式 {{datetime_format}}"
            },
            "uid": {
              "unknown_test": "未找到测试"
            }
          }
        },
        "importers/participants/row": {
          "attributes": {
            "access_code": {
              "not_allow_update_test_group_user": "用户存在且不允许更新它"
            },
            "date_of_birth": {
              "invalid_date": "应该有的格式 {{datetime_format}}"
            },
            "ethnicity": {
              "unknown_ethnicities": {
                "one": "{{unknown_ethnicities}} 不存在，请从以下可接受的选项中选择：{{ethnicities}}",
                "other": "{{unknown_ethnicities}} 不存在，请从以下可接受的选项中选择：{{ethnicities}}"
              }
            },
            "gender": {
              "unknown_gender": "性别不存在，请从以下可接受的选项中选择：{{genders}}"
            },
            "phone_number": {
              "invalid": "是无效的数字"
            },
            "population_name": {
              "unknown_population": "{{unknown_population}} 不存在，请从以下可接受的选项中选择：{{population_names}}"
            },
            "self_described_gender": {
              "not_allowed": "如果性别不是“倾向于自我描述”，则无法填写"
            },
            "sex": {
              "unknown_sex": "性别不存在，请从以下可接受的选项中选择：{{sexes}}"
            },
            "sexual_orientation": {
              "unknown_sexual_orientation": "性取向不存在，请从以下可接受的选项中选择：{{sexual_orientations}}"
            },
            "status": {
              "unknown_status": "状态无效，请从以下可接受的选项中选择：{{statuses}}"
            },
            "races": {
              "unknown_races": {
                "one": "{{unknown_races}} 不存在，请从以下可接受的选项中选择：{{races}}",
                "other": "{{unknown_races}} 不存在，请从以下可接受的选项中选择：{{races}}"
              }
            },
            "vaccination_status": {
              "unknown_vaccination_status": "vaccination status is invalid, pick from the following acceptable values: {{vaccination_statuses}}"
            }
          }
        },
        "importers/organization_user/row": {
          "attributes": {
            "role": {
              "insufficient_privileges": "没有足够的特权来设置角色"
            },
            "user_id": {
              "unknown_user": "找不到用户"
            }
          }
        },
        "importers/additional_information/row": {
          "attributes": {
            "additional_information": {
              "invalid_json": "无效的JSON"
            },
            "user_id": {
              "unknown_user": "找不到用户"
            }
          }
        },
        "importers/emr/row": {
          "attributes": {
            "appointment_location": {
              "unknown_location": "找不到预约地点"
            },
            "date_of_birth": {
              "invalid_date": "应该有的格式 {{datetime_format}}"
            },
            "email": {
              "duplicated": "已经使用"
            },
            "ethnicity": {
              "unknown_ethnicities": {
                "one": "{{unknown_ethnicities}} 不存在，请从以下可接受的值中选择：{{ethnicities}}",
                "other": "{{unknown_ethnicities}} 不存在，请从以下可接受的值中选择：{{ethnicities}}"
              }
            },
            "gender": {
              "unknown_gender": "性别不存在，请从以下可接受的值中选择：{{genders}}"
            },
            "phone_number": {
              "invalid": "是无效的数字"
            },
            "population": {
              "unknown_population": "{{unknown_population}} 不存在，请从以下可接受的值中选择：{{population_names}}"
            },
            "self_described_gender": {
              "not_allowed": "如果性别不是“倾向于自我描述”，则无法填写"
            },
            "sex": {
              "unknown_sex": "性别不存在，请从以下可接受的值中选择：{{sexes}}"
            },
            "sexual_orientation": {
              "unknown_sexual_orientation": "性取向不存在，请从以下可接受的值中选择：{{sexual_orientations}}"
            },
            "races": {
              "unknown_races": {
                "one": "{{unknown_races}} 不存在，请从以下可接受的值中选择：{{races}}",
                "other": "{{unknown_races}} 不存在，请从以下可接受的值中选择：{{races}}"
              }
            }
          }
        },
        "importers/additional_consents/row": {
          "attributes": {
            "consent_form_id": {
              "unknown_consent_form": "consent_form not found"
            },
            "access_code": {
              "unknown_user": "user not found"
            }
          }
        },
        "importers/vaccine/row": {
          "attributes": {
            "date_of_birth": {
              "invalid_date": "should have format {{date_format}}"
            },
            "phone_number": {
              "invalid": "is an invalid number"
            },
            "email": {
              "duplicated": "is already used"
            },
            "races": {
              "unknown_races": {
                "one": "{{unknown_races}} does not exist, pick from the following acceptable values: {{races}}",
                "other": "{{unknown_races}} do not exist, pick from the following acceptable values: {{races}}"
              }
            },
            "gender": {
              "unknown_gender": "gender does not exist, pick from the following acceptable values: {{genders}}"
            },
            "sexual_orientation": {
              "unknown_sexual_orientation": "sexual_orientation does not exist, pick from the following acceptable values: {{sexual_orientations}}"
            },
            "self_described_gender": {
              "not_allowed": "cannot fill it if the gender is not \"prefer_to_self_describe\""
            },
            "sex": {
              "unknown_sex": "sex does not exist, pick from the following acceptable values: {{sexes}}"
            },
            "ethnicity": {
              "unknown_ethnicities": {
                "one": "{{unknown_ethnicities}} does not exist, pick from the following acceptable values: {{ethnicities}}",
                "other": "{{unknown_ethnicities}} do not exist, pick from the following acceptable values: {{ethnicities}}"
              }
            },
            "tag_action": {
              "unknown": "{{unknown_tag_action}} is an invalid value, pick from the following acceptable values: {{tag_actions}}"
            },
            "population": {
              "unknown_population": "{{unknown_population}} does not exist, pick from the following acceptable values: {{population_names}}"
            },
            "appointment_location": {
              "unknown_location": "appointment location not found"
            },
            "appointment_date": {
              "invalid_date": "should have format {{date_format}}"
            },
            "dose_type": {
              "invalid_dose": "{{unknown_dose}} is not a valid dose, pick from the following acceptable values: {{acceptable_doses}}",
              "unavailable_dose": "{{dose_type}} is not available within the vaccine_lot, pick from the following available values: {{available_doses}}"
            },
            "vis_version_date": {
              "invalid_date": "should have format {{date_format}}"
            }
          }
        },
        "importers/base/row": {
          "attributes": {
            "appointment_time": {
              "invalid_time": "should have format {{time_format}}"
            }
          }
        }
      }
    }
  },
  "activerecord": {
    "errors": {
      "models": {
        "test_location": {
          "attributes": {
            "metadata": {
              "wrong_json": "is not a valid JSON"
            },
            "name": {
              "taken": "并且地址已在另一个现有位置使用"
            }
          }
        }
      }
    },
    "attributes": {
      "organization_user": {
        "organization_user_leads": {
          "one": "User",
          "other": "Users"
        }
      },
      "appointment_slot_group": {
        "organization_user_leads": {
          "one": "Location",
          "other": "Locations"
        }
      }
    }
  },
  "hispanic_questions": {
    "hispanic": "西班牙裔或拉丁美洲裔",
    "not_hispanic": "不是西班牙或拉丁美洲裔",
    "hispanic_expanded": {
      "other": "另一个西班牙裔、拉丁美洲或西班牙人来源",
      "mexican": "墨西哥人、墨西哥裔美国人、奇卡诺人",
      "puerto_rican": "波多黎各人",
      "cuban": "古巴人"
    },
    "subtitle": "法规要求我们收集以下信息",
    "help": "族裔是指一组共同特征比方说语言、文化习俗、宗教以及用于区分人群的其他特征。它不一定与种族身份一致。",
    "unknown": "未知",
    "title": "族裔"
  },
  "sex_at_birth": {
    "question": "性别",
    "female": "女性",
    "male": "男性",
    "help": "性别是指您的出生证明上的身份。",
    "non_binary": "非二元性别",
    "subtitle": "法规要求我们收集以下信息",
    "decline": "拒绝回答",
    "unknown": "未知"
  },
  "gender": {
    "help": "性别身份是指您对自己的个人认同。 它可能与您出生时分配的性别一致，也可能不一致。",
    "subtitle": "如果您想添加有关您的性别身份的信息，请这样做。"
  },
  "sexual_orientation": {
    "title": "性取向",
    "subtitle": "如果您想添加有关您的性取向的信息，请这样做。",
    "gay": "男同性恋、女同性恋或同性恋者",
    "heterosexual": "异性恋者",
    "bisexual": "双性恋者",
    "questioning": "质疑您的性取向/不确定/不知道",
    "prefer_not_to_disclose": "选择不透露",
    "unknown": "未知",
    "orientation_not_listed": "取向未列出",
    "not_applicable": "不适用",
    "pansexual": "泛性恋",
    "queer": "酷儿"
  },
  "pronouns": {
    "title": "首选代词",
    "he": "他",
    "she": "她",
    "they": "他们",
    "choose_not_to_disclose": "选择不透露",
    "prefer_to_self_describe": "倾向于自我描述"
  },
  "no_insurance_information": {
    "driver_license_or_state_id": "驾驶执照或州身份证的号码",
    "driver_license": "驾驶执照",
    "use_ssn_instead": "使用社会保险号码（SSN）",
    "social_security_number": "社会保险号码（SSN）",
    "state": "州",
    "upload_front_of_driver_license": "上载您的驾照正面或州身份证号码",
    "choose_file": "选择文件",
    "no_file_chosen": "未选取文件",
    "no_identification": "我没有身份证",
    "insurance_card_has_a_back": "我的保险卡有背面",
    "upload_front_of_insurance_card": "上载您的保险卡正面",
    "front_of_insurance_card_uploaded": "您的保险卡正面已上载",
    "insurance_card": "保险卡",
    "insurance_card_back": "保险卡背面",
    "back_of_insurance_card_uploaded": "您的保险卡背面已上载"
  },
  "quidel": {
    "certify_text": "我证明我只会记录这个测试的结果一次。",
    "entering_results": "输入您的结果",
    "review_instructions_1": "仔细审查说明",
    "review_instructions_2": "您可以审查测试随附的说明、阅读分步指南或观看下面的说明视频：",
    "read_instructions": "看说明",
    "watch_video": "看视频",
    "timer": "在试管中搅拌棉签后开始计时器。计时器将在1分钟后停止（用于您从管中取出棉签）。请注意：您必须恢复计时器以开始10分钟让您的测试出结果。",
    "start_timer": "开始计时器（可选）",
    "restart_timer": "停止和重新开始",
    "resume": "恢复",
    "one_minute_countdown": "已经1分钟了！ 从试管中取出棉签并恢复计时器。",
    "take_photo": "拍照您的测试条",
    "photo_warning": "需要提供照片以继续。",
    "enter_results": "输入您的结果",
    "choose_result": "选择最能反映您的测试条的选项。需要帮助吗？点击此处查看结果范例。",
    "positive": "阳性",
    "negative": "阴性",
    "unknown": "未知",
    "clarify": "澄清您的结果",
    "strip_question": "您的测试条最像哪个？",
    "no_lines": "没有线条",
    "pink_line": "只有粉色的线条",
    "not_sure": "还是不确定"
  },
  "ihealth": {
    "take_a_photo_of_your_test": "结果照片",
    "retake_photo": "重新拍照",
    "capture_photo": "捕获照片",
    "confirm_information_correct": "我确认以上信息是正确的",
    "submit": "提交",
    "title": "输入 {{name}} 的结果",
    "subtitle": "您不需要下载或使用 iHealth “我的健康”新冠肺炎App",
    "instruction_title": "查看 iHealth 说明",
    "instruction_guide": "浏览说明",
    "video_guide": "观看视频",
    "add_photo": "添加照片",
    "confirm_result": "确认结果",
    "result_warning": "选择结果以提交",
    "confirm_warning": "确认结果以提交",
    "administered_at_label": "您何时做的这个测试？",
    "instruction_subtitle": "查看伴随您测试的说明或在此重看说明"
  },
  "public_test_groups": {
    "title": "新冠病毒试剂盒",
    "appointment_recoveries_title": "返回？",
    "appointment_recoveries_button_text": "找到我的链接",
    "search_test_group_title": "第一次？",
    "search_test_group_button_text": "现在注册",
    "title_2": "简单的注册与报告",
    "step_one": "账户注册",
    "step_two": "收集唾液样品以寄回",
    "step_three": "线上查看结果",
    "title_3": "注册以激活您的试剂盒",
    "population_title": "注册",
    "population_button": "激活",
    "faq_subtitle": "了解更多",
    "faq_title": "常见问题",
    "faq_1": "如何退回我的试剂盒？",
    "faq_1_footer": "查看“唾液导向漏斗”的说明书",
    "faq_2": "我已经注册但我忘记了我的账户",
    "faq_3": "如何激活我的试剂盒？",
    "faq_2_subtitle": "作为一个回头用户，您可以使用上方的 '**找到我的链接**' 按钮来确认您的报告.",
    "faq_3_subtitle": "请使用下列选项来浏览 Primary.Health 的账户信息：",
    "footer_text_1": "该产品并未得到美国食品与药品监管局（FDA）的证明或批准，但已被FDA授权在紧急状况下使用（获得EUA证明；",
    "footer_text_2": "该产品仅被授权收集和维系唾液样品以帮助检测来自SARS-CoV-2的核酸，不可用于任何其他病毒或病原体",
    "footer_text_3": "该产品的紧急使用状态仅被授权在该申明所陈述的情况存在符合联邦食品、药品和化妆品法案，21 U.S.C. § 360bbb-3(b)(1)，第564(b)(1)条下医疗设备的紧急使用的时限内，除非该申明被提前终止或授权被提前撤销。”",
    "description": "为了您的方便，您可以使用下列按钮来激活您的试剂盒：",
    "subtitle": "第一次使用 Primary（试剂盒）？",
    "subtitle_1": "关于您的新冠病毒试剂盒",
    "faq_1_1": "将关好的样品管放入生物样品袋中",
    "faq_1_2": "将生物样品袋放入它原来所在的标有“唾液导向”的盒中。将标有“唾液导向”的盒子放入联邦快递FedEx UN 3373包裹中。",
    "faq_1_3": "移除FedEx UN 3373包裹的胶带纸，用力按压以封紧包裹。",
    "faq_1_4": "请在您收集的同一天送来您的样品，在最后的取样品时间前。请不要在周末派送您的样品到投件箱",
    "faq_1_5": "访问联邦快递网站 **[fedex.com/labreturns](https://www.fedex.com/labreturns)**来查看投件箱位置与取件时间。",
    "demo": {
      "title": "COVID-19 at-home test kit additional information & instructions",
      "online_instructions": "Online instructions",
      "download_instructions": "Download instruction sheet",
      "begin": "Begin",
      "instructions": {
        "welcome_title": "Welcome to the at-home COVID-19 testing portal.",
        "welcome_text_1": "Before you start testing, wash you hands or use hand sanitizer.",
        "welcome_text_2": "Make sure your hands are dry before starting.",
        "continue": "Continue",
        "back": "Back",
        "step_one_title": "Step 1: Check your test kit",
        "step_one_text_1": "Locate the kit components:",
        "step_one_text_2": "It is recommended gloves (not provided) also be used during testing.",
        "step_two_title": "Step 2: Place tube in the tube holder",
        "step_two_text_1": "Remove cap from one **TUBE** and place it in the **TUBE HOLDER.**",
        "step_two_text_2": "**NOTE:** Use of gloves is recommended.",
        "step_five_title": "Step 5: Remove swab from the tube",
        "step_five_text_1": "After **ONE MINUTE**, remove the swab from the **TUBE** by rubbing the swab head against the inside wall of the tube to squeeze out as much liquid as possible.",
        "step_five_text_2": "Dispose of swab in the trash.",
        "step_six_title": "Step 6: Open the test strip",
        "step_six_text_1": "Open the **TEST STRIP** pouch carefully at the slit and hold the **TEST STRIP** as indicated.",
        "step_seven_title": "Step 7: Place test strip in the tube",
        "step_seven_text_1": "Place the **TEST STRIP** into the **TUBE** with the arrows pointing down. Leave the strip in the **TUBE** for a **FULL TEN MINUTES**– do not handle or remove.",
        "step_eight_title": "Step 8: Remove test strip from the tube",
        "step_eight_text_1": "At **TEN MINUTES**, remove the **TEST STRIP** from the **TUBE**. Next, place the **TEST STRIP** on the outline in Steps 10-12. Ensure the **TEST STRIP** is on a flat surface in good lighting.",
        "step_eight_text_2": "**Note:** The test is intended to be read at 10 minutes. If the test is read before this or is read more than 5 minutes after the indicated read time, results may be inaccurate (false negative, false positive, or invalid) and the test should be repeated.",
        "step_nine_title": "Step 9: Check your results",
        "step_nine_text_1": "There are three type of results possible.",
        "step_nine_text_2": "Check for a Positive Result",
        "step_nine_text_3": "Check for a Negative Result",
        "step_nine_text_4": "Check for an Invalid Result",
        "step_ten_title": "Step 10: Remove test strip from the tube",
        "step_ten_text_1": "Place the **TEST STRIP** on the test strip outline below and compare with test result examples shown.",
        "step_three_title": "Step 3: Swab the nostrils",
        "step_three_text_1": "Remove the **SWAB** from its wrapper, being careful not to touch the **SWAB** head.",
        "step_three_text_2": "Gently insert the **SWAB** ½ to ¾ of an inch into the nostril, depending on the size of the person’s nose. Firmly rub the **SWAB** in a circular motion around the inside wall of EACH NOSTRIL at least 4 times.",
        "step_three_text_3": "Be sure to rub BOTH nostrils with the SAME SWAB.",
        "step_three_text_5": "**NOTE:** Failure to swab properly may cause false negative results.",
        "step_four_title": "Step 4: Place swab in the tube",
        "step_four_text_1": "Immediately place the **SWAB** into the liquid inside the **TUBE**, and ensure it is touching the bottom. Stir 3-4 times.",
        "step_four_text_2": "Leave the swab in the solution for **ONE MINUTE**.",
        "step_four_text_3": "**NOTE**: If the swab is in the solution for more than 10-minutes it should not be used.",
        "step_three_text_4": "**NOTE:** If you are swabbing others, please wear a face mask. With children, you may not need to insert the swab as far into the nostril. For very young children, you may need another person to steady the child’s head while swabbing."
      },
      "choose_language": "Choose your language"
    }
  },
  "self_resulting": {
    "report_result": "报告结果",
    "report_result_subtitle": "如果您的机构提供自我检测，并且您已经准备好上报结果，请点击下方的按钮。",
    "need_help": "需要帮助?",
    "assistance": "如果您需要帮助，或是想更改您的联系信息，请联系客服中心。",
    "contact_support": "联系客服",
    "save_link": "储存链接供稍后回报结果",
    "send_via_email": "通过电子邮箱发送链接",
    "send_via_text": "通过手机短信发送链接",
    "copy_link": "复制链接",
    "resend_email": "重新发送电子邮箱验证",
    "phone_verified": "手机已验证",
    "add_vaccination_status": "添加/更新新冠肺炎疫苗接种状况",
    "terms_of_service": "服务条款",
    "verify_contact_information": "验证您的联络资料，以确保您可以收到结果和通讯。如果您需要更改联络资料，请联络我们的支援中心。",
    "resend_text": "重新发送手机短信验证",
    "loading": "加载中",
    "add_to_account": "添加到账户",
    "hi": "您好",
    "email_verified": "电子邮箱已验证",
    "go_to_my_account": "去我的账户",
    "activate_kit": "激活测试工具盒",
    "report_custom_result": "报告结果: {{test}}",
    "activate_custom_test": "激活测试: {{test}}",
    "update_vaccination_status": "更新疫苗接种状态",
    "order_a_test_kit": "订购测试包"
  },
  "enter_information": {
    "contact_info": "输入您的联系信息",
    "label": "手机或邮箱",
    "invalid": "这不是一个有效的电子邮箱或手机号码。",
    "send": "我们会发送一个密码到您的手机或邮箱",
    "legal": "通过输入您的手机号码或邮箱，您同意健康第一（Primary Health）的 **[服务条款](https://primary.health/terms-of-service/)** 以及 **[隐私政策](https://primary.health/privacy-policy/)**。",
    "subtitle": "我们会通过短信或电子邮件发送信息，以帮助您查询记录或提供链接，用以报告测试结果。",
    "find_link": "已经注册了？找回您的链接",
    "not_registered": "尚未登记？搜寻您的网页",
    "search_by_keyword": "以名称、关键词、城市、邮政编码、或组织代码搜索！",
    "search": "搜索",
    "register_here": "在此注册"
  },
  "contact_support": {
    "invalid": "无效资讯",
    "invalid_explanation": "您最近向健康第一（Primary.Health）请求一条登录链接。但是，",
    "never_registered": "从未登记？请向您的检测提供商查询登记链接，或在下面搜寻您的网页。",
    "get_help": "您可以从……获取帮助来匹配您的记录到您的联系信息",
    "get_help_calling": "或通过打电话",
    "invalid_explanation_bold": "您所提供的资讯无效",
    "get_help_label": "寻求帮助",
    "mobile": {
      "invalid_explanation": "没有找到可以添加到您账户的用户。",
      "get_help": "如果您有额外问题，请联系",
      "reason": "不是所有 Primary.Health 的用户都有资格使用我们的新账户功能。一旦您获取资格，您会从 Primary.Health 或从您的群组管理者那儿收到一条邀请。"
    },
    "zen_name": "联系人姓名",
    "ticket_type": "这是关于什么",
    "appointment_access_code": "确认编码（如果知道的话）",
    "zen_desc": "信息",
    "contact_us": "联系我们",
    "need_assistance": "如果您需要協助或更改您的聯絡資訊，請聯絡支援人員。"
  },
  "show_appointments": {
    "welcome": "寻找您的资料",
    "welcome_subtitle_1": "结果显示如下",
    "added_to_account": "添加至账户",
    "error": "该参与者可能已有账户",
    "welcome_subtitle_2": "未在下方看到姓名？",
    "welcome_not_logged_in": "寻找网站链接",
    "welcome_logged_in": "选择您的所在组",
    "welcome_subtitle_1_logged_in": "要完成添加成员，请选择用户和组。成员将被分配到同一账户。",
    "welcome_subtitle_2_logged_in": "如果您未在上方看到您的成员，您可以",
    "welcome_subtitle_1_not_logged_in": "请选择需要查找其站点的人或添加新成员。未找到成员？",
    "welcome_subtitle_3_logged_in": "尝试使用其他联系方式",
    "welcome_subtitle_4_logged_in": "或联络我们",
    "welcome_subtitle_2_not_logged_in": "更新搜索",
    "welcome_subtitle_3": "更新您的搜索"
  },
  "enter_code": {
    "code": "密码是什么",
    "enter_code": "输入发送给……的密码",
    "incorrect_code": "您输入了一条错误的密码",
    "verify": "验证帐户",
    "verified": "已验证",
    "verify_account_information": "验证您的帐户"
  },
  "general_self_checkout": {
    "choose_test": "您接受了什么测试?",
    "photo_required": "需要结果照片",
    "find_your_test": "找到您的测试"
  },
  "login": {
    "create_account": "创建账户",
    "create_account_help": "注册帐号并不等于登记检测或接种。当您注册帐号后",
    "create_account_help_schedule": "安排预约",
    "create_account_help_record": "记录居家测试",
    "create_account_help_match": "管理疫苗接种与测试记录。",
    "continue": "以游客身份继续",
    "already": "已有账户？点击",
    "login": "来登录。",
    "or_sign_in": "或用：……来登录",
    "no_account": "没有账户？点击",
    "signup": "来注册",
    "here": "这里",
    "email_address": "电子邮箱地址:",
    "password": "密码：",
    "complete_registration": "完成注册",
    "last_covid_test": "最新新冠肺炎（COVID-19）测试结果：",
    "no_record": "没有记录",
    "viewing_information": "查看 {{first_name}} 的信息",
    "download": "下载",
    "not_registered": "您似乎没有注册到任何群组",
    "please_follow_link": "请使用邮箱里的邀请链接预约和存取您在Primary.Health的资讯",
    "log_results": "定期记录我的测试结果",
    "book_appointment": "预约",
    "no_new_appointments": "目前不接受任何新预约",
    "upload_vaccinations": "上传接种的疫苗",
    "vaccination": "接种疫苗",
    "new_appointment": "新预约",
    "log_otc_results": "定期记录非处方药 OTC 测试的结果",
    "no_household_members": "您还未有任何家庭成员",
    "no_upcoming": "没有即将到来的预约",
    "update_vaccination": "更新接种的疫苗",
    "upload_vaccination": "上传接种的疫苗",
    "title": "欢迎来到 Primary.Health",
    "sub_title": "Before registering please create an account.",
    "p1": "创建一个账户让您：",
    "p2": "安排未来的预约",
    "p3": "记录新冠肺炎（COVID-19）的居家测试",
    "p4": "管理家属的疫苗接种与测试记录",
    "p5": "来自 my.primary.health 的简单登录手续",
    "no_vaccination_record": "尚未添加疫苗",
    "vaccination_title_card": "Covid-19新冠疫苗接种状态",
    "account_and_settings": "账户和设置",
    "delete_account": "删除账户",
    "language": "语言",
    "language_current": "当前语言： {{language}}",
    "log_out": "登出",
    "delete_account_title": "您确定要删除您的账户吗？",
    "new_encounter": "{{first_name}}的新历程",
    "new_encounter_subtitle": "该新历程属于哪个组?",
    "no_programs": "看上去您没有加入任何项目",
    "edit_members": "编辑成员",
    "edit_members_subtitle": "选择您想要去的面板或添加另一名成员到您的账户",
    "add_members": "添加成员",
    "delete_member": "删除 {{full_name}} 的用户账户？",
    "delete_member_subtitle": "移除用户会删除与此账户有关的所有信息",
    "select_member": "成员选择",
    "edit_appointment": "编辑预约",
    "route": {
      "me": "控制面板",
      "history": "历史",
      "programs": "添加一项测试",
      "contact_us": "帮助",
      "choose_member": "成员",
      "settings": "设置",
      "help": "帮助"
    },
    "user_dashboard": "{{first_name}} 的控制面板",
    "view_full_results": "在结果处查看",
    "required": "必填，如果您正在使用Primary应用程式。",
    "no_email": "我没有电邮地址"
  },
  "vaccination_status": {
    "not_vaccinated": "未接种",
    "fully_vaccinated": "完全接种",
    "partially_vaccinated": "部分接种",
    "vaccination_records": "疫苗记录",
    "title": "您的新冠病毒疫苗接种情况？",
    "definition_title": "疫苗接种情况定义",
    "definition_cdc": "疾病管制与预防中心（CDC）疫苗接种情况定义",
    "definition_osha": "职业安全与健康管理局（OSHA）疫苗接种情况定义",
    "definition": {
      "fully_vaccinated": "接种了第二剂辉瑞/生物科技或莫德纳双剂疫苗，或一剂强生/杨森疫苗的两周后。",
      "partially_vaccinated": "只接种了两剂基础剂中的一剂，或接种了第二剂双剂疫苗或一剂强生/杨森疫苗后不足两周。",
      "not_vaccinated": "没有接种新冠病毒疫苗。",
      "fully_vaccinated_with_one_booster": "接种了两剂完整的疫苗或一剂强生/杨森疫苗后，接种了一剂辉瑞/生物科技或莫德纳加强针。",
      "fully_vaccinated_with_two_boosters": "接种两级完整疫苗或一剂强生/杨森疫苗和加强针后，接种了第二剂辉瑞/生物科技或莫德纳加强针。"
    },
    "label": {
      "fully_vaccinated": "完全接种",
      "partially_vaccinated": "部分接种",
      "not_vaccinated": "未接种",
      "fully_vaccinated_with_one_booster": "完全接种 + 加强针",
      "fully_vaccinated_with_two_boosters": "完全接种 + 两剂加强针",
      "prefer_not_to_answer": "不愿回答"
    },
    "progress_bar_title": "新冠病毒疫苗记录",
    "upload_record_card": "上传您的新冠病毒疫苗记录卡",
    "show_example": "显示范例",
    "hide_example": "隐藏范例",
    "take_photo": "点击进行拍照",
    "or": "或",
    "upload_image_or_pdf_instead": "改为上传图片／PDF文档",
    "select_doses_received": "选择您已接种的所有新冠病毒疫苗",
    "first_dose": "第一剂",
    "second_dose": "第二剂",
    "first_booster_dose": "第一剂加强针",
    "second_booster_dose": "第二剂加强针",
    "additional_dose": "额外剂数",
    "first_dose_manufacturer": "您接种的第一针新冠病毒疫苗来自哪家制造商？",
    "first_dose_date": "您在何时接种的第一针？",
    "second_dose_manufacturer": "您接种的第二针新冠病毒疫苗来自哪家制造商？",
    "second_dose_date": "您在何时接种的第二针？",
    "first_booster_dose_manufacturer": "您接种的第一剂加强针来自哪家制造商？",
    "first_booster_dose_date": "您在何时接种的第一剂加强针？",
    "second_booster_dose_manufacturer": "您接种的第二剂加强针来自哪家制造商？",
    "second_booster_dose_date": "您在何时接种的第二剂加强针？",
    "additional_dose_manufacturer": "您接种的额外剂数来自哪家制造商？",
    "additional_dose_date": "您在何时接种的额外剂数？",
    "completed_one": "已完成",
    "completed_two": "感谢您更新您的疫苗记录！",
    "progress_bar_complete": "完成",
    "upload_image": "上传照片",
    "retake_photo": "重拍照片",
    "other": "其他",
    "remove_first_dose": "移除第一针记录",
    "remove_second_dose": "移除第二针记录",
    "remove_first_booster_dose": "移除第一剂加强针记录",
    "remove_second_booster_dose": "移除第二剂加强针记录",
    "remove_additional_dose": "移除额外剂数记录",
    "exemption": "您拥有豁免资格吗？",
    "exempt": "我有豁免资格",
    "not_exempt": "我没有豁免资格",
    "enter_exemption": "输入豁免资格",
    "upload_exemption_documentation": "上传您的豁免证明文件",
    "remove_dose": "移除剂数",
    "continue": "继续",
    "enter_credentials": "请输入您的登入凭证",
    "incorrect_credentials": "登入凭证不正确，请再尝试。",
    "add_photo": "添加照片"
  },
  "mailers": {
    "appointment_mailer": {
      "payment_pending": "您好 {{name}}，我们注意到您还未完成您 {{group_name}} 与{{org_name}}的预约。为保证预约成功，您需要完成付费。请点击这个链接去完成这一程序 {{complete_url}}。如果您想取消您的预约，请点击这个链接 {{cancel_url}}。感谢您的合作"
    }
  },
  "otc": {
    "record_results_for_abbott": "测试和记录您在BinaxNOW测试的结果",
    "record_results": "测试和记录您的结果",
    "activate_a_kit": "激活工具盒",
    "activate_a_kit_for_test": "为 {{test}} 激活工具盒",
    "todays_test_process": "今天的测试流程",
    "date_of_birth_question": "{{first_name}} 的出生日期是什么?",
    "confirm_dob_to_continue": "请输入参加者的出生日期以继续：",
    "incorrect_dob": "出生日期错误",
    "file_too_large": "请确保上载之档案小于 {{size}}",
    "enter_valid_date_in_last_x_days": "输入一个近 {{n}} 天内的有效日期",
    "barcode_format_not_valid": "条形码格式无效",
    "complete_button": "完成",
    "next_button": "下一个",
    "back_button": "后退",
    "report_a_test": "报告测试",
    "x_%_completed": "{{n}}%已完成",
    "answer_survey": "回答问卷问题",
    "steps": {
      "answer_survey": "回答问卷",
      "review_instructions": "回顾指引",
      "scan_code": "扫码",
      "enter_time": "输入时间",
      "enter_result": "输入结果",
      "take_photo": "拍照",
      "confirm_result": "确认结果",
      "selest_test_kit": "选择测试种类",
      "enter_date": "输入日期",
      "enter_date_and_time": "输入日期和时间"
    },
    "review_instructions": "回顾测试指引",
    "follow_instructions_or_below": "按照您测试附带的说明行动或回顾下方说明。",
    "watch_video": "观看影片",
    "view_instructions": "检视指引",
    "follow_instructions": "按照您测试附带的说明行动。",
    "scan_code": "扫描测试二维码",
    "qr_code_description": "二维码是特有的，可以在您的测试的右上角找到",
    "show_example": "显示范例",
    "hide_example": "隐藏范例",
    "barcode_scanned": "条形码扫描成功",
    "enter_barcode": "输入条形码",
    "scan_qr_code": "扫描二维码",
    "which_test_did_you_take": "您接受了哪项测试?",
    "when_did_you_take_test": "您在何时接受此测试?",
    "what_day_did_you_take_test": "您在什么日子接受此测试?",
    "what_time_did_you_take_test": "您在什么时间接受此测试?",
    "time": {
      "today": "今天",
      "yesterday": "昨天",
      "two_days_ago": "两天前"
    },
    "enter_current_time": "输入当前时间",
    "enter_result": "输入测试结果",
    "choose_result_option": "选择描述您测试卡结果的选项",
    "clarify_results": "阐明您的结果",
    "take_result_photo": "替结果拍照",
    "take_result_photo_description": "将已完成的结果记录卡拍照来记录您的结果。",
    "my_result": "我的结果",
    "switch_camera": "切换摄像头",
    "confirm_result": "确认结果",
    "name": "名字:",
    "date": "日期：",
    "result": "结果:",
    "results": "结果",
    "test_submitted": "谢谢您, {{first_name}}! 您的{{test}}已成功提交",
    "dob": "出生日期：",
    "administered": "已配给:",
    "or": "或",
    "upload": "上载",
    "change_uploaded_file": "更改已上传的文件",
    "take_photo": "拍照",
    "confirm_information": "确认信息",
    "barcode": "条形码：",
    "scan_test_barcode": "扫描测试条形码",
    "barcode_description": "条形码是您的测试特有的，可以在您的测试上找到",
    "enter_date_in_last_30_days": "输入30天内的一个日期以及进行测试的大约时间",
    "add_image": "添加图片",
    "change_image": "更改图像",
    "skip_barcode_reader": "无法扫码? 点击下一页回报测试结果",
    "enter_date_and_time": "输入检测日期和时间",
    "enter_date": "输入测试日期",
    "did_you_take_test_today": "您是今天进行的测试吗",
    "record_results_for_generic": "測試並記錄 {{name}} 測試的結果",
    "choose_result_option_custom": "選擇描述您的 {{test_name}} 結果的選項："
  },
  "yes": "是",
  "no": "不是",
  "event_token": {
    "title": "请在自动售货机输入下列编码以取得测试包",
    "loading_text": "加载中，请在一分钟内再次查看"
  },
  "appointment_recovery": {
    "complete_title": "您已完成！",
    "subtitle_1": "如果您在使用iPhone手机，请触碰此屏幕左上角的**完成**来返回您的控制面板。",
    "subtitle_2": "如果您在使用安卓手机，触碰此屏幕左上角的**X**",
    "subtitle_0": "请退出浏览器以继续"
  },
  "primary": {
    "support_email": "支持@primary.health"
  },
  "consented_relationship": "同意关系",
  "finish_registration": {
    "title": "完成您的登记",
    "subtitle": "请在预约前填写以下资料：",
    "button": "完成註冊"
  },
  "arab_ethnicity": {
    "arab": "阿拉伯裔",
    "non_arab": "非阿拉伯裔",
    "prefer_not_to_answer": "不愿回答",
    "question": "阿拉伯族群",
    "subtitle": "法例要求我们收集以下资讯。",
    "help": "种族划分是指一组共同特征，例如语言、文化习俗、宗教和其他用于区分人群的特征。它可能与种族认同相符，也可能不相符。"
  },
  "custom_comms_quick_add": {
    "mobile_app_links": {
      "email_text": "根據您的設備，點擊下面的連結下載 Primary Health Pass。"
    }
  },
  "stepper": {
    "next": "下一项",
    "back": "返回",
    "complete": "完成"
  },
  "registration_direcory": {
    "keyword_search_label": "关键词、城市、邮编、组织名称",
    "keyword_search_label_mobile": "城市、邮编、组织名称",
    "zero_results": {
      "title": "我们未找到与该搜索词匹配的服务提供者",
      "sub_title": "请尝试通过以下方式搜索您的服务提供者：",
      "p1": "邮编",
      "p2": "城市/城镇",
      "p3": "县",
      "p4": "服务提供者或组织名称"
    }
  },
  "registration_flyer": {
    "open_camera": "在您的设备上打开照相机应用",
    "point_to_qr_code": "指向二维码然后点击链接",
    "complete_registration": "完成注册",
    "need_help": "需要帮助？在……联系我们"
  },
  "vaccine_info_sheets": {
    "vaccine_info_attestation": "疫苗資訊證明",
    "select_checkbox": "繼續之前，請查看以下內容並選擇下面對應的複選框。",
    "opportunity_to_read": "我已閱讀，或有人為我閱讀，有關接種疫苗的疫苗信息聲明（VIS）或緊急使用授權（“EUA”）情況說明書：",
    "vaccine_info_sheet": "疫苗資訊",
    "vaccine_fact_sheet": "疫苗資訊",
    "vaccine_info_statement": "疫苗信息声明"
  },
  "exemption": {
    "dtap_tdap": "白喉、破伤风及无细胞百日咳混合疫苗",
    "mmr": "麻疹、腮腺炎和风疹联合疫苗",
    "ipv_opv": "脊髓灰质炎疫苗",
    "hib": "b型流感嗜血杆菌疫苗",
    "hep_b": "乙型肝炎疫苗",
    "var": "水痘疫苗",
    "hep_a": "甲型肝炎疫苗",
    "pcv": "肺炎球菌结合疫苗",
    "mcv": "脑膜炎球菌结合疫苗"
  },
  "test_strip": {
    "A": "這測試包包括衣原體和淋病檢測。",
    "B": "這測試包包括衣原體和淋病檢測。",
    "C": "這測試包包括梅毒檢測。",
    "D": "這測試包包括愛滋病毒、梅毒、披衣菌和淋病的檢測。",
    "E": "該測試包包括愛滋病毒和梅毒檢測。",
    "box_type": "盒 {{type}}",
    "administered_text": "我們會在您的測試包到達實驗室時，並且您的結果準備好時，跟您聯繫。",
    "activate_kit": "啟動我的測試包",
    "register_another_test": "註冊另一個測試",
    "credentials": {
      "title": "開始吧",
      "label": "聯繫",
      "p1": "我們將從一些有關您的資訊開始。",
      "location_step": {
        "p1": "是誰提供您的測試包？",
        "load": "加載更多"
      }
    },
    "checklist": {
      "not_urinated": "您在過去一小時內沒有排尿。",
      "bathroom": "您可以使用浴室進行尿液收集。",
      "anal_swab": "您有一個私人隱密的地方以進行肛門拭子檢查。",
      "blood_extraction": "您有一個乾淨的環境以進行血液抽取。",
      "washed_hands": "您已用肥皂和溫水洗手 30 秒。",
      "title": "您準備好開始樣本採集了嗎？",
      "no_alcohol": "收集樣本當天請勿飲酒。",
      "menstruation": "請勿在月經期間或性交後 24 小時內採集陰道拭子樣本。"
    },
    "confirm": {
      "title": "Now, begin collecting your sample",
      "p1": "Is this kit information correct?"
    },
    "display_name": {
      "A": "衣原體和淋病（單點）",
      "B": "衣原體和淋病（3 個站點）",
      "C": "梅毒",
      "shortened": {
        "A": "衣原體和淋病",
        "B": "衣原體和淋病",
        "C": "梅毒"
      },
      "CC": "COVID-19",
      "CE": "乳糜瀉篩檢",
      "CR": "肌酸酐測試",
      "D": "四版面检测组合 - 愛滋病毒、梅毒、衣原體和淋病 （單站點）",
      "DD": "糖尿病篩檢 ​​(HbA1c)",
      "E": "愛滋病毒和梅毒",
      "H": "HPV病毒",
      "P": "C型肝炎",
      "HS": "單純皰疹病毒",
      "I": "愛滋病毒",
      "TT": "睪固酮水平",
      "VD": "維生素D",
      "VS": "病毒監測",
      "AP": "Chlamydia & Gonorrhea",
      "NN": "COVID-19",
      "HH": "COVID-19, Flu A/B, & RSV",
      "FFFWW": "COVID-19 & Flu A/B",
      "YYYWW": "COVID-19, Flu A/B & RSV",
      "HA": "COVID-19, Flu A/B, & RSV"
    },
    "rescan": "重新掃描",
    "scanned": "您已掃描 盒裝 {{type}}",
    "not_yet": "還沒行",
    "success_registration": "註冊成功！",
    "no_box": "沒有盒子？",
    "faq_link": "瀏覽常見問題",
    "welcome": "歡迎！",
    "scan": {
      "title": "You're one step closer to a healthier you. Scan or enter the **kit bar code** on the side of your box.",
      "p1": "Please begin when you are ready to begin sample collection. Otherwise, return to this page when you are ready.",
      "unknown_barcode": "You’ve entered an unknown barcode. Please contact support for assistance."
    },
    "appointments": {
      "title": "選擇病患和提供者",
      "p1": "我們發現了不只一份與您的聯絡資訊相關的個人資料。請選擇正確的配置：",
      "new": "新病患或提供者"
    },
    "verify": {
      "title": "Confirm your identity",
      "p1": "我們找到你的病歷。"
    },
    "section_1": "確認測驗包內容",
    "section_2": "收集測試",
    "section_3": "包裝",
    "section_4": "Return kit",
    "box_a": "A 盒",
    "box_b": "B 盒",
    "box_c": "C 盒",
    "kit_flow": {
      "button_1": "繼續包裝",
      "button_2": "我準備好了",
      "button_3": "我的測驗包已完成和包裝好了",
      "button_4": "我完成了",
      "button_5": "下一個測試",
      "button_6": "跳過說明"
    },
    "contents": {
      "header": "好！讓我們確保您測驗包的套件完整",
      "text_1": "Does your test kit box contain the following?",
      "blood_collection_card": "採血卡",
      "blood_collection_card_subtitle": "（1 或 2 取決於測試套件）",
      "alchohol_pads": "2塊酒精墊",
      "lancets": "單用採血針",
      "up_to_other": "（最多 4 個，取決於測試套件）",
      "adhesive_bandages": "繃帶",
      "pipette": "吸管",
      "urine_tube": "尿液收集管",
      "urine_cup": "尿液收集杯",
      "oral_swab": "口腔拭子",
      "oral_tube": "口腔採集管",
      "anal_swab": "肛門拭子",
      "anal_tube": "肛門採集管",
      "vaginal_tube": "1 陰道採集管",
      "vaginal_swab": "1 根陰道拭子",
      "biodegradable_paper": "可生物降解的收集紙",
      "bottle_and_probe": "採樣瓶和探頭",
      "prepaid_envelope": "預付運送信封",
      "biohazard_subtitle": "（附吸水墊）",
      "biohazard_bag": "1個樣本袋",
      "biohazard_bag_plural": "({{count}}) 生物危險品專用袋",
      "alcohol_pads_plural": "{{count}}酒精墊",
      "sterile_gauze_pad": "1 無菌紗布墊",
      "collection_tube": "1 collection tube",
      "collection_swabs": "2 collection swabs"
    },
    "packaging": {
      "title": "包裝清單",
      "subtitle": "Before you ship or drop your kit at a kiosk, make sure that:"
    },
    "packaging_box_a": {
      "step_1": "You have packed all test kit contents according to the instructions",
      "step_2": "You have used all collection materials contained in the kit to collect the required specimen",
      "step_3": "Your **date-of-birth** is written on the specimen collection tube using MM/DD/YYYY format",
      "step_4": "The **collection date** is written on the specimen collection tube using MM/DD/YYYY format",
      "step_5": "樣本採集管密封在生物危險品專用袋裏",
      "step_6": "所有樣本均放入盒內",
      "step_7": "盒子已放入預付運輸信封中",
      "step_8": "退貨信封已完全密封"
    },
    "packaging_box_b": {
      "step_1": "即使您跳過了測試，您也已包裝好所有測試套件內容",
      "step_2": "您已使用套件中包含的所有採集材料來收集所需的樣本",
      "step_3": "您的**出生日期**以 MM/DD/YYYY 格式寫在所有樣本採集管上",
      "step_4": "**採集日期**使用 MM/DD/YYYY 格式寫在所有採樣管上",
      "step_5": "每個樣本採集管各密封在分開的生物危險品專用袋中（每袋僅**一個**樣本）",
      "step_6": "所有樣本均放入盒內",
      "step_7": "盒子已放入預付運輸信封中",
      "step_8": "退貨信封已完全密封"
    },
    "packaging_box_c": {
      "step_1": "您已包裝好所有測試套件內容，包括用過的刺血針。",
      "step_2": "您的**出生日期**以 MM/DD/YYYY 格式寫在採血卡上",
      "step_3": "**採集日期**以 MM/DD/YYYY 格式寫在採血卡上",
      "step_4": "採血卡和所有用過的採血針已放入生物危險品專用袋中",
      "step_5": "生物危險品專用袋已放入盒中",
      "step_6": "盒子已放入預付運輸信封中",
      "step_7": "退貨信封已完全密封"
    },
    "success": {
      "header_1": "好！",
      "text_1": "您已準備好收集第一個樣本。",
      "header_2": "您做到了！",
      "text_2": "**下一步：** 把您的測驗包打包以寄回",
      "header_3": "做得好！",
      "text_3": "您距離健康又近一步了。",
      "header_4": "做得好！",
      "text_4": "再多一步您就完成了！",
      "text_5": "You're ready to collect your sample."
    },
    "instructions": {
      "title": "選擇一個測試以查看說明：",
      "subtitle": "測試",
      "button": "說明",
      "pdf": "PDF 說明"
    },
    "instructions_box_a": {
      "title": "尿液採集",
      "subtitle": "以進行此測試，您需要以下材料：",
      "step_1": "收集前至少 1 小時內不要排尿。 最好使用晨尿。",
      "step_2": "直接將尿液倒入 PeeCanter 或收集杯中，填充 1/3 至 1/2。",
      "step_3": "從 PeeCanter 傾倒尿液或使用移液管將尿液從收集杯轉移到標有橙色 <span style=\"color: #FF5000\">**\"URINE\"**</span> 標籤的收集管中。",
      "step_4": "填充收集管，直到合併的液體位於指定的填充線之間。",
      "step_4_sublabel": "填充管時請勿超過填充線，否則樣本將被拒絕。",
      "step_5": "將蓋子蓋在收集管上並確保其均勻且緊密地關閉。",
      "step_6": "將您的出生日期和標本採集日期（今天的日期）以 MM/DD/YYYY 格式寫在試管上的指定區域。",
      "step_7": "把樣本跟吸收墊放入生物危險品專用袋中，並確保密封完全關閉。"
    },
    "instructions_box_b": {
      "test_1": {
        "title": "尿液採集",
        "subtitle": "以進行此測試，您需要以下材料：",
        "step_1": "收集前至少 1 小時內不要排尿。 最好使用晨尿。",
        "step_2": "直接將尿液倒入 PeeCanter 或收集杯中，填充 1/3 至 1/2。",
        "step_3": "從 PeeCanter 傾倒尿液或使用移液管將尿液從收集杯轉移到標有橙色 <span style=\"color: #FF5000\">**\"URINE\"**</span> 標籤的收集管中。",
        "step_4": "填充收集管，直到合併的液體位於指定的填充線之間。",
        "step_4_subtitle": "填充管時請勿超過填充線，否則樣本將被拒絕。",
        "step_5": "將蓋子蓋在收集管上並確保其均勻且緊密地關閉。",
        "step_6": "將您的出生日期和標本採集日期（今天的日期）以 MM/DD/YYYY 格式寫在試管上的指定區域。",
        "step_7": "把樣本跟吸收墊放入生物危險品專用袋中，並確保密封完全關閉。"
      },
      "test_2": {
        "title": "口腔拭子",
        "subtitle": "以進行此測試，您需要以下材料：",
        "step_1": "將拭子放在刻痕線（如有）和拭子的棉質部分之間。",
        "step_2": "將拭子插入口裡，然後用拭子尖端摩擦喉嚨後部 10 秒。",
        "step_3": "小心取出拭子，放入標示為藍色 <span style=\"color: #001F70\">**\"ORAL\"**</span> 標籤的採集管中。 透過彎曲收集管，在刻痕線處折斷拭子。",
        "step_4": "將蓋子蓋在收集管上並確保其均勻且緊密地關閉。",
        "step_5": "將您的出生日期和標本採集日期（今天的日期）以 MM/DD/YYYY 格式寫在試管的指定區域。",
        "step_6": "把樣本跟吸收墊放入生物危險品專用袋中，並確保密封完全關閉。"
      },
      "test_3": {
        "title": "肛門拭子",
        "subtitle": "以進行此測試，您需要以下材料：",
        "step_1": "將拭子放在刻痕線（如有）和拭子的棉質部分之間。",
        "step_2": "將棉花棒插入肛管 3 - 5 公分（1 - 2 英吋）。 輕輕轉動拭子 5 - 10 秒以收集任何潛在的微生物。",
        "step_3": "小心地取出拭子並放入標有綠色<span style=\"color: #00C08C\">**“ANAL”**</span>標籤的收集管中。 透過彎曲收集管，在刻痕線處折斷拭子。",
        "step_4": "將蓋子蓋在收集管上並確保其均勻且緊密地關閉。",
        "step_5": "將您的出生日期和標本採集日期（今天的日期）以 MM/DD/YYYY 格式寫在試管的指定區域。",
        "step_6": "把樣本跟吸收墊放入生物危險品專用袋中，並確保密封完全關閉。"
      }
    },
    "instructions_box_c": {
      "title": "血液收集",
      "subtitle": "以進行此測試，您需要以下材料：",
      "step_1": "在指定欄位中寫下您的姓名、出生日期和領取日期。 請用 MM/DD/YYYY 格式。",
      "step_2": "打開血卡蓋，露出採血紙上的圓形。 請勿觸摸採血紙。",
      "step_3": "用溫水洗手至少 30 秒，然後用力搖動 15 秒，以促進血液流向手指。",
      "step_4": "用酒精墊清潔指尖。 最好使用非慣用手的中指或無名指。",
      "step_5": "拿起採血針並旋開蓋子。 將小尖端用力按入指尖，直到針頭發出喀喀聲彈出。 採集時將手保持在心臟下方，從根部到尖端按摩手指以促進血液流動。",
      "step_5_subtitle": "採血針是一次性的。 所有採血針需與樣本一起退回實驗室進行處置。",
      "step_6": "從中心開始，滴 3-6 滴以填滿圓圈並浸透收集紙。 請勿用手指觸摸紙張，因為這會限制血液流動。 一旦圈滿，就進入下一個圈。 血跡延伸到線外是可以的，但不要讓血點互相擴散。",
      "step_6_subtitle": "一旦完成或乾了，請勿在圓圈中添加額外的血液。 血液「分層」將使採集無效。",
      "step_7": "不要關閉血卡，將其放在平坦的表面上，讓採血紙在室溫下風乾至少 30 分鐘。 請勿加熱、吹乾採血紙或將採血紙暴露在陽光直射下。 熱量會損壞樣本。",
      "step_7_subtitle": "檢查採血紙的背面。 血液應該完全飽和並填滿收集紙的每個圓圈。",
      "step_8": "當採血紙已乾時，折起蓋板以關閉血卡。 將採血卡和採血針與乾燥劑包一起放入生物危險品專用袋中。 確保生物危險品專用袋正確密封。"
    },
    "test_tips": {
      "title": "正確採血的技巧",
      "subtitle": "以獲得最佳結果：",
      "step_1": "確保您在收集時沒有脫水。 保持水分作用可促進血液流動。",
      "step_2": "不要吸煙後立即進行收集。",
      "step_3": "用溫水洗手並暖手有助於促進手部血液流動。",
      "step_4": "用力向地板晃動雙手，以促進血液流向手指。",
      "step_5": "在採集過程中將採集裝置和手保持在心臟下方，以獲得最佳的血液流動。",
      "step_6": "您可能需要不只一次手指刺破。 在每次手指刺穿之間重複這些提示。"
    },
    "shipping": {
      "header": "您的測驗包已準備好發貨！",
      "text": "**Congrats!** You finished your health screening."
    },
    "pick_up": "從現場工作人員處獲取推薦的測驗包並在下面註冊",
    "short_display_name": {
      "A": "衣原體和淋病",
      "B": "衣原體和淋病",
      "C": "梅毒",
      "CC": "大腸直腸癌",
      "CE": "乳糜瀉",
      "CR": "肌酸酐",
      "D": "愛滋病毒、梅毒、衣原體和淋病",
      "DD": "糖尿病",
      "E": "愛滋病毒和梅毒",
      "H": "HPV病毒",
      "P": "C型肝炎",
      "HS": "單純皰疹病毒",
      "I": "愛滋病毒",
      "TT": "睪固酮",
      "VD": "維生素D",
      "VS": "病毒監測"
    },
    "test_kit": "測試包",
    "view_results": "查看結果",
    "recommended_kit_title": "測試包推薦",
    "instructions_box_h": {
      "title": "陰道拭子採集",
      "subtitle": "收集管可能含有液體防腐劑。 <span class=\"text-danger\">**不要從收集管中清空液體防腐劑（如有）。**</span>",
      "step_1": "用一隻手將拭子放在微弱的刻痕線（如果有）和拭子的棉籤部分之間，然後分離陰唇（陰道口周圍的皮膚褶皺）。",
      "step_2": "將拭子插入陰道口 5 公分（2 吋）。 輕輕轉動拭子 30 秒，同時用拭子摩擦陰道壁。",
      "step_3": "小心取出拭子並放入標示<span class=\"text-danger\">**紅色「陰道」標籤**</div>的採集管中。 彎曲收集管以折斷拭子。",
      "step_4": "將蓋子蓋在收集管上並確保其均勻且緊密地關閉。",
      "step_5": "將您的出生日期以 MM/DD/YYYY 格式寫在管子的指定區域 (DOB) 上。",
      "step_6": "將樣本採集日期（今天的日期）以 MM/DD/YYYY 格式寫在試管的指定區域（Coll. Date）。",
      "step_7": "將樣本放入帶有吸收墊的生物危險品專用袋中，並確保密封完全關閉。"
    },
    "packaging_box_h": {
      "step_3": "您的**出生日期**以 MM/DD/YYYY 格式寫在取樣管上",
      "step_4": "您的**採集日期**採用 MM/DD/YYYY 格式寫在採樣管上",
      "step_6": "生物危險品專用袋已放入盒中"
    },
    "instructions_box_cc": {
      "title": "糞便樣本採集",
      "step_1": "將您的出生日期和標本採集日期（今天的日期）以 MM/DD/YYYY 格式寫在試管的指定區域。 扭轉和提起綠色蓋子。",
      "step_2": "將提供的收集紙放入馬桶中，放在水面上。",
      "step_3": "將糞便樣本放在收集紙上。",
      "step_4": "在收集紙沉沒和糞便樣本接觸水之前收集糞便樣本。",
      "step_5": "用樣品探針刮擦糞便樣品的表面。",
      "step_6": "把糞便樣本完全覆蓋樣本探針的凹槽部分。",
      "step_7": "插入樣品探針並旋緊綠色蓋子，關閉採樣瓶。 不要重新打開。",
      "step_8": "沖洗。 收集紙可生物降解，並不會損害化糞池系統。",
      "step_9": "將採樣瓶包裹在吸水墊中並放入生物危險品專用袋中。"
    },
    "contact_support": {
      "title": "讓我們解決這個問題",
      "text_1": "得知出現問題，我們深感遺憾！",
      "text_2": "請聯絡我們告知問題所在，我們將協助更換您的測試包。"
    },
    "contact_support_success": {
      "title": "我們收到您的信息",
      "text_1": "謝謝您聯絡我們。",
      "text_2": "我們的支援團隊成員將很快與您聯繫。",
      "button_text": "回首頁"
    },
    "kit_in_transit_to_patient": "您的訂單正在運送中！ 大多數訂單會在 2-5 個工作天內送達。 當您收到套件後，我們將向您發送有關如何啟動套件並將其寄回的更多資訊。\n\n追蹤連結：https://tools.usps.com/go/TrackConfirmAction_input?strOrigTrackNum={{tracking_number}}",
    "kit_received_at_lab": "實驗室收到您的測驗包了！ 結果準備好後，您會在患者平台上收到通知",
    "registration_confirmation": "您的註冊現已完成！ 造訪您的註冊確認頁面以啟動您的測驗包：",
    "kit_ordered_online": "我們已收到您的測驗包訂單，發貨後我們將發送更新！ 訂單編號：{{order_number}} 訂單日期：{{order_date}}",
    "kit_ordered_online_email": {
      "header": "我們收到了您的訂單！",
      "p1": "我們已收到您的訂單！ 您的訂單發貨後，我們將向您發送另一次更新。",
      "order_number": "訂單編號 #",
      "order_date": "訂單日期"
    },
    "completed": "完成",
    "I": "該測驗包包括 HIV 檢測。",
    "CC": "this kit includes tests for COVID-19.",
    "CE": "該測驗包包括乳糜瀉測試。",
    "CR": "該測驗包包括肌酸酐測試。",
    "DD": "該測驗包包括糖尿病測試。",
    "H": "該測驗包包括 HPV 檢測。",
    "P": "該測驗包包括丙型肝炎檢測。",
    "HH": "this kit includes tests for COVID-19, Flu A/B, and RSV.",
    "HS": "該測驗包包括 HSV-2 測試。",
    "TT": "該測驗包包括睾酮測試。",
    "VD": "該測驗包包括維生素 D 測試。",
    "nasal_collection": {
      "title": "Nasal collection",
      "subtitle": "For this test you will need the following materials:",
      "step_1": "Write your Date of Birth and the Collection Date on your collection tube. Use MM/DD/YYYY format for the dates (i.e., 08/29/2023).",
      "step_2": "Wash your hands with soap and water thoroughly before proceeding and after completeing all steps.",
      "step_3": "Remove the swab from the wrapper with your clean hands. Hold the swab with the white tip up. Do not touch the tip of the swab to anything.",
      "step_4": "Holding the swab with one hand, gently insert the white tip of the swab about 1/2 to 3/4 inces (1 to 2 cm) into the opening of your nose. Rotate the swab for 15 to 30 seconds. Make sure the swab touches the insides of your nose. Remove the swab from your nose being careful not to touch your skin. Do not set the swab down. Repeat in the other nostril.",
      "step_5": "While still holding the swab, unscrew and remove the cap from the transport tube without setting the cap down. Place the swab into the tube with the white tip down, taking great care not to spill or splash the liquid in the collection tube.",
      "step_6": "Place lid onto the collection tube and ensure it is closed evenly and tightly.",
      "step_7": "Place specimen into the empty biohazard bag with the absobent pad and ensure the seal is closed completely. Return the biohazard bag to the kiosk drop box."
    },
    "NN": "this kit includes tests for COVID-19.",
    "YYYWW": "this kit includes tests for COVID-19, Flu A/B, and RSV.",
    "FFFWW": "this kit includes tests for COVID-19 and Flu A/B.",
    "HA": "this kit includes tests for COVID-19, Flu A/B, and RSV.",
    "drop_off": {
      "header": "Your kit is ready to drop off!"
    }
  },
  "copy_link": "複製頁面連結",
  "copied_link": "已複製連結！",
  "vaccines": {
    "descriptions": {
      "vaccine_flu": "預防常見流感病毒",
      "vaccine_covid": "預防新冠肺炎病毒",
      "tdap": "Tdap ( 破傷風、白喉、百日咳) 疫苗可預防破傷風",
      "polio": "預防小兒麻痺病毒。 兒童上學前必須接種。",
      "varicella": "預防水痘的疫苗",
      "shingles": "預防帶狀皰疹的疫苗。 最低年齡為 18 歲。",
      "human_papillomavirus": "預防 HPV 的疫苗。 建議 11 歲兒童使用。",
      "meningococcal": "疫苗可預防四種類型的腦膜炎雙球菌。"
    }
  },
  "viral_surveillance": {
    "shipping": {
      "title": "Prepare to ship your sample:",
      "step_1": {
        "label": "Put the biohazard bag into box.",
        "substep_1": "Remove the return shipping mailer from your test kit.",
        "substep_2": "Place the biohazard bag into the original collection kit box and close the box."
      },
      "step_2": {
        "label": "Put box into return shipping mailer.",
        "substep_1": "Place the box into the return shipping mailer and seal the mailer.",
        "substep_2": "Do not fold or cover the UN3373 diamond symbol."
      },
      "step_3": {
        "label": "Return to Fedex location.",
        "substep_1": "Return kit on the same day of collection (before the last pickup) to a Fedex location.",
        "substep_2": "Call for a FedEx pickup at 1.800.GoFedEx."
      }
    },
    "overview": {
      "title": "Welcome to the Viral Sequencing Program!",
      "subtitle": "Your participation helps provide valuable information on which respiratory pathogen variants are circulating in your area. Please follow the instructions that came with your kit to get started."
    },
    "instructions": {
      "title": "Collect your sample:",
      "step_1": "Wash your hands.",
      "step_2": "Remove the cap of the collection tube and open the swab package.",
      "step_2a": "Remove the swab from the package. Do not touch the soft end with your hands or anything else.",
      "step_3": "Collect a nasal swab.",
      "step_3a": "Insert the entire soft end of the swab into your nostril no more than 3/4 of an inch (1/5cm) into your nose.",
      "step_3b": "Slowly rotate the swab",
      "step_3c": "Gently remove the swab.",
      "step_3d": "Using the same swab, repeat in your other nostril with the same end of the swab.",
      "step_4": "Put swab into the collection tube and cap the tube securely.",
      "step_5": "Put the collection tube into the biohazard bag and seal.",
      "step_6": "Apply even pressure to the ziplock to seal the bag shut."
    },
    "click_to_get_started_button": "Click to get started",
    "get_started_button": "Get started",
    "thank_you": {
      "title": "Thank you for using Primary.Health!",
      "p_3": "You may now close the browser.",
      "p_2": "Please make sure to drop off the viral surveillance kit at your closest FedEx location.",
      "p_1": "Your participation actively helps Public Health officials fight the spread of disease. If you would like to see the impact you are making on Public Health, please take a moment to explore more via [this link]({{link}}). You will not be contacted about your results, as they are anonymous."
    },
    "verify_dob_title": "First, let's confirm your details",
    "verify_dob_subtitle": "This helps us ensure the correct person is using the test kit.",
    "activate_kit": "Activate a kit:",
    "steps_complete": "Steps complete",
    "opt_in": {
      "section_1_title": "How it works",
      "section_1_text_1": "Sign up below and receive a viral sequencing test kit in 1-3 business days.",
      "section_1_text_2": "Upon receiving the test kit",
      "section_1_text_3": "Send the sample back per the instructions in the kit using our prepaid label.",
      "sign_up": "Sign me up!",
      "no_to_participate": "No",
      "section_2_title": "What is the impact of your participation?",
      "section_2": {
        "card_1_title": "Rapid response to new variants",
        "card_1_text": "Genetic sequencing helps track the spread and emergence of new respiratory pathogen variants, allowing public health systems to quickly ramp up preparedness efforts.",
        "card_2_title": "Track how Respiratory Pathogens are changing",
        "card_2_text": "Respiratory pathogen sequencing analyzes the “genetic blueprint” of the virus. This helps scientists trace how the virus changes, enabling it to spread more easily, make people more sick",
        "card_3_title": "You have complete privacy",
        "card_3_text": "The sequencing will be processed anonymously. The lab will never contact you about your results"
      },
      "title": "You’re eligible for California’s Free Respiratory Pathogen Viral Sequencing Project!",
      "subtitle": "You can help public health officials protect communities against respiratory pathogens! If you opt in to participate you will receive a free viral sequencing kit that will test what respiratory pathogen strain you are infected with. Results will help officials track the spread of respiratory pathogen variants. See below for more information and to sign up.",
      "continue_to_result": "Continue to my result",
      "create_page_title": "Thank you!",
      "create_page_text_1": "Your kit should arrive in the next 1-3 business days.",
      "create_page_text_2": "Your participation actively helps Public Health officials fight the spread of diseases. You will be notified when your test kit order has been approved and when it has shipped."
    }
  },
  "family_registrations": {
    "find_clinic": "尋找診所",
    "select_pin": "選擇一個定位指針以查看詳細信息",
    "miles_shorten": "{{miles}} 英里",
    "next_available": "下一個有空",
    "appointment_type": "預約類型",
    "individual_appointment": "個人預約",
    "family_appointment": "家庭預約",
    "vaccines": "疫苗",
    "what_kind_appointment": "您想預約哪一種預約？",
    "select_family_or_individual": "如果您同時為多位家庭成員安排預約，請選擇家庭預約。",
    "vaccines_selected": "已選擇疫苗",
    "where_care": "您想在哪裡接受服務？",
    "select_vaccines_for_individual": "選擇您預約的疫苗（可選）。",
    "select_vaccines_for_family": "為您的家庭預約選擇疫苗（可選）。",
    "schedule_services": "安排服務",
    "add_family": "添加家庭成员并选择以下服务。",
    "family_member": "家庭成員 {{number}}",
    "morning": "早上",
    "afternoon": "下午",
    "slot_available": "{{number}} 可預約",
    "within_x_miles": "在 {{miles}} 英里內",
    "any_distance": "任何距離",
    "partial_results": "部分結果",
    "partial_matches": "以下診所只提供部分，並不是全部，您正在尋找的疫苗",
    "no_matches": "該搜尋沒有找到任何匹配項。 嘗試更改過濾選項以獲得更好的結果。",
    "no_clinics_found": "沒有任何診所符合您的搜尋。",
    "broaden_filters": "嘗試擴大過濾選項以獲得更好的結果。",
    "unavailable_vaccines": "沒提供的疫苗：",
    "available_vaccines": "有提供的疫苗：",
    "select_date": "選擇日期",
    "available_appointments": "可預約",
    "appointment_scheduling_info": "在下一步中，您將能夠為特定家庭成員分配預約時間。如有需要，您可以安排預約在不同的日子。",
    "hold_selected_appointments": "我們會將您選擇的預約保留 15 分鐘。",
    "appointments_selected": "所選的預約",
    "no_appointments_selected": "未選擇預約",
    "vaccines_needed": "所需疫苗",
    "select_x_appointments": "選擇 {{number}} 預約。",
    "more": "更多",
    "less": "更少",
    "register_next_family_member": "注册下一位家庭成员",
    "successfully_registered_x_of_y_family_members": "您已成功注册 {{y}} 位家庭成员中的 {{x}} 位",
    "next_family_member": "下一位家庭成员",
    "appointments_abbreviated": "预约",
    "register_for_clinic": "诊所注册",
    "select_services": "选择服务",
    "person": "人员 {{number}}",
    "add_person": "添加其他人",
    "registration_confirmation": "注册成功"
  },
  "user_mailer": {
    "verify_email": "讓我們驗證您的電郵地址！",
    "hi_full_name": "您好，{{full_name}},",
    "verify_email_button": "驗證電郵",
    "please_verify_email": "請用以下按鈕來驗證您的電郵地址。"
  },
  "services": {
    "dptap_dt": "DTaP/Tdap/Td（破傷風、白喉、百日咳）",
    "hepatitis_a": "甲型肝炎",
    "hepatitis_b": "B型肝炎",
    "hepatitis_a_b": "甲型和乙型肝炎",
    "hib": "乙型流感嗜血杆菌",
    "mmr": "麻疹、腮腺炎和德國麻疹",
    "meningococcal": "腦膜炎球菌",
    "mpox": "猴痘",
    "pneumococcal": "肺炎球菌",
    "polio": "小兒麻痺",
    "rsv": "呼吸道合胞病毒",
    "rotovirus": "輪狀病毒",
    "zoster": "帶狀皰疹",
    "tetanus_diptheria": "破傷風和白喉",
    "tdap": "破傷風、白喉、百日咳",
    "typhoid": "傷寒",
    "varicella": "水痘",
    "covid-19": "新冠肺炎",
    "covid-19_adult": "新冠肺炎 (成人）",
    "covid-19_adult_description": "適合 12 歲或以上的人。",
    "covid-19_children": "新冠肺炎 (小童）",
    "covid-19_children_description": "適合4-11歲兒童。",
    "covid-19_infants": "新冠肺炎 (嬰兒）",
    "covid-19_infants_description": "適合 6 個月至 3 歲的兒童。",
    "influenza": "流感",
    "mmrv": "麻疹、腮腺炎、风疹和水痘联合疫苗"
  },
  "deep_archived": {
    "admin": "出于数据安全之考量，在 {{test_group_name}} ({{slug}}) 组中之数据已经归档，无法在此页面查看或更改。如需协助，请联系您的客户经理或发送邮件至support@primary.health",
    "participant": "此页面已不可用。请联系您的健康活动联系人：{{test_group_name}}。如果您在查看历史记录时遇到问题，请联系 support@primary.health 以获取帮助。"
  }
}