export const KIT_TYPE_REGEX = /PR-([A-Za-z]+)|T[A-Za-z]{3}([A-Za-z]{2})-[A-Za-z0-9]{5}/;
const TEST_GROUP_CODE_REGEX = /T([A-Z0-9]{3})/;
export const RECALLED_KIT_REGEX = /TAAAHH/i;

function extractTestGroupCode(barcode) {
  return barcode.toUpperCase().match(TEST_GROUP_CODE_REGEX)?.[1] ?? null;
}

export function extractKitType(barcode) {
  if (!barcode) return null;
  const match = barcode.toUpperCase().match(KIT_TYPE_REGEX);

  if (!match) return null;
  const type = match[1] || match[2];

  return type === 'WW' ? extractTestGroupCode(barcode)?.concat('WW') : type;
}

export const kitTypeDisplayLetters = kitType => 
  kitType?.includes('WW') ? 'WW' : kitType;
