export default {
  "date": {
    "order": [
      "year",
      "month",
      "day"
    ],
    "month": "महीना ",
    "day": "दिन ",
    "year": "वर्ष ",
    "january": "जनवरी ",
    "february": "फ़रवरी ",
    "march": "मार्च ",
    "april": "अप्रैल ",
    "may": "मई ",
    "june": "जून ",
    "july": "जुलाई ",
    "august": "अगस्त ",
    "september": "सितंबर ",
    "october": "अक्तूबर ",
    "november": "नवम्बर ",
    "december": "दिसंबर "
  },
  "user": {
    "send_appointment_confirmation_message": "आपका अपॉइन्ट्मन्ट {{name}} के लिए निर्धारित कर दिया है।",
    "landline_appointment_reminder_message": "नमस्ते,  {{full_name}}. यह पर आपके {{name}} अपॉइंटमेंट के लिए एक रिमाइंडर है। आपका अपॉइंटमेंट {{date}} को {{time}}  बजे {{address}} पर है।",
    "send_at_home_visit_confirmation_message": "आपका अपॉइन्ट्मन्ट {{date}} को {{time}} के बाद के लिए निर्धारित कर दिया है।",
    "send_mail_order_confirmation_message": "आपके मेल ऑर्डर की पुष्टि हो गई है",
    "send_waitlist_message": "आपको  के लिए प्रतीक्षा सूची {{name}} में रखा गया है। कृपया तब तक उपस्थित न हों जब तक हम नियुक्ति समय की पुष्टि नहीं कर देते।",
    "verify_contact": {
      "phone": "कृपया क्लिक करके अपने फ़ोन नंबर की पुष्टि करें: {{confirmation_link}}",
      "email": "{{confirmation_link}}"
    },
    "duplicate_user_continue_registering": "अपॉइंटमेंट के लिए यहां पंजीकरण जारी रखें: {{url}}",
    "on_demand_confirmation_message": "आपने {{name}} के लिए सफलतापूर्वक पंजीकरण कर लिया है.",
    "appointment_reminder": " पर अपनी नियुक्ति से पहले {{start_time}}, कृपया किसी भी बकाया कार्रवाई आइटम को पूरा करने के लिए लिंक पर क्लिक करें और अपनी पुष्टि देखें। कृपया अपनी नियुक्ति से 15 मिनट से अधिक समय पहले न आएं।",
    "appointment_reminder_base": " {{text}} यहां क्लिक करें: {{link}}",
    "test_result_notifier": {
      "text_message": "{{first_name_with_last_initial}} के लिए रोगी पोर्टल अपडेट कर दिया गया है। {{url}} पर जाएं और कोड का उपयोग करें: {{access_code}}",
      "email_subject": "आपके रोगी पोर्टल में नए अपडेट",
      "email_intro": "नमस्ते {{first_name_with_last_initial}}",
      "email_p1": "आपके लिए एक नया संदेश या रोगी अद्यतन उपलब्ध है।",
      "email_p2": "अपने रोगी पोर्टल को देखने और कोड का उपयोग करने के लिए नीचे दिए गए बटन पर क्लिक करें या लिंक का उपयोग करें",
      "email_p3": "रोगी पोर्टल यहां देखें:'",
      "email_button": "पोर्टल देखें"
    },
    "landline_appointment_reminder_message_without_time": "नमस्ते, {{full_name}} . यहा {{name}} पर आपके अपॉइंटमेंट के लिए एक रिमाइंडर है। आपका अपॉइंटमेंट {{date}}  को {{address}} पर है।",
    "appointment_reminder_on_demand": " {{date}} को अपनी नियुक्ति से पहले, कृपया किसी भी बकाया कार्रवाई आइटम को पूरा करने के लिए लिंक पर क्लिक करें और अपनी पुष्टि देखें।",
    "new_test_result_notifier": {
      "email_intro": "आपका रोगी पोर्टल अपडेट कर दिया गया है.",
      "email_text_1": "किसी भी नए रोगी के रिकॉर्ड या लैब रिपोर्ट देखने के लिए नीचे दिए गए अपने मेडिकल हिस्ट्री पोर्टल पर जाएँ।",
      "email_button": "मेडिकल हिस्ट्री",
      "email_text_2": "आप इस यूआरएल को कॉपी करके अपने ब्राउज़र में पेस्ट भी कर सकते हैं:"
    },
    "mailers": {
      "email_contact_us": "कोई सवाल? यहाँ संपर्क करें"
    }
  },
  "errors": {
    "generic": {
      "message": "कुछ गलत हो गया। कृपया पुन: प्रयास करें"
    },
    "messages": {
      "blank": "खाली न छोड़ें "
    },
    "incorrect_credentials": "आपके क्रेडेंशियल गलत थे, कृपया पुनः प्रयास करें।",
    "error_activating_test_kit": "परीक्षण किट सक्रिय करने में त्रुटि हुई."
  },
  "type": "टाइप ",
  "payment": {
    "continue_label": "भुगतान जारी रखें",
    "policy_text": "यदि आप अपनी नियुक्ति रद्द करते हैं या नहीं आते हैं, तो हम आपकी नियुक्ति तिथि के 7 दिन बाद, आपके भुगतान का 80% वापस कर देंगे। आप पूरी [सेवा की शर्तें]({{link}}) देख सकते हैं [Terms of Service]({{link}}",
    "card_number": "कार्ड संख्या",
    "expiry_date": "समाप्ति तिथि",
    "pay_button": "वेतन",
    "no_credit_card": "मैं साइट पे भुगतान करूंगा/करूंगी",
    "cash_payment_helper": "कृपया अपनी नियुक्ति **{{payment}}**  में लाना सुनिश्चित करें",
    "invoice": "बीजक",
    "pay_by_card": "कार्ड से भुगतान करें",
    "cost": "लागत",
    "total": "कुल",
    "pay_cash_at_appointment": "मैं अपनी नियुक्ति का नकद भुगतान करूंगा/करूंगी {{appointment}}",
    "view_invoice": "चालान देखें/प्रिंट करें",
    "refund_policy": "अगर आप अपना अपॉइंटमेंट ख़ारिज करते हैं, तो हम आपकी द्वारा किए गए भुगतान का {{amount}}, आपके अपॉइंटमेंट की तिथि के सात दिन के बाद तक वापस लौटा देंगे। आप पूरी [Terms of service]({{link}}) देख सकते हैं",
    "refund_window_passed": "आपका अपॉइंटमेंट राशि वापसी के योग्य नहीं है क्यूंकि आपके अपॉइंटमेंट का समय बीत चुका है। किसी भी समाधान के लिए कृपया सीधे साइट कोऑर्डिनेटर से संपर्क कीजिए।",
    "amount_paid": "राशि अदा कर दी गई",
    "balance": "बकाया"
  },
  "consent_waiver": {
    "upload_waiver_or_sign": "सहमति माफी अपलोड करें या नीचे हस्ताक्षर बॉक्स पर हस्ताक्षर करें",
    "stop": "पंजीकरण पूरा करने के लिए आवश्यक सहमति",
    "parent_or_guardian_required_html": "आपके पंजीकरण को अंतिम रूप देने के लिए, आपके माता-पिता, अभिभावक, या अधिकृत निर्णय निर्माता को नीचे दिए गए फॉर्म पर सहमति देनी होगी और उनके नाम पर हस्ताक्षर करना होगा। आप स्वयं इस सेवा के लिए सहमति <b>नहीं</b> कर सकते हैं।",
    "send_parent_or_guardian": "आप अपने माता-पिता या अभिभावक को यह लिंक भेज सकते हैं या उन्हें इस पृष्ठ पर नीचे हस्ताक्षर करवा सकते हैं।",
    "consent_guardian_name_signature": "दिया नाम ",
    "consent_for_minor": "नाबालिग की ओर से सहमति पर हस्ताक्षर करें",
    "guardian_signature": "माता-पिता, अभिभावक, या अधिकृत चिकित्सा निर्णयकर्ता के हस्ताक्षर",
    "after_you_schedule": "{{age}} से कम उम्र के प्रतिभागियों के पास माता-पिता या अभिभावक द्वारा उनकी ओर से हस्ताक्षरित सहमति होनी चाहिए। कृपया अपने अभिभावक को भेजने या अपने आश्रित के लिए हस्ताक्षर करने के लिए पंजीकरण के अंत तक जारी रख",
    "consent_guardian_last_name_signature": "उपनाम ",
    "consent_first_and_last_name": "दिया नाम तथा उपनाम ",
    "phone_number": "अभिभावक का फोन नम्बर",
    "email": "अभिभावक का ईमेल",
    "consent_registrar_with_guardian_name_signature": "अभिभावक का पहला नाम",
    "consent_registrar_with_guardian_last_name_signature": "अभिभावक का अंतिम नाम",
    "consent_registrar_first_name_signature": "रजिस्ट्रार का पहला नाम",
    "consent_registrar_last_name_signature": "रजिस्ट्रार का अंतिम नाम",
    "consent_on_behalf": "मैं {{first_name}} {{last_name}} की ओर से हस्ताक्षर कर रहा हूं।",
    "consent_on_behalf_registrar": "मुझे {{first_name}} {{last_name}} द्वारा उनकी ओर से हस्ताक्षर करने के लिए मौखिक रूप से अधिकृत किया गया है।",
    "consent_on_behalf_registrar_with_guardian": "मुझे {{first_name}} {{last_name}} के अभिभावक द्वारा उनकी ओर से हस्ताक्षर करने के लिए मौखिक रूप से अधिकृत किया गया है।",
    "registrar_phone": "रजिस्ट्रार का फ़ोन नंबर",
    "registrar_email": "रेजिस्ट्रार का ईमेल",
    "consented_by_parent_guardian": "माता-पिता/अभिभावक",
    "consented_by_decision_maker": "अधिकृत चिकित्सा निर्णय-निर्माता",
    "consented_by_guardian_verbal": "अभिभावक के साथ रजिस्ट्रार (मौखिक सहमति प्राप्त)",
    "consented_by_registrar": "रजिस्ट्रार (मौखिक सहमति प्राप्त)",
    "full_name": "पूरा नाम",
    "print": "प्रिंट करें"
  },
  "registration": {
    "contact_information": "नाम और पता",
    "contact_information_additional": "नियुक्ति संचार और परिणामों के लिए माता-पिता या अभिभावक संपर्क जानकारी",
    "personal_information": "जनसांख्यिकीय जानकारी",
    "address_placeholder": "अपने घर का पता दे दीजिए ",
    "first_name_label": "दिया नाम ",
    "last_name_label": "उपनाम ",
    "errors": {
      "state": "राज्य एक वैध 2 वर्ण राज्य कोड होना चाहिए",
      "phone_number_invalid": "फ़ोन नंबर अमान्य",
      "required": "यह मान आवश्यक है",
      "email_domain": "आपकी प्रविष्टि अमान्य है; कृपया दोबारा जांच करें। पंजीकरण प्रायोजक संगठन द्वारा चुने गए प्रतिभागियों तक ही सीमित है। कृपया अपने कार्य या सामुदायिक ईमेल का उपयोग करने का प्रयास करें। यदि आपको लगता है कि आप पात्र हैं और कोई त्रुटि है, तो कृपया सहायता से संपर्क करें।",
      "age_requirement": "जन्म तिथि उम्र की आवश्यकता को पूरा नहीं करती है",
      "signature_required": "हस्ताक्षर आवश्यक है",
      "regex_invalid": "यह मान अमान्य प्रतीत होता है।",
      "date_invalid": "यह तारीख मौजूद नहीं है",
      "invalid_entry": "कृपया पंजीकरण जारी न रखें। स्क्रीन पर दिए गए निर्देशों का पालन करें या अपने संपर्क बिंदु तक पहुंचें।",
      "city": "एक वैध शहर होना चाहिए",
      "survey_unanswered": "कृपया जांच लें कि * से चिह्नित सभी आवश्यक प्रश्नों का उत्तर दे दिया गया है।",
      "postal_code": "एक वैध 5 अंकों का पोस्टल कोड होना चाहिए",
      "field_required": "{{field}} एक मूल्य होना चाहिए",
      "option_required": "आपको विकल्पों में से एक चुनना होगा",
      "year_length_invalid": "अपने जन्मतिथि के चार अंको वाला वर्ष-अंक अंकित करें( वर्ष)",
      "invalid_day": "दिन १ से ३१के बीच होना चाहिए",
      "reached_max_chars": "आप वर्णों की अधिकतम संख्या तक पहुँच चुके हैं।",
      "chars_max": "अधिकतम वर्ण",
      "minimum_length": "इनपुट कम से कम {{length}} अक्षर लंबा होना चाहिए।"
    },
    "insurance_status": {
      "question": "आप स्वास्थ्य बीमा है?",
      "have_health_insurance": "हाँ, मेरे पास स्वास्थ्य बीमा है",
      "do_not_have_health_insurance": "नहीं, मेरे पास स्वास्थ्य बीमा नहीं है"
    },
    "insurance_policy_holder": {
      "question": "पॉलिसी धारक कौन है?",
      "i_am": "मैं हूँ ",
      "my_spouse": "मेरा पति / पत्नी / जीवनसाथी ",
      "my_parents": "मेरे माता पिता ",
      "someone_else": "कोई और ",
      "policy_first_name": "पॉलिसी धारक का पहला नाम",
      "policy_last_name": "पॉलिसी धारक का अंतिम नाम",
      "policy_dob": "पॉलिसी धारक की जन्म तिथि",
      "name": "पॉलिसी धारक का नाम"
    },
    "insurance_information": {
      "title": "बीमे की जानकारी",
      "company_name": "बीमा कंपनी का नाम",
      "id_number": "सदस्य पहचान संख्या",
      "group_number": "सदस्य समूह संख्या",
      "secondary_insurance_label": "मेरे पास द्वितीयक बीमा है",
      "take_photo": "कृपया अपने बीमा कार्ड की फ़ोटो लें। फ़ोटो लेने से कुछ जानकारी स्वतः भर जाएगी।",
      "front_of_card": "कार्ड का अग्र भाग",
      "card_information": "बीमा कार्ड से संबंधित जानकारी",
      "back_of_card": "कार्ड का पृष्ठ भाग"
    },
    "employment_information": {
      "label": "रोज़गार",
      "address_1": "कंपनी का पता ",
      "address_2": "उदाहरण: सुइट 200",
      "phone_number": "कंपनी का फोन नंबर ",
      "company": "कंपनी का नाम ",
      "postal_code": "नियोजक का पोस्टल कोड",
      "employed": "नियोजित",
      "sole_prorietor": "एक मात्र अधिकारी",
      "not_employed": "बेरोज़गार",
      "reporting_supervisor": "रिपोर्टिंग सुपरवाइजर",
      "reporting_department": "रिपोर्टिंग विभाग",
      "supervisor_name": "पर्यवेक्षक का नाम",
      "supervisor_email": "पर्यवेक्षक का ईमेल"
    },
    "location_availability": "वर्तमान में इन क्षेत्रों में रहने वाले समुदाय के सदस्यों के लिए परीक्षण को प्राथमिकता दी गई है",
    "custom_survey": "प्रश्नमाला",
    "confirmation": "निरीक्षण",
    "waitlisted": "प्रतीक्षा सूची में रखे गए",
    "schedule_your_appointment": "नियुक्ति की सूची बनाना",
    "information": "जानकारी",
    "consent": "सहमति",
    "location": "स्थान",
    "symptoms": "लक्षण",
    "address_required": "अपना पता दर्ज करें",
    "consent_required": "जारी रखने के लिए सहमति दें",
    "required_field": "अवश्य स्थान की ओर संकेत करता ",
    "phone_number": "मोबाइल नम्बर",
    "email": "ईमेल ",
    "date_of_birth": "जन्म तिथि ",
    "minimum_age": "भागीदारी की न्यूनतम आयु",
    "no_minimum_age": "परीक्षण के लिए कोई न्यूनतम आयु नहीं है",
    "continue_button": "जारी रखें",
    "email_required": "यह मान एक मान्य ईमेल होना चाहिए।",
    "done": "हो गाया",
    "signature": "हस्ताक्षर ",
    "clear_button": "मिटायें ",
    "back_button": "पीछे ",
    "choose_location": "एक स्थान चुनें",
    "no_slots": " {{location}} पर कोई और स्लॉट उपलब्ध नहीं है",
    "choose_appointment": " {{location}} पर अपॉइंटमेंट का समय चुनें",
    "appointment_required": "कृपया एक नियुक्ति समय चुनें",
    "phone_number_required": "फ़ोन नंबर अमान्य है",
    "phone_number_label": "कृपया एक नंबर दर्ज करें जो तेजी से परीक्षण परिणामों के लिए पाठ संदेश प्राप्त कर सके",
    "symptoms_experiencing": "कृपया उन लक्षणों पर क्लिक करें जो आप वर्तमान में अनुभव कर रहे हैं। यदि आपके कोई लक्षण नहीं हैं, तो कृपया जारी रखें।",
    "household": "घर के सदस्य",
    "household_p1": "वैकल्पिक रूप से, घर के उन सदस्यों के लिए अपॉइंटमेंट बनाएं, जिन्हें भी परीक्षण करने की आवश्यकता है।",
    "add_dependent": "घरेलू सदस्य जोड़ें",
    "remove_dependent": "आश्रित को हटा दें",
    "dependent_consents": "सहमति",
    "submit": "पूर्ण",
    "add_waitlist": "प्रतीक्षा सूची में जोड़ें",
    "address": "घर का पता",
    "address_1": "पता पंक्ति 1",
    "address_2": "उपयुक्त/सुइट #",
    "address_city": "शहर",
    "address_state": "राज्य",
    "postal_code": "डाक कोड",
    "race_ethnicity": "जाति",
    "gender": "लिंग पहचान",
    "self_described_gender": "स्वयं वर्णित लिंग",
    "interpreter": "दुभाषिया चाहिए? अगर हाँ, कौनसी भाषा ?",
    "consent_to_terms": "मैं इन शर्तों से सहमत हूं।",
    "reg_not_open": "रजिस्ट्रेशन नहीं खुले",
    "no_more_avail_spots": "आपके द्वारा चयनित समय स्लॉट अब उपलब्ध नहीं है। कृपया पुन: प्रयास करें।",
    "consent_helper": "हस्ताक्षर करने के लिए अपने माउस या उंगली को नीचे दिए गए बॉक्स पर क्लिक करें और खींचें",
    "phone_number_unreachable_label": "लैंडलाइन?",
    "select": "चुनते हैं",
    "test_surveys": "नियुक्ति प्रश्न",
    "edit": "संपादित करें",
    "continue_to_registration": "पंजीकरण जारी रखें",
    "accounts": {
      "already_have_an_account": "क्या आपके पास पहले से एक खाता मौजूद है?",
      "log_in": "लॉग इन",
      "sign_up": "साइन अप करें",
      "sign_up_description": "अपना खाता बनाने और भविष्य के पंजीकरण पर समय बचाने के लिए कृपया अपनी जानकारी दर्ज करें।",
      "log_in_description": "कृपया अपना ई-मेल और पासवर्ड दर्ज करें, या नीचे Google या आउटलुक के माध्यम से साइन इन करें",
      "sign_in_with_label": "से साइन इन करें",
      "password_must_match": "पासवर्डों को मेल खाना चाहिए",
      "password_uppercase": "आपके पासवर्ड में कम से कम (%s) अपरकेस अक्षर होना चाहिए।",
      "password_lowercase": "आपके पासवर्ड में कम से कम (%s) लोअरकेस अक्षर होना चाहिए।",
      "password_number": "आपके पासवर्ड में कम से कम (%s) संख्या होनी चाहिए।",
      "password_special": "आपके पासवर्ड में कम से कम (%s) विशेष वर्ण होने चाहिए।"
    },
    "password": "पासवर्ड",
    "password_confirmation": "पासवर्ड पुष्टि",
    "consent_for": "{{name}} की सहमति",
    "book_one_time_appointment": "वन-टाइम अपॉइंटमेंट बुक करें",
    "duplicate_users": {
      "exists": "आप पहले से ही पंजीकृत हैं",
      "overlapping_email_and_phone_p1": "हमने आपके फोन नंबर और ईमेल पते पर एक संदेश भेजा है।",
      "p2": "अपने संदेश का उपयोग करके, आप अपनी नियुक्ति बदल सकते हैं या परीक्षा परिणाम दर्ज कर सकते हैं।",
      "overlapping_phone_and_email_p3": "विवरण के लिए कृपया अपना ईमेल या फोन देखें।",
      "overlapping_email_p3": "विवरण के लिए कृपया अपना ईमेल देखें।",
      "overlapping_phone_p3": "विवरण के लिए कृपया अपने फोन की जांच करें।",
      "overlapping_phone_p1": "हमने आपके फ़ोन नंबर पर एक संदेश भेजा है।",
      "overlapping_email_p1": "हमने आपके ईमेल पते पर एक संदेश भेजा है।",
      "p4": "अगर आपको लगता है कि यह एक त्रुटि है, तो कृपया हमसे support@primary.health पर संपर्क करें",
      "overlapping_email_and_phone_p3": "विवरण के लिए कृपया अपना ईमेल या फोन देखें।"
    },
    "duplicate_waitlist": {
      "exists": "आप पहले से ही प्रतीक्षा सूची में हैं",
      "overlapping_email_and_phone_p1": "हमने आपके फ़ोन नंबर और ईमेल पते पर एक और पुष्टिकरण भेजा है",
      "p2": "अपनी पुष्टि का उपयोग करके, आप अतिरिक्त जानकारी प्रदान कर सकते हैं या प्रतीक्षा सूची से स्वयं को हटा सकते हैं।",
      "overlapping_phone_and_email_p3": "विवरण के लिए कृपया अपना ईमेल या फोन देखें",
      "overlapping_email_p3": "विवरण के लिए कृपया अपना ईमेल देखें",
      "overlapping_phone_p3": "विवरण के लिए कृपया अपने फोन की जांच करें",
      "overlapping_email_p1": "हमने आपके ईमेल पर एक और पुष्टिकरण भेजा है।"
    },
    "insist_guardian_consent": "मैं की ओर से सहमति प्रदान कर रहा हूँ",
    "confirmation_section": {
      "title": "समीक्षा करें और पुष्टि करें",
      "p1": "पंजीकरण पूरा करने से पहले कृपया अपनी जानकारी की समीक्षा करें।",
      "edit_information": "वापस जाएं और संपादित करें"
    },
    "iemodal": {
      "title": "असमर्थित ब्राउज़र",
      "body": "पंजीकरण वर्तमान में इन ब्राउज़रों पर संभव है"
    },
    "show_other_locations": "अन्य स्थान दिखाने के लिए क्लिक करें",
    "non_us_address": "यह एक अंतरराष्ट्रीय पता है",
    "test_group_user_survey": "प्रतिभागी स्क्रीनिंग",
    "self_consent": "मैं अपने लिए सहमति दे रहा हूं",
    "address_country": "देश",
    "appointment": "अपॉइंटमेंट",
    "employee_id": "कर्मचारी आयडी",
    "appointment_time": "मिलने का समय",
    "appointment_location": "स्थान",
    "phone_or_email": "फोन नम्बर अथवा ईमेल",
    "no_self_consent": "मैं अपने लिए सहमति नहीं देता/देती हूँ",
    "no_insist_guardian_consent": "मैं %{full_ name} की ओर से सहमति नहीं देता/ देती हूँ",
    "minimum_age_with_months": "भाग लेने की न्यूनतम आयु {{year}} वर्ष {{month}} महीने है।",
    "additional_consents_helpertext": "अन्य सहमतियों के लिए बाद में निवेदन किया जा सकता है।",
    "assistive_technology": "सहायक तकनीकि",
    "covid_vaccine_y_n": "क्या आप इस बुकिंग के साथ कोविड-19 का टीका भी लेंगे?",
    "received_covid_vaccine_y_n": "क्या आपको पहले कोविड-19 का टीका लग चुका है?",
    "covid_vaccinations": "कोविड-19 टीका",
    "select_vaccine_dose": "कृपया उस टीके के डोज़ का चयन कीजिए जिसे आप लेना चाहते हैं",
    "demographic_info": "जनसांख्यिकीय जानकारी",
    "additional_info": "अतिरिक्त जानकारी",
    "self_described_race": "स्व वर्णित जाति",
    "verify_contact_information": "जांच करना",
    "verify_contact": {
      "we_sent_you_a_code": "कृपया वह कोड दर्ज करें जो हमने {{contact}} को भेजा था",
      "confirmation_code": "पुष्टि कोड",
      "code_resent": {
        "email": "आपके ईमेल पर एक अन्य कोड भेजा गया था",
        "phone_number": "आपके फ़ोन पर एक अन्य कोड भेजा गया था"
      },
      "did_not_receive_code": "पुष्टिकरण कोड नहीं मिला?",
      "verify_by": {
        "email": "इसके बजाय ईमेल से जांचे",
        "phone_number": "इसके बजाय फ़ोन नंबर से जांचे"
      }
    },
    "preferred_method_of_communication": {
      "question": "संचार का पसंदीदा तरीका",
      "helper_text": "हम नियुक्ति संबंधी अपडेट के साथ आपसे संपर्क करेंगे",
      "phone_number": "फ़ोन (केवल एसएमएस संदेश और डेटा दरें लागू हो सकती हैं)",
      "landline": "हम इस समय लैंडलाइन का समर्थन नहीं कर सकते. कृपया महत्वपूर्ण संचार के लिए एक ईमेल प्रदान करें।",
      "verification_method": "आप अपना प्रमाणीकरण कोड कैसे प्राप्त करना चाहते हैं?",
      "verification_helper_text": "लॉग इन करने से पहले आपकी पहचान सत्यापित करने के लिए आपको एक कोड भेजा जाएगा।"
    },
    "skip_for_now": "अभी के लिए छोड़ दे",
    "default_information": {
      "title": "ठीक है, हम इस पता का इस्तमाल करेंगे",
      "title_v2": "ठीक है, हम इस संपर्क जानकारी का उपयोग करेंगे"
    },
    "middle_name_label": "मध्य नाम",
    "confirm_appointment": "अपॉइन्ट्मन्ट की पुष्टि करें",
    "dob": "जन्म तिथि",
    "different_appointment": "अलग अपॉइन्ट्मन्ट",
    "select_appointment_time": "अपॉइन्ट्मन्ट समय का चयन करें",
    "decline_address": "हम पता देने से इनकार करते हैं। इसके बजाय कार्यक्रम के पता का इस्तमाल करें।",
    "patient_information": "रोगी की जानकारी",
    "scan_license": "राइवर लाइसेन्स स्कैन करें।",
    "how_to_contact": "हम आपसे संपर्क कैसे करें?",
    "decline_email_or_phone": "हम ईमेल या फोन नंबर देने से इनकार करतें हैं। हम कार्यक्रम प्रशासक को हमारे परिणाम प्राप्त करने के लिए अधिकृत करतें हैं.",
    "medical_screening": "मेडिकल स्क्रीनिंग या जांच",
    "show_service_at_this_location": "इस स्थान पर सेवा उपलब्ध करें",
    "verbal_consent_provided": "अमेरिकन विकलांगता अधिनियम के तहत, प्रतिभागी या उनके कानूनी रूप से अधिकृत निर्णयकर्ता ने मौखिक सहमति प्रदान की है। प्रतिभागी या उनके कानूनी रूप से अधिकृत निर्णयकर्ता से 24 घंटे के भीतर लिखित सहमति प्राप्त की जाएगी।",
    "unexpired_written_consent_present": "परीक्षण स्थल की फ़ाइल में लिखित सहमति अभी भी वैध है।",
    "select_to_proceed": "कृपया आगे की प्रक्रिया के लिए ऊपर दिए गए विकल्पों में से एक चुनें।",
    "guardians_information": "अभिभावकों से संबंधित जानकारी",
    "registrars_information": "पंजीयक से संबंधित जानकारी",
    "optional": "ऐच्छिक",
    "vfc_eligibility": "वी.एफ.सी योजना के तहत बच्चों की पात्रता",
    "vfc_eligibility_subtext": "आपका बच्चा सी.डी.सी के बच्चों के लिए टीकाकरण (वी.एफ.सी) कार्यक्रम के तहत, उन स्वास्थ्य सेवा प्रदाताओं के कार्यालयों, फार्मेसियों और स्वास्थ्य क्लीनिकों में बिना किसी लागत के टीके प्राप्त करने में सक्षम हो सकता है, जो वी.एफ.सी कार्यक्रम में पंजीकृत हैं।"
  },
  "gender_key": {
    "male": "पुरुष",
    "female": "महिला",
    "cisgender": "सिसजेंडर",
    "non_binary": "जेंडरक्यूअर या गैर-बाइनरी",
    "other": "अन्य",
    "prefer_to_self_describe": "पहचान सूचीबद्ध नहीं है",
    "prefer_not_to_disclose": "उत्तर देने से इंकार",
    "transgender_male": "ट्रांस पुरुष / ट्रांसमैन",
    "transgender_female": "ट्रांस महिला / ट्रांसवुमन",
    "unknown": "अनजान",
    "non_binary_only": "गैर-द्विगुण",
    "intersex": "इंटरसेक्स",
    "transgender": "किन्नर",
    "gender_fluid": "जेंडर फ्लूइड",
    "not_applicable": "लागू नहीं"
  },
  "ethnicity": {
    "american_indian_alaska_native": "भारतीय अमेरिकन या अलास्का का मूल निवासी",
    "american_indian_central_america": "दक्षिण या मध्य अमेरिका से अमेरिकी भारतीय",
    "asian": "एशियाई",
    "black": "अश्वेत या अफ्रीकी अमेरिकी",
    "latinx": "लातीनी, लैटिनक्स या हिस्पैनिक",
    "middle_eastern_north_african": "मध्य पूर्वी या उत्तरी अफ़्रीकी",
    "native_hawaiian_pacific_islander": "मूल निवासी हवाईयन या प्रशांत द्वीप वासी",
    "white_european": "सफेद या कोकेशियान",
    "unknown": "अनजान",
    "prefer_not_to_disclose": "खुलासा नहीं करना पसंद करते हैं",
    "asian_indian": "एशियाई भारतीय",
    "filipino": "filipino",
    "japanese": "जापानी",
    "korean": "कोरियाई",
    "vietnamese": "वियतनामी",
    "other_asian": "अन्य एशियाई",
    "native_hawaiian": "मूल हवाईयन",
    "guamanian_or_chamorro": "गुआमनियाई या कमोरो",
    "samoan": "सामोन",
    "other_pacific_islander": "अन्य प्रशांत द्वीपसमूह",
    "chinese": "चीनी",
    "help": "नस्ल लोगों के समूहों के बीच अंतर करने के लिए उपयोग की जाने वाली भौतिक विशेषताओं के एक समूह को संदर्भित करती है। नस्ल राष्ट्रीयता, या जिस देश में आपके पास नागरिकता है, के साथ संरेखित हो भी सकती है और नहीं भी।",
    "subtitle": "विनियमों के लिए आवश्यक है कि हम निम्नलिखित सभी जानकारी एकत्र करें।",
    "laotian": "स्थान",
    "cambodian": "कम्बोडियन",
    "other": "अन्य",
    "bangladeshi": "बांग्लादेशी",
    "hmong": "ह्मोंग",
    "indonesian": "इंडोनेशियाई",
    "malaysian": "मलेशियाई",
    "pakistani": "पाकिस्तानी",
    "sri_lankan": "श्रीलंकाई",
    "thai": "थाई",
    "taiwanese": "ताइवानी",
    "fijian": "फिजी",
    "guamanian": "गुआम",
    "tongan": "टोंगा",
    "bahamian": "बहामियन"
  },
  "languages": {
    "en": "अंग्रेज़ी",
    "fr": "फ़्रैंकैसी",
    "es": "स्पेनोलि",
    "so": "सुमाली",
    "hmn": "हमोंग",
    "asl": "अमेरिकी सांकेतिक भाषा (एएसएल)",
    "kar": "करेनी",
    "am": "अम्हारिक्",
    "ru": "Русский",
    "om": "ओरोमो"
  },
  "symptoms": {
    "fever": "बुखार या ठंड लगना",
    "cough": "खांसी",
    "muscle_aches": "मांसपेशी में दर्द",
    "severe_fatigue": "गंभीर थकान (सामान्य से अधिक)",
    "trouble_breathing": "साँस लेने में कठिनाई",
    "diarrhea": "दस्त",
    "loss_of_smell": "गंध की हानि",
    "loss_of_taste": "स्वाद का नुकसान",
    "shortness_of_breath": "सांस की तकलीफ या सांस लेने में कठिनाई",
    "headache": "सिरदर्द",
    "sore_throat": "गले में खरास",
    "congestion": "भीड़भाड़ या बहती नाक",
    "nausea": "उलटी अथवा मितली",
    "close_contact": "संक्रमित व्यक्ति के साथ निकट संपर्क*",
    "helper_text": {
      "close_contact": "*क्या आपने किसी ऐसे व्यक्ति के साथ निकट संपर्क (कम से कम 15 मिनट के लिए 6 फीट के भीतर) किया है, जिसने COVID-19 की पुष्टि की है?"
    },
    "suspected_exposure": "संदिग्ध एक्सपोजर",
    "none": "कोई लक्षण नहीं"
  },
  "instructions": {
    "title": "आप नामांकित हैं लेकिन जाने से पहले...",
    "p1": "इस पृष्ठ में आपके अपॉइंटमेंट के साथ-साथ आपके अपॉइंटमेंट बारकोड के बारे में जानकारी है।",
    "appointment_scheduled": "आपकी नियुक्ति निर्धारित है",
    "verify_info": {
      "title": "अपनी जानकारी सत्यापित करें",
      "p1": "अपनी संपर्क जानकारी को सत्यापित करना महत्वपूर्ण है ताकि आप अपने रोगी पोर्टल तक शीघ्रता से पहुंच सकें।",
      "p2": "आपके ईमेल पर पुष्टि भेजी गई है। कृपया दिए गए लिंक पर क्लिक करें।",
      "p3": "यदि आपको अपनी संपर्क जानकारी बदलने की आवश्यकता है, तो कृपया सहायता बटन दबाएं।"
    },
    "verified": "सत्यापित",
    "not_verified": "प्रमाणित नहीं है",
    "resend_text": "लिंक नहीं मिला?",
    "resend": "पुन: भेजें",
    "skip": "सत्यापन छोड़ें और पुष्टि दिखाएं",
    "name": "नाम",
    "appointment_details": "मुलाकात",
    "date_and_time": "तिथि और समय",
    "add_to_calendar": "कैलेंडर में जोड़ें",
    "instructions": "सामान्य निर्देश",
    "successfully_flash": "अपॉइंटमेंट सफलतापूर्वक बनाया गया!",
    "success_flash_email": "ईमेल को सफलतापूर्वक सत्यापित किया गया",
    "success_flash_phone": "फ़ोन नंबर सफलतापूर्वक सत्यापित किया गया",
    "mail_order": "आपको अपना परीक्षण-किट सप्ताह के भीतर डाक में प्राप्त हो जाएगा।",
    "at_home": "इस समय के आसपास एक चिकित्सा प्रदाता आपके घर आएगा",
    "at_home_instructions": "घर पर निर्देश",
    "mail_order_instructions": "मेल आदेश निर्देश",
    "request_additional_appointment": "बुक फॉलो-अप अपॉइंटमेंट",
    "book_additional_appointment": "एक और अपॉइंटमेंट बुक करें",
    "confirmation_code": "पुष्टि कोड",
    "completed": "पूरा हुआ",
    "appointment_barcode": "अपॉइंटमेंट बारकोड",
    "dependent_links": "घरेलू अपॉइंटमेंट लिंक",
    "on_demand_title": "आप नामांकित हैं लेकिन जाने से पहले...",
    "save_link": "इस लिंक को संचित करें",
    "verify_phone": "अपने फ़ोन नम्बर को सत्यापित करें",
    "verify_phone_description": "अपनी जानकारी की पुष्टि करें ताकि आप अपने स्मारक को तेज़ी से उपयोग कर सकें। पुष्टिकरण संदेश आपके नम्बर  पर भेज दिया गया है।",
    "verify_email_description": "अपनी जानकारी की पुष्टि करें ताकि आप अपने स्मारक का तेज़ी से उपयोग कर सकें। पुष्टिकरण संदेश आपकी ई -मेल आई डी पर भेज दिया गया है। ",
    "information": "पंजीकरण की जानकारी",
    "follow_up_appointment": "आगे की जाँच की लिए नियुक्ति",
    "get_directions": "दिशा-निर्देश प्राप्त करें",
    "cancel": "नियुक्ति रद्द ",
    "reschedule_appointment": "नियुक्ति पुनर्योजित",
    "reschedule_linked_appointments": "नियुक्ति पुनर्योजित",
    "no_slots": "और कोई समय उपलब्ध नहीं है",
    "check_results": "रोगी पोर्टल  जाँच करें",
    "follow_up_modal_header": "कृपया अपनी दूसरी डोज़ की समय सारणी करें",
    "are_you_sure_you_want_to_cancel": "क्या आप वाक़ई अपॉंट्मेंट रद्द करना चाहते हैं?",
    "please_choose_cancellation_reason": "कृपया रद्द करने का कारण चुने",
    "additional_details": "नीचे अपनी अधिक जानकारी दर्ज करें",
    "errors": {
      "missing_cancellation_reason": "कृपया रद्द करने का कारण चुनिए"
    },
    "verify_email": "अपने ई-मेल को सत्यापित करें",
    "redcap_url": "निरीक्षण की पूर्ति करण के लिए क्लिक करें",
    "verify_contact_information": "अपनी सम्पर्क जानकारी को सत्यापित करें",
    "please_also": "कृपया ये भी",
    "new_title": "{{name}} के लिए पुष्टीकरण",
    "contact_method": "संपर्क करने की विधि",
    "next_steps": {
      "title": "अगले कदम",
      "p1": "आपके पास एक टेस्ट किट है जिसे आपको सक्रिय करना है। जब आप टेस्ट लेने के लिए तैय्यार हों, आपके ईमेल पे भेजे गए लिंक द्वारा इस पेज पर वापिस या कर अपने किट को सक्रिय बनाएं ।",
      "administered": "We will contact you when your kit reaches the lab and when your results are ready. Results typically take 24-48 hours after they reach the lab. You can view results from your [Medical history]({{link}}) page when they are ready.",
      "resulted": "परिणाम आपके [मेडिकल हिस्ट्री]({{link}}) पेज पर उपलब्ध हैं।",
      "shipping_information": "शिपिंग सूचना"
    },
    "save_this_page": {
      "title": "इस पेज को सेव करें",
      "p1": "सेल्फ टेस्ट या मेडिकल हिस्ट्री द्वारा अपने परिणाम देखने के लिए इस पेज का इस्तमाल करें।",
      "p2": "भविष्य में इस पेज तक पहुँचने के लिए अपने पुष्टिकरण ईमेल या एसएमएस पाठ में दिए गए लिंक पर क्लिक करें।",
      "p3": "आप इस पृष्ठ को बुकमार्क करके, होम स्क्रीन पर जोड़कर या लिंक को किसी सुरक्षित स्थान पर कॉपी करके सहेज सकते हैं।"
    },
    "show_my_barcode": "हमारा बरकोड़े दिखाएं",
    "my_account": "हमारा खाता",
    "register_another": "किसी अन्य प्रतिभागी को पंजीकृत करें",
    "update_vaccine": "टीके की जानकारी अपडेट करें",
    "medical_history": "मेडिकल हिस्ट्री",
    "upcoming_appointments": "आगामी अपॉइंटमेंट्स",
    "reschedule": "पुनः निर्धारित करें",
    "resend_confirmation": "पुष्टिकरण दोबारा भेजें",
    "appointment_details_v2": "अपॉइंटमेंट से संबंधित जानकारी",
    "confirm_cancellation": "रद्दीकरण की पुष्टि करें",
    "confirm_cancellation_question": "क्या आप निश्चित रूप से इस अपॉइंटमेंट को रद्द करना चाहते हैं?",
    "select_new_appointment_time_below": "कृपया नीचे नया अपॉइंटमेंट समय चुनें। यदि आपको सेवाओं या अपॉइंटमेंट स्थान में कोई बदलाव करना है, तो कृपया इस अपॉइंटमेंट को रद्द करें और एक नया अपॉइंटमेंट निर्धारित करें।",
    "no_take_me_back": "नहीं, वापस जाएँ",
    "yes_cancel_appointment": "हाँ, अपॉइंटमेंट रद्द करें",
    "update_appointment": "अपॉइंटमेंट अपडेट करें",
    "select_new_appointment_time": "नया अपॉइंटमेंट समय चुनें",
    "clinic": "क्लिनिक",
    "services": "सेवाएँ",
    "appointment_missed": "छूटा हुआ अपॉइंटमेंट",
    "appointment_canceled": "रद्द किया गया अपॉइंटमेंट",
    "screening_complete_for": "Screening complete for {{name}}"
  },
  "result_page": {
    "errors": {
      "invalid_access_code": "अमान्य ऐक्सेस कोड"
    },
    "landing": {
      "p1": "अपना परिणाम जानने के लिए, दर्ज करें:",
      "access_code_label": "ऐक्सेस ",
      "submit_button": "जमा करें",
      "recent_results": "आपका हाल का परिणाम"
    },
    "view_results": {
      "headline": "{{name}} के लिए जाँच परिणाम ",
      "reregister": "दोबारा -पंजीकरण",
      "status": {
        "likely_positive": "अनिश्चित परिणाम",
        "test_not_performed": "दोबारा जाँच की आवश्यकता",
        "results_ready": "परिणाम तय्यार हैं",
        "processing": "प्रसंस्करण",
        "test_status": "परीक्षा की स्तिथि",
        "test_result": "जाँच परिणाम",
        "administered": "प्रशासित",
        "results_pending": "लंबित परिणाम ",
        "test_results": "टेस्ट परिणाम "
      },
      "at": "{{address}} पर"
    },
    "result_label": "परिणाम",
    "result": {
      "flu_a": {
        "result_label": "फ्लू ए का परिणाम"
      },
      "flu_b": {
        "result_label": "फ्लू बी का परिणाम"
      },
      "covid19": {
        "result_label": "कोविड-19 का परिणाम"
      },
      "covid": {
        "result_label": "कोविड-19 का परिणाम"
      },
      "sars": {
        "result_label": "सार्स (SARS) का परिणाम"
      },
      "control": {
        "result_label": "नियंत्रण"
      },
      "rsv": {
        "result_label": "आरएसवी परिणाम"
      },
      "result": {
        "result_label": "परिणाम"
      },
      "hba1c": {
        "normal": "सामान्य",
        "warning": "परेडियाबेटेस",
        "danger": "डाइअबीटीज़"
      },
      "lead_venous": {
        "danger": "असामान्य",
        "normal": "सामान्य"
      },
      "tc": {
        "result_label": "TC परिणाम"
      },
      "hdl": {
        "result_label": "HDL परिणाम"
      },
      "trg": {
        "result_label": "TRG परिणाम"
      },
      "ldl": {
        "result_label": "LDL परिणाम"
      },
      "non_hdl": {
        "result_label": "गैर LDL परिणाम"
      },
      "tc_hdl_ratio": {
        "result_label": "TC/HDL अनुपात"
      },
      "glu": {
        "result_label": "GLU परिणाम"
      },
      "alere_cholestech_ldx": {
        "danger": "खतरे में",
        "warning": "असामान्य",
        "normal": "सामान्य"
      },
      "lead": {
        "result_label": "लेड"
      },
      "zinc": {
        "result_label": "ज़िंक"
      },
      "lead_standard_profile": {
        "danger": "असामान्य",
        "normal": "सामान्य"
      },
      "creatinine": {
        "danger": "असामान्य",
        "normal": "सामान्य"
      },
      "igg": {
        "result_label": "IgG टेस्ट"
      },
      "igm": {
        "result_label": "IgM टेस्ट"
      },
      "blood_glucose_fasted": {
        "normal": "सामान्य",
        "warning": "उच्च",
        "danger": "उच्च",
        "hypoglycemia": "हाइपोग्लाइसीमिया",
        "prediabetes": "प्री-डायबिटीज",
        "diabetes": "मधुमेह"
      },
      "total_cholesterol_fasted": {
        "normal": "सामान्य",
        "elevated": "उच्च",
        "high": "उच्च",
        "low": "निम्न",
        "abnormal": "असामान्य"
      },
      "total_cholesterol_unfasted": {
        "normal": "सामान्य",
        "elevated": "उच्च",
        "high": "उच्च"
      },
      "a1c_now": {
        "normal": "सामान्य",
        "warning": "पूर्व-मधुमेह",
        "danger": "मधुमेह"
      },
      "blood_glucose": {
        "warning": "कम",
        "normal": "सामान्य",
        "prediabetes": "पूर्व मधुमेह",
        "danger": "मधुमेह",
        "hypoglycemia": "हाइपोग्लाइसीमिया",
        "medical_emergency": "चिकित्सा आपातकाल",
        "inconclusive_1": "अनिर्णायक 1",
        "inconclusive_2": "अनिर्णायक 2",
        "possible_diabetes": "संभावित मधुमेह"
      },
      "triglycerides": {
        "result_label": "ट्राइग्लिसराइड्स"
      },
      "blood_glucose_ucsf": {
        "low": "कम",
        "normal": "सामान्य",
        "high": "उच्च"
      },
      "syphilis": {
        "result_label": "सिफिलिस परिणाम"
      },
      "hepatitis_c": {
        "result_label": "हेपेटाइटिस C परिणाम"
      },
      "hiv": {
        "result_label": "एचआईवी परिणाम"
      },
      "rapid_hiv": {
        "positive": "प्रतिक्रियाशील (प्रारंभिक सकारात्मक)",
        "negative": "अप्रतिक्रियाशील (नकारात्मक)",
        "did_not_result": "अमान्य (अनिर्णीत)"
      },
      "rapid_hcv": {
        "positive": "प्रतिक्रियाशील (प्रारंभिक सकारात्मक)",
        "negative": "अप्रतिक्रियाशील (नकारात्मक)",
        "did_not_result": "अमान्य (अनिर्णीत)"
      },
      "rapid_syphilis": {
        "positive": "प्रतिक्रियाशील (प्रारंभिक सकारात्मक)",
        "negative": "अप्रतिक्रियाशील (नकारात्मक)",
        "did_not_result": "अमान्य (अनिर्णीत)"
      }
    },
    "documents": "दस्तावेज़",
    "self_administered": "{{location}} पर स्व-प्रशासित परीक्षण उठाया गया",
    "patient": "रोगी",
    "medical_history": "मेडिकल हिस्ट्री",
    "overview_title": "अधिक विवरण और आवश्यक अतिरिक्त कार्रवाई वाले आइटम देखने के लिए एक परीक्षण या सेवा का चयन करें। ध्यान दें कि कुछ टेस्ट परिणाम अभी तक उपलब्ध नहीं हो सकें हैं या किसी प्रदाता से बात करने के बाद ही उपलब्ध होंगे।",
    "insurance_information": "बीमा संबंधी जानकारी भरें",
    "contact_support": "सहायता हेतु कृपया समर्थन से संपर्क करें।",
    "show": "दिखाएं",
    "hide": "छिपायें",
    "lab_report": "लैब रिपोर्ट",
    "contact_provider": {
      "header": "आपके पास सकारात्मक परिणाम हैं",
      "description": "क्या आप किसी प्रदाता से अपने परिणाम की चर्चा और इलाज के बारे मे बात करना चाहेंगे?",
      "yes_option_text": "हाँ, हम प्रदाता से बात करना चाहेंगे",
      "no_option_text": "नहीं, हमने अपने परिणामों को पढ़कर समझ लिया है और हमे प्रदाता से बात नहीं करनी है",
      "confirm_phone_number_header": "अपने फोन नंबर की पुष्टि करें",
      "confirm_phone_number_description": "आपके परामर्श के लिए, अपने फोननंबर की पुष्टि करें",
      "confirm_button_text": "पुष्टि करें",
      "consultation_confirmed_header": "परामर्श  की पुष्टि हो गई है",
      "consultation_confirmed_description": "अपको {{phone_number}} पर, 2-3 दिन के अंदर, एक प्रदाता कल करेंगे।",
      "acknowledgement_option_helper_text": "कुछ ऐसा जो उपचार के महत्व को समझाता है और उपयोगकर्ता को यह बताता है कि यदि उनका मन बदल जाए तो कॉल को कैसे शेड्यूल किया जाए।",
      "acknowledgement_confirmed_header": "अपॉइन्ट्मन्ट की पुष्टि हो गई है",
      "acknowledgement_confirmed_description": "जुड़े संसाधनों के साथ उपचार प्राप्त करने के महत्व के बारे में कुछ। याद रखें कि परामर्श पूरी तरह से नि:शुल्क है और प्रदाता संक्रमण के समाधान में मदद के लिए ड्वाइस लिख सकतें है या फिर संक्रमण के समाधान के लिए जो भी जरूरी है वो कर सकतें हैं।",
      "acknowledgement_confirmed_change_mind_text": "अगर आपका मन बदल जी तो आप बस नीचे दी गए \"मुझे कान्सल्टैशन चाहिए\" वाला बटन दबाएं.",
      "request_consultation_button_text": "हमें कान्सल्टैशन चाहिए"
    },
    "phone": "फोन",
    "source": "Source"
  },
  "required": {
    "errors": {
      "address_not_in_bounding_box": "आपका पता इस साइट की पात्रता आवश्यकताओं से मेल नहीं खाता|"
    }
  },
  "member": {
    "medical_history": "इतिहास",
    "view_instructions": "पुष्टि देखें",
    "next_appointment": "अगली नियुक्ति",
    "over_18": "मैं पुष्टि करता/करती हूँ कि मैं १८ या १८ वर्ष से बड़ा हूँ",
    "choose_test_configurations_title": "कृपया नियुक्ति के लिए व्यवस्था/व्यवस्थाओं का चयन करें",
    "member_taken_error": "उपयोगकर्ता का पहले से ही एक खाता मौजूद है",
    "choose_test_configurations_subtitle": "सेवाएँ सभी घर के सदस्य पर लागू होंगी",
    "service": "सेवा",
    "group_or_location": "समूह/ जगह",
    "details": "विवरण",
    "see_all": "पूरा देखें",
    "no_history": "इस समय कोई इतिहास उपलब्ध नहीं है",
    "medical_history_title_with_name": "{{name}} इतिहास",
    "no_dashboard": "इस समय कुछ भी उपलब्ध नहीं है",
    "product": "उत्पाद",
    "price": "कीमत",
    "quantity": "परिमाण",
    "total_services_selected": "कुल चयनित सेवाएं",
    "total_price": "कुल कीमत"
  },
  "or": "या",
  "account": {
    "errors": {
      "must_be_13": "खाता पंजीकरण करने के लिए १३ वर्ष का होना आवश्यक है"
    }
  },
  "self_administration": {
    "title": "स्वयं प्रशासन",
    "self_administer_action": "स्वयं-प्रशासन",
    "skip_to_link": "स्व-प्रशासन पर जाएं",
    "select_person": "चैक आउट करने के लिए एक व्यक्ति का चयन करें",
    "adult": "बालिग़ ",
    "child": "शिशु",
    "checked_out": "चैक आउट ",
    "go_back": "वापस जाए",
    "switch_to_scanner": "स्कैनर पर स्विच करें",
    "enter_barcode": "बारकोड दर्ज करें",
    "scan_barcode": "बारकोड स्कैन करें",
    "cancel": "रद्द करें",
    "barcode_for": "इसके लिए बारकोड",
    "enter_barcode_manually": "मैन्युअल रूप से बारकोड दर्ज करें",
    "instructions": "अपनी टेस्ट ट्यूब के नीचे  स्थित बारकोड को स्कैन करें या दर्ज करें।",
    "regex_prefix": "बारकोड में शामिल होना चाहिए",
    "all_completed": "पूर्ण: घर के सभी सदस्यों ने यह चरण पूरा कर लिया है",
    "errors": {
      "no_appointment": "अपॉइंटमेंट ढूंढने में समस्या",
      "barcode_in_use": "यह बारकोड पहले भी इस्तेमाल किया जा चुका है। यदि आपने पहले इस किट के साथ स्व-प्रशासन नहीं किया था, तो कृपया उस साइट के कर्मचारियों से बात करें जिसने आपको किट दी थी और एक नई किट के लिए पूछें। अन्यथा, नीचे दिए गए सहायता बटन पर क्लिक करें।",
      "test_error_general": "परीक्षण बनाने में समस्या",
      "different_barcodes": "आपके द्वारा दर्ज किए गए बारकोड मेल नहीं खाते।"
    },
    "confirm_barcode_input": "पुष्टि करने के लिए फिर से बारकोड दर्ज करें",
    "click_to_self_test": "स्व-परीक्षण के लिए यहां क्लिक करें",
    "or_enter_barcode_manually": "Or enter manually with all letters and numbers"
  },
  "location_details": {
    "next_appointment": {
      "waitlist": "प्रतीक्षा सूची",
      "unavailable": "कोई नियुक्ति उपलब्ध नहीं है"
    },
    "labels": {
      "address": "पता",
      "next_appointment": "अगली नियुक्ति उपलब्ध",
      "services": "उपलब्ध सुविधाएँ"
    }
  },
  "waiting_room": {
    "header": "अभी आप परीक्षा कक्ष में ; कृपया प्रतीक्षा करें,हम आपको समय-सारणी सिस्टम से जोड़ रहे  हैं।",
    "p1": "कृपया धीरज रखें। हम सब मिल कर इस वैश्विक महामारी को ख़त्म करेंगे।",
    "signature": "भवदीय",
    "p2": "हम जानते हैं कि कोविद-१९ का अनुभव निराश-युक्त हो सकता है।कृपया आप प्रतीक्षा करें, जबकि हम आपकी नियुक्तिदर्ज करने के लिए आपको साइट पर लाने के लिए यथासंभव तेजी से काम करते हैं।"
  },
  "users": {
    "send_appointment_confirmation_message": "नमस्कार, {{full_name}} यह आपकी {{name}} नियुक्ति के बारे में याद दिलाने के लिए है।आपकी नियुक्ति {{date}} इस दिनांक, {{address}}  और इस समय {{time}} पर है।",
    "send_registration_link": "नमस्कार, {{full_name}}। पंजीकरण करने के लिए इस लिंक पर जाएँ {{name}} {{registration_link}}।"
  },
  "follow_up": {
    "first_dose": "पहली डोज़",
    "previous_appointment": "पिछली नियुक्ति",
    "booked_appointment": "आपकी नियुक्ति",
    "second_dose": "दूसरी डोज़",
    "choose_second_location": "दूसरी डोस के लिए जगह चुनें"
  },
  "cancellation_types": {
    "cant_get_to_location": "मुझे ",
    "timing_conflict": "मुझे कोरोना लग गया ",
    "became_ill_with_covid19": "मुझे यह सेवा कहीं और मिली ",
    "received_service_elsewhere": "अन्य",
    "other": "अन्यथा अपना नाम टाइप करें",
    "duplicate_appointment": "यह एक नकली समयादेश था"
  },
  "translation": {
    "consent": {
      "type_name_instead": "नाम टाइप करें",
      "type_name": "अपने हस्ताक्षर टाइप करें "
    }
  },
  "email": {
    "confirmation": {
      "your_access_code_is": "आपका एक्सेस कोड है",
      "please_confirm_your_email": "नीचे दिये गए बटन को क्लिक कर कृपया अपना ईमेल कन्फर्म करें",
      "hello": "हॅलो ",
      "click_to_reschedule": "निर्देश अथवा रिस्केड्यूल देखने के लिए क्लिक करें ",
      "click_to_reschedule_on_demand": "निर्देश देखने के लिए क्लिक करें"
    },
    "cancellation": {
      "your_appointment_has_been_canceled": "{{time}} पर {{location}} पर आपकी अपॉइन्ट्मन्ट रद्द कर दी गई है। यदि यह कोई गलती है, तो कृपया support@primary.health पर ईमेल करें"
    }
  },
  "signature_lines": {
    "name": "भाग लेने वाले का नाम",
    "date": "तिथि ",
    "signature": "भाग लेने वाले का हस्ताक्षर",
    "and_or": "और / या ",
    "guardian_name": "माता-पिता/अभिभावक का नाम",
    "guardian_signature": "माता-पिता/अभिभावक का हस्ताक्षर"
  },
  "employer_testing": {
    "hello_name": "हेलो {{name}}",
    "results": {
      "negative": "ना",
      "positive": "हाँ",
      "did_not_result": "अज्ञात",
      "unknown": "अज्ञात",
      "invalid": "अमान्य",
      "pending": "रुका हुआ है"
    },
    "code_reader": {
      "scan_your_code": "अपने टेस्ट कार्ड पे अंकित कोड को स्कैन करें",
      "find_in_top_right_hand_corner": "आपका स्कैन कोड आपके टेस्ट के लिए विशिष्ट है, उसे आप अपने टेस्ट के ऊपरी दाहिने कोने में ढूंढ सकते हैं।",
      "center_code": "कोड को यहाँ सेंटर में करें।",
      "already_used_error": "इस टेस्ट बारकोड का पहले से ही इस्तेमाल हो चुका है। कृपया सहायता के लिए अपने टेस्ट किट प्रदाता से सम्पर्क कीजिए।",
      "click_to_scan": "क्यू आर कोड स्कैन करने के लिए क्लिक कीजिए",
      "scan_new_test_card": "नए टेस्ट कार्ड को स्कैन करने के लिए यहाँ क्लिक कीजिए",
      "not_working": "काम नहीं कर रहा है?",
      "try_again": "दोबारा कोशिश करने के लिए यहाँ क्लिक करें "
    },
    "continue": "जारी रखिए",
    "verify_date_of_birth": {
      "confirm_date_of_birth": "कृपया जारी रखने के लिए भाग लेने वाले की जन्मतिथि कंफर्म कीजिए",
      "contact_administrator": "अगर दर्शायी गई सूचना गलत है तो कृपया प्रशासक से सहायता के लिए सम्पर्क करें",
      "error": "अंकित की गई जन्मतिथि गलत है- कृपया दोबारा प्रयास करें या अपने साइट प्रशासक से सम्पर्क कर इसे अपटेड करवाएँ।"
    },
    "result_entry": {
      "code_is_registered": "आपका कोड रजिस्टर हो चुका है",
      "take_the_test": "टेस्ट लीजिए",
      "follow_the_instructions": "अपने जाँच के साथ आने वाले निर्देशों का पालन करते हुए, अपने नाक से लिए गए स्वाब को टेस्टिंग कार्ड के वॉयल में रखने के बाद अपना 15 मिनट का टाइमर शुरू कर दें।",
      "cant_find_instructions": "निर्देश नहीं मिल रहा?",
      "view_instructions": "बाईनेक्स नाऊ निर्देश दिखाएँ। ",
      "start_timer": "15 मिनट का टाइमर प्रारंभ करें (ऐच्छिक)।",
      "submit_within_15": "सुझाव- 15 मिनट के अंदर अपने जाँच परिणाम को जमा करें।",
      "enter_test_results": "जाँच के परिणाम दर्ज करिए",
      "choose_a_result": "उस विकल्प का चुनाव करें  जो आपके टेस्टिंग कार्ड परिणाम का वर्णन करता है। ",
      "submit": "जमा करें",
      "must_log_result_and_photo": "अपने परिणाम को अनिवार्य रूप से दर्ज करें और जारी रखने के लिए अपने जांच की तस्वीर लें।",
      "submit_within_15_v2": "सुझाव: टेस्ट लेने के 15 मिनट पूरे हो जाने पर अपने परिणाम को जमा करें। ",
      "click_to_take_photo": "तस्वीर खींचने के लिए क्लिक करें",
      "results_may_be_invalid": "जाँच के परिणाम अमान्य हो सकते हैं"
    },
    "summary": {
      "title": "परिणाम सार",
      "negative_subtitle": "आपको कोविड-१९ नहीं है",
      "negative_message": "हमने आपके नियोक्ता को एक अधिसूचना भेजी है जिसमें आप वापस अपने ऑनसाइट कार्य-स्थल पर लौटने के लिए योग्य ठहराए गये हैं।",
      "positive_subtitle": "हम माफ़ी चाहते हैं लेकिन आपको कोविड-१९ है",
      "positive_message": "हमने आपके नियोक्ता को आपके कोविड-19 पॉजिटिव होने की एक अधिसूचना भेजी है।",
      "what_should_you_do": "आपको ये करना चाहिए",
      "employer_name_resources": "नियोक्ता नाम सांसाधन",
      "follow_cdc_guidelines": "सी डी सी द्वारा सुझाए गए दिशानिर्देशों का पालन करें।"
    },
    "clarifying_results": {
      "title": "आपके परिणाम का स्पष्टीकरण किया जा रहा है।",
      "which_did_your_result_most_look_like": "आपका परिणाम सबसे ज़्यादा किसके जैसा दिख रहा था?",
      "no_lines": "कोई रेखा नहीं देखी गई।",
      "blue_control_line_only": "केवल ब्लू कंट्रोल लाइन",
      "pink_sample_line_only": "केवल पिंक सैम्पल लाइन",
      "blue_control_line_and_pink_sample_line": "ब्लू कंट्रोल लाइन और पिंक/पर्पल सैम्पल लाइन",
      "still_not_sure": "अभी भी स्पष्ट नहीं।",
      "submit_results": "परिणाम जमा करें।",
      "thank_you": "धन्यवाद !",
      "clarification_received_message": "आपके परिणाम का स्पष्टीकरण प्राप्त कर लिया गया है।",
      "only_pink_line": "केवल गुलाबी रेखा",
      "no_lines_v2": "कोई रेखा नहीं"
    },
    "return_to_confirmation": "स्वीकृति पेज पर वापस लौटे।"
  },
  "preferred_language": {
    "title": "पसंदीदा भाषा",
    "subtitle": "आप कौन सी भाषा का चयन करना चाहते हैं?"
  },
  "appointment_type": {
    "initial_vaccine_appointment": "प्रारम्भिक टीका श्रंखला",
    "one_dose": "विशेष डोज के लिए एक अपॉइंटमेंट बुक करें। खासकर ये मेरा",
    "title": "डोज़  चुनना",
    "first_dose": "पहली डोज़",
    "second_dose": "दूसरी डोज़",
    "single_dose_title": "एक डोज़",
    "additional": "अतिरिक्त",
    "booster": "बूस्टर",
    "supplemental": "पूरक",
    "third_dose": "केवल तीसरी खुराक",
    "no_vaccine": "इनमें से कोई नहीं"
  },
  "activemodel": {
    "errors": {
      "models": {
        "importers/results/row": {
          "attributes": {
            "administered_at": {
              "invalid_datetime": "फार्मेट अनिवार्य  {{datetime_format}}"
            },
            "results_at": {
              "invalid_datetime": "फार्मेट अनिवार्य  {{datetime_format}}"
            },
            "uid": {
              "unknown_test": "टेस्ट नहीं मिला"
            }
          }
        },
        "importers/participants/row": {
          "attributes": {
            "access_code": {
              "not_allow_update_test_group_user": "पहले से ही यूजर मौजूद और अपडेट की अनुमति नहीं"
            },
            "date_of_birth": {
              "invalid_date": "फार्मेट अनिवार्य  {{datetime_format}}"
            },
            "ethnicity": {
              "unknown_ethnicities": {
                "one": "{{unknown_ethnicities}} मौजूद नहीं है; निम्नलिखित स्वीकार्य मूल्यों में से चुने: {{ethnicities}}",
                "other": "{{unknown_ethnicities}} मौजूद नहीं है, दिए गए स्वीकार्य मूल्यों में से चुनें: {{ethnicities}}"
              }
            },
            "gender": {
              "unknown_gender": "लिंग मौजूद नहीं है, दिए गए स्वीकार्य मूल्यों में से चुनें: {{genders}}"
            },
            "phone_number": {
              "invalid": "यह नम्बर अमान्य है"
            },
            "population_name": {
              "unknown_population": "{{unknown_population}} मौजूद नहीं है, दिए गए स्वीकार्य मूल्यों में से चुनें: {{population_names}}"
            },
            "self_described_gender": {
              "not_allowed": "भरा नहीं जा सकता अगर लिंग.."
            },
            "sex": {
              "unknown_sex": "मौजूद नहीं है, दिए गए स्वीकार्य मूल्यों में से चुनें: {{sexes}}"
            },
            "sexual_orientation": {
              "unknown_sexual_orientation": "यौन अभिविन्यास मौजूद नहीं है, दिए गए स्वीकार्य मूल्यों में से चुनें: {{sexual_orientations}}"
            },
            "status": {
              "unknown_status": "स्तिथि अमान्य है, स्वीकार मूल्यों में से चुनें: {{statuses}}"
            },
            "races": {
              "unknown_races": {
                "one": "{{unknown_races}} मौजूद नहीं हैं, स्वीकार्य मूल्यों में से चयन करें: {{races}}",
                "other": "{{unknown_races}} मौजूद नहीं हैं, स्वीकार्य मूल्यों में से चयन: {{races}}"
              }
            },
            "vaccination_status": {
              "unknown_vaccination_status": "vaccination status is invalid, pick from the following acceptable values: {{vaccination_statuses}}"
            }
          }
        },
        "importers/organization_user/row": {
          "attributes": {
            "role": {
              "insufficient_privileges": "भूमिका निर्धारित करने के लिए अपर्याप्त विशेषाधिकार"
            },
            "user_id": {
              "unknown_user": "उपयोगकर्ता नहीं मिला"
            }
          }
        },
        "importers/additional_information/row": {
          "attributes": {
            "additional_information": {
              "invalid_json": "अमान्य JSON"
            },
            "user_id": {
              "unknown_user": "उपयोगकर्ता नहीं मिला"
            }
          }
        },
        "importers/emr/row": {
          "attributes": {
            "appointment_location": {
              "unknown_location": "नियुक्ति स्थान नहीं मिला"
            },
            "date_of_birth": {
              "invalid_date": "फ़ॉर्मैट होना चाहिए {{date_format}}"
            },
            "email": {
              "duplicated": "पहली ही उपायोग किया जा चुका है"
            },
            "ethnicity": {
              "unknown_ethnicities": {
                "one": "{{unknown_ethnicities}} मौजूद नहीं है, स्वीकार्य मूल्यों में से चयन करें: {{ethnicities}}",
                "other": "{{unknown_ethnicities}} मौजूद नहीं हैं, स्वीकार्य मूल्यों में से चयन करें: {{ethnicities}}"
              }
            },
            "gender": {
              "unknown_gender": "लिंग मौजूद नहीं है, स्वीकार्य मूल्यों में से चयन: {{genders}}"
            },
            "phone_number": {
              "invalid": "यह नम्बर अमान्य है"
            },
            "population": {
              "unknown_population": "{{unknown_population}} मौजूद नहीं है, स्वीकार्य मूल्यों में से चयन करें:  {{population_names}}"
            },
            "self_described_gender": {
              "not_allowed": "भरा नहीं जा सकता अगर लिंग.."
            },
            "sex": {
              "unknown_sex": "मौजूद नहीं है, स्वीकार्य मूल्यों में से चयन करें: {{sexes}}"
            },
            "sexual_orientation": {
              "unknown_sexual_orientation": "यौन अभिविन्यास मौजूद नहीं है, दिए गए स्वीकार्य मूल्यों में से चुनें : {{sexual_orientations}}"
            },
            "races": {
              "unknown_races": {
                "one": "{{unknown_races}} मौजूद नहीं है, स्वीकार्य मूल्यों में से चयन करें: {{races}}",
                "other": "{{unknown_races}}  मौजूद नहीं है, स्वीकार्य मूल्यों में से चयन करें: {{races}}"
              }
            }
          }
        },
        "importers/additional_consents/row": {
          "attributes": {
            "consent_form_id": {
              "unknown_consent_form": "consent_form not found"
            },
            "access_code": {
              "unknown_user": "user not found"
            }
          }
        },
        "importers/vaccine/row": {
          "attributes": {
            "date_of_birth": {
              "invalid_date": "should have format {{date_format}}"
            },
            "phone_number": {
              "invalid": "is an invalid number"
            },
            "email": {
              "duplicated": "is already used"
            },
            "races": {
              "unknown_races": {
                "one": "{{unknown_races}} does not exist, pick from the following acceptable values: {{races}}",
                "other": "{{unknown_races}} do not exist, pick from the following acceptable values: {{races}}"
              }
            },
            "gender": {
              "unknown_gender": "gender does not exist, pick from the following acceptable values: {{genders}}"
            },
            "sexual_orientation": {
              "unknown_sexual_orientation": "sexual_orientation does not exist, pick from the following acceptable values: {{sexual_orientations}}"
            },
            "self_described_gender": {
              "not_allowed": "cannot fill it if the gender is not \"prefer_to_self_describe\""
            },
            "sex": {
              "unknown_sex": "sex does not exist, pick from the following acceptable values: {{sexes}}"
            },
            "ethnicity": {
              "unknown_ethnicities": {
                "one": "{{unknown_ethnicities}} does not exist, pick from the following acceptable values: {{ethnicities}}",
                "other": "{{unknown_ethnicities}} do not exist, pick from the following acceptable values: {{ethnicities}}"
              }
            },
            "tag_action": {
              "unknown": "{{unknown_tag_action}} is an invalid value, pick from the following acceptable values: {{tag_actions}}"
            },
            "population": {
              "unknown_population": "{{unknown_population}} does not exist, pick from the following acceptable values: {{population_names}}"
            },
            "appointment_location": {
              "unknown_location": "appointment location not found"
            },
            "appointment_date": {
              "invalid_date": "should have format {{date_format}}"
            },
            "dose_type": {
              "invalid_dose": "{{unknown_dose}} is not a valid dose, pick from the following acceptable values: {{acceptable_doses}}",
              "unavailable_dose": "{{dose_type}} is not available within the vaccine_lot, pick from the following available values: {{available_doses}}"
            },
            "vis_version_date": {
              "invalid_date": "should have format {{date_format}}"
            }
          }
        },
        "importers/base/row": {
          "attributes": {
            "appointment_time": {
              "invalid_time": "should have format {{time_format}}"
            }
          }
        }
      }
    }
  },
  "activerecord": {
    "errors": {
      "models": {
        "test_location": {
          "attributes": {
            "metadata": {
              "wrong_json": "is not a valid JSON"
            },
            "name": {
              "taken": "और पता पहले से ही उपस्थित स्थान पर उपयोग किया जा रहा "
            }
          }
        }
      }
    },
    "attributes": {
      "organization_user": {
        "organization_user_leads": {
          "one": "User",
          "other": "Users"
        }
      },
      "appointment_slot_group": {
        "organization_user_leads": {
          "one": "Location",
          "other": "Locations"
        }
      }
    }
  },
  "hispanic_questions": {
    "hispanic": "स्पेनी/लातिनो",
    "not_hispanic": "ग़ैर स्पेनी/लातिनो",
    "hispanic_expanded": {
      "other": "एक और हिस्पैनिक, लातीनी/ए या स्पेनिश मूल",
      "mexican": "मैक्सिकन, मैक्सिकन अमेरिकन, चिकानो/ए",
      "puerto_rican": "पुएर्तो रीको का",
      "cuban": "क्यूबन"
    },
    "subtitle": "नियमों के लिए आवश्यक है कि हम निम्नलिखित जानकारी एकत्र करें",
    "help": "जातीयता लोगों के समूहों को अलग करने के लिए उपयोग की जाने वाली भाषा, सांस्कृतिक रीति-रिवाजों, धर्म और अन्य विशेषताओं जैसे सामान्य लक्षणों के एक समूह को संदर्भित करती है। यह नस्लीय पहचान के साथ भी संरेखित हो भी सकता है और नहीं भी।",
    "unknown": "अज्ञात",
    "title": "मानवजाति"
  },
  "sex_at_birth": {
    "question": "लिंग ",
    "female": "स्त्री ",
    "male": "पुरुष ",
    "help": "सेक्स से तात्पर्य आपके जन्म प्रमाण पत्र पर क्या है।",
    "non_binary": "नॉन बाइनरी",
    "subtitle": "विनियमों के लिए आवश्यक है कि हम निम्नलिखित सभी जानकारी एकत्र करें।",
    "decline": "उत्तर देने से इंकार",
    "unknown": "अनजान"
  },
  "gender": {
    "help": "जेंडर आइडेंटिटी से तात्पर्य है कि आप व्यक्तिगत रूप से कैसे पहचान करते हैं। यह उस लिंग के साथ संरेखित हो भी सकता है और नहीं भी जिसे आपको जन्म के समय सौंपा गया था।",
    "subtitle": "यदि आप अपनेलिंग पहचान के बारे में जानकारी देना चाहते हैं तो कृपया दे दीजिए।"
  },
  "sexual_orientation": {
    "title": "यौन अभिविन्यास",
    "subtitle": "यदि आप अपनी यौन अभिविन्यास के बारे में जानकारी देना चाहते हैं तो ,कृपया दे दीजिए ।",
    "gay": "समलैंगिक पुरुष, समलैंगिक स्त्री या समलैंगिक कामी",
    "heterosexual": "इतरलिंगी ",
    "bisexual": "उभयव्यक्ति",
    "questioning": "प्रश्न करना/अनिश्चित/पता नहीं",
    "prefer_not_to_disclose": "खुलासा नहीं करना चुनें",
    "unknown": "अनजान",
    "orientation_not_listed": "अभिविन्यास सूचीबद्ध नहीं है",
    "not_applicable": "लागू नहीं",
    "pansexual": "पैनसेक्सुअल",
    "queer": "क्वीर"
  },
  "pronouns": {
    "title": "पसंदीदा सर्वनाम",
    "he": "वह उसे",
    "she": "वह / उसके",
    "they": "वे उन्हें",
    "choose_not_to_disclose": "खुलासा नहीं करना चुनें",
    "prefer_to_self_describe": "आत्म-वर्णन करना पसंद करते हैं"
  },
  "no_insurance_information": {
    "driver_license_or_state_id": "चालक लाइसेंस या राज्य आईडी नंबर",
    "driver_license": "चालक लाइसेंस",
    "use_ssn_instead": "इसके बजाय एसएसएन का प्रयोग करें",
    "social_security_number": "सामाजिक सुरक्षा संख्या",
    "state": "राज्य",
    "upload_front_of_driver_license": "अपने ड्राइवर लाइसेंस या स्टेट आईडी नम्बर के सामने वाले हिस्से को अपलोड करें",
    "choose_file": "फाइल चुनें",
    "no_file_chosen": "कोई फाइल नहीं चुनी गई",
    "no_identification": "मेरे पास कोई पहचान नहीं है",
    "insurance_card_has_a_back": "मेरे इन्सुरेंस कार्ड में पिछला हिस्सा भी है",
    "upload_front_of_insurance_card": "अपने इन्सुरेंस कार्ड के सामने वाले हिस्से को अपलोड करें",
    "front_of_insurance_card_uploaded": "आपके इन्सुरेंस कार्ड का सामने वाला हिस्सा अपलोड हो चुका है",
    "insurance_card": "इन्सुरेंस कार्ड",
    "insurance_card_back": "इन्सुरेंस कार्ड का पिछला हिस्सा",
    "back_of_insurance_card_uploaded": "आपके इन्सुरेंस कार्ड का पिछला हिस्सा अपलोड हो चुका है"
  },
  "quidel": {
    "certify_text": "मैं प्रमाणित करता/करती हूं कि मैं इस परीक्षा के परिणाम केवल एक बार दर्ज कर रहा हूं।",
    "entering_results": "अपने परिणाम दर्ज करना",
    "review_instructions_1": "निर्देशों की सावधानीपूर्वक समीक्षा करें",
    "review_instructions_2": "आप अपने परीक्षण के साथ आए निर्देशों की समीक्षा कर सकते हैं, चरण-दर-चरण मार्गदर्शिका पढ़ सकते हैं या नीचे एक निर्देशात्मक वीडियो देख सकते हैं:",
    "read_instructions": "अद्धयन को पढ़िए",
    "watch_video": "विडीओ देखिए",
    "timer": "ट्यूब में स्वाब को हिलाने के बाद टाइमर शुरू करें। 1 मिनट के बाद टाइमर बंद हो जाएगा (आपके लिए ट्यूब से स्वैब निकालने के लिए)। कृपया ध्यान दें: अपने परीक्षण के परिणाम के लिए 10 मिनट शुरू करने के लिए आपको टाइमर को फिर से शुरू करना होगा।",
    "start_timer": "टाइमर शुरू करें (वैकल्पिक)",
    "restart_timer": "रुकें और फिर से शुरू करें",
    "resume": "फिर शुरू करना",
    "one_minute_countdown": "1 मिनट बीत चुका है! ट्यूब से अपना स्वाब निकालें और टाइमर फिर से शुरू करें।",
    "take_photo": "अपनी  टेस्ट स्ट्रिप की तस्वीर लें",
    "photo_warning": "आगे बढ़ने के लिए तस्वीर देनी आवश्यक है|",
    "enter_results": "परिणाम दर्ज करें",
    "choose_result": "वह विकल्प चुनें जो आपकी परीक्षण पट्टी को सर्वोत्तम रूप से दर्शाता हो। मदद की ज़रूरत है? उदाहरण परिणाम देखने के लिए यहां क्लिक करें।",
    "positive": "सकारात्मक",
    "negative": "नकारात्मक",
    "unknown": "अनजान",
    "clarify": "अपने परिणाम स्पष्ट करना",
    "strip_question": "आपकी टेस्ट स्ट्रिप किसकी तरह दिखती थी?",
    "no_lines": "कोई लाइन नहीं थी ",
    "pink_line": "केवल गुलाबी रंग की लाइन ",
    "not_sure": "अभी तक पक्का पता नहीं "
  },
  "ihealth": {
    "take_a_photo_of_your_test": "परिणाम की तस्वीर",
    "retake_photo": "तस्वीर दोबारा लें",
    "capture_photo": "तस्वीर खींचे",
    "confirm_information_correct": "मैं उपर्युक्त सूचना के सही होने की पुष्टि करता हूँ",
    "submit": "जमा करें",
    "title": "परिणाम दर्ज करें {{name}}",
    "subtitle": "आपको आइ हेल्थ कोविड-१९ ऐप को डाउनलोड या उपयोग  करने की आवश्यकता नहीं है।",
    "instruction_title": "स्वास्थ्य निर्देश देखें",
    "instruction_guide": " निर्देश पढ़ें",
    "video_guide": " वीडियो देखें",
    "add_photo": "तस्वीर डालें",
    "confirm_result": "परिणाम की पुष्टि करें",
    "result_warning": "दर्ज करने के लिए चयन करें",
    "confirm_warning": "जमा करने के लिए परिणाम की पुष्टि करें",
    "administered_at_label": "आपने यह टेस्ट कब लिया था?",
    "instruction_subtitle": "अपने परीक्षण के साथ आए निर्देश देखें या यहां दिए गए निर्देशों की समीक्षा करें।"
  },
  "public_test_groups": {
    "title": "कोविड-१९ टेस्ट किट",
    "appointment_recoveries_title": "वापस जा रहें हैं?",
    "appointment_recoveries_button_text": "मेरा लिंक खोजें",
    "search_test_group_title": "पहली बार?",
    "search_test_group_button_text": "पंजीकरण करें",
    "title_2": "सरल पंजीकरण तथा रिपोर्टिंग",
    "step_one": "खाता पंजीकरण",
    "step_two": "वापस भेजने के लिए लार का नमूना लीजिए",
    "step_three": "जाँच का परिणाम ऑनलाइन देखें",
    "title_3": "अपनी किट को चालू करने के लिए पंजीकरण करें",
    "population_title": "पंजीकरण",
    "population_button": "सक्रिय",
    "faq_subtitle": "अधिक जानकारी",
    "faq_title": "अक्सर पूछे जाने वाले प्रश्न",
    "faq_1": "अपनी किट वापिस कैसे करु?",
    "faq_1_footer": "सलाइवा डाइरेक्ट फ़नल संग्रह निर्देश देखें",
    "faq_2": "मैं पहले से पंजीकृत हूँ लेकिन मैं अपना खाता भूल गया हूँ।",
    "faq_3": "मैं अपनी किट को सक्रिय कैसे कारु?",
    "faq_2_subtitle": "एक लौटने वाले उपयोगकर्ता के रूप में, आप ऊपर दिए गए '**मेरा लिंक ढूंढें**' बटन का उपयोग करके अपनी रिपोर्ट की पुष्टि कर सकते हैं।",
    "faq_3_subtitle": "कृपया नीचे दिए गए विकल्पों का उपयोग करके प्प्राइमरी हेल्थ खाता प्रक्रिया देखें:",
    "footer_text_1": "इस उत्पाद को FDA द्वारा स्वीकृत या स्वीकृत नहीं किया गया है, लेकिन EUA के तहत FDA द्वारा आपातकालीन उपयोग के लिए अधिकृत किया गया है;",
    "footer_text_2": "इस उत्पाद को केवल SARS-CoV-2 से न्यूक्लिक एसिड का पता लगाने में सहायता के रूप में लार के नमूनों के संग्रह और रखरखाव के लिए अधिकृत किया गया है, किसी अन्य वायरस या रोगजनकों के लिए नहीं;",
    "footer_text_3": "इस उत्पाद का आपातकालीन उपयोग केवल घोषणा की अवधि के लिए अधिकृत है कि संघीय खाद्य, औषधि और कॉस्मेटिक अधिनियम, 21 यू.एस.सी की धारा 564 (बी) (1) के तहत चिकित्सा उपकरणों के आपातकालीन उपयोग के प्राधिकरण को उचित ठहराने वाली परिस्थितियां मौजूद हैं। 360bbb-3(b)(1), जब तक कि घोषणा को समाप्त नहीं किया जाता है या प्राधिकरण को जल्द ही निरस्त नहीं किया जाता है।",
    "description": "आपकी सुविधा के लिए, आप नीचे दिए गए बटनों का उपयोग करके अपने परीक्षण किट को सक्रिय कर सकते हैं:",
    "subtitle": "प्राइमरी पर पहली बार?",
    "subtitle_1": "आपकी कोविड-१९ की टेस्ट किट के बारे में",
    "faq_1_1": "बंद सैंपल ट्यूब को बायोस्पेसिमेन बैग में रखें",
    "faq_1_2": "बायोस्पेसिमेन बैग को उस सलाइवाडायरेक्ट-लेबल वाले बॉक्स में रखें, जिसमें वह आया था। सालिवाडायरेक्ट-लेबल वाले बॉक्स को  इसकेअंदर रखें FedEx UN 3373 Pak.",
    "faq_1_3": "FedEx UN 3373 Pak के चिपकने वाले लाइनर को हटा दें और पाक को कसकर सील करने के लिए मजबूती से दबाएं।",
    "faq_1_4": "कृपया अपना नमूना उसी दिन छोड़ दें जिस दिन आप इसे एकत्र करते हैं और अंतिम पिकअप समय से पहले। सप्ताहांत पर अपना नमूना ड्रॉप बॉक्स में न दें।",
    "faq_1_5": "ड्रॉप बॉक्स स्थान देखने और शेड्यूल लेने के लिए FedEx की वेबसाइट **[fedex.com/labreturns](https://www.fedex.com/labreturns)** पर जाएं।",
    "demo": {
      "title": "COVID-19 at-home test kit additional information & instructions",
      "online_instructions": "Online instructions",
      "download_instructions": "Download instruction sheet",
      "begin": "Begin",
      "instructions": {
        "welcome_title": "Welcome to the at-home COVID-19 testing portal.",
        "welcome_text_1": "Before you start testing, wash you hands or use hand sanitizer.",
        "welcome_text_2": "Make sure your hands are dry before starting.",
        "continue": "Continue",
        "back": "Back",
        "step_one_title": "Step 1: Check your test kit",
        "step_one_text_1": "Locate the kit components:",
        "step_one_text_2": "It is recommended gloves (not provided) also be used during testing.",
        "step_two_title": "Step 2: Place tube in the tube holder",
        "step_two_text_1": "Remove cap from one **TUBE** and place it in the **TUBE HOLDER.**",
        "step_two_text_2": "**NOTE:** Use of gloves is recommended.",
        "step_five_title": "Step 5: Remove swab from the tube",
        "step_five_text_1": "After **ONE MINUTE**, remove the swab from the **TUBE** by rubbing the swab head against the inside wall of the tube to squeeze out as much liquid as possible.",
        "step_five_text_2": "Dispose of swab in the trash.",
        "step_six_title": "Step 6: Open the test strip",
        "step_six_text_1": "Open the **TEST STRIP** pouch carefully at the slit and hold the **TEST STRIP** as indicated.",
        "step_seven_title": "Step 7: Place test strip in the tube",
        "step_seven_text_1": "Place the **TEST STRIP** into the **TUBE** with the arrows pointing down. Leave the strip in the **TUBE** for a **FULL TEN MINUTES**– do not handle or remove.",
        "step_eight_title": "Step 8: Remove test strip from the tube",
        "step_eight_text_1": "At **TEN MINUTES**, remove the **TEST STRIP** from the **TUBE**. Next, place the **TEST STRIP** on the outline in Steps 10-12. Ensure the **TEST STRIP** is on a flat surface in good lighting.",
        "step_eight_text_2": "**Note:** The test is intended to be read at 10 minutes. If the test is read before this or is read more than 5 minutes after the indicated read time, results may be inaccurate (false negative, false positive, or invalid) and the test should be repeated.",
        "step_nine_title": "Step 9: Check your results",
        "step_nine_text_1": "There are three type of results possible.",
        "step_nine_text_2": "Check for a Positive Result",
        "step_nine_text_3": "Check for a Negative Result",
        "step_nine_text_4": "Check for an Invalid Result",
        "step_ten_title": "Step 10: Remove test strip from the tube",
        "step_ten_text_1": "Place the **TEST STRIP** on the test strip outline below and compare with test result examples shown.",
        "step_three_title": "Step 3: Swab the nostrils",
        "step_three_text_1": "Remove the **SWAB** from its wrapper, being careful not to touch the **SWAB** head.",
        "step_three_text_2": "Gently insert the **SWAB** ½ to ¾ of an inch into the nostril, depending on the size of the person’s nose. Firmly rub the **SWAB** in a circular motion around the inside wall of EACH NOSTRIL at least 4 times.",
        "step_three_text_3": "Be sure to rub BOTH nostrils with the SAME SWAB.",
        "step_three_text_5": "**NOTE:** Failure to swab properly may cause false negative results.",
        "step_four_title": "Step 4: Place swab in the tube",
        "step_four_text_1": "Immediately place the **SWAB** into the liquid inside the **TUBE**, and ensure it is touching the bottom. Stir 3-4 times.",
        "step_four_text_2": "Leave the swab in the solution for **ONE MINUTE**.",
        "step_four_text_3": "**NOTE**: If the swab is in the solution for more than 10-minutes it should not be used.",
        "step_three_text_4": "**NOTE:** If you are swabbing others, please wear a face mask. With children, you may not need to insert the swab as far into the nostril. For very young children, you may need another person to steady the child’s head while swabbing."
      },
      "choose_language": "Choose your language"
    }
  },
  "self_resulting": {
    "report_result": "एक परिणाम सूचित करें",
    "report_result_subtitle": "अगर आपकी संस्था स्वयं-जाँच/ सेल्फ टेस्टिंग करवाती है तथा आप एक परिणाम सूचित करने के लिए तैयार हैं तो, कृपया  नीचे दिये गए बटन को दबाएँ।",
    "need_help": "सहायता चाहिए?",
    "assistance": "अगर आपको सहायता चाहिए, या आपको अपनी संपर्क सूचना में किसी प्रकार का बदलाव करना है, तो कृपया सहायता केंद्र से संपर्क करें।",
    "contact_support": "सहायता से संपर्क करें",
    "save_link": "परिणामों को बाद में सूचित करने के लिए ये लिंक सेव कर लें",
    "send_via_email": "ईमेल द्वारा लिंक भेजें",
    "send_via_text": "एसएमएस संदेश द्वारा लिंक भेजें",
    "copy_link": "लिंक कॉपी करें",
    "resend_email": "ईमेल सत्यापन दोबारा भेजें",
    "phone_verified": "फोन सत्यापित कर लिया गया",
    "add_vaccination_status": "अपने कोविड-19 टीकाकरण की जानकारी को शामिल करें/ अपडेट करें।",
    "terms_of_service": "सेवा शर्तें",
    "verify_contact_information": "परिणाम प्राप्त करने के लिए व संपर्क में बने रहने के लिए अपनी संपर्क सूचना को सत्यापित करें। अगर आपको अपनी संपर्क सूचना में कोई बदलाव करना है तो, कृपया सहायता केंद्र से संपर्क करें।",
    "resend_text": "एसएमएस सन्देश सत्यापन दोबारा भेजें",
    "loading": "लोड हो रहा है",
    "add_to_account": "अकाउंट से जोड़ें",
    "hi": "हैलो",
    "email_verified": "ईमेल सत्यापित कर लिया गया",
    "go_to_my_account": "मेरे अकांउट में जाएँ",
    "activate_kit": "जाँच किट को चालू कीजिए",
    "report_custom_result": "परिणाम को रिपोर्ट कीजिए: {{test}}",
    "activate_custom_test": "जाँच चालू कीजिए {{test}}",
    "update_vaccination_status": "टीकाकरण की स्थिति साझा करें",
    "order_a_test_kit": "टेस्ट किट ऑर्डर करें"
  },
  "enter_information": {
    "contact_info": "अपनी सम्पर्क सूचना दर्ज कीजिए",
    "label": "फोन या ईमेल",
    "invalid": "यह ईमेल या फोन नम्बर अमान्य है।",
    "send": "हम आपके फोन या ईमेल पर एक कोड भेजेंगे",
    "legal": "अपना फ़ोन नम्बर और ईमेल अंकित करते ही आप प्राइमरी हेल्थ के **[ सेवा शर्तों से सहमति जताते हैं ](https://primary.health/terms-of-service/)** और **[गोपनीयता नीति](https://primary.health/privacy-policy/)**.",
    "subtitle": "आपको अपने रिकॉर्ड या टेस्ट परिणाम की रिपोर्ट का लिंक ढूंढने में मदद के लिए SMS या ईमेल भेजा जाएगा!",
    "find_link": "यदि आप पंजीकृत हैं, तो कृपया अपना लिंक ढूंढें।",
    "not_registered": "रजिस्टर नहीं हुए हैं? अपनी साइट खोजें",
    "search_by_keyword": "नाम, कीवर्ड, शहर, ज़िप या संगठन कोड के आधार पर खोजें!",
    "search": "खोजें",
    "register_here": "यहाँ पंजीकरण करें"
  },
  "contact_support": {
    "invalid": "अमान्य सूचना",
    "invalid_explanation": "आपने हाल में ही प्राइमरी डॉट हेल्थ के साइन इन लिंक के लिए निवेदन किया। बहरहाल,",
    "never_registered": "पहले कभी रजिस्टर नहीं किया? रजिस्ट्रेशन लिंक के लिए कृपया अपने टेस्टिंग प्रदाता से संपर्क करें, या नीचे अपनी साइट खोजें।",
    "get_help": "आप अपने रिकार्ड को अपनी सम्पर्क सूचना से मिलाने के लिए यहाँ से मदद ले सकते हैं",
    "get_help_calling": "या कॉल करके",
    "invalid_explanation_bold": "आपने द्वारा दी गई सूचना अमान्य थी",
    "get_help_label": "सहायता प्राप्त कीजिए",
    "mobile": {
      "invalid_explanation": "ऐसा कोई उपयोगकर्ता नहीं मिला जिसे आपके खाते में जोड़ा जा सके।",
      "get_help": "यदि आपके कोई अतिरिक्त प्रश्न हैं, तो कृपया संपर्क करें।",
      "reason": "सभी प्राथमिक स्वास्थ्य उपयोगकर्ता हमारी नई खाता सुविधा के लिए योग्य नहीं हैं। एक बार जब आप योग्य हो जाते हैं, तो आपको प्राथमिक स्वास्थ्य विभाग या आपके समूह व्यवस्थापक से आमंत्रण प्राप्त होगा।"
    },
    "zen_name": "सम्पर्क नाम",
    "ticket_type": "ये किस बारे में है?",
    "appointment_access_code": "पुष्टि कोड( अगर ज्ञात हो)",
    "zen_desc": "संदेश",
    "contact_us": "हमसे संपर्क करें",
    "need_assistance": "यदि आपको सहायता की आवश्यकता है, या अपनी संपर्क जानकारी बदलना है, तो कृपया सहायता से संपर्क करें।"
  },
  "show_appointments": {
    "welcome": "अपनी प्रोफ़ाइल ढूंढें",
    "welcome_subtitle_1": "नीचे परिणाम दिखाए जा रहे हैं",
    "added_to_account": "खाते में जोड़ा गया",
    "error": "इस प्रतिभागी का पहले से ही एक खाता हो सकता है",
    "welcome_subtitle_2": "नीचे नाम नहीं मिला?",
    "welcome_not_logged_in": "साइट लिंक ढूंढा जा रहा है",
    "welcome_logged_in": "अपना समूह चुनें",
    "welcome_subtitle_1_logged_in": "सदस्य जोड़ने की प्रक्रिया पूरी करने के लिए, उपयोगकर्ता और समूह चुनें। सदस्यों को एक खाता सौंपा जाएगा।",
    "welcome_subtitle_2_logged_in": "यदि आपको ऊपर आपका सदस्य सूचीबद्ध नहीं दिख रहा है, तो आप",
    "welcome_subtitle_1_not_logged_in": "चुनें कि किसे अपनी साइट ढूंढनी है या नया सदस्य जोड़ना है। सदस्य नहीं मिला?",
    "welcome_subtitle_3_logged_in": "कोई भिन्न संपर्क विधि  का प्रयास करें",
    "welcome_subtitle_4_logged_in": "या हमसे संपर्क करें",
    "welcome_subtitle_2_not_logged_in": "खोज को अपडेट करें",
    "welcome_subtitle_3": "अपनी खोज अपडेट करें"
  },
  "enter_code": {
    "code": "कोड क्या है?",
    "enter_code": "भेजे गए कोड को अंकित करें",
    "incorrect_code": "आपने एक गलत कोड अंकित किया है",
    "verify": "अकाउंट सत्यापित करें",
    "verified": "सत्यापित",
    "verify_account_information": "अपने खाते की पुष्टि करें"
  },
  "general_self_checkout": {
    "choose_test": "आपने कौन सा जाँच करवाया?",
    "photo_required": "परिणाम की तस्वीर आवश्यक है",
    "find_your_test": "अपनी जाँच ढूंढें"
  },
  "login": {
    "create_account": "एक अकांउट बनाएँ",
    "create_account_help": "खाता बनाना टेस्टिंग या टीकाकरण के लिए रजिस्टर करने के समान नहीं है। एक बार जब आप रजिस्टर हो जाते हैं",
    "create_account_help_schedule": "समयादेश ले सकते हैं",
    "create_account_help_record": "घरेलू-जाँच को रिकार्ड कर सकते हैं",
    "create_account_help_match": "टीका व जाँच रिकार्ड को संभाल सकते हैं",
    "continue": "एक गेस्ट के तौर पर जारी रख सकते हैं",
    "already": "पहले से ही अकाउंट है? क्लिक कीजिए",
    "login": "लॉग इन के लिए",
    "or_sign_in": "या इससे साइन इन कीजिए:",
    "no_account": "अकांउट नहीं है? क्लिक कीजिए",
    "signup": "साइन अप करने के लिए",
    "here": "यहाँ",
    "email_address": "ईमेल अड्रेस:",
    "password": "पासवर्ड:",
    "complete_registration": "रजिस्ट्रेशन पूरा कीजिए",
    "last_covid_test": "पिछला कोविड-19 जाँच परिणाम",
    "no_record": "कोई रिकार्ड नहीं है",
    "viewing_information": "दिखा रहा हैं {{first_name}} की सूचना",
    "download": "डाउनलोड",
    "not_registered": "ऐसा प्रतीत होता है कि आप किसी भी ग्रुप से रजिस्टर्ड नहीं हैं",
    "please_follow_link": "प्राइमरी डॉट हेल्थ से अपनी शिड्यूल व जानकारी प्राप्त करने के लिए अपने ईमेल में आए निवेदन लिंक को फॉलो करें। ",
    "log_results": "मेरे जाँच परिणामों को लॉग करें",
    "book_appointment": "समयादेश बुक करें",
    "no_new_appointments": "फिलहाल नया समयादेश नहीं स्वीकार किया जा रहा है",
    "upload_vaccinations": "टीका अपलोड करें",
    "vaccination": "टीका",
    "new_appointment": "नया समयादेश",
    "log_otc_results": "ओटीसी जांच का परिणाम लॉग करें",
    "no_household_members": "आपके पास अब तक कोई भी घरेलू सदस्य नहीं है।",
    "no_upcoming": "कोई भी आनेवाला समयादेश नहीं है",
    "update_vaccination": "टीका जानकारी अपडेट करें",
    "upload_vaccination": "टीका जानकारी अपलोड करें",
    "title": "प्राइमरी डॉट हेल्थ पर आपका स्वागत है",
    "sub_title": "Before registering please create an account.",
    "p1": "एक एकाउंट बनाकर आप:",
    "p2": "भविष्य के समयादेश का कार्यक्रम बना सकते हैं",
    "p3": "कोविड-19 के घरेलू-जाँच को रिकार्ड कर सकते हैं",
    "p4": "आश्रितों के टीके व जांच रिकार्ड को संभाल सकते हैं",
    "p5": "माई डॉट प्राइमरी डॉट हेल्थ से सरल लॉग इन प्रक्रिया",
    "no_vaccination_record": "कोई भी टीका अभी तक जोड़ा नहीं गया",
    "vaccination_title_card": "कोविड-19 टीके का स्टेटस",
    "account_and_settings": "अकाउंट और सेटिंग्स",
    "delete_account": "अकाउंट डीलीट करें",
    "language": "भाषा",
    "language_current": "मौजूदा भाषा: {{language}}",
    "log_out": "लॉग आउट करें",
    "delete_account_title": "क्या आप निश्चित रूप से अपना अकाउंट डिलीट करना चाहते हैं?",
    "new_encounter": "{{first_name}} का नया इनकाउंटर",
    "new_encounter_subtitle": "यह नया इनकाउंटर किस समूह के लिए है?",
    "no_programs": "ऐसा लगता है आप किसी भी कार्यक्रम के लिए नामांकित नहीं हैं",
    "edit_members": "सदस्यों को संपादित करें",
    "edit_members_subtitle": "आप जिस डैशबोर्ड पर जाना चाहते हैं उसका चयन कीजिए या अन्य सदस्य को अपने अकाउंट के साथ जोड़िए",
    "add_members": "सदस्य को जोड़े",
    "delete_member": "{{full_name}} का यूजर अकाउंट डिलीट करना है?",
    "delete_member_subtitle": "यूजर को हटाते ही उसके अकाउंट से जुड़ी सारी सूचनाएँ डिलीट हो जाएंगी।  ",
    "select_member": "सदस्य का चयन",
    "edit_appointment": "नियुक्ति एडिट करें",
    "route": {
      "me": "डैशबोर्ड",
      "history": "इतिहास",
      "programs": "जाँच को जोड़ें",
      "contact_us": "सहायता",
      "choose_member": "सदस्य",
      "settings": "सेटिंग्स",
      "help": "सहायता"
    },
    "user_dashboard": "{{first_name}} का डैशबोर्ड",
    "view_full_results": "परिणाम पोर्टल में देखें",
    "required": "यदि आप प्रायमरी ऐप का उपयोग कर रहे हैं तो आवश्यक है।",
    "no_email": "मेरा कोई ईमेल पता नहीं है"
  },
  "vaccination_status": {
    "not_vaccinated": "टीका नहीं लिया है",
    "fully_vaccinated": "पूरी तरह से टीका लिया है",
    "partially_vaccinated": "आंशिक रूप से टीका लिया है",
    "vaccination_records": "टीकाकरण रिकॉर्ड",
    "title": "आपके COVID-19 टीकाकरण की स्थिति क्या है?",
    "definition_title": "टीकाकरण की स्थिति की परिभाषा",
    "definition_cdc": "CDC टीकाकरण स्थिति की परिभाषा",
    "definition_osha": "OSHA टीकाकरण स्थिति की परिभाषा",
    "definition": {
      "fully_vaccinated": "या तो फाइजर-बायोएनटेक या मॉडर्न की 2 खुराकों वाली श्रृंखला में अंतिम खुराक, या जॉनसन एंड जॉनसन के जेनसेन वैक्सीन की 1 खुराक के 2 सप्ताह बाद।",
      "partially_vaccinated": "प्राथमिक श्रृंखला में 2 में से केवल 1 खुराक प्राप्त की है, या 2 खुराकों वाली श्रृंखला में अंतिम खुराक प्राप्त करने के बाद या जॉनसन एंड जॉनसन के जेनसेन की 1 खुराक प्राप्त करने के बाद 2 सप्ताह से कम।",
      "not_vaccinated": "कोई COVID-19 वैक्सीन नहीं दी गई है।",
      "fully_vaccinated_with_one_booster": "फाइजर-बायोएनटेक या मॉडर्न वैक्सीन की पूरी श्रृंखला या जॉनसन एंड जॉनसन के जेनसेन वैक्सीन की 1 खुराक के बाद बूस्टर खुराक प्राप्त की।",
      "fully_vaccinated_with_two_boosters": "फाइजर-बायोएनटेक या मॉडर्न वैक्सीन की पूरी श्रृंखला या जॉनसन एंड जॉनसन के जेनसेन वैक्सीन की खुराक के बाद दूसरी बूस्टर खुराक प्राप्त की।"
    },
    "label": {
      "fully_vaccinated": "पूरी तरह से टीका लगाया गया",
      "partially_vaccinated": "आंशिक रूप से टीकाकरण हुआ",
      "not_vaccinated": "टीकाकरण नहीं हुआ",
      "fully_vaccinated_with_one_booster": "पूरी तरह से टीका लगाया गया + बूस्टर",
      "fully_vaccinated_with_two_boosters": "पूरी तरह से टीकाकरण हुआ + 2 बूस्टर",
      "prefer_not_to_answer": "उत्तर देना नहीं चाहते"
    },
    "progress_bar_title": "COVID-19 टीकाकरण इतिहास",
    "upload_record_card": "अपना COVID-19 टीकाकरण रिकॉर्ड कार्ड अपलोड करें",
    "show_example": "उदाहरण दिखाएं",
    "hide_example": "उदाहरण छुपाएं",
    "take_photo": "फोटो लेने के लिए क्लिक करें",
    "or": "या",
    "upload_image_or_pdf_instead": "इसके बजाय छवि/पीडीएफ अपलोड करें",
    "select_doses_received": "आपको प्राप्त हुईं सभी COVID-19 टीकाकरण खुराकों का चयन करें।",
    "first_dose": "पहली खुराक",
    "second_dose": "दूसरी खुराक",
    "first_booster_dose": "पहली बूस्टर खुराक",
    "second_booster_dose": "दूसरी बूस्टर खुराक",
    "additional_dose": "अतिरिक्त खुराक",
    "first_dose_manufacturer": "आपकी पहली खुराक के लिए COVID-19 वैक्सीन निर्माता कौन था?",
    "first_dose_date": "आपकी पहली खुराक की तारीख क्या थी?",
    "second_dose_manufacturer": "आपकी दूसरी खुराक के लिए COVID-19 वैक्सीन निर्माता कौन था?",
    "second_dose_date": "आपकी दूसरी खुराक की तारीख क्या थी?",
    "first_booster_dose_manufacturer": "आपकी पहली बूस्टर खुराक के लिए COVID-19 वैक्सीन निर्माता कौन था?",
    "first_booster_dose_date": "आपकी पहली बूस्टर खुराक की तारीख क्या थी?",
    "second_booster_dose_manufacturer": "आपकी दूसरी बूस्टर खुराक के लिए COVID-19 वैक्सीन निर्माता कौन था?",
    "second_booster_dose_date": "आपकी दूसरी बूस्टर खुराक की तारीख क्या थी?",
    "additional_dose_manufacturer": "आपकी अतिरिक्त खुराक के लिए COVID-19 वैक्सीन निर्माता कौन था?",
    "additional_dose_date": "आपकी अतिरिक्त खुराक की तारीख क्या थी?",
    "completed_one": "पूरा हो गया है",
    "completed_two": "अपने टीकाकरण की स्थिति को अद्यतन करने के लिए धन्यवाद!",
    "progress_bar_complete": "पूर्ण करें",
    "upload_image": "तस्वीर अपलोड करें",
    "retake_photo": "तस्वीर दोबारा खींचें",
    "other": "अन्य",
    "remove_first_dose": "पहली खुराक का इतिहास हटाएं",
    "remove_second_dose": "दूसरी खुराक का इतिहास हटाएं",
    "remove_first_booster_dose": "पहली बूस्टर खुराक का इतिहास हटाएं",
    "remove_second_booster_dose": "दूसरी बूस्टर खुराक का इतिहास हटाएं",
    "remove_additional_dose": "अतिरिक्त खुराक का इतिहास हटाएं",
    "exemption": "क्या आपको छूट है?",
    "exempt": "मुझे छूट है",
    "not_exempt": "मुझे छूट नहीं है",
    "enter_exemption": "छूट दर्ज करें",
    "upload_exemption_documentation": "अपनी छूट के लिए दस्तावेज़ अपलोड करें",
    "remove_dose": "खुराक हटाएं",
    "continue": "जारी रखें",
    "enter_credentials": "कृपया अपने क्रेडेंशियल दर्ज करें",
    "incorrect_credentials": "गलत क्रेडेंशियल, कृपया फिर से कोशिश करें।",
    "add_photo": "तस्वीर जोड़ें"
  },
  "mailers": {
    "appointment_mailer": {
      "payment_pending": "हेलो {{name}} , हमने ये गौर किया है कि आपने {{org_name}} के साथ अपने {{group_name}} अपॉइंटमेंट का समय सुनिश्चित नहीं किया है। अपने अपॉइंटमेंट को मान्य रखने के लिए, आपको राशि भुगतान करने की प्रक्रिया पूरी करनी होगी। कृपया इस लिंक को क्लिक कर प्रक्रिया पूरी कीजिए {{complete_url}} अगर आप अपना अपॉइंटमेंट ख़ारिज करना चाहते हैं तो कृपया इस लिंक को क्लिक कीजिए {{cancel_url}}। धन्यवाद।"
    }
  },
  "otc": {
    "record_results_for_abbott": "बाईनैक्सनाउ जाँच लेकर अपने परिणामों को दर्ज कीजिए",
    "record_results": "जाँच लेकर अपने परिणामों को दर्ज कीजिए",
    "activate_a_kit": "किट को चालू कीजिए",
    "activate_a_kit_for_test": "किट को {{test}} के लिए चालू कीजिए",
    "todays_test_process": "आज के जाँच की प्रक्रिया",
    "date_of_birth_question": "{{first_name}}'s की जन्मतिथि क्या है?",
    "confirm_dob_to_continue": "जारी रखने के लिए भागीदार की जन्मतिथि की पुष्टि कीजिए",
    "incorrect_dob": "गलत जन्मतिथि",
    "file_too_large": "कृपया ये सुनिश्चित कर लें कि अपलोड किया गया फाइल {{size}} से छोटा है।",
    "enter_valid_date_in_last_x_days": "पिछले {{n}} दिनों में एक मान्य तिथि दर्ज कीजिए",
    "barcode_format_not_valid": "यह बारकोड फार्मेट अमान्य है।",
    "complete_button": "पूरा हुआ",
    "next_button": "आगे",
    "back_button": "पीछे",
    "report_a_test": "जाँच को रिपोर्ट कीजिए",
    "x_%_completed": "{{n}}%पूर्ण",
    "answer_survey": "उत्तर सर्वेक्षण के प्रश्न",
    "steps": {
      "answer_survey": "उत्तर सर्वेक्षण",
      "review_instructions": "निर्देशों को पुनः देखें",
      "scan_code": "कोड स्कैन कीजिए",
      "enter_time": "समय दर्ज कीजिए",
      "enter_result": "परिणाम दर्ज कीजिए",
      "take_photo": "तस्वीर लीजिए",
      "confirm_result": "परिणाम की पुष्टि कीजिए",
      "selest_test_kit": "परिणाम का प्रकार चुनें",
      "enter_date": "तिथि दर्ज कीजिए",
      "enter_date_and_time": "तिथि और समय दर्ज कीजिए"
    },
    "review_instructions": "जाँच निर्देशों को पुनः देखें",
    "follow_instructions_or_below": "अपने जाँच के साथ आए निर्देशों का पालन कीजिए या नीचे दिए गये निर्देशों को पुनः देखिए",
    "watch_video": "वीडियो देखिए",
    "view_instructions": "निर्देशों को देखिए",
    "follow_instructions": "अपने जाँच के साथ आए निर्देशों का पालन कीजिए",
    "scan_code": "जाँच क्यू आर कोड स्कैन कीजिए",
    "qr_code_description": "आपके जाँच का एक खास क्यू आर कोड है जोकि आपके जाँच के ऊपर के दाहिने कोने पे मौजूद है।",
    "show_example": "उदाहरण दिखाएं",
    "hide_example": "उदाहरण छिपाएं",
    "barcode_scanned": "बारकोड को सफलतापूर्वक स्कैन कर लिया गया है",
    "enter_barcode": "बारकोड दर्ज कीजिए",
    "scan_qr_code": "क्यू आर कोड स्कैन कीजिए",
    "which_test_did_you_take": "आपने कौन सा जाँच करवाया?",
    "when_did_you_take_test": "आपने यह जाँच कब करवाया?",
    "what_day_did_you_take_test": "आपने किस दिन यह जाँच लिया था?",
    "what_time_did_you_take_test": "आपने किस समय पर यह जाँच करवाया?",
    "time": {
      "today": "आज",
      "yesterday": "कल",
      "two_days_ago": "दो दिन पहले"
    },
    "enter_current_time": "मौजूदा समय दर्ज कीजिए",
    "enter_result": "जाँच का परिणाम दर्ज कीजिए",
    "choose_result_option": "उस विकल्प को चुनें जो आपके जाँच कार्ड के परिणाम का वर्णन करता है",
    "clarify_results": "अपने परिणामों को स्पष्ट कीजिए",
    "take_result_photo": "परिणामों की तस्वीर लीजिए",
    "take_result_photo_description": "अपना परिणाम दर्ज करने के लिए पूर्ण कर लिए गये परिणाम कार्ड की तस्वीर लीजिए ",
    "my_result": "मेरा परिणाम",
    "switch_camera": "कैमरा स्विच कीजिए",
    "confirm_result": "परिणाम की पुष्टि कीजिए",
    "name": "नाम:",
    "date": "तिथि",
    "result": "परिणाम",
    "results": "परिणामों",
    "test_submitted": "धन्यवाद, {{first_name}}! आपका {{test}} सफलतापूर्वक जमा हो गया है।",
    "dob": "जन्मतिथि",
    "administered": "दिया जा चुका है:",
    "or": "या",
    "upload": "अपलोड कीजिए",
    "change_uploaded_file": "अपलोड किए गये फ़ाइल को बदलें",
    "take_photo": "तस्वीर लीजिए",
    "confirm_information": "सूचना की पुष्टि कीजिए",
    "barcode": "बारकोड",
    "scan_test_barcode": "जाँच बारकोड स्कैन कीजिए",
    "barcode_description": "आपके जांच का एक खास बारकोड है जो आपके जाँच के साथ पाया जा सकता है।",
    "enter_date_in_last_30_days": "पिछले तीस दिन में से एक तिथि और जाँच करवाने का एक अनुमानित समय दर्ज कीजिए",
    "add_image": "तस्वीर लगाईए",
    "change_image": "तस्वीर बदलें",
    "skip_barcode_reader": "कोड स्कैन नहीं कर पा रहे हैं? कोई बात नहीं, जाँच परिणाम को रिपोर्ट करने के लिए नेक्स्ट को क्लिक कीजिए।",
    "enter_date_and_time": "अपने जाँच की तिथि और समय दर्ज कीजिए",
    "enter_date": "जाँच की तिथि दर्ज कीजिए",
    "did_you_take_test_today": "क्या आपने आज परीक्षा दी?",
    "record_results_for_generic": "{{name}} परीक्षण के लिए अपने परिणामों का परीक्षण करें और रिकॉर्ड करें",
    "choose_result_option_custom": "वह विकल्प चुनें जो आपके {{test_name}} परिणाम का वर्णन करता हो:"
  },
  "yes": "हाँ",
  "no": "नहीं",
  "event_token": {
    "title": "अपने जाँच किट को प्राप्त करने के लिए वेंडिंग मशीन में नीचे कोड दर्ज कीजिए।",
    "loading_text": "लोड हो रहा है, कृपया 1 मिनट बाद पुनः प्रयास करें"
  },
  "appointment_recovery": {
    "complete_title": "आप पूरी तरह से तैयार हैं",
    "subtitle_1": "अगर आप आईफोन इस्तेमाल कर रहें हैं तो डैशबोर्ड पर वापस आने के लिए अपने स्क्रीन के ऊपरी बाएँ कोने में **डन** को दबाइए।",
    "subtitle_2": "अगर आप एंड्रॉयड इस्तेमाल कर रहें हैं तो अपने स्क्रीन के ऊपरी बाएँ कोने में **X** को दबाइए।",
    "subtitle_0": "जारी रखने के लिर ब्राउज़र को छोड़िए"
  },
  "primary": {
    "support_email": "support@primary.health"
  },
  "consented_relationship": "सहमति से संबंध",
  "finish_registration": {
    "title": "अपना रजिस्ट्रेशन पूरा करें",
    "subtitle": "कृपया अपनी अपॉइंटमेंट से पहले निम्नलिखित जानकारी को पूरा करें:",
    "button": "पंजीकरण समाप्त करें"
  },
  "arab_ethnicity": {
    "arab": "अरब",
    "non_arab": "गैर-अरब",
    "prefer_not_to_answer": "उत्तर देना नहीं चाहते",
    "question": "अरब जातीयता",
    "subtitle": "विनियमों के लिए आवश्यक है कि हम निम्नलिखित जानकारी एकत्र करें।",
    "help": "जातीयता लोगों के समूहों को अलग करने के लिए इस्तेमाल की जाने वाली भाषा, सांस्कृतिक रीति-रिवाजों, धर्म और अन्य विशेषताओं जैसे सामान्य लक्षणों के एक समूह को संदर्भित करती है। यह नस्लीय पहचान के साथ भी संरेखित हो भी सकता है और नहीं भी।"
  },
  "custom_comms_quick_add": {
    "mobile_app_links": {
      "email_text": "अपने डिवाइस के आधार पर, प्राथमिक स्वास्थ्य पास डाउनलोड करने के लिए नीचे दिए गए लिंक पर क्लिक करें।"
    }
  },
  "stepper": {
    "next": "अगला",
    "back": "वापस जाएं",
    "complete": "पूर्ण करें"
  },
  "registration_direcory": {
    "keyword_search_label": "कीवर्ड, शहर, ज़िप कोड, संगठन का नाम",
    "keyword_search_label_mobile": "शहर, ज़िप कोड, संगठन का नाम",
    "zero_results": {
      "title": "हमने उस खोज शब्द के साथ कोई प्रदाता नहीं पाया।",
      "sub_title": "अपने प्रदाता को खोजने का प्रयास करें:",
      "p1": "ज़िप कोड",
      "p2": "शहर या कस्बे का नाम",
      "p3": "काउंटी का नाम",
      "p4": "प्रदाता या संगठन का नाम"
    }
  },
  "registration_flyer": {
    "open_camera": "अपने डिवाइस के कैमरा ऐप को खोलिए",
    "point_to_qr_code": "क्यू आर कोड पर जाइए और लिंक को टैप कीजिए",
    "complete_registration": "रजिस्ट्रेशन पूरा कीजिए",
    "need_help": "सहायता चाहिए? यहाँ सम्पर्क कीजिए"
  },
  "vaccine_info_sheets": {
    "vaccine_info_attestation": "वैक्सीन सूचना सत्यापन",
    "select_checkbox": "कृपया निम्नलिखित की समीक्षा करें और आगे बढ़ने से पहले नीचे उपयुक्त चेकबॉक्स का चयन करें।",
    "opportunity_to_read": "मुझे प्रशासित किए जाने वाले टीके के लिए प्रदान किए गए वैक्सीन सूचना विवरण (\"वीआईएस\") या आपातकालीन उपयोग प्राधिकरण (\"ईयूए\") तथ्य पत्रक को पढ़ने का अवसर दिया गया है या मैंने मुझे पढ़ा है। :",
    "vaccine_info_sheet": "टीका सूचना पत्रक",
    "vaccine_fact_sheet": "वैक्सीन फैक्ट शीट",
    "vaccine_info_statement": "टीका सूचना बयान"
  },
  "exemption": {
    "dtap_tdap": "DTaP/TDap",
    "mmr": "एम.एम.आर",
    "ipv_opv": "पोलियो",
    "hib": "एच.आई.बी.",
    "hep_b": "एच.ई.पी.बी",
    "var": "वेरिसेला",
    "hep_a": "एच.ई.पी.ए",
    "pcv": "पी.सी.वी",
    "mcv": "एम.सी.वी"
  },
  "test_strip": {
    "A": "इस किट में क्लैमाइडिया और गोनोरिया के परीक्षण शामिल हैं।",
    "B": "इस किट में क्लैमाइडिया और गोनोरिया के परीक्षण शामिल हैं।",
    "C": "इस किट में सिफलिस के परीक्षण शामिल हैं।",
    "D": "इस किट में एचआईवी, सिफलिस, क्लैमाइडिया और गोनोरिया के परीक्षण शामिल हैं।",
    "E": "इस किट में एचआईवी और सिफलिस के परीक्षण शामिल हैं।",
    "box_type": "बॉक्स {{type}}",
    "administered_text": "जब आपकी किट प्रयोगशाला में पहुंच जाएगी और जब आपके परिणाम तैयार हो जाएंगे तो हम आपसे संपर्क करेंगे।",
    "activate_kit": "मेरी परीक्षण किट सक्रिय करें",
    "register_another_test": "एक और परीक्षण पंजीकृत करें",
    "credentials": {
      "title": "आएँ शुरू करें",
      "label": "संपर्क",
      "p1": "हम आपके बारे में कुछ जानकारी के साथ शुरुआत करेंगे।",
      "location_step": {
        "p1": "आपकी परीक्षण किट किसने प्रदान की?",
        "load": "और लोड करें"
      }
    },
    "checklist": {
      "not_urinated": "आपने पिछले एक घंटे में पेशाब नहीं किया है.",
      "bathroom": "मूत्र एकत्र करने के लिए आपके पास बाथरूम तक पहुंच है।",
      "anal_swab": "गुदा स्वैब करने के लिए आपके पास एक निजी स्थान तक पहुंच है।",
      "blood_extraction": "रक्त निकालने के लिए आपके पास स्वच्छ वातावरण तक पहुंच है।",
      "washed_hands": "आपने अपने हाथ साबुन और गर्म पानी से 30 सेकंड तक धोए हैं।",
      "title": "क्या आप नमूना संग्रह शुरू करने के लिए तैयार हैं?",
      "no_alcohol": "संग्रह के दिन शराब न पियें।",
      "menstruation": "मासिक धर्म के दौरान या संभोग के 24 घंटों के भीतर योनि स्वाब का नमूना एकत्र न करें।"
    },
    "confirm": {
      "title": "Now, begin collecting your sample",
      "p1": "Is this kit information correct?"
    },
    "display_name": {
      "A": "क्लैमाइडिया और गोनोरिया (एकल साइट)",
      "B": "क्लैमाइडिया और गोनोरिया (3 साइट)",
      "C": "उपदंश",
      "shortened": {
        "A": "क्लैमाइडिया और गोनोरिया",
        "B": "क्लैमाइडिया और गोनोरिया",
        "C": "उपदंश"
      },
      "CC": "COVID-19",
      "CE": "सीलिएक रोग स्क्रीनिंग",
      "CR": "क्रिएटिनिन टेस्ट",
      "D": "4 पैनल परीक्षण - एचआईवी, सिफलिस, क्लैमाइडिया और गोनोरिया (एकल साइट)",
      "DD": "मधुमेह स्क्रीनिंग (HbA1c)",
      "E": "एचआईवी और सिफलिस",
      "H": "एचपीवी",
      "P": "Hepatitis C",
      "HS": "हर्पीज सिंप्लेक्स",
      "I": "HIV",
      "TT": "टेस्टोस्टेरोन स्तर",
      "VD": "विटामिन डी",
      "VS": "वायरल निगरानी",
      "AP": "Chlamydia & Gonorrhea",
      "NN": "COVID-19",
      "HH": "COVID-19, Flu A/B, & RSV",
      "FFFWW": "COVID-19 & Flu A/B",
      "YYYWW": "COVID-19, Flu A/B & RSV",
      "HA": "COVID-19, Flu A/B, & RSV"
    },
    "rescan": "पुन: स्कैन",
    "scanned": "आपने बॉक्स {{type}} स्कैन कर लिया है",
    "not_yet": "अभी तक नहीं",
    "success_registration": "पंजीकरण सफलता!",
    "no_box": "कोई बक्सा नहीं है?",
    "faq_link": "अक्सर पूछे जाने वाले प्रश्न पर जाएँ",
    "welcome": "स्वागत!",
    "scan": {
      "title": "You're one step closer to a healthier you. Scan or enter the **kit bar code** on the side of your box.",
      "p1": "Please begin when you are ready to begin sample collection. Otherwise, return to this page when you are ready.",
      "unknown_barcode": "You’ve entered an unknown barcode. Please contact support for assistance."
    },
    "appointments": {
      "title": "रोगी एवं प्रदाता का चयन करें",
      "p1": "हमें आपकी संपर्क जानकारी से संबद्ध एक से अधिक प्रोफ़ाइल मिलीं। सही प्रोफ़ाइल चुनें:",
      "new": "नया रोगी या प्रदाता"
    },
    "verify": {
      "title": "Confirm your identity",
      "p1": "हमें आपके मरीज़ का रिकॉर्ड मिल गया।"
    },
    "section_1": "किट सामग्री की पुष्टि करें",
    "section_2": "परीक्षण संग्रह",
    "section_3": "पैकेजिंग",
    "section_4": "Return kit",
    "box_a": "बॉक्स ए",
    "box_b": "बॉक्स बी",
    "box_c": "बॉक्स सी",
    "kit_flow": {
      "button_1": "पैकेजिंग जारी रखें",
      "button_2": "मैं तैयार हूं",
      "button_3": "मेरी किट पूरी और पैक हो गई है",
      "button_4": "मैं समाप्त कर रहा हूँ",
      "button_5": "अगला परीक्षण",
      "button_6": "निर्देश छोड़ें"
    },
    "contents": {
      "header": "बहुत बढ़िया! आइए सुनिश्चित करें कि आपकी किट पूरी है",
      "text_1": "Does your test kit box contain the following?",
      "blood_collection_card": "रक्त संग्रह कार्ड",
      "blood_collection_card_subtitle": "(परीक्षण किट के आधार पर 1 या 2)",
      "alchohol_pads": "2 अल्कोहल पैड",
      "lancets": "एकल उपयोग लैंसेट",
      "up_to_other": "(परीक्षण किट के आधार पर 4 तक)",
      "adhesive_bandages": "चिपकाने वाली पट्टियां",
      "pipette": "विंदुक",
      "urine_tube": "मूत्र संग्रह ट्यूब",
      "urine_cup": "मूत्र संग्रह कप",
      "oral_swab": "मौखिक स्वाब",
      "oral_tube": "मौखिक संग्रह ट्यूब",
      "anal_swab": "गुदा स्वाब",
      "anal_tube": "गुदा संग्रह ट्यूब",
      "vaginal_tube": "1 योनि संग्रह ट्यूब",
      "vaginal_swab": "1 योनि स्वाब",
      "biodegradable_paper": "बायोडिग्रेडेबल संग्रह कागज",
      "bottle_and_probe": "नमूना बोतल और जांच",
      "prepaid_envelope": "प्री-पेड शिपिंग लिफाफा",
      "biohazard_subtitle": "(अवशोषक पैड के साथ)",
      "biohazard_bag": "1 नमूना बैग",
      "biohazard_bag_plural": "({{count}}) बायोहाज़र्ड बैग",
      "alcohol_pads_plural": "{{count}} अल्कोहल पैड",
      "sterile_gauze_pad": "1 बाँझ धुंध पैड",
      "collection_tube": "1 collection tube",
      "collection_swabs": "2 collection swabs"
    },
    "packaging": {
      "title": "पैकेजिंग चेकलिस्ट",
      "subtitle": "Before you ship or drop your kit at a kiosk, make sure that:"
    },
    "packaging_box_a": {
      "step_1": "You have packed all test kit contents according to the instructions",
      "step_2": "You have used all collection materials contained in the kit to collect the required specimen",
      "step_3": "Your **date-of-birth** is written on the specimen collection tube using MM/DD/YYYY format",
      "step_4": "The **collection date** is written on the specimen collection tube using MM/DD/YYYY format",
      "step_5": "नमूना संग्रह ट्यूब को उसके बायोहाज़र्ड बैग में सील कर दिया गया है",
      "step_6": "सभी नमूनों को बॉक्स में रखा गया है",
      "step_7": "बॉक्स को प्री-पेड शिपिंग लिफाफे में रखा गया है",
      "step_8": "वापसी शिपिंग लिफाफा पूरी तरह से सील है"
    },
    "packaging_box_b": {
      "step_1": "आपने सभी परीक्षण किट सामग्री पैक कर ली है, भले ही आपने परीक्षण छोड़ दिया हो",
      "step_2": "आपने आवश्यक नमूने एकत्र करने के लिए किट में मौजूद सभी संग्रह सामग्रियों का उपयोग किया है",
      "step_3": "आपकी **जन्मतिथि** सभी नमूना संग्रह ट्यूबों पर MM/DD/YYYY प्रारूप का उपयोग करके लिखी गई है",
      "step_4": "**संग्रह तिथि** सभी नमूना संग्रह ट्यूबों पर MM/DD/YYYY प्रारूप का उपयोग करके लिखी गई है",
      "step_5": "प्रत्येक नमूना संग्रह ट्यूब को उसके स्वयं के बायोहाज़र्ड बैग में सील कर दिया जाता है (प्रति बैग केवल **एक** नमूना)",
      "step_6": "सभी नमूनों को बॉक्स में रखा गया है",
      "step_7": "बॉक्स को प्री-पेड शिपिंग लिफाफे में रखा गया है",
      "step_8": "वापसी शिपिंग लिफाफा पूरी तरह से सील है"
    },
    "packaging_box_c": {
      "step_1": "आपने प्रयुक्त लैंसेट सहित सभी परीक्षण किट सामग्री पैक कर ली है।",
      "step_2": "आपकी **जन्मतिथि** रक्त संग्रहण कार्ड पर MM/DD/YYYY प्रारूप का उपयोग करके लिखी गई है",
      "step_3": "**संग्रह तिथि** रक्त संग्रह कार्ड पर MM/DD/YYYY प्रारूप का उपयोग करके लिखी जाती है",
      "step_4": "रक्त संग्रह कार्ड और सभी उपयोग किए गए लैंसेट को बायोहाज़र्ड बैग में रखा जाता है",
      "step_5": "बायोहाज़र्ड बैग को बॉक्स में रखा गया है",
      "step_6": "बॉक्स को प्री-पेड शिपिंग लिफाफे में रखा गया है",
      "step_7": "वापसी शिपिंग लिफाफा पूरी तरह से सील है"
    },
    "success": {
      "header_1": "बढ़िया",
      "text_1": "आप अपना पहला नमूना एकत्र करने के लिए तैयार हैं।",
      "header_2": "तुमने यह काम पूरा कर लिया",
      "text_2": "**अगला:** वापस भेजने के लिए अपनी किट पैक करें।",
      "header_3": "बहुत बढ़िया",
      "text_3": "आप बेहतर स्वास्थ्य की ओर एक कदम और करीब हैं।",
      "header_4": "अच्छे से हुआ",
      "text_4": "बस एक और और आपका काम हो गया!",
      "text_5": "You're ready to collect your sample."
    },
    "instructions": {
      "title": "निर्देश देखने के लिए एक परीक्षण चुनें:",
      "subtitle": "परीक्षण",
      "button": "निर्देश",
      "pdf": "पीडीएफ निर्देश"
    },
    "instructions_box_a": {
      "title": "मूत्र संग्रह",
      "subtitle": "इस परीक्षण के लिए आपको निम्नलिखित सामग्रियों की आवश्यकता होगी:",
      "step_1": "संग्रह से कम से कम 1 घंटे पहले तक पेशाब न करें। सुबह के पहले मूत्र का प्रयोग सर्वोत्तम है।",
      "step_2": "1/3 और 1/2 के बीच भरते हुए सीधे पीकैंटर या कलेक्शन कप में पेशाब करें।",
      "step_3": "पीकैंटर से डालें या पिपेट का उपयोग करके संग्रह कप से मूत्र को नारंगी रंग के लेबल से चिह्नित संग्रह ट्यूब में स्थानांतरित करें।",
      "step_4": "संग्रह ट्यूबों को तब तक भरें जब तक कि संयुक्त तरल निर्दिष्ट भरण रेखाओं के बीच न आ जाए।",
      "step_4_sublabel": "ट्यूब को अधिकतम भराव रेखा से अधिक न भरें अन्यथा नमूना अस्वीकार कर दिया जाएगा।",
      "step_5": "संग्रहण ट्यूब पर ढक्कन लगाएं और सुनिश्चित करें कि यह समान रूप से और कसकर बंद है।",
      "step_6": "निर्दिष्ट क्षेत्र में ट्यूब पर अपनी जन्मतिथि और नमूना संग्रह तिथि (आज की तारीख) को MM/DD/YYYY प्रारूप में लिखें।",
      "step_7": "नमूने को अवशोषक पैड के साथ एक खाली बायोहाज़र्ड बैग में रखें और सुनिश्चित करें कि सील पूरी तरह से बंद है।"
    },
    "instructions_box_b": {
      "test_1": {
        "title": "मूत्र संग्रह",
        "subtitle": "इस परीक्षण के लिए आपको निम्नलिखित सामग्रियों की आवश्यकता होगी:",
        "step_1": "संग्रह से कम से कम 1 घंटे पहले तक पेशाब न करें। सुबह के पहले मूत्र का प्रयोग सर्वोत्तम है।",
        "step_2": "1/3 और 1/2 के बीच भरते हुए सीधे पीकैंटर या कलेक्शन कप में पेशाब करें।",
        "step_3": "पीकैंटर से डालें या पिपेट का उपयोग करके संग्रह कप से मूत्र को नारंगी रंग के लेबल से चिह्नित संग्रह ट्यूब में स्थानांतरित करें।",
        "step_4": "संग्रह ट्यूबों को तब तक भरें जब तक कि संयुक्त तरल निर्दिष्ट भरण रेखाओं के बीच न आ जाए।",
        "step_4_subtitle": "ट्यूब को अधिकतम भराव रेखा से अधिक न भरें अन्यथा नमूना अस्वीकार कर दिया जाएगा।",
        "step_5": "संग्रहण ट्यूब पर ढक्कन लगाएं और सुनिश्चित करें कि यह समान रूप से और कसकर बंद है।",
        "step_6": "निर्दिष्ट क्षेत्र में ट्यूब पर अपनी जन्मतिथि और नमूना संग्रह तिथि (आज की तारीख) को MM/DD/YYYY प्रारूप में लिखें।",
        "step_7": "नमूने को अवशोषक पैड के साथ एक खाली बायोहाज़र्ड बैग में रखें और सुनिश्चित करें कि सील पूरी तरह से बंद है।"
      },
      "test_2": {
        "title": "मौखिक स्वाब",
        "subtitle": "इस परीक्षण के लिए आपको निम्नलिखित सामग्रियों की आवश्यकता होगी:",
        "step_1": "स्वैब को धुंधली स्कोर रेखा (यदि मौजूद हो) और स्वैब के कपास-शीर्ष भाग के बीच रखें।",
        "step_2": "स्वाब को अपने मुँह में डालें और स्वाब की नोक को अपने गले के पिछले हिस्से पर 10 सेकंड के लिए रगड़ें।",
        "step_3": "स्वैब को सावधानी से निकालें और नीले ORAL लेबल से चिह्नित संग्रह ट्यूब में रखें। संग्रहण ट्यूब के सामने झुककर स्कोर लाइन पर स्वाब को तोड़ें।",
        "step_4": "संग्रहण ट्यूब पर ढक्कन लगाएं और सुनिश्चित करें कि यह समान रूप से और कसकर बंद है।",
        "step_5": "निर्दिष्ट क्षेत्र में ट्यूब पर अपनी जन्मतिथि और नमूना संग्रह तिथि (आज की तारीख) को MM/DD/YYYY प्रारूप में लिखें।",
        "step_6": "नमूने को अवशोषक पैड के साथ एक खाली बायोहाज़र्ड बैग में रखें और सुनिश्चित करें कि सील पूरी तरह से बंद है।"
      },
      "test_3": {
        "title": "गुदा स्वाब",
        "subtitle": "इस परीक्षण के लिए आपको निम्नलिखित सामग्रियों की आवश्यकता होगी:",
        "step_1": "स्वैब को धुंधली स्कोर रेखा (यदि मौजूद हो) और स्वैब के कपास-शीर्ष भाग के बीच रखें।",
        "step_2": "स्वाब को गुदा नलिका में 3 - 5 सेमी (1 - 2\") डालें। किसी भी संभावित जीव को इकट्ठा करने के लिए स्वाब को 5-10 सेकंड के लिए धीरे से घुमाएं।",
        "step_3": "स्वैब को सावधानी से निकालें और हरे ANAL लेबल से चिह्नित संग्रह ट्यूब में रखें। संग्रहण ट्यूब के सामने झुककर स्कोर लाइन पर स्वाब को तोड़ें।",
        "step_4": "संग्रहण ट्यूब पर ढक्कन लगाएं और सुनिश्चित करें कि यह समान रूप से और कसकर बंद है।",
        "step_5": "निर्दिष्ट क्षेत्र में ट्यूब पर अपनी जन्मतिथि और नमूना संग्रह तिथि (आज की तारीख) को MM/DD/YYYY प्रारूप में लिखें।",
        "step_6": "नमूने को अवशोषक पैड के साथ एक खाली बायोहाज़र्ड बैग में रखें और सुनिश्चित करें कि सील पूरी तरह से बंद है।"
      }
    },
    "instructions_box_c": {
      "title": "रक्त संग्रह",
      "subtitle": "इस परीक्षण के लिए आपको निम्नलिखित सामग्रियों की आवश्यकता होगी:",
      "step_1": "निर्दिष्ट फ़ील्ड में अपना नाम, जन्म तिथि और संग्रहण तिथि लिखें। एमएम/डीडी/वाईवाईवाईवाई प्रारूप का प्रयोग करें।",
      "step_2": "रक्त संग्रह पेपर पर बने वृत्तों को उजागर करने के लिए ब्लड कार्ड फ्लैप खोलें। रक्त संग्रहण कागज को न छुएं।",
      "step_3": "हाथों को कम से कम 30 सेकंड तक गर्म पानी से धोएं, फिर अपनी उंगलियों में रक्त के प्रवाह को प्रोत्साहित करने के लिए 15 सेकंड तक हाथों को जोर-जोर से हिलाएं।",
      "step_4": "अल्कोहल पैड से उंगलियों को साफ करें। अपने गैर-प्रमुख हाथ की मध्यमा या अनामिका का उपयोग करना सबसे अच्छा है।",
      "step_5": "लैंसेट लें और टोपी को मोड़ें। छोटी नोक को अपनी उंगली की नोक में तब तक मजबूती से दबाएं, जब तक सुई एक क्लिक के साथ बाहर न निकल जाए। संग्रह के दौरान अपने हाथ को अपने हृदय के नीचे रखते हुए, रक्त प्रवाह को प्रोत्साहित करने के लिए आधार से सिरे तक उंगली की मालिश करें।",
      "step_5_subtitle": "लैंसेट एकल उपयोग हैं। निपटान के लिए सभी लैंसेट को आपके नमूने के साथ प्रयोगशाला में वापस करना होगा।",
      "step_6": "केंद्र से शुरू करते हुए, गोले को भरने के लिए 3-6 बूंदें लगाएं और संग्रह कागज में भिगो दें। कागज को अपनी उंगली से न छुएं क्योंकि इससे रक्त प्रवाह बाधित होगा। एक बार जब चक्र पूरा हो जाए, तो अगले चक्र पर जाएँ। खून का रेखाओं से आगे बढ़ना ठीक है, लेकिन खून के धब्बों को एक-दूसरे में फैलने न दें।",
      "step_6_subtitle": "एक बार चक्र पूरा हो जाने या सूख जाने पर उसमें अतिरिक्त रक्त न डालें। रक्त की \"लेयरिंग\" संग्रह को अमान्य कर देगी।",
      "step_7": "ब्लड कार्ड को बंद किए बिना, इसे एक सपाट सतह पर रखें और रक्त संग्रह पेपर को कमरे के तापमान पर कम से कम 30 मिनट तक हवा में सूखने दें। रक्त संग्रहण कागज़ को गर्म न करें, ब्लो ड्राई न करें, या सीधे सूर्य की रोशनी में न रखें। गर्मी नमूने को नुकसान पहुंचाएगी.",
      "step_7_subtitle": "रक्त संग्रहण कागज़ के पिछले भाग की जाँच करें। रक्त पूरी तरह से संतृप्त होना चाहिए और संग्रह पत्र के nप्रत्येक घेरे को भरना चाहिए।",
      "step_8": "जब रक्त संग्रह कागज सूख जाए तो फ्लैप लगाकर रक्त कार्ड को बंद कर दें। ब्लड कार्ड और लैंसेट को डेसिकेंट पैक के साथ बायोहाज़र्ड बैग में रखें। सुनिश्चित करें कि बायोहाज़र्ड बैग ठीक से सील किया गया है"
    },
    "test_tips": {
      "title": "उचित रक्त संग्रह के लिए युक्तियाँ",
      "subtitle": "अच्छे नतीजे के लिये:",
      "step_1": "सुनिश्चित करें कि संग्रह करते समय आप निर्जलित न हों। जलयोजन रक्त प्रवाह को बढ़ावा देता है।",
      "step_2": "धूम्रपान के तुरंत बाद संग्रह न करें।",
      "step_3": "गर्म पानी के नीचे अपने हाथों को धोने और गर्म करने से आपके हाथों में रक्त के प्रवाह को बढ़ावा देने में मदद मिलेगी।",
      "step_4": "अपनी उंगलियों में रक्त के प्रवाह को प्रोत्साहित करने के लिए हाथों को फर्श की ओर जोर-जोर से हिलाएं।",
      "step_5": "सर्वोत्तम रक्त प्रवाह के लिए संग्रहण के दौरान संग्रहण उपकरण और हाथों को अपने हृदय के नीचे रखें।",
      "step_6": "आपको एक से अधिक अंगुलियों की चुभन की आवश्यकता हो सकती है। प्रत्येक उंगली चुभन के बीच इन युक्तियों को दोहराएं।"
    },
    "shipping": {
      "header": "आपकी किट भेजने के लिए तैयार है!",
      "text": "**Congrats!** You finished your health screening."
    },
    "pick_up": "साइट स्टाफ से अपनी अनुशंसित परीक्षण किट लें और नीचे पंजीकरण करें",
    "short_display_name": {
      "A": "क्लैमाइडिया और गोनोरिया",
      "B": "क्लैमाइडिया और गोनोरिया",
      "C": "उपदंश",
      "CC": "कोलोरेक्टल कैंसर",
      "CE": "सीलिएक",
      "CR": "क्रिएटिनिन",
      "D": "एचआईवी, सिफलिस,",
      "DD": "क्लैमाइडिया और गोनोरिया",
      "E": "मधुमेह",
      "H": "एचपीवी",
      "P": "Hepatitis C",
      "HS": "हर्पीज सिंप्लेक्स",
      "I": "HIV",
      "TT": "टेस्टोस्टेरोन",
      "VD": "विटामिन डी",
      "VS": "वायरल निगरानी"
    },
    "test_kit": "परीक्षण का सामान",
    "view_results": "परिणाम दर्शन",
    "recommended_kit_title": "अनुशंसित परीक्षण किट",
    "instructions_box_h": {
      "title": "योनि स्वाब संग्रह",
      "subtitle": "संग्रह ट्यूबों में एक तरल परिरक्षक हो सकता है। <span class='text-danger'>**यदि संग्रह नलियों में तरल परिरक्षक मौजूद है तो उसे खाली न करें।**</span>",
      "step_1": "स्वैब को एक हाथ में धुंधली स्कोर लाइन (यदि मौजूद हो) और स्वैब के कपास-टिप वाले हिस्से के बीच पकड़ें और लेबिया (योनि के उद्घाटन के आसपास की त्वचा की तह) को अलग करें।",
      "step_2": "स्वाब को योनि के उद्घाटन में 5 सेमी (2 इंच) डालें। योनि की दीवारों पर स्वाब को रगड़ते हुए धीरे-धीरे 30 सेकंड के लिए स्वाब को घुमाएं।",
      "step_3": "स्वैब को सावधानी से निकालें और **लाल वैजाइनल लेबल** से चिह्नित कलेक्शन ट्यूब में रखें। संग्रहण ट्यूब के सामने झुककर स्वाब को तोड़ें।",
      "step_4": "संग्रहण ट्यूब पर ढक्कन लगाएं और सुनिश्चित करें कि यह समान रूप से और कसकर बंद है।",
      "step_5": "निर्दिष्ट क्षेत्र (डीओबी) में ट्यूब पर अपनी जन्मतिथि एमएम/डीडी/वाईवाईवाईवाई प्रारूप में लिखें।",
      "step_6": "निर्दिष्ट क्षेत्र में ट्यूब पर नमूना संग्रह तिथि (आज की तारीख) को MM/DD/YYYY प्रारूप में लिखें (कॉल दिनांक)।",
      "step_7": "नमूने को अवशोषक पैड के साथ खाली बायोहाज़र्ड बैग में रखें और सुनिश्चित करें कि सील पूरी तरह से बंद है।"
    },
    "packaging_box_h": {
      "step_3": "आपकी **जन्मतिथि** नमूना संग्रह ट्यूब पर MM/DD/YYYY प्रारूप का उपयोग करके लिखी गई है",
      "step_4": "आपकी **संग्रह तिथि** नमूना संग्रह ट्यूब पर MM/DD/YYYY प्रारूप का उपयोग करके लिखी गई है",
      "step_6": "बायोहाज़र्ड बैग को बॉक्स में रखा गया है"
    },
    "instructions_box_cc": {
      "title": "मल संग्रह",
      "step_1": "निर्दिष्ट क्षेत्र में ट्यूब पर अपनी जन्मतिथि और नमूना संग्रह तिथि (आज की तारीख) को MM/DD/YYYY प्रारूप में लिखें। हरी टोपी को घुमाकर और उठाकर खोलें।",
      "step_2": "आपूर्ति किए गए संग्रहण कागज़ को शौचालय के कटोरे में पानी के ऊपर रखें।",
      "step_3": "संग्रहण कागज के ऊपर मल का नमूना जमा करें।",
      "step_4": "इससे पहले कि कागज डूब जाए और मल का नमूना पानी को छू जाए, मल से नमूना एकत्र कर लें।",
      "step_5": "नमूना जांच से मल के नमूने की सतह को खुरचें।",
      "step_6": "नमूना जांच के खांचे वाले हिस्से को मल के नमूने से पूरी तरह ढक दें।",
      "step_7": "सैम्पल जांच डालकर और हरे रंग की टोपी को कसकर बंद करके सैम्पलिंग बोतल को बंद कर दें। पुनः मत खोलो",
      "step_8": "फ्लश. संग्रहण कागज बायोडिग्रेडेबल है और सेप्टिक सिस्टम को नुकसान नहीं पहुंचाएगा।",
      "step_9": "सैंपलिंग बोतल को अवशोषक पैड में लपेटें और बायोहाज़र्ड बैग में डालें।"
    },
    "contact_support": {
      "title": "आइए इसे ठीक करें",
      "text_1": "हमें यह सुनकर दुख हुआ कि कुछ गलत है!",
      "text_2": "कृपया हमसे संपर्क करके हमें बताएं कि क्या खराबी है और हम आपकी किट को बदलने में मदद करेंगे।"
    },
    "contact_support_success": {
      "title": "हमें आपका संदेश प्राप्त हुआ",
      "text_1": "हमसे संपर्क करने के लिए धन्यवाद।",
      "text_2": "हमारी सहायता टीम का एक सदस्य जल्द ही संपर्क करेगा।",
      "button_text": "होम पर लौटें"
    },
    "kit_in_transit_to_patient": "आपका आदेश मार्ग में है! अधिकांश ऑर्डर 2-5 व्यावसायिक दिनों के भीतर वितरित किए जाते हैं। एक बार जब आप अपनी किट प्राप्त कर लेंगे, तो हम आपको इसे सक्रिय करने के तरीके के बारे में अधिक जानकारी भेजेंगे और इसे वापस भेजेंगे। ट्रैकिंग लिंक: https://tools.usps.com/go/TrackConfirmAction_input?strOrigTrackNum={{tracking_number}}",
    "kit_received_at_lab": "लैब को आपकी किट प्राप्त हो गई! परिणाम तैयार होने पर आपको अपने रोगी पोर्टल की जाँच करने के लिए एक सूचना प्राप्त होगी",
    "registration_confirmation": "आपका पंजीकरण अब पूरा हो गया है! अपनी परीक्षण किट सक्रिय करने के लिए अपने पंजीकरण पुष्टिकरण पृष्ठ पर जाएँ:",
    "kit_ordered_online": "हमें आपका किट ऑर्डर प्राप्त हो गया है और इसके शिप होने पर हम अपडेट भेजेंगे! ऑर्डर #: %{ऑर्डर_नंबर} ऑर्डर की तारीख: %{ऑर्डर_डेट}",
    "kit_ordered_online_email": {
      "header": "हमें आपका ऑर्डर प्राप्त हुआ!",
      "p1": "हमें आपका आदेश प्राप्त हो गया है! एक बार आपका ऑर्डर शिप हो जाने पर, हम आपको एक और अपडेट भेजेंगे।",
      "order_number": "ऑर्डर #",
      "order_date": "आर्डर की तारीख"
    },
    "completed": "पूरा हुआ ",
    "I": "इस किट में एचआईवी के परीक्षण शामिल हैं।",
    "CC": "this kit includes tests for COVID-19.",
    "CE": "इस किट में सीलिएक रोग के परीक्षण शामिल हैं।",
    "CR": "इस किट में क्रिएटिनिन के परीक्षण शामिल हैं।",
    "DD": "इस किट में मधुमेह के परीक्षण शामिल हैं।",
    "H": "इस किट में एचपीवी के परीक्षण शामिल हैं।",
    "P": "this kit includes tests for hepatitis C.",
    "HH": "this kit includes tests for COVID-19, Flu A/B, and RSV.",
    "HS": "इस किट में HSV-2 के परीक्षण शामिल हैं।",
    "TT": "इस किट में टेस्टोस्टेरोन के परीक्षण शामिल हैं।",
    "VD": "इस किट में विटामिन डी के परीक्षण शामिल हैं।",
    "nasal_collection": {
      "title": "Nasal collection",
      "subtitle": "For this test you will need the following materials:",
      "step_1": "Write your Date of Birth and the Collection Date on your collection tube. Use MM/DD/YYYY format for the dates (i.e., 08/29/2023).",
      "step_2": "Wash your hands with soap and water thoroughly before proceeding and after completeing all steps.",
      "step_3": "Remove the swab from the wrapper with your clean hands. Hold the swab with the white tip up. Do not touch the tip of the swab to anything.",
      "step_4": "Holding the swab with one hand, gently insert the white tip of the swab about 1/2 to 3/4 inces (1 to 2 cm) into the opening of your nose. Rotate the swab for 15 to 30 seconds. Make sure the swab touches the insides of your nose. Remove the swab from your nose being careful not to touch your skin. Do not set the swab down. Repeat in the other nostril.",
      "step_5": "While still holding the swab, unscrew and remove the cap from the transport tube without setting the cap down. Place the swab into the tube with the white tip down, taking great care not to spill or splash the liquid in the collection tube.",
      "step_6": "Place lid onto the collection tube and ensure it is closed evenly and tightly.",
      "step_7": "Place specimen into the empty biohazard bag with the absobent pad and ensure the seal is closed completely. Return the biohazard bag to the kiosk drop box."
    },
    "NN": "this kit includes tests for COVID-19.",
    "YYYWW": "this kit includes tests for COVID-19, Flu A/B, and RSV.",
    "FFFWW": "this kit includes tests for COVID-19 and Flu A/B.",
    "HA": "this kit includes tests for COVID-19, Flu A/B, and RSV.",
    "drop_off": {
      "header": "Your kit is ready to drop off!"
    }
  },
  "copy_link": "पेज लिंक कॉपी करें",
  "copied_link": "लिंक कॉपी किया गया!",
  "vaccines": {
    "descriptions": {
      "vaccine_flu": "सामान्य फ्लू वायरस से सुरक्षा",
      "vaccine_covid": "COVID-19 वायरस से सुरक्षा",
      "tdap": "टीडीएपी टीका टिटनेस को रोक सकता है",
      "polio": "पोलियो वायरस से सुरक्षा. स्कूल शुरू करने से पहले बच्चों के लिए आवश्यक।",
      "varicella": "टीका जो चिकनपॉक्स से बचाता है",
      "shingles": "टीका जो दाद से बचाता है। न्यूनतम आयु 18 वर्ष है.",
      "human_papillomavirus": "टीका जो एचपीवी से बचाता है। 11 वर्ष के बच्चों के लिए अनुशंसित.",
      "meningococcal": "टीका चार प्रकार के मेनिंगोकोकल बैक्टीरिया से बचाता है।"
    }
  },
  "viral_surveillance": {
    "shipping": {
      "title": "Prepare to ship your sample:",
      "step_1": {
        "label": "Put the biohazard bag into box.",
        "substep_1": "Remove the return shipping mailer from your test kit.",
        "substep_2": "Place the biohazard bag into the original collection kit box and close the box."
      },
      "step_2": {
        "label": "Put box into return shipping mailer.",
        "substep_1": "Place the box into the return shipping mailer and seal the mailer.",
        "substep_2": "Do not fold or cover the UN3373 diamond symbol."
      },
      "step_3": {
        "label": "Return to Fedex location.",
        "substep_1": "Return kit on the same day of collection (before the last pickup) to a Fedex location.",
        "substep_2": "Call for a FedEx pickup at 1.800.GoFedEx."
      }
    },
    "overview": {
      "title": "Welcome to the Viral Sequencing Program!",
      "subtitle": "Your participation helps provide valuable information on which respiratory pathogen variants are circulating in your area. Please follow the instructions that came with your kit to get started."
    },
    "instructions": {
      "title": "Collect your sample:",
      "step_1": "Wash your hands.",
      "step_2": "Remove the cap of the collection tube and open the swab package.",
      "step_2a": "Remove the swab from the package. Do not touch the soft end with your hands or anything else.",
      "step_3": "Collect a nasal swab.",
      "step_3a": "Insert the entire soft end of the swab into your nostril no more than 3/4 of an inch (1/5cm) into your nose.",
      "step_3b": "Slowly rotate the swab",
      "step_3c": "Gently remove the swab.",
      "step_3d": "Using the same swab, repeat in your other nostril with the same end of the swab.",
      "step_4": "Put swab into the collection tube and cap the tube securely.",
      "step_5": "Put the collection tube into the biohazard bag and seal.",
      "step_6": "Apply even pressure to the ziplock to seal the bag shut."
    },
    "click_to_get_started_button": "Click to get started",
    "get_started_button": "Get started",
    "thank_you": {
      "title": "Thank you for using Primary.Health!",
      "p_3": "You may now close the browser.",
      "p_2": "Please make sure to drop off the viral surveillance kit at your closest FedEx location.",
      "p_1": "Your participation actively helps Public Health officials fight the spread of disease. If you would like to see the impact you are making on Public Health, please take a moment to explore more via [this link]({{link}}). You will not be contacted about your results, as they are anonymous."
    },
    "verify_dob_title": "First, let's confirm your details",
    "verify_dob_subtitle": "This helps us ensure the correct person is using the test kit.",
    "activate_kit": "Activate a kit:",
    "steps_complete": "Steps complete",
    "opt_in": {
      "section_1_title": "How it works",
      "section_1_text_1": "Sign up below and receive a viral sequencing test kit in 1-3 business days.",
      "section_1_text_2": "Upon receiving the test kit",
      "section_1_text_3": "Send the sample back per the instructions in the kit using our prepaid label.",
      "sign_up": "Sign me up!",
      "no_to_participate": "No",
      "section_2_title": "What is the impact of your participation?",
      "section_2": {
        "card_1_title": "Rapid response to new variants",
        "card_1_text": "Genetic sequencing helps track the spread and emergence of new respiratory pathogen variants, allowing public health systems to quickly ramp up preparedness efforts.",
        "card_2_title": "Track how Respiratory Pathogens are changing",
        "card_2_text": "Respiratory pathogen sequencing analyzes the “genetic blueprint” of the virus. This helps scientists trace how the virus changes, enabling it to spread more easily, make people more sick",
        "card_3_title": "You have complete privacy",
        "card_3_text": "The sequencing will be processed anonymously. The lab will never contact you about your results"
      },
      "title": "You’re eligible for California’s Free Respiratory Pathogen Viral Sequencing Project!",
      "subtitle": "You can help public health officials protect communities against respiratory pathogens! If you opt in to participate you will receive a free viral sequencing kit that will test what respiratory pathogen strain you are infected with. Results will help officials track the spread of respiratory pathogen variants. See below for more information and to sign up.",
      "continue_to_result": "Continue to my result",
      "create_page_title": "Thank you!",
      "create_page_text_1": "Your kit should arrive in the next 1-3 business days.",
      "create_page_text_2": "Your participation actively helps Public Health officials fight the spread of diseases. You will be notified when your test kit order has been approved and when it has shipped."
    }
  },
  "family_registrations": {
    "find_clinic": "एक क्लिनिक खोजें",
    "select_pin": "विवरण देखने के लिए एक पिन चुनें",
    "miles_shorten": "{{miles}} मील",
    "next_available": "अगला उपलब्ध",
    "appointment_type": "अपॉइंटमेंट प्रकार",
    "individual_appointment": "व्यक्तिगत अपॉइंटमेंट",
    "family_appointment": "पारिवारिक अपॉइंटमेंट",
    "vaccines": "टीके",
    "what_kind_appointment": "आप किस प्रकार की अपॉइंटमेंट बुक करना चाहते हैं?",
    "select_family_or_individual": "यदि आप एक साथ परिवार के कई सदस्यों के लिए अपॉइंटमेंट का समय निर्धारित कर रहे हैं तो पारिवारिक अपॉइंटमेंट का चयन करें।",
    "vaccines_selected": "टीके चयनित",
    "where_care": "आप कहाँ देखभाल प्राप्त करना चाहते हैं?",
    "select_vaccines_for_individual": "अपनी अपॉइंटमेंट के लिए टीके चुनें (वैकल्पिक)।",
    "select_vaccines_for_family": "अपने पारिवारिक अपॉइंटमेंट के लिए टीके चुनें (वैकल्पिक)।",
    "schedule_services": "सेवायों के लिए शेड्यूल करे",
    "add_family": "परिवार के सदस्यों को जोड़ें और नीचे सेवाएँ चुनें।",
    "family_member": "परिवार के सदस्य {{number}}",
    "morning": "सुबह",
    "afternoon": "दोपहर",
    "slot_available": "{{number}} उपलब्ध है",
    "within_x_miles": "{{miles}} मील के भी",
    "any_distance": "से कोई भी दूरी",
    "partial_results": "आंशिक परिणाम",
    "partial_matches": "निम्नलिखित क्लीनिक उन सभी टीकों में से कुछ नहीं बल्कि कुछ की पेशकश करते हैं जिनकी आप तलाश कर रहे हैं",
    "no_matches": "उस खोज में कोई मिलान नहीं मिला। बेहतर परिणामों के लिए अपने फ़िल्टर बदलने का प्रयास करें।",
    "no_clinics_found": "ऐसा कोई क्लीनिक नहीं है जो आपकी खोज से मेल खाता हो।",
    "broaden_filters": "बेहतर परिणामों के लिए अपने फ़िल्टर को विस्तृत करने का प्रयास करें।",
    "unavailable_vaccines": "अनुपलब्ध टीके:",
    "available_vaccines": "उपलब्ध टीके:",
    "select_date": "एक तारीख चुनें",
    "available_appointments": "उपलब्ध नियुक्तियाँ/अपॉइंटमेंट",
    "appointment_scheduling_info": "आप अगले चरण में परिवार के विशिष्ट सदस्यों को नियुक्ति का समय निर्दिष्ट करने में सक्षम होंगे। यदि आप चाहें तो आप अलग-अलग दिनों में अपॉइंटमेंट शेड्यूल कर सकते हैं",
    "hold_selected_appointments": "हम आपकी चयनित नियुक्तियों को 15 मिनट के लिए रोकेंगे।",
    "appointments_selected": "नियुक्तियाँ/अपॉइंटमेंट चयनित",
    "no_appointments_selected": "कोई अपॉइंटमेंट चयनित/सिलेक्शन नहीं",
    "vaccines_needed": "टीकों की जरूरत है",
    "select_x_appointments": "%{संख्या} नियुक्तियों का चयन करें.",
    "more": "और दिखाएँ",
    "less": "कम दिखाएं",
    "register_next_family_member": "परिवार के अगले सदस्य का पंजीकरण करें",
    "successfully_registered_x_of_y_family_members": "आपने {{y}} परिवार के सदस्यों में से {{x}} को सफलतापूर्वक पंजीकृत किया है:",
    "next_family_member": "परिवार का अगला सदस्य",
    "appointments_abbreviated": "एपॉइंटमेंट्स",
    "register_for_clinic": "क्लिनिक के लिए पंजीकरण करें",
    "select_services": "सेवाएँ चुनें",
    "person": "व्यक्ति {{number}}",
    "add_person": "एक और व्यक्ति जोड़ें",
    "registration_confirmation": "पंजीकरण की पुष्टि"
  },
  "user_mailer": {
    "verify_email": "आइए आपका ईमेल सत्यापित करें!",
    "hi_full_name": "नमस्ते {{full_name}},",
    "verify_email_button": "ईमेल सत्यापित करें",
    "please_verify_email": "कृपया अपना ईमेल सत्यापित करने के लिए नीचे दिए गए बटन का उपयोग करें।"
  },
  "services": {
    "dptap_dt": "डीटीएपी/टीडीएपी/टीडी",
    "hepatitis_a": "हेपेटाइटिस ए",
    "hepatitis_b": "हेपेटाइटिस बी",
    "hepatitis_a_b": "हेपेटाइटिस ए और बी",
    "hib": "हिब",
    "mmr": "खसरा कण्ठमाला का रोग रूबेला",
    "meningococcal": "मेनिंगोकोक्सल",
    "mpox": "एमपॉक्स",
    "pneumococcal": "न्यूमोकोकल",
    "polio": "पोलियो",
    "rsv": "श्वसनतंत्र /सांस लेने संबंधी बहुकेंद्रकी वाइरस",
    "rotovirus": "रोटावायरस",
    "zoster": "ज़ोस्टर (दाद)",
    "tetanus_diptheria": "टेटनस और डिप्थीरिया",
    "tdap": "टीडीएपी",
    "typhoid": "आंत्र ज्वर या टाइफाइड",
    "varicella": "वैरिसेला (चिकनपॉक्स)",
    "covid-19": "कोविड-19",
    "covid-19_adult": "कोविड-19 (बालिग)",
    "covid-19_adult_description": "12 वर्ष या उससे अधिक उम्र के लोगों के लिए.",
    "covid-19_children": "कोविड-19 (बाल)",
    "covid-19_children_description": "4 - 11 वर्ष की आयु के बच्चों के लिए।",
    "covid-19_infants": "कोविड-19 (शिशु)",
    "covid-19_infants_description": "6 महीने से 3 साल के बच्चों के लिए।",
    "influenza": "इंफ्लुएंजा",
    "mmrv": "मिज़ल्स, मम्प्स, रूबेला और वेरिसेला"
  },
  "deep_archived": {
    "admin": "डेटा सुरक्षा उद्देश्यों के लिए, समूह {{test_group_name}} ({{slug}}) का डेटा आर्काइव किया गया है और इसे इस पृष्ठ से न तो देखा जा सकता है और न ही बदला जा सकता है। यदि आपको सहायता की आवश्यकता है, तो कृपया अपने खाता प्रबंधक से संपर्क करें या support@primary.health पर लिखें।",
    "participant": "यह पृष्ठ अब उपलब्ध नहीं है। कृपया स्वास्थ्य अभियान से जुड़े अपने संपर्कों से आगे जानकारी प्राप्त करें : {{test_group_name}}। यदि पुराने रिकॉर्ड देखने में कठिनाई हो तो सहायता के लिए support@primary.health पर संपर्क करें।"
  }
}